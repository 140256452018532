/*eslint-disable no-unused-vars*/
export const mutations = {
    SET_MESSAGE_PROVEEDOR(state,msj){
        state.messageProveedor = msj;
    },
    SET_COMMIT_PROVEEDOR(state, res){
        state.proveedorList = res;
    },
    SET_COMMIT_GET_PROVEEDORES(state, val){
        state.getProveedores = val;
    },
    SET_COMMIT_PROVEEDOR_ADD(state, element){
        state.registroProveedor.push(element);
    },
    SET_COMMIT_EDIT_PROVEEDOR(state, id){
        state.editIdProveedor = id;
    },
    SET_COMMIT_PROVEEDOR_DELETE(state, element){
        state.proveedorList.splice(element, 1);
    },
};