<template>
    <b-card header-tag="header" class="mt-1 card-mini" >
        <b-row >
            <b-col class="col-xs-2 col-sm-2 col-md-2">
                <b-card style="background-color: #ebebeb;">
                    <b-form-group id="codigo" label-for="codigo" class="mb-0">
                        <input  v-on:keyup.enter="clicBusquedaDirecta" @focus="$event.target.select()" v-model="search" required autocomplete="off" type="text" class="form-control"
                        placeholder="Código..." aria-label="PRODUCTOS" aria-describedby="basic-addon">
                        <b-row align-h="center" align-w="center" class="mt-2">
                            <Boton tooltip="Realizar la búsqueda" class="mr-1"
                            icon="search" fontscale="2" :onClic="clicBusquedaDirecta" variant="info" btn="btn-secundary" size="sm"/>

                            <Boton tooltip="Realizar búsqueda por código" class="ml-1"
                            icon="camera" fontscale="2" :onClic="showCamera" variant="info" btn="btn-default" size="sm"/>
                        </b-row>
                    </b-form-group>
                </b-card>
            </b-col>
            <b-col class="col-xs-2 col-sm-2 col-md-2">
                <b-row class="col-xs-12 col-sm-12">
                    <Boton text="Grabar" tooltip="Grabar" style="height: 7rem; width: 7rem;"
                    :disabled="ordencomprainventario.cajaCompra.length == 0"
                    icon="lock-fill" fontscale="4" :onClic="guardar" variant="success" btn="btn-secundary" size="md"/>
                </b-row>
            </b-col>
            <b-col class="col-xs-8 col-sm-8 col-md-8">
                <b-row class="row ml-3" align-h="end" align-w="end">
                    <div class="mr-3"
                        v-on:click="gotToCompra" v-b-tooltip.hover title="Realizar la compra">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/compras.png')" width="110" height="110"
                        ></b-img>
                    </div>
                    <div :class="isRoute('/ordencomprainventario/crear') ? 'mr-3 disabledbutton' : 'mr-3'"
                        v-on:click="goToIngreso" v-b-tooltip.hover title="Realizar ingreso">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/ingreso.png')" width="110" height="110"
                        ></b-img>
                        
                    </div>
                    <!-- <div :class="isRoute('/ordencompra/crear') ? 'mr-3 disabledbutton' : 'mr-3'"
                        v-on:click="goToPago" v-b-tooltip.hover title="Realizar pago">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/credito.png')" width="110" height="110"
                        ></b-img>
                    </div>
                    <div :class="isRoute('/ordencompra/crear') ? 'mr-3 disabledbutton' : 'mr-3'"
                        v-on:click="goToExistencias" v-b-tooltip.hover title="Realizar existencias">
                        <b-img class="mousehoverItem" 
                            :src="require('@/assets/botones/devolucion.png')" width="110" height="110"
                        ></b-img>
                    </div>
                    <div :class="isRoute('/ordencompra/crear') ? 'mr-3 disabledbutton' : 'mr-3'"
                        v-on:click="goToMovimientos" v-b-tooltip.hover title="Ver movimientos">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/cobros.png')" width="110" height="110"
                        ></b-img>
                    </div> -->
                    <div class="mr-3"
                        v-on:click="cancelar"
                        v-b-tooltip.hover title="Cancelar">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/cancelar.png')" width="110" height="110"
                        ></b-img>
                    </div>
                </b-row>
            </b-col>
        </b-row>
        <ModalCompra />
    </b-card>
</template>

<script>
    import router from '@/router'
    import Boton from '@/components/Boton'
    import ModalCompra from "./ModalCompra";
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
        },
        props:[ "clicProducto" ],
        components:{ Boton, ModalCompra },
        data(){
            return{ search:'', }
        },
        computed: {
            ...mapState([ "ordencomprainventario" ])
        },
        methods:{
            ...mapActions(["articuloClaveGet"]),
            clicBusquedaDirecta(){
                console.log("asdsddd: " , this.search);
                // this.articuloGetDirecto(this.search);
                this.articuloClaveGet(this.search);
                
                setTimeout(async ()=>{
                    this.search = "";
                },800)
            },
            guardar(){
                this.$bvModal.show('ModalCompra');
            },
            cancelar(){
                console.log("asdasdasdasd: ");
            },
            // dirige a pantalla de contado
            goToIngreso(){
                // router.push('/venta/crear/contado')
            },
            gotToCompra(){
                // router.push('/gotToCompra')
            },
            goToPago(){
                // router.push('/venta/crear')
            },
            goToExistencias(){
                // router.push('/devolucion/crear')
            },
            goToMovimientos(){
                // router.push('/cobro/crear')
            },
            isRoute(ruta){
                return router.history.current.path == ruta;
            }
        }
    }
</script>