import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        message: "",
        tipoVenta: 1, // venta de contado = 1, venta especial = 4
        clienteSelectedVar: { nombre_razon_social:"No Seleccionado", limite_credito: "0.00" }
        
    },
    mutations,
    actions,
};
