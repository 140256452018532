/*eslint-disable no-unused-vars*/
import {  categoria_list, cliente_list, pos_detail_create, pos_header_create  } from '@/api/venta'
//import venta from '.';
import router from '@/router'

export const actions = {
    
    categoriaList: async({ rootState, commit }) => categoria_list(rootState.auth.user.idUser).then(
        res=>{
            commit("SET_COMMIT_PRODUCTOS", res.data);
        }).catch(err=>console.log(err)
    ),
    clientesList: async({ rootState, commit }) => cliente_list(rootState.auth.user.idUser).then(
        res=>{
            // Borra item de PUBLICO EN GENERAL en pantalal de credito
            if(router.history.current.path == '/venta/crear' || router.history.current.path == '/cobro/crear'){
                let searchRes = res.data.filter(item=>item.id_cliente === 24)
                res.data.splice(res.data.indexOf(searchRes[0]), 1);
            }

            commit("SET_COMMIT_CLIENTES", res.data);
        }).catch(err=>console.log(err)
    ),

    detailCreate: async({commit}, venta) => pos_detail_create(venta).then(
        res=>{
            if(res.data.status>400) commit("SET_MESSAGE",res.data.message)
            else{
            // localStorage.setItem('aceromax_HASH',res.data.HASH)
            // localStorage.setItem('aceromax_USER',JSON.stringify({
                commit('SET_CREATE_VENTA',{
                idVenta: res.data.id_venta,
                idArticulo: res.data.id_articulo,
                idUnidad: res.data.id_unidad,
                Cantidad: res.data.cantidad,
                Precio: res.data.precio,
                Descuento: res.data.descuento,
                idSucursal: res.data.id_sucursal,
                Equivalencia: res.data.equivalencia,
                idUsuario: res.data.id_usuario
        
                })
                router.push('/venta')
            }
        }).catch(err=>console.log(err)),
        
        headerCreate: async({rootState, commit, dispatch}, venta) => pos_header_create(venta).then(
            res=>{
                if(res.data.status>400) commit("SET_MESSAGE",res.data.message)
                else{
                console.log(res.data);
                }
            }).catch(err=>console.log(err)),

        
};