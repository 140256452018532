<template>
    <b-card header-tag="header" class="mt-1 card-mini" style="height: 10.5rem; background-color: #E5E5E5; color: black;">
        <b-row>
                <b-col class="col-md-12" >
                    <h2>Devolución</h2>
                </b-col>
                 <b-col class="col-md-6">
                    <!-- <Autocomplete 
                    placeholder="Seleccione un cliente" 
                    cols="col-md-9"
                  
                label="Cliente:" filter="nombre_razon_social" /> -->
                    <b-input-group>
                        <b-input-group-append>
                            <Boton :onClic="buscar"  
                            tooltip="Buscar por folio" 
                            icon="search" fontscale="2" size="sm"/> 
                        </b-input-group-append>
                        <b-form-input @focus="$event.target.select()" v-model="search" 
                            required v-on:keyup.enter="buscar" autocomplete="off" 
                            type="text" 
                            class="form-control" 
                            placeholder="Ingrese el folio..." aria-label="PRODUCTOS" 
                            aria-describedby="basic-addon"></b-form-input>
                        </b-input-group> 
                </b-col>       
                <b-col class="col-md-6" style="padding-top: 32px;">
                    <b-input-group class="mt-6">
                            
                            
                </b-input-group>
                </b-col>
               
        </b-row>
<b-row>
  
</b-row>
    </b-card>
</template>

<script>
    import Boton from '@/components/Boton'
    //import Autocomplete from '@/components/Autocomplete'
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
            this.$store.commit("SET_COMMIT_LOAD_COMPRAS", true);
            this.$store.commit("SET_COMMIT_VENTADETAIL", []);
            this.clientesList();
        },
        watch: {
            "id_cliente": function (val) {
                if(val){
                    let cliente = this.getCliente(val);
                    // console.log("cliente: ", cliente);
                    // actualiza la variable clienteSelected
                    this.$store.commit("SET_COMMIT_CLIENTE_SELECTED", cliente)
                    this.clienteSelelctedVar = cliente;
                }
            },
            
        },
        components:{ Boton },
        props:[ ],
        data(){
            return{
                search:'', id_cliente:"", 
                value: 100,
                max: 100,
                clienteSelelctedVar: {
                    rfc: '',
                    limite_credito: '',
               },
           }
        },
        computed: mapState(["devolucion", "venta" ,"caja"]),
        methods:{
            ...mapActions(["clientesList", "ventaDetailList", "articuloGetDirectoDevolucion"]),
            // obtiene el cliente seleccionado
            getCliente(id){
                let searchRes = this.venta.clientesList.filter(item=>item.id_cliente === id)
                return searchRes[0]
            },
            buscar(){
                if (this.search != ""){
                    // console.log("asdsddd: " , this.search);
                    this.ventaDetailList(this.search)
                    this.articuloGetDirectoDevolucion(this.search)
                }
                // this.$store.commit("SET_COMMIT_VENTADETAIL", []);
                // this.$store.commit("SET_COMMIT_LOAD_COMPRAS", true);
            }
        }
    }
</script>