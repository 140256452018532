//*APIs seccion devolucion*/
import { HTTP } from "./http-common";
export const venta_detail_list = (id_usuario, id_venta)  => new Promise ((rv, rj) => {
    HTTP.get("venta-detail-list/?id_usuario=" + id_usuario + "&id_venta=" + id_venta).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const venta_header_get = (id_usuario, id_venta)  => new Promise ((rv, rj) => {
    HTTP.get("venta-header-get/?id_usuario=" + id_usuario + "&id_venta=" + id_venta).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const devolucion_mov_create = (data) => new Promise ((rv, rj) => {
    HTTP.post("devolucion-mov-create/", data).then(
        res=>{ rv(res) },
        err=>{ rj(err) })
});

export const articulo_list = (id_usuario, id_subcategoria) => new Promise ((rv, rj) => {
    HTTP.get("articulo-list/?id_usuario=" + id_usuario + "&id_categoria=" + id_subcategoria).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});

export const devolucion_detail_create = (data) => new Promise ((rv, rj) => {
    HTTP.post("devolucion-detail-create/", data).then(
        res=>{ rv(res) },
        err=>{ rj(err) })
});