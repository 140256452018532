import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        proveedor: false,
        messageProveedor: { mensaje:"", clase:""},
        //id_proveedor:0,
        getProveedores: {},
        editIdProveedor: 0,
        proveedorGet: [],
        proveedorList: []

    },
    mutations,
    actions,
};