<template>
  <b-modal id="ModalEditarCarrito" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="EDITAR" title-class="modal-title w-100" body-class="p-4" >
    <b-row>
        <b-col class="col-md-6" >
          <b-form-group id="input-group-1" label="NOMBRE:" label-class="form-label" label-for="input-1"  class="mb-0">
            <b-form-input @focus="$event.target.select()" disabled v-model="datosModal.nombre" ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="col-md-6" >
          <b-form-group id="input-group-1" label="CÓDIGO:" label-class="form-label" label-for="input-1"  class="mb-0">
            <b-form-input @focus="$event.target.select()" disabled v-model="datosModal.clave" ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col class="col-md-6" >
          <b-form-group id="input-group-1" label="UNIDAD:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-form-select disabled v-model="unidad_id" :options="caja.unidadesList"
              class="mb-3" value-field="id_unidad" text-field="unidad" disabled-field="notEnabled" ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col class="col-md-6" >
          <b-form-group id="input-group-1" label="IMPORTE:" label-class="form-label" label-for="input-1"  class="mb-0">
            <b-input-group disabled class="m-0" size="md" prepend="$">
              <b-form-input @focus="$event.target.select()" v-model="datosModal.importe" disabled ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- <b-col class="col-md-6" >
            <Autocomplete class="mr-1" placeholder="Selecciona una opción" cols="col-md-12"
            :search="false" :items="caja.precioVentaList" text="precio" v-model="datosModal.precio"
            assign="id" label="Precio:" filter="precio" />
        </b-col> -->
        
        
        <b-col class="col-md-6">
          <b-form-group id="input-group-1" label="CANTIDAD:" label-class="form-label" label-for="input-1"  class="mb-0">
            <b-input-group>
                <b-input-group-prepend>
                    <b-button @click="datosModal.cantidad > 1 ? datosModal.cantidad-- : datosModal.cantidad=1">-</b-button>
                </b-input-group-prepend>

                <b-form-input @focus="$event.target.select()"  v-model.number="datosModal.cantidad" max="10000" type="number" min="1"></b-form-input>

                <b-input-group-append>
                    <b-button @click="datosModal.cantidad++">+</b-button>
                </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col class="col-md-6">
          <b-form-group id="input-group-1" label="DESCUENTO:" label-class="form-label" label-for="input-1"  class="mb-0">
            <b-input-group>
                <b-input-group-prepend>
                    <b-button @click="datosModal.descuento > - returnfactor_pv() ? datosModal.descuento = datosModal.descuento-1 : datosModal.descuento=datosModal.descuento">-</b-button>
                </b-input-group-prepend>

                <b-form-input @focus="$event.target.select()"  v-model.number="datosModal.descuento" type="number" ></b-form-input>

                <b-input-group-append>
                    <b-button @click="datosModal.descuento < returnfactor_pv() ? datosModal.descuento = datosModal.descuento+1 : datosModal.descuento=datosModal.descuento">+</b-button>
                </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col class="col-md-6" >
          <b-form-group id="input-group-1" label="PRECIO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-form-select v-model="precio_id" :options="datosModal.datosPrecios"
              class="mb-3" value-field="id" text-field="precio" ></b-form-select>
          </b-form-group>
        </b-col>


    </b-row>

    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Guardar" class="mr-3"
        icon="lock-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-secundary" size="sm"/>
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  // import Autocomplete from '@/components/Autocomplete'
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  // import Autocomplete from '@/components/Autocomplete'
  export default {
    mounted(){
      this.$store.commit("SET_COMMIT_CARRITO_EDIT", 0);
    },
    components:{ Boton },
    data() {
      return {
        modalShow: false,
        datosModal:{
          nombre: "",
          clave:"",
          precio:"",
          importe:"",
          cantidad:"",
          precio_: "",
        },
        unidad_id: "",
        precio_id: "",
        loading: false,
      };
    },
    watch: {
      // es el onchangue de UNIDAD
      "unidad_id"(val){
          if(val){ this.precioVentaListEdit({id_articulo: this.caja.editIdCarrito, id_unidad:val}); }
      },
      // es el onchangue de precio
      "precio_id"(val){
          if(val){
            let ress = this.datosModal.datosPrecios.filter(function(item) {
                return item["id"] == val
            })
            
            this.datosModal.precio = ress[0]["precio"];
            this.actualziarr();
          }
      },
      "caja.editIdCarrito": function (val) {
        if(val >= 1){
          // para actualizar el unidad
          this.unidadList(val);
          this.datosModal = this.getItemCarrito(val);
          this.precioVentaListEdit({id_articulo: this.caja.editIdCarrito, id_unidad:this.datosModal.id_unidad});
          this.unidad_id = this.datosModal.id_unidad;
          this.precio_id = this.datosModal.datosArticulo.id;


          setTimeout(async ()=>{
            this.$bvModal.show('ModalEditarCarrito');
          },90)

          // let precio__ = this.getPrecio(this.datosModal.precio);
          // if(precio__){
          //   this.datosModal.precio_ = precio__;
          // }else{
          //   precio__ = this.getPrecio("Capturar");
          //   this.datosModal.precio_ = precio__;
          //   this.datosModal.precio_.precio = this.datosModal.precio;
          // }
          // console.log("datosModal: " , this.datosModal);
        }
      },
      "caja.precioVentaListEdit": function (val) {
        if(val != []){
          this.descuentoo();
        }
      },
      // actualiza el importe
      "datosModal.cantidad": function () {
        this.actualziarr();
      },
      // actualiza el descuento
      "datosModal.descuento": function () {
        if(this.caja.precioVentaListEdit != []){
          this.descuentoo();
        }
      },
      // actualiza el importe
      "datosModal.precio_": function () {
        // this.datosModal.precio = this.datosModal.precio_.precio;

        // this.datosModal.importe = this.restrictDecimal(this.datosModal.cantidad * this.datosModal.precio);
        // this.updateTotalCarrito();
      }
        
    },
    computed:{
      ...mapState([ "caja", "auth", "contado"]),
    },
    methods:{
      ...mapActions([ "updateTotalCarrito", "unidadList", "precioVentaList", "precioVentaListEdit" ]),
      actualziarr(){
        this.datosModal.importe = this.restrictDecimal(this.datosModal.cantidad * this.datosModal.precio);
        this.updateTotalCarrito();
      },
      descuentoo(){
          let precioBuscar = this.getPrecioById(this.datosModal.datosArticulo.id);
          if(precioBuscar){
            let desc = this.datosModal.descuento / 100;
            let res = precioBuscar["precio"] * desc;
            res = precioBuscar["precio"] - res;
            this.datosModal.precio = this.restrictDecimal(res);
            
            this.actualziarr();
          }
      },
      getPrecioById(id){
        let searchRes = this.caja.precioVentaListEdit.filter(item=>item.id === id)
        return searchRes[0]
      },
      returnfactor_pv(){
        if(this.datosModal.datosArticulo.id == 1){
          return this.datosModal.factor_pv_1;
        }else if(this.datosModal.datosArticulo.id == 2){
          return this.datosModal.factor_pv_2;
        }else if(this.datosModal.datosArticulo.id == 3){
          return this.datosModal.factor_pv_3;
        }else if(this.datosModal.datosArticulo.id == 4){
          return this.datosModal.factor_pv_4;
        }
      },
      getItemCarrito(id){
          let searchRes = this.caja.cajaCarrito.filter(item=>item.id_articulo === id)
          return searchRes[0]
      },
      getPrecio(id){
        console.log("precioVentaList", this.caja.precioVentaList);
        let searchRes = this.caja.precioVentaList.filter(item=>item.precio === id)
        return searchRes[0]
      },

      restrictDecimal(val) {
        let cambio = parseFloat(val);
        return cambio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      },
      // cierra el modal
      cancel() {
        this.$store.commit("SET_COMMIT_PRECIO_VENTA_EDIT", []);
        this.modalShow = false;
        this.loading = false;
        this.datosModal = { nombre: "", clave:"", precio:"", importe:"", cantidad:"", precio_:"" };
        this.$store.commit("SET_COMMIT_CARRITO_EDIT", 0);
        return this.modalShow;
      },
      // setea todo el array
      setDetailRequisicionArray() {
        this.loading = true;
        this.cancel();
      },
      clearDigit(value){
        return Number(value.replace(/[^0-9.-]+/g,""));
      },
      realizarVenta(){
        console.log("jejeje");

      }
    },
  };
</script>
<style>
</style>