<template>
  <b-modal id="modalEditar" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="EDITAR" title-class="modal-title w-100" body-class="p-4" >
    <b-row>
        <!-- <b-row > -->
          <b-card bg-variant="light" style="width: 50rem;"  header="DATOS CONTACTO">
            <b-row >
<b-col class="col-md-12" >
            <b-form-group id="input-group-1" label="NOMBRE / RAZON SOCIAL:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" required="" v-model="proveedor.getProveedores.nombre_razon_social" ></b-form-input>
                <!--  -->
              </b-input-group>
            </b-form-group>
          </b-col>
           <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="RFC:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="proveedor.getProveedores.rfc" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
           <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="TELÉFONO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm">
                <b-form-input @focus="$event.target.select()"  v-model="proveedor.getProveedores.telefono" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col style="padding-top:2.5rem;" class="col-md-4">
      <b-row>
        <b-form-checkbox v-model="proveedor.getProveedores.persona_moral" switch size="">PERSONA MORAL</b-form-checkbox>
      <!-- <b-form-checkbox style="padding-left:4rem;" switch size="lg">Inactivo</b-form-checkbox> -->
      </b-row>
      
    </b-col>
           <b-col class="col-md-6">
            <b-form-group id="input-group-1" label="CONTACTO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm">
                <b-form-input @focus="$event.target.select()"  v-model="proveedor.getProveedores.contacto" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-6">
            <b-form-group id="input-group-1" label="E-MAIL:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm">
                <b-form-input @focus="$event.target.select()" type="email"  v-model="proveedor.getProveedores.e_mail" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
              </b-row>
            </b-card>

          <b-card bg-variant="light" style="width: 50rem;"  header="DATOS DOMICILIO">
            <b-row >
 <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="DIRECCIÓN:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="proveedor.getProveedores.direcion"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
<b-col class="col-md-4">
            <b-form-group id="input-group-1" label="COLONIA:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="proveedor.getProveedores.colonia"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
<b-col class="col-md-4">
            <b-form-group id="input-group-1" label="CIUDAD:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="proveedor.getProveedores.ciudad" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
<b-col class="col-md-4">
            <b-form-group id="input-group-1" label="ESTADO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm">
                <b-form-input @focus="$event.target.select()"  v-model="proveedor.getProveedores.estado" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
            </b-row>
          </b-card>
          
          <b-card bg-variant="light" style="width: 50rem;"  header="DATOS CRÉDITO">
            <b-row>
<b-col class="col-md-6">
            <b-form-group id="input-group-1" label="DESCUENTO PRONTO PAGO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm">
                <b-form-input @focus="$event.target.select()" type="number" v-model="proveedor.getProveedores.descuento_pronto_pago" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>


            </b-row>
          </b-card>
         
          

    </b-row>
    

      
    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Guardar" tooltip="Guardar registro" class="mr-3"
        icon="lock-fill" fontscale="2" 

        :disabled="proveedor.getProveedores.nombre_razon_social===' ' || proveedor.getProveedores.rfc===' ' || proveedor.getProveedores.direccion===' '
        || proveedor.getProveedores.descuento_pronto_pago < 0 || proveedor.getProveedores.descuento_pronto_pago == ' ' || proveedor.getProveedores.descuento_pronto_pago == ''"
        
        :onClic="realizarRegistro" variant="info" btn="btn-secundary" size="sm"/>

        <Boton text="Cancelar" tooltip="Cancelar registro"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/>
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  import Vue2Filters from 'vue2-filters'
  
  export default {
    mounted(){
      this.$store.commit("SET_COMMIT_EDIT_PROVEEDOR", 0);
    },
    mixins: [Vue2Filters.mixin],
    components:{ Boton },
    data() {
      return {
        totalTexto:"",
        modalShow: false,
       
        loading: false
      };
    },
    watch: {
     
      "proveedor.editIdProveedor": function (val) {
         console.log("val: " , val);
        if(val >= 1){
          this.$bvModal.show('modalEditar');
          this.proveedorGet(val);

        }
      },
        "proveedor.proveedorGet": function (val) {
          console.log("val: " , val);
        },
         "proveedor.proveedorList": function (val) {
          console.log("val: " , val);
        },
       "proveedor.messageProveedor": function (val) {
                if(val.clase == "toast-success"){
                     this.generateAlert(val.mensaje, val.clase);
                }
            }
    },
    computed:{
      ...mapState([ "proveedor", "auth"]),
    },
    methods:{
      ...mapActions([  "proveedorUpdate", "proveedorGet", "proveedorList"]),

     
      // cierra el modal
      cancel() {
        this.modalShow = false;
        this.loading = false;
        this.$store.commit("SET_COMMIT_EDIT_PROVEEDOR", 0);
        this.$store.commit("SET_COMMIT_GET_PROVEEDORES", 0);
        this.proveedorList();
        return this.modalShow;
      },
      
      // clearDigit(value){
      //   return Number(value.replace(/[^0-9.-]+/g,""));
      // },
      realizarRegistro(){
        let data = {
          id_proveedor: this.proveedor.editIdProveedor ? this.proveedor.editIdProveedor: "NO TECLEADO",
          nombre_razon_social: this.proveedor.getProveedores.nombre_razon_social ? this.proveedor.getProveedores.nombre_razon_social: "NO TECLEADO",
          direccion: this.proveedor.getProveedores.direcion ? this.proveedor.getProveedores.direcion: "NO TECLEADO" ,
          colonia: this.proveedor.getProveedores.colonia ? this.proveedor.getProveedores.colonia: "NO TECLEADO",
          telefono: this.proveedor.getProveedores.telefono ? this.proveedor.getProveedores.telefono: "NO TECLEADO" ,
          contacto: this.proveedor.getProveedores.contacto ? this.proveedor.getProveedores.contacto: "NO TECLEADO",
          e_mail: this.proveedor.getProveedores.e_mail ? this.proveedor.getProveedores.e_mail: "NO TECLEADO",
          estado: this.proveedor.getProveedores.estado ? this.proveedor.getProveedores.estado: "NO TECLEADO",
          ciudad: this.proveedor.getProveedores.ciudad ? this.proveedor.getProveedores.ciudad: "NO TECLEADO",
          rfc: this.proveedor.getProveedores.rfc ? this.proveedor.getProveedores.rfc: "NO TECLEADO",
          persona_moral:this.proveedor.getProveedores.persona_moral  ? this.proveedor.getProveedores.persona_moral : 0,
          descuento_pronto_pago: this.proveedor.getProveedores.descuento_pronto_pago  ? this.proveedor.getProveedores.descuento_pronto_pago : 0,
          id_usuario: this.auth.user.idUser
        } 
        this.proveedorUpdate(data);
        //this.proveedorList();
        this.cancel();
      },
    },
  };
</script>
<style>
</style>