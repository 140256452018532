import ordencomprainventario from '../pages/ordencomprainventario';

export default [
    {
      path: '/ordencomprainventario/crear',
      component: ordencomprainventario,
      name: 'crearOrdencomprainventario',
      
    }
]
