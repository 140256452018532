<template>
    <b-card header-tag="header" class="mt-3 card-mini">

        <!-- <template v-slot:header> -->
            <!-- <b-row class="justify-content-md-center"> -->
                <!-- INFORMACIÓN -->
            <!-- </b-row> -->
        <!-- </template> -->


        <!-- <b-col class="col-md-12 mb-2"> -->
        <!-- <b-list-group-item> -->
            <b-row class="justify-content-md-center" v-b-tooltip.hover title="Cliente">
                <b-avatar button variant="primary" :text="iniciales" class="align-baseline"></b-avatar>
            </b-row>
            <b-row class="justify-content-md-center mb-2" >
                <strong class="col-md-12 col-sm-12 col-xl-12">{{contado.clienteSelectedVar.nombre_razon_social}}</strong>
            </b-row>
            <b-row class="justify-content-md-center mb-2" >
                <strong class="col-md-12 col-sm-12 col-xl-12">facturacion_fac@aceromax.com</strong>
            </b-row>
            <b-row class="justify-content-md-center mb-2" v-b-tooltip.hover title="RFC">
                <strong class="col-md-12 col-sm-12 col-xl-12">{{ contado.clienteSelectedVar.rfc }}</strong>
            </b-row>
            
            <!-- <b-input-group size="sm" class="mb-2 mt-2" v-b-tooltip.hover title="Fecha de la compra">
                <template v-slot:prepend>
                    <span class="input-group-text" id="basic-addon">
                        <b-icon icon="calendar2-date-fill"></b-icon>
                    </span>
                </template>
                <b-form-input @focus="$event.target.select()" value="01/01/2020" disabled aria-label="Small text input with custom switch"></b-form-input>
            </b-input-group> -->

            <b-input-group size="sm" prepend="Sub $" class="mb-2"  v-b-tooltip.hover title="Sub total">
                <b-form-input style="text-align:right;" @focus="$event.target.select()" :value="caja.totalCarrito.subtotal" disabled aria-label="Small text input with custom switch"></b-form-input>
            </b-input-group>
            <b-input-group size="sm" prepend="I.V.A $" class="mb-2"  v-b-tooltip.hover title="I.V.A">
                <b-form-input style="text-align:right;" @focus="$event.target.select()" :value="caja.totalCarrito.iva" disabled aria-label="Small text input with custom switch"></b-form-input>
            </b-input-group>
            <b-input-group size="sm" prepend="Total $" class="mb-2"  v-b-tooltip.hover title="Total">
                <b-form-input style="text-align:right;" @focus="$event.target.select()" :value="caja.totalCarrito.total" disabled aria-label="Small text input with custom switch"></b-form-input>
            </b-input-group>
            <!-- <b-input-group size="sm" prepend="Factura" class="mb-2"  v-b-tooltip.hover title="Folio">
                <b-form-input @focus="$event.target.select()" value="Serie + Folio" disabled aria-label="Small text input with custom switch"></b-form-input>
            </b-input-group> -->


                        <!-- <b-col class="col-md-12 mt-2" > -->

            

                            <!-- <b-form-select placeholder="Seleccione método de pago" v-model="selected"  :options="options1"></b-form-select> -->
                        <!-- </b-col> -->
        <!-- </b-list-group-item> -->
        <!-- </b-col> -->


            <!-- <b-col md="12" class="m-1">
                
                <b-form-select placeholder="Selecciona cliente" style="" v-model="selected" :options="optionsC"></b-form-select>
            </b-col> -->
            <b-col class="col-md-12">
                <b-form-group>
                    <b-row>
                        
                        <b-row class="row mt-2 justify-content-md-center" align-v="start" align-h="start">
                            
                            <b-col class="col-md-12 col-sm-12 col-xl-5 m-2">
                                <Boton text="Cancelar" tooltip="Cancelar venta"
                                icon="x-circle-fill" fontscale="2" :onClic="cancelarVenta" variant="info" btn="btn-cancel" size="sm"/>
                            </b-col>
                        
                            <b-col class="col-md-12 col-sm-12 col-xl-5 m-2">
                                <Boton text="Guardar" tooltip="Realizar venta"
                                :disabled="contado.clienteSelectedVar.nombre_razon_social==='No Seleccionado' || caja.totalCarrito.subtotal == '0.00'"
                                icon="lock-fill" fontscale="2" :onClic="realizarVenta" variant="info" btn="btn-secundary" size="sm"/>
                            </b-col>
                            
                        </b-row>

                    </b-row>
                </b-form-group>

            </b-col>
            <!-- modalpago -->
            <ModalEjemplo  />
    </b-card>
</template>

<script>
    import router from '@/router'
    import ModalEjemplo from "./ModalEjemplo";
    import Boton from '@/components/Boton'
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
        },
        components:{ Boton, ModalEjemplo },
        data(){
            return{ search:"" }
        },
        computed: {
            ...mapState([ "contado", "caja", "auth"]),
            iniciales(){
                let separado = this.contado.clienteSelectedVar.nombre_razon_social.split(" ");
                return separado[0].charAt(0) + separado[1].charAt(0);
            }
        },
        watch:{
            // despues de actualixar vairbale messaje muestra el mensaje
            "caja.messageCaja": function (val) {
                // muestra alerta
                this.generateAlert(val.mensaje, val.clase);
                if(val.clase == "toast-success"){
                    // limpia carrito 
                    this.$store.commit("SET_COMMIT_CARRITO_CLEAR");
                    // limpia cliente seleccionado
                    this.$store.commit("SET_COMMIT_CLIENTE_SELECTED", {nombre_razon_social:"No Seleccionado", limite_credito: "0.00"})
                    // this.cancel();
                }
            },
            // cuando hace el cambio de id venta lanza el ticket
            "cotizacion.id_venta_cotizacion": function (val) {
                if(val != 0){
                    console.log("id_venta_cotizacion: " , val);
                    this.printTicket(val);
                }
            }
        },
        methods:{
            ...mapActions(["headerCreateContado", "clicReverse", "printTicket"]),
            cancelarVenta(){
                this.clicReverse("subcategoria");
                this.$store.commit("SET_COMMIT_CARRITO_CLEAR");
                this.generateAlert("Carrito cancelado", "toast-success");
                router.push('/caja')
            },
            realizarVentaMODAL(){
                this.$bvModal.show('modalEjemplo');
            },
            clearDigit(value){
                return Number(value.replace(/[^0-9.-]+/g,""));
            },
            realizarVenta(){
                this.$bvModal.show('modalEjemplo');
            }
        }
    }
</script>