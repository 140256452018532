/*eslint-disable no-unused-vars*/
import { cliente_get, create_Cliente, update_Cliente, cliente_list, regimen_fiscal_list } from '@/api/cliente'
import router from '@/router'

export const actions = {
    //Lista de regimen fiscal
    regimenFiscalList: async({ rootState, commit, dispatch }) => regimen_fiscal_list(rootState.auth.user.idUser).then(
        res => {
            console.log("regimenFiscalList", res.data);
            commit("SET_COMMIT_REGIMEN_FISCAL", res.data);
            //dispatch("setLoad")
        }).catch(err => console.log(err)),


    //Lista de clientes
    clienteList: async({ rootState, commit, dispatch }) => cliente_list(rootState.auth.user.idUser).then(
        res => {
            commit("SET_COMMIT_CLIENTES", res.data);
            //dispatch("setLoad")
        }).catch(err => console.log(err)),
    //Obtener el cliente
    clienteGet: async({ rootState, commit, dispatch }, id_cliente) => cliente_get(rootState.auth.user.idUser, id_cliente).then(
        res => {
            commit("SET_COMMIT_GET_CLIENTES", res.data);
            //commit("SET_COMMIT_SHOW", "cliente");

        }).catch(err => console.log(err)),
    //Registrar cliente
    clienteCreate: async({ commit, dispatch }, data) => create_Cliente(data).then(
        res => {
            console.log("res: ", data);
            commit("SET_MESSAGE_CLIENTE", {
                mensaje: res.message,
                clase: (res.data.message === "EXITO") ? "toast-success" : "toast-danger"
            })

            // console.log("res: " , res.data);
        }).catch(err => console.log(err)),

    // successCliente:({ commit })=>{

    //     // dispatch("clicReverse");
    //     router.push('/caja')
    // },

    //Actualizar cliente
    clienteUpdate: async({ commit, dispatch }, data) => update_Cliente(data).then(
        res => {
            commit("SET_MESSAGE_CLIENTE", {
                mensaje: res.data.message,
                clase: (res.data.message === "EXITO") ? "toast-success" : "toast-danger"
            })

            // console.log("res: " , res.data);
        }).catch(err => console.log(err)

    ),
    //Eliminar cliente
    clienteDelete: async({ commit, dispatch }, data) => update_Cliente(data).then(
        res => {
            commit("SET_MESSAGE_CLIENTE", {
                mensaje: res.data.message,
                clase: (res.data.message === "EXITO") ? "toast-success" : "toast-danger"
            })

            // console.log("res: " , res.data);
        }).catch(err => console.log(err)

    ),
};