<template>
    <div class="message-form-general">
        <p>{{message}}</p>
    </div>
</template>

<script>
export default {
    props:["message"]
}
</script>

<style>
.message-form-general{
    border-radius: 2px !important;
    box-shadow: 1px 1px 3px #C7C7C7 !important;
    background-color: rgb(226, 82, 82) !important;
    text-align: center;
}
.message-form-general p{
    padding: 0.4rem;
    color: white !important;
}
</style>