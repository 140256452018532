<template>
  <b-modal id="ModalImagen" v-model="modalShow" hide-footer size="md" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="IMAGEN" title-class="modal-title w-100" body-class="p-2" >
    <b-row align-v="center" align-h="center">
        <!-- <b-col class="col-md-12"> -->
        <!-- <b-row > -->
          <!-- <b-col class="col-md-6" > -->
            <!-- <b-form-group id="input-group-1" label="IMAGEN" label-class="form-label" label-for="input-1"  class="mb-0"> -->
              
      <b-img fluid class="mousehoverItem" width="400" height="400"
        :src="caja.imagenCarrito ? 'data:image/jpeg;base64,' + caja.imagenCarrito : require('@/assets/img/nodisponible.png')" 
      ></b-img>

                    
            <!-- </b-form-group> -->
          <!-- </b-col> -->
        <!-- </b-row> -->
      <!-- </b-col> -->
    </b-row>

    <b-row class="mt-2" align-v="center" align-h="center">
      <b-col cols="12" class="text-right">

        <Boton text="Cerrar" class="mr-3"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-secundary" size="sm"/>

        <!-- <Boton text="Cancelar" tooltip="Cancelar"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/> -->
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import { mapState } from "vuex";
  import Boton from '@/components/Boton'
  export default {
    mounted(){
      this.$store.commit("SET_COMMIT_IMG_CARRITO", 0);
    },
    components:{ Boton },
    data() {
      return { modalShow: false, };
    },
    watch: {
      "caja.imagenCarrito": function (val) {
        // console.log("val: " , val);
        if(val != 0){
          this.$bvModal.show('ModalImagen');
        }
      },
        
    },
    computed:{
      ...mapState([ "caja" ]),
    },
    methods:{
      // cierra el modal
      cancel() {
        this.modalShow = false;
        this.$store.commit("SET_COMMIT_IMG_CARRITO", 0);
        return this.modalShow;
      },
    },
  };
</script>
<style>
</style>