<template>
  <b-container fluid>
    <b-col class="p-0 pb-5 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
        
        <b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
        
        </b-row>
        
        <Articulos />
        
    </b-col>
  </b-container>
</template>

<script> 
import Articulos from "./Articulos"

import { mapState, mapActions } from "vuex";

export default {
    components: { Articulos },
     mounted(){
        this.$store.commit("SET_COMMIT_EDIT_ARTICULOS_LIST", 0);
        
    },
    data() {
        return {
            
        }
    },
    computed: mapState(["articulos", "caja"]),
    methods: {
        ...mapActions([]),
        clicProducto(){

        }
    }
}
</script>