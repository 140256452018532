<template :id="idTemplate">
    <div  >
        <!-- listado de items -->
        <b-col v-b-scrollspy:scrollspy-nested v-if="items.length > 0" class="col-md-12 mt-3" id="scrollspy-nested" style="max-height:calc(52vh); overflow-y:auto">
            <b-row v-b-toggle="'colapse-desc-'+(items.indexOf(item) + 1)" class="mt-1 mousehoverItem animate__animated animate__bounceIn" v-for="(item, index) in itemsPaginated" v-bind:key="index">
                
                <b-col class="col-md-1">
                    <b-row >
                        <b-col cols="12" class="p-0 pt-2 text-titulo" >
                            <h1 class="text-center w-100" >{{ proveedor.proveedorList.indexOf(item) + 1 }}</h1>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >RFC</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.rfc }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >PROVEEDOR</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.nombre_razon_social }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >CIUDAD</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.ciudad }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >TELÉFONO</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.telefono }}
                        </b-col>
                    </b-row>
                </b-col>
                    
              

                
                <b-col class="col-md-3 col-sm-3 col-xl-3">
                    <b-row >
                        <b-col class="p-1 pl-2 text-titulo text-left text-shadow mb-0 col-md-12" >
                            <strong >ACCIONES</strong>
                        </b-col>
                        <b-col class="justify-content-md-center text-shadow text-descripcion col-md-12" >
                            <b-row class="justify-content-md-center m-1" align-v="center">
                                 <b-col class="col-md-6 col-sm-6 col-xl-6">
                                    <Boton 
                                        icon="pencil-square" fontscale="1" :onClic="edit" 
                                        :valorEventClic="item.id_proveedor"
                                        variant="primary" btn="btn-default" size="sm" />
                                </b-col>
                                <!-- <b-col class="col-md-6 col-sm-6 col-xl-6">
                                    <Boton 
                                        icon="trash-fill" fontscale="1" :onClic="realizarEliminacion" :valorEventClic="item"
                                        variant="danger" btn="btn-cancel" size="sm" />
                                </b-col> -->
                               
                                 <!-- <b-col class="col-md-6 col-sm-6 col-xl-6">
                                    <Boton :tooltip="`Ver imagen de ${item.clave}`" :onClic="verImagen" :valorEventClic="item.imagen"
                                        icon="image" fontscale="1" variant="primary" btn="btn-detail" size="sm" />
                                </b-col>  -->
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>

            </b-row>
        </b-col>
       
    </div>
</template>
<script>
    import Boton from '@/components/Boton'
        import { mapState, mapActions } from 'vuex';

    export default {
        props:[ "item", "perPage", "currentPage", "items", "SET_COMMIT_IMG", "SET_COMMIT_DELETE", "SET_COMMIT_EDIT", "idTemplate", "search", "actions", "collapse" ],
        data() {
            return {
       
            };
        },
        components:{ Boton },
        computed:{
             ...mapState(["proveedorList", "auth", "proveedor"]),
            // paginación
             itemsPaginated(){ 
                return this.searchInDetail()
             }
        },
        watch: {
             "proveedor.messageProveedor": function (val) {
                if(val.clase == "toast-success"){
                     this.generateAlert(val.mensaje, val.clase);
                     this.proveedorList();
                }
            }
        },
        methods:{
            ...mapActions(["proveedorUpdate", "proveedorList"]),
            // busqueda dentro del array
             searchInDetail(){
                 return this.items.filter(req => this.search.toLowerCase()
                 .split(' ').every(v => this.conditionalFilter(v, req)))
             },
             conditionalFilter(v, req){ 
                 for (const prop in req) {
                     if(req[prop] !== null){
                         if(req[prop].toString().toLowerCase().includes(v)){
                             return true;
                         }
                     }
                 }
             },
            // muestra los primeros 172 caracteres del texto
            toggleText(text) {
                let res = "";
                let value = (text.length >= 172) ? 172 : (text.length / 2);
                for (let i = 0; i < value; i++) { res += text[i]; }
                return res += " ...";
            },
            // borra la posocion del array
            eliminar() {
                // console.log("eliminar: " , item);
                // console.log("indexOf: " , this.items.indexOf(item));
                //this.$store.commit(this.SET_COMMIT_DELETE, this.items.indexOf(item))
            },
            // inicializa la variable id para mostrar en el modal EDITAR, usar watch para accionar el modal
            edit(item) {
                this.$store.commit(this.SET_COMMIT_EDIT, item)
                //console.log("edit: " , item);
            },
           realizarEliminacion(item){
               let data = {
                    id_proveedor: item.id_proveedor,
                    nombre_razon_social: item.nombre_razon_social,
                    direccion: item.direcion, 
                    colonia: item.colonia,
                    telefono: item.telefono, 
                    contacto: item.contacto,
                    e_mail: item.e_mail,
                    estatus: 0,
                    estado: item.estado,
                    ciudad: item.ciudad,
                    rfc: item.rfc, 
                    persona_moral:item.persona_moral ,
                    descuento_pronto_pago: item.descuento_pronto_pago ,
                    id_usuario: this.auth.user.idUser                  
               }
               this.proveedorUpdate(data);
               this.proveedorList();
           }
           
        }
    };
</script>
<style scoped>
</style>