import { HTTP } from "./http-common";
export const cliente_list = (id_usuario) => new Promise((rv, rj) => {
    HTTP.get("cliente-list/?id_usuario=" + id_usuario).then(
        res => { rv(res) },
        err => { rj(err) })
});
export const cliente_get = (id_usuario, id_cliente) => new Promise((rv, rj) => {
    HTTP.get("cliente-get/?id_usuario=" + id_usuario + "&id_cliente=" + id_cliente).then(
        res => { rv(res) },
        err => { rj(err) })
});

export const create_Cliente = (data) => new Promise((rv, rj) => {
    HTTP.post("cliente-create/", data).then(
        res => { rv(res) },
        err => { rj(err) })
});

export const update_Cliente = (data) => new Promise((rv, rj) => {
    HTTP.put("cliente-update/", data).then(
        res => { rv(res) },
        err => { rj(err) })
});
export const cliente_abonos_list = (id_usuario, id_cliente) => new Promise((rv, rj) => {
    HTTP.get("cliente-abonos-list/?id_usuario=" + id_usuario + "&id_cliente=" + id_cliente).then(
        res => { rv(res) },
        err => { rj(err) })
});
export const regimen_fiscal_list = (id_usuario) => new Promise((rv, rj) => {
    HTTP.get("/regimen-fiscal-list/?id_usuario=" + id_usuario).then(
        res => { rv(res) },
        err => { rj(err) })
});