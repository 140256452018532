import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        cotizacion: false,
        message: "",
        imprimirPrecioUnitario: false,
        imprimirCantidad: false,
        imprimirImporte: true,
        id_venta_cotizacion: 0,
    },
    mutations,
    actions,
};