/*eslint-disable no-unused-vars*/
import { pos_header_createContado, pos_detail_createContado, categoria_list, subcategoria_list, articulo_list,
    articulo_get, unidad_list, precio_venta_list, articulo_get_directo, venta_tipo_pago_create, corte_caja_list } from '@/api/caja'
import { print_ticket, estatus_caja } from '@/api/printer'
import { abono_mov_create } from '@/api/cobro'
import { venta_detail_list } from '@/api/devolucion'
import { articulo_clave_get } from "@/api/articulo";
import router from '@/router'
import Vue from 'vue'

export const actions = {
    // list detalles de Venta 
    listVentaCaja: async({ rootState, commit }, id_venta) => venta_detail_list(rootState.auth.user.idUser, id_venta).then(
        res=>{
            // valida contenido de array
            console.log("res.data: " , res.data);
            if(res.data.length == 0){
                const vm = new Vue();
                vm.generateAlert("No existe movimiento de venta", "toast-success");
            }else{
                res.data.forEach(element => {
                    let _unidad = { unidad: element.unidad, id_unidad: element.id_unidad }
                    element.unidad = _unidad;

                    let precio_ = element.precio.replace(/[^0-9.-]+/g,"");
                    let importe_ = element.importe.replace(/[^0-9.-]+/g,"");
                    element.precio = precio_;
                    element.importe = importe_;

                    let _datosArticulo = { equivalencia: 1 }
                    element.datosArticulo = _datosArticulo;
                });
                commit("SET_COMMIT_CARRITO_VENTA_COTIZACION", res.data);
            }
        }).catch(err=>console.log(err)),

    // lista las categorias con imagen
    estatusCaja: async({ rootState, commit }) => estatus_caja(rootState.auth.user.URL_IMPRESORA, "jeje").then(
        res=>{
            console.log("estatusCaja: " , res);
        }).catch(err=>console.log(err)
    ),
    // lista las categorias con imagen
    printTicket: async({ rootState, commit }, id_venta) => print_ticket(rootState.auth.user.URL_IMPRESORA, rootState.auth.user.idUser, rootState.auth.user.firstName, id_venta).then(
        res=>{
            console.log("PRINTER: " , res);
        }).catch(err=>console.log(err)
    ),
    // cambia la variable load a false
    setLoad:({commit})=>{
        commit("SET_COMMIT_LOAD_PRODUCTOS", false);
    },
    // lista las categorias con imagen
    categoriaList: async({ rootState, commit, dispatch }) => categoria_list(rootState.auth.user.idUser).then(
        res=>{
            console.log("categoriaList::: " , res.data);
            commit("SET_COMMIT_PRODUCTOS", res.data);
            dispatch("setLoad")
        }).catch(err=>console.log(err)
    ),
    // lista las subcatsuegorias con imagen
    subCategoriaList: async({ rootState, commit, dispatch }, id_categoria) => subcategoria_list(rootState.auth.user.idUser, id_categoria).then(
        res=>{
            console.log("subCategoriaList::: " , res.data);
            commit("SET_COMMIT_PRODUCTOS", res.data);
            dispatch("setLoad")
        }).catch(err=>console.log(err)
    ),
    // list los articulos 
    articuloList: async({ rootState, commit, dispatch }, id_subcategoria) => articulo_list(rootState.auth.user.idUser, id_subcategoria).then(
        res=>{
            console.log("articuloList::: " , res.data);
            commit("SET_COMMIT_PRODUCTOS", res.data);
            dispatch("setLoad")
        }).catch(err=>console.log(err)
    ),
    // obtiene el articulos seleccionado
    articuloGet: async({ rootState, commit, dispatch }, id_articulo) => articulo_get(rootState.auth.user.idUser, id_articulo).then(
        res=>{
            console.log("articuloGet::: " , res.data);
            commit("SET_COMMIT_PRODUCTOS", [res.data]);
            dispatch("setLoad")
        }).catch(err=>console.log(err)
    ),
    // lista el catalogo de unidad del articulo seleccionado
    unidadList: async({ rootState, commit }, id_articulo) => unidad_list(rootState.auth.user.idUser, id_articulo).then(
        res=>{
            commit("SET_COMMIT_UNIDADES", res.data);
        }).catch(err=>console.log(err)
    ),
    // lista el precio del articulo seleccionado
    precioVentaList: async({ rootState, commit }, data) =>
        precio_venta_list(rootState.auth.user.idUser, data.id_articulo, data.id_unidad, rootState.auth.user.id_sucursal).then(
        res=>{
            // console.log("res.data: " , res.data);
            let ventas = new Array();
            let pesopromedio = "";
            if(res.data.length > 0){
                pesopromedio = res.data[0].VARPesoPromedio;
                ventas[0] = {precio: res.data[0].pv_1, id: 1, color: "background-color: #FFFFF; ", equivalencia: res.data[0].equivalencia, colorText: "color: black;"};
                ventas[1] = {precio: res.data[0].pv_2, id: 2, color: "background-color: #458c2b; ", equivalencia: res.data[0].equivalencia, colorText: "color: white;"};
                ventas[2] = {precio: res.data[0].pv_3, id: 3, color: "background-color: #2b458c; ", equivalencia: res.data[0].equivalencia, colorText: "color: white;"};
                ventas[3] = {precio: res.data[0].pv_4, id: 4, color: "background-color: #E40000; ", equivalencia: res.data[0].equivalencia, colorText: "color: white;"};
                ventas[4] = {precio: "Capturar", id: 5, color: "background-color: black; ", equivalencia: res.data[0].equivalencia, colorText: "color: white;"};
                
            }
            let rrr = {
                precios: ventas ? ventas : new Array(),
                peso: pesopromedio,
            }
            commit("SET_COMMIT_PRECIO_VENTA", rrr);
        }).catch(err=>console.log(err)
    ),
    // lista el precio del articulo seleccionado para editarlo
    precioVentaListEdit: async({ rootState, commit }, data) =>
        precio_venta_list(rootState.auth.user.idUser, data.id_articulo, data.id_unidad, rootState.auth.user.id_sucursal).then(
        res=>{
            // console.log("res.data: " , res.data);
            let ventas = new Array();
            if(res.data.length > 0){
                ventas[0] = {precio: res.data[0].pv_1, id: 1, color: "background-color: #FFFFF; ", equivalencia: res.data[0].equivalencia, colorText: "color: black;"};
                ventas[1] = {precio: res.data[0].pv_2, id: 2, color: "background-color: #458c2b; ", equivalencia: res.data[0].equivalencia, colorText: "color: white;"};
                ventas[2] = {precio: res.data[0].pv_3, id: 3, color: "background-color: #2b458c; ", equivalencia: res.data[0].equivalencia, colorText: "color: white;"};
                ventas[3] = {precio: res.data[0].pv_4, id: 4, color: "background-color: #E40000; ", equivalencia: res.data[0].equivalencia, colorText: "color: white;"};
                ventas[4] = {precio: "Capturar", id: 5, color: "background-color: black; ", equivalencia: res.data[0].equivalencia, colorText: "color: white;"};
                // console.log("precioVentaList: " , ventas)
            }
            commit("SET_COMMIT_PRECIO_VENTA_EDIT", ventas);
        }).catch(err=>console.log(err)
    ),
    // actualiza el total del carrito
    updateTotalCarrito:({ rootState, commit})=>{
            let importe_ = 0.00;
            //let cambio_ = 0;
            if(rootState.caja.cajaCarrito.length !== 0){
                rootState.caja.cajaCarrito.forEach(element => {
                    if (typeof element.importe !== 'undefined') {
                        // console.log("element.importe: ", element.importe);
                        importe_ += Number(element.importe.replace(/[^0-9.-]+/g,""));
                    }
                });
            }

            // let resImporte = parseFloat(importe_);
            // let resIva = parseFloat(resImporte * .16);
            // let resTotal = parseFloat(importe_ + resIva);
            //let resCambio = parseFloat(cambio_);
            let resTotal = parseFloat(importe_);
            let resImporte = parseFloat(resTotal / 1.16);
            let resIva = parseFloat(resTotal - resImporte);

            commit("SET_COMMIT_TOTAL", {
                subtotal: resImporte.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                iva: resIva.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
                total: resTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
            })
    },
    abonoMovCreateDesdeCredito: async({ commit }, datos) => abono_mov_create(datos).then(
        res=>{
            // console.log("abonoMovCreate: " , res.data);
            commit("SET_MESSAGE_COBRO",{mensaje: res.data.message,
                clase: (res.data.message === "EXITO") ? "toast-success" : "toast-danger"})
        }).catch(err=>console.log(err)
            
    ),
    headerCreateContado: async({ commit, rootState, dispatch }, data) => await pos_header_createContado(data).then(
        res=>{
            commit("SET_ID_VENTA_COTIZACION", res.data.id_venta);
            let datos = new Array();
            let i = 0;

            rootState.caja.cajaCarrito.forEach(element => {
                datos[i] = {
                    id_venta : res.data.id_venta,
                    id_articulo : element.id_articulo,

                    // // asi se enviaba antes
                    // // id_unidad : element.id_unidad,
                    // // se corrijio
                    id_unidad : element.unidad.id_unidad,

                    cantidad : element.cantidad,
                    precio : element.precio,
                    descuento : element.descuento,
                    id_sucursal : rootState.auth.user.id_sucursal,
                    equivalencia : element.datosArticulo.equivalencia,
                    id_usuario : rootState.auth.user.idUser,
                }

                i++;
            });
            
            // si hay una cantidad en efectivo lanza el controler
            if(rootState.caja.efectivo != "" && rootState.caja.efectivo != "0" && rootState.caja.efectivo != " " && rootState.caja.efectivo != 0){
                let datos0 = {
                    cantidad: rootState.caja.efectivo > rootState.caja.totalCarrito.total.replace(/[^0-9.-]+/g,"") ? rootState.caja.totalCarrito.total.replace(/[^0-9.-]+/g,"") : rootState.caja.efectivo,
                    id_venta: res.data.id_venta,
                    id_tipo_pago: 1,
                    id_usuario: rootState.auth.user.idUser,
                };
                // console.log("datos0: " , datos0);
                dispatch("ventaTipoPagoCreate", datos0)

                if(router.history.current.path == "/venta/crear"){
                    let datosAbono = {
                        id_venta: res.data.id_venta,
                        cobro: rootState.caja.efectivo,
                        id_tipo_pago: 1,
                        id_usuario: rootState.auth.user.idUser,
                    }
                    dispatch("abonoMovCreateDesdeCredito", datosAbono)
                }
            }
            // si hay una cantidad en cheque lanza el controler
            if(rootState.caja.cheque != "" && rootState.caja.cheque != "0" && rootState.caja.cheque != " " && rootState.caja.cheque != 0){
                let datos1 = {
                    // cantidad: rootState.caja.cheque > rootState.caja.totalCarrito.total ? rootState.caja.totalCarrito.total : rootState.caja.cheque,
                    cantidad: rootState.caja.cheque,
                    id_venta: res.data.id_venta,
                    id_tipo_pago: 3,
                    id_usuario: rootState.auth.user.idUser,
                };
                // console.log("datos1: " , datos1);
                dispatch("ventaTipoPagoCreate", datos1)

                if(router.history.current.path == "/venta/crear"){
                    let datosAbono1 = {
                        id_venta: res.data.id_venta,
                        cobro: rootState.caja.cheque,
                        id_tipo_pago: 3,
                        id_usuario: rootState.auth.user.idUser,
                    }
                    dispatch("abonoMovCreateDesdeCredito", datosAbono1)
                }
            }
            // si hay una cantidad en tarjeta lanza el controler
            if(rootState.caja.tarjeta != "" && rootState.caja.tarjeta != "0" && rootState.caja.tarjeta != " " && rootState.caja.tarjeta != 0){
                let datos2 = {
                    cantidad: rootState.caja.tarjeta,
                    id_venta: res.data.id_venta,
                    id_tipo_pago: 2,
                    id_usuario: rootState.auth.user.idUser,
                };
                // console.log("datos2: " , datos2);
                dispatch("ventaTipoPagoCreate", datos2)
                if(router.history.current.path == "/venta/crear"){
                    let datosAbono2 = {
                        id_venta: res.data.id_venta,
                        cobro: rootState.caja.tarjeta,
                        id_tipo_pago: 2,
                        id_usuario: rootState.auth.user.idUser,
                    }
                    dispatch("abonoMovCreateDesdeCredito", datosAbono2)
                }
            }
            // console.log("headerCreateContado:  " ,  datos);
            dispatch("detailCreateContado", { articulos: datos });
        }).catch(err=>console.log(err)
    ),
    ventaTipoPagoCreate: async({ state }, datos) => venta_tipo_pago_create(datos).then(
        res=>{
            console.log("res: " , res.data);
        }).catch(err=>console.log(err)
    ),
    detailCreateContado: async({commit, rootState, dispatch}, data) => await pos_detail_createContado(data).then(
        res=>{
            commit("SET_MESSAGE_CAJA",{mensaje: res.data[0].message,
                clase: (res.data[0].message === "EXITO") ? "toast-success" : "toast-danger"})

            // console.log("res: " , res.data);

            setTimeout(async ()=>{
                dispatch("successVenta");
            }, 2000)


            // console.log("rootState.venta.facutura:  " ,  rootState.venta.facutura);
            if(rootState.venta.facutura){
                let params = {
                    id_venta: rootState.cotizacion.id_venta_cotizacion,
                    id_usuario: rootState.auth.user.idUser
                }
                // console.log("params:  " ,  params);
                dispatch("facturaCreate", params);
            }

        }).catch(err=>console.log(err)
    ),
    
    successVenta:({ commit })=>{
        commit("SET_COMMIT_CARRITO_CLEAR");
        // dispatch("clicReverse");
        router.push('/caja')
    },
    
    // para avanzar en reversa en productos
    clicReverse:({rootState, commit, dispatch}, element)=>{
        // if(element !== 'categoria'){
            // limpia la lista de productos para despes actualizarla
            commit("SET_COMMIT_PRODUCTOS", []);
            console.log("element_ " , element);
            // if(element.length > 0){
                if(element == 'categoria'){
                    dispatch("categoriaList")
                    commit("SET_COMMIT_SHOW", "categoria");
                }
                if(element == 'subcategoria'){
                    dispatch("categoriaList")
                    commit("SET_COMMIT_SHOW", "categoria");
                }
                if(element == 'articulos'){
                    dispatch("subCategoriaList", rootState.caja.id_categoria);
                    commit("SET_COMMIT_SHOW", "subcategoria");
                }
                if(element == 'articulo'){
                    dispatch("articuloList", rootState.caja.id_sub_categoria);
                    commit("SET_COMMIT_SHOW", "articulos");
                }
            // }else{
            //     this.categoriaList();
            //     return true;
            // }
        // }
    },
    articuloGetDirecto: async({ rootState, commit, dispatch }, id_articulo) => articulo_get_directo(rootState.auth.user.idUser, id_articulo).then(
        res=>{
            commit("SET_COMMIT_PRODUCTOS", [res.data]);
            commit("SET_COMMIT_SHOW", "articulo");
            dispatch("setLoad")
        }).catch(err=>console.log(err)
    ),
    articuloClaveGet: async({ rootState, commit, dispatch }, clave) => articulo_clave_get(rootState.auth.user.idUser, clave).then(
        res=>{
            console.log("articuloClaveGet: " , res.data);
            commit("SET_COMMIT_PRODUCTOS", [res.data]);
            commit("SET_COMMIT_SHOW", "articulo");
            dispatch("setLoad")
        }).catch(err=>console.log(err)
    ),
    //Lista de corte de caja
    corteCajaList: async({ rootState, commit, dispatch }, fecha) => corte_caja_list(rootState.auth.user.idUser, fecha).then(
        res=>{
            commit("SET_COMMIT_CORTECAJA", res.data);
            dispatch("setLoad")
        }).catch(err=>console.log(err)
    ),

};