<template>
    <b-card header-tag="header" class="mt-3 card-mini" style="min-height: 670px;">
        <template v-slot:header>
            <b-row class="p-2">
                PRODUCTOS
                <b-input-group class="mt-3">
                    <b-form-input @focus="$event.target.select()" v-model="search" autocomplete="off" type="text" class="form-control"
                    placeholder="Buscar en productos..." aria-label="PRODUCTOS" aria-describedby="basic-addon"></b-form-input>
                    <b-input-group-append>
                        <Boton :onClic="regresar" :valorEventClic="caja.show" :disabled="caja.show === 'categoria'"
                        tooltip="Regresar" icon="arrow-left-short" fontscale="2" btn="btn-secundary" size="sm" />
                    </b-input-group-append>
                </b-input-group>
            </b-row>
        </template>
        <ItemsImage thumbnail fluid :precioCampo="true" SET_COMMIT_IMG="SET_COMMIT_IMG_CARRITO"
            :items="caja.cajaProductos"
            :clicReverse="regresar"
            :show="caja.show"
            :load="caja.loadProductos"
            :onClic="clic" :search="search" :addToCarrito="addToCompra"
            :descuentoComponent="false"
        />
    </b-card>
</template>
<script>
    import Boton from '@/components/Boton'
    import ItemsImage from '@/components/ItemsImage'
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
            // limpia las variables declaradas
            this.$store.commit("SET_COMMIT_LOAD_PRODUCTOS", true);
            this.$store.commit("SET_COMMIT_SHOW", "categoria");
            this.$store.commit("SET_COMMIT_PRODUCTOS", []);
            // carga la lsita de categoria
            this.categoriaList();
        },
        components:{ ItemsImage, Boton },
        data(){
            return{ search:"", }
        },
        computed: mapState([ "caja" ]),
        methods:{
            ...mapActions(["categoriaList", "subCategoriaList", "articuloList", "articuloGet", "clicReverse"]),
            // para avanzar de frente en productos
            clic(element, prop){
                // limpia la lista de productos para despes actualizarla
                this.$store.commit("SET_COMMIT_PRODUCTOS", []);
                this.$store.commit("SET_COMMIT_LOAD_PRODUCTOS", true);
                if(prop == 'categoria'){
                    this.subCategoriaList(element.id_categoria);
                    // actualiza caja.id_categoria
                    this.$store.commit("SET_COMMIT_ID_CATEGORIA", element.id_categoria);
                    this.$store.commit("SET_COMMIT_SHOW", "subcategoria");
                }
                if(prop == 'subcategoria'){
                    this.articuloList(element.id_sub_categoria);
                    // actualiza caja.id_sub_categoria
                    this.$store.commit("SET_COMMIT_ID_SUBCATEGORIA", element.id_sub_categoria);
                    this.$store.commit("SET_COMMIT_SHOW", "articulos");
                }
                if(prop == 'articulos'){
                    this.articuloGet(element.id_articulo);
                    this.$store.commit("SET_COMMIT_SHOW", "articulo");
                }
                if(prop == 'articulo'){
                    this.$store.commit("SET_COMMIT_SHOW", "articulos");
                    this.articuloList(element[0].id_sub_categoria);
                }
            },
            regresar(vista){
                this.$store.commit("SET_COMMIT_LOAD_PRODUCTOS", true);
                this.clicReverse(vista);
            },
            // para agregar el elemento a la compra
            addToCompra(element){
                this.$store.commit("SET_COMMIT_COMPRA_ADD", {
                    ...element.item,
                    cantidad:element.cantidad,
                    precio:element.precio,
                    descuento:element.descuento,
                    unidad:element.unidad,
                    importe:element.importe,
                    datosArticulo:element.datosArticulo,
                });
            },
        }
    }
</script>