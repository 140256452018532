//*APIs seccion devolucion*/
import { HTTP } from "./http-common";
export const lisstttEJEMPLOO = (id_usuario, id_venta)  => new Promise ((rv, rj) => {
    HTTP.get("venta-detail-list/?id_usuario=" + id_usuario + "&id_venta=" + id_venta).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const abono_mov_create = (data) => new Promise ((rv, rj) => {
    HTTP.post("abono-mov-create/", data).then(
        res=>{ rv(res) },
        err=>{ rj(err) })
});
