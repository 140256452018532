/*eslint-disable no-unused-vars*/
import { abonos_factura_list, ventas_factura_list, factura_create, factura_general_create } from '@/api/facturacion'
import Vue from 'vue'
// import router from '@/router'

export const actions = {
    abonosFacturaList: async({ commit, rootState }, param) => abonos_factura_list(param.fi, param.ff, rootState.auth.user.idUser).then(
        res => {
            if (res.data.length == 0) {
                const vm = new Vue()
                vm.generateAlert("No hay registros", 'toast-success')
            }
            console.log("abonosFacturaList: ", res.data);
            commit("SET_LIST_ABONOS_FACTURACION", res.data);
        }).catch(err => console.log(err)),
    ventasFacturaList: async({ commit, rootState }, param) => ventas_factura_list(param.fi, param.ff, rootState.auth.user.idUser).then(
        res => {
            if (res.data.length == 0) {
                const vm = new Vue()
                vm.generateAlert("No hay registros", 'toast-success')
                commit("SET_LIST_VENTAS_FACTURACION", []);
            } else {
                const filtroPG = (v, req) => {
                    for (const prop in req) {
                        console.log("");
                        console.log("req: ", req);
                        console.log("prop: ", prop);
                        console.log("v: ", v);
                        console.log("");
                        if (req[prop] !== null) {
                            if (req[prop].toString().toLowerCase().includes(v)) {
                                return true;
                            }
                        }
                    }
                };
                const filtroC = (v, req) => {
                    if (req !== null) {
                        if (req.nombre_razon_social.toString().toLowerCase() != v) {
                            return true;
                        }
                    }
                };
                // lista de solo los de public en general
                let general = res.data.filter(req => "PUBLICO EN GENERAL".toLowerCase()
                        .split(' ').every(v => filtroPG(v, req)))
                    // lista del resto del array sin PUBLICO GENERAL
                let cliente = res.data.filter(req => "PUBLICO EN GENERAL".toLowerCase()
                    .split('  ').every(v => filtroC(v, req)))

                general = general.concat(cliente)

                commit("SET_LIST_VENTAS_FACTURACION", general);
            }
        }).catch(err => console.log(err)),
    facturaCreate: async({ commit, rootState }, params) => await factura_create(params).then(
        res => {
            console.log("facturaCreate: ", res.data);
            // if (typeof res.data.response !== 'undefined') {
            //     if (res.data.response.status == 200) {
            if (typeof res.data.estatus !== 'undefined') {
                if (res.data.estatus.descripcion == "Timbrado del CFDI realizado con éxito") {
                    const vm = new Vue()
                    vm.generateAlert(res.data.estatus.descripcion, 'toast-success')

                    // filtro para publico general
                    const filtroPG = (v, req) => {
                        for (const prop in req) {
                            if (req[prop] !== null) {
                                if (req[prop].toString().toLowerCase().includes(v)) {
                                    return true;
                                }
                            }
                        }
                    };
                    // arreglo de public general checkeados
                    let publicoGeneral = rootState.facturacion.listVentaChecked.filter(req => "PUBLICO EN GENERAL".toLowerCase()
                        .split(' ').every(v => filtroPG(v, req)))
                    let cantPG = publicoGeneral.length;
                    let totalLimit = rootState.facturacion.listVentaChecked.length - cantPG;

                    // Sirve porque agregaba una iteracion al ser un solo cliente
                    // en actions tambien se utiliza
                    if (cantPG != 0) {
                        totalLimit = totalLimit + (cantPG - (cantPG - 1))
                    }

                    // filtro para solo clientes
                    const filtroC = (v, req) => {
                        if (req !== null) {
                            if (req.nombre_razon_social.toString().toLowerCase() != v) {
                                return true;
                            }
                        }
                    };
                    // arreglo de clientes checkeados
                    let clientes = rootState.facturacion.listVentaChecked.filter(req => "PUBLICO EN GENERAL".toLowerCase()
                        .split('  ').every(v => filtroC(v, req)))

                    let progres = 100 / totalLimit;
                    // establece el avance de porcentaje
                    commit("SET_INDEX_API", progres)
                    commit("SET_DELETE_LIST_VENTAS", rootState.facturacion.listVentasFacturacion.indexOf(clientes[rootState.facturacion.iClientes - 1]));

                    // valida que si ya es el ultimo, finaliza el progreso y limpia arreglos
                    if (rootState.facturacion.iteracionVentas == totalLimit) {
                        // if (rootState.facturacion.iteracionVentas == rootState.facturacion.listVentaChecked.length){
                        commit("SET_COLOR_PROGRESBAR", "success");
                        commit("SET_ESTATUS_VENTA", "enviado.");

                        setTimeout(() => {
                            commit("SET_LOADING", false);
                            commit("SET_CLEAR_INDEX_API");
                            commit("SET_COLOR_PROGRESBAR", "primary");
                            commit("SET_ESTATUS_VENTA", "");
                            commit("SET_RAZON_VENTA", "");

                            commit("SET_RELOAD_VENTA", 1);
                            commit("SET_ITER_CLIENTES", 0);
                            commit("SET_ITER_VENTAS", 0);
                        }, 5000)
                    }

                }
            } else {
                const vm = new Vue()
                vm.generateAlert(res.data.estatus.descripcion, 'toast-danger')
                commit("SET_COLOR_PROGRESBAR", "danger");
                commit("SET_ESTATUS_VENTA", "error.");
                commit("SET_LOADING", false);
            }
            return true;
            // commit("SET_LIST_VENTAS_FACTURACION", res.data);
        }).catch(err => {
        const vm = new Vue()
        vm.generateAlert("Error", 'toast-danger')
        commit("SET_COLOR_PROGRESBAR", "danger");
        commit("SET_ESTATUS_VENTA", "error.");
        commit("SET_LOADING", false);
        console.log("ERRfacturaCreate", err)
        return false;
        // const vm = new Vue()
        // vm.generateAlert(res.data.Descripcion,'toast-danger')
    }),
    facturaGeneralCreate: async({ commit, rootState }, params) => await factura_general_create(params).then(
        res => {
            console.log("facturaGeneralCreate: ", res.data);
            // if (typeof res.data.response !== 'undefined'
            // if (res.data.response[0].status == 200) {) {
            if (typeof res.data.estatus !== 'undefined') {
                if (res.data.estatus.descripcion == "Timbrado del CFDI realizado con éxito") {
                    // commit("SET_INDEX_API", 1)
                    const vm = new Vue()
                    vm.generateAlert(res.data.estatus.descripcion, 'toast-success')

                    // filtro para publico general
                    const filtroPG = (v, req) => {
                        for (const prop in req) {
                            if (req[prop] !== null) {
                                if (req[prop].toString().toLowerCase().includes(v)) {
                                    return true;
                                }
                            }
                        }
                    };
                    // arreglo de public general checkeados
                    let publicoGeneral = rootState.facturacion.listVentaChecked.filter(req => "PUBLICO EN GENERAL".toLowerCase()
                        .split(' ').every(v => filtroPG(v, req)))
                    let cantPG = publicoGeneral.length;
                    let totalLimit = rootState.facturacion.listVentaChecked.length - cantPG;
                    totalLimit = totalLimit + (cantPG - (cantPG - 1))

                    let progres = 100 / totalLimit;
                    // establece el avance de porcentaje
                    commit("SET_INDEX_API", progres)

                    // recorre solo lode publico general checkeados
                    publicoGeneral.forEach(element => {
                        // borra de uno en uno los de public general que estan checkeados
                        commit("SET_DELETE_LIST_VENTAS", rootState.facturacion.listVentasFacturacion.indexOf(element))
                    });

                    // valida que si ya es el ultimo, finaliza el progreso y limpia arreglos
                    if (rootState.facturacion.iteracionVentas == totalLimit) {
                        // if (rootState.facturacion.iteracionVentas == rootState.facturacion.listVentaChecked.length){
                        commit("SET_COLOR_PROGRESBAR", "success");
                        commit("SET_ESTATUS_VENTA", "enviado.");

                        setTimeout(() => {
                            commit("SET_LOADING", false);
                            commit("SET_CLEAR_INDEX_API");
                            commit("SET_COLOR_PROGRESBAR", "primary");
                            commit("SET_ESTATUS_VENTA", "");
                            commit("SET_RAZON_VENTA", "");

                            commit("SET_RELOAD_VENTA", 1);
                            commit("SET_ITER_CLIENTES", 0);
                            commit("SET_ITER_VENTAS", 0);
                        }, 5000)
                    }
                }
            } else {
                const vm = new Vue()
                vm.generateAlert(res.data.estatus.descripcion, 'toast-danger')
                commit("SET_COLOR_PROGRESBAR", "danger");
                commit("SET_ESTATUS_VENTA", "error.");
                commit("SET_LOADING", false);
            }
            return true;
            // commit("SET_LIST_VENTAS_FACTURACION", res.data);
        }).catch(err => {
        const vm = new Vue()
        vm.generateAlert("Error", 'toast-danger')
        commit("SET_COLOR_PROGRESBAR", "danger");
        commit("SET_ESTATUS_VENTA", "error.");
        commit("SET_LOADING", false);
        console.log("ERRfacturaGeneralCreate", err)
        return false;
        // const vm = new Vue()
        // vm.generateAlert(res.data.Descripcion,'toast-danger')
    }),

};