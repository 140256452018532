<template :id="idTemplate" :per-page="perPage" :current-page="currentPage">
    <div  >
        <!-- listado de items -->
        <b-col v-b-scrollspy:scrollspy-nested v-if="items.length > 0" class="col-md-12 " id="scrollspy-nested" style="max-height:calc(52vh); overflow-y:auto">
            <b-row v-b-toggle="'colapse-desc-'+(items.indexOf(item) + 1)" class="mt-3 mousehoverItem animate__animated animate__bounceIn" v-for="(item, index) in itemsPaginated" v-bind:key="index">
                
                <b-col class="col-md-1" >
                    <b-row >
                        <b-col cols="12" class="p-0 pt-2 text-titulo" >
                            <h1 class="text-center w-100" >{{ items.indexOf(item) + 1 }}</h1>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-3" >
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >TOTAL</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ toMoneda(item.total) }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-3" >
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >COBRADO</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ toMoneda(item.pagado) }} 
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-3" >
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >ADEUDO</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ toMoneda(item.adeudo) }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2" >
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >PAGOS REALIZADOS</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.pagos_realizados }}
                        </b-col>
                    </b-row>
                </b-col>


                <b-col class="col-md-4" >
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >FECHA VENTA</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.fecha }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-4" >
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >ULTIMO PAGO</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                            {{ item.ultimo_pago }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-4" >
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >COBRO</strong>
                        </b-col>
                            <b-form-input @focus="$event.target.select()" type="number" size="sm"
                            :max="item.adeudo"
                            @change="cambioIngreso(item)" :min="0" v-model.lazy="item.cobro"
                             ></b-form-input>
                    </b-row>
                </b-col>

            </b-row>
        </b-col>
        <!-- muestra que está vacío -->
        <b-col v-else class="col-md-12" >
            <b-row align-h="center" align-w="center" class="mb-1 mt-5" v-b-tooltip.hover title="Carrito vacío">
                <b-avatar icon="cart3" style="width: 170px; height: 170px;"></b-avatar>
            </b-row>
            <b-row align-h="center" align-w="center" class="" >
                <h3><strong class="">¡{{tituloItemsEmpty}}!</strong></h3>
            </b-row>
        </b-col>
    </div>
</template>
<script>
    // import Boton from '@/components/Boton'
    // import { mapState } from "vuex";
    export default {
        props:[ "tituloItemsEmpty", "perPage", "currentPage", "items", "idTemplate", "search", "edit" ],
        data() {
            return {
            };
        },
        components:{  },
        computed: { 
            // ...mapState([ "ingreso" ]),
            // paginación
            itemsPaginated(){ 
                return this.searchInDetail()
            }
        },
        watch: {
            "items": function () {
                // console.log("DETALLES: " , this.items);
            },
        },
        methods:{
            // busqueda dentro del array
            searchInDetail(){
                return this.items.filter(req => this.search.toLowerCase()
                .split(' ').every(v => this.conditionalFilter(v, req)))
            },
            conditionalFilter(v, req){
                for (const prop in req) {
                    if(req[prop] !== null){
                        if(req[prop].toString().toLowerCase().includes(v)){
                            return true;
                        }
                    }
                }
            },
            // muestra los primeros 172 caracteres del texto
            toggleText(text) {
                let res = "";
                let value = (text.length >= 172) ? 172 : (text.length / 2);
                for (let i = 0; i < value; i++) { res += text[i]; }
                return res += " ...";
            },
            cambioIngreso(item){
                if(item.cobro > item.adeudo){
                    item.cobro = item.adeudo;
                }
            },
            toMoneda(value){
                // return value;
                // console.log("value: " , value);
                return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            },
        }
    };
</script>
<style scoped>
</style>