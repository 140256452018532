import ordencompra from '../pages/ordencompra';

export default [
    {
      path: '/ordencompra/crear',
      component: ordencompra,
      name: 'crearOrdencompra',
      
    }
]
