import axios from "axios";
// import { HTTP } from "./http-common-printer";
export const print_ticket = (url_impresora, id_usuario, vendedor, id_venta) => new Promise ((rv, rj) => {
    axios.get(url_impresora + `caja.php?token=${localStorage.getItem('aceromax_HASH')}&id_venta=${id_venta}&id_usuario=${id_usuario}&vendedor=${vendedor}`).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const print_corte_caja = (url_impresora, id_usuario, vendedor, fecha) => new Promise ((rv, rj) => {
    axios.get(url_impresora `cortecaja.php?token=${localStorage.getItem('aceromax_HASH')}&fecha=${fecha}&id_usuario=${id_usuario}&vendedor=${vendedor}`).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const estatus_caja = (url_impresora, fecha) => new Promise ((rv, rj) => {
    axios.get(url_impresora + `estatus.php?fecha=${fecha}`).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});