<template>
    <b-card header-tag="header" class="mt-3 card-mini">
        <template v-slot:header>
            <!-- <b-row class="justify-content-md-center"> -->
                INFORMACIÓN
            <!-- </b-row> -->
        </template>
        <b-row class="justify-content-md-center" v-b-tooltip.hover title="Cliente">
            <b-avatar button variant="primary"  class="align-baseline"></b-avatar> 
            <!-- :text="iniciales"-->
        </b-row>
        <b-row class="justify-content-md-center" >
            <strong class="">{{devolucion.ventaHeader.nombre_razon_social}}</strong>
        </b-row>
        <b-input-group size="sm" class="mb-2 mt-2" v-b-tooltip.hover title="Fecha de la compra">
            <template v-slot:prepend>
                <span class="input-group-text" id="basic-addon">
                    <b-icon icon="calendar2-date-fill"></b-icon>
                </span>
            </template>
            <b-form-input @focus="$event.target.select()" :value="formatFecha(devolucion.ventaHeader.fecha)" disabled aria-label="Small text input with custom switch"></b-form-input>
        </b-input-group>

        <b-input-group size="sm" prepend="$" class="mb-2"  v-b-tooltip.hover title="Monto total de la compra">
            <b-form-input style="text-align:right;" @focus="$event.target.select()" :value="devolucion.ventaHeader.total" disabled aria-label="Small text input with custom switch"></b-form-input>
        </b-input-group>


                        <!-- <b-form-textarea id="textarea" placeholder="Motivo" rows="3" max-rows="6" ></b-form-textarea> -->

        <b-col class="col-md-12">
            <b-form-group>
                <b-row>
                    <b-row class="row mt-2 justify-content-md-center" align-v="start" align-h="start">

                        <b-col class="col-md-5 mr-2">
                            <Boton text="Cancelar" tooltip="Cancelar devolución"
                            icon="x-circle-fill" fontscale="2" :onClic="clicCancelar" variant="info" btn="btn-cancel" size="sm"/>
                        </b-col>

                        <b-col class="col-md-5 ml-2">
                            <Boton text="Guardar" tooltip="Guardar devolución"
                            :disabled="devolucion.arrayNuevo.length == '0'"
                            icon="file-earmark-check" fontscale="2" :onClic="realizarDevolucion" variant="info" btn="btn-default" size="sm"/>
                        </b-col>

                    </b-row>

                </b-row>
            </b-form-group>
        </b-col>
        <!-- modalpago -->
        <ModalEjemplo  />
    </b-card>
</template>

<script>
    import moment from 'moment'
    import router from '@/router'
    import ModalEjemplo from "./ModalEjemplo";
    import Boton from '@/components/Boton'
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
        },
        components:{ Boton, ModalEjemplo },
        data(){
            return{ search:"" }
        },
        computed: {
            ...mapState([ "contado", "devolucion"]),
        },
        watch:{
            // despues de actualizar la variable message y fue exitoso limpia cliente selected
            "caja.messageCaja": function (val) {
                if(val.clase == "toast-success"){
                    this.id_cliente = "";
                }
            },
        },
        methods:{
            ...mapActions([]),
            formatFecha(value){
                if(value){
                    return moment(value).format('YYYY-MM-DD')
                }
                return "";
            },
            clicCancelar(){

                router.push('/caja')
            },
            realizarVentaMODAL(){
                this.$bvModal.show('modalEjemplo');
            },
            clearDigit(value){
                return Number(value.replace(/[^0-9.-]+/g,""));
            },
            realizarDevolucion(){
                this.$bvModal.show('modalEjemplo');
            }
        }
    }
</script>