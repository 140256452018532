<template>
  <b-modal id="modal" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="REGISTRO" title-class="modal-title w-100" body-class="p-4" >
    <b-row>
        <!-- <b-row > -->
            <b-card bg-variant="light" style="width: 50rem;"  header="DATOS CONTACTO">
            <b-row >
                <b-col class="col-md-12" >
            <b-form-group id="input-group-1" label="NOMBRE / RAZON SOCIAL:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="dataModal.nombre_razon_social" ></b-form-input>
                <!--  -->
              </b-input-group>
            </b-form-group>
          </b-col>
           <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="RFC:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="dataModal.rfc" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="TELÉFONO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm">
                <b-form-input @focus="$event.target.select()" type="phone"  v-model="dataModal.telefono" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col style="padding-top:2.5rem;" class="col-md-4">
      <b-row>
        <b-form-checkbox switch v-model="dataModal.persona_moral" size="">PERSONA MORAL</b-form-checkbox>
      <!-- <b-form-checkbox style="padding-left:4rem;"  switch size="lg">Inactivo</b-form-checkbox> -->
      </b-row>
      
    </b-col>
           <b-col class="col-md-6">
            <b-form-group id="input-group-1" label="CONTACTO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm">
                <b-form-input @focus="$event.target.select()"  v-model="dataModal.contacto" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-6">
            <b-form-group id="input-group-1" label="E-MAIL:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm">
                <b-form-input @focus="$event.target.select()"  v-model="dataModal.e_mail" type="email" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
            </b-row>
            </b-card>
              <b-card bg-variant="light" style="width: 50rem;"  header="DATOS DOMICILIO">
            <b-row >
<b-col class="col-md-12">
            <b-form-group id="input-group-1" label="DIRECCIÓN:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="dataModal.direccion"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
            <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="COLONIA:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="dataModal.colonia"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
<b-col class="col-md-4">
            <b-form-group id="input-group-1" label="CIUDAD:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="dataModal.ciudad" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
 <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="ESTADO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm">
                <b-form-input @focus="$event.target.select()"  v-model="dataModal.estado" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
              </b-row>
            </b-card>
        
                     <b-card bg-variant="light" style="width: 50rem;"  header="DATOS CRÉDITO">
            <b-row>
        
       <b-col class="col-md-6">
            <b-form-group id="input-group-1" label="DESCUENTO PRONTO PAGO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm">
                <b-form-input @focus="$event.target.select()" type="number" v-model="dataModal.descuento_pronto_pago" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
       
         
           </b-row>
          </b-card>
          
         

    </b-row>
    

      
    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Guardar" tooltip="Guardar registro" class="mr-3"
        icon="lock-fill" fontscale="2" :onClic="realizarRegistro" 
        :disabled="dataModal.nombre_razon_social===' ' || dataModal.rfc===' ' || dataModal.direccion===' '
        || dataModal.descuento_pronto_pago < 0 || dataModal.descuento_pronto_pago == ' ' || dataModal.descuento_pronto_pago == ''"
        variant="info" btn="btn-secundary" size="sm"/>

        <Boton text="Cancelar" tooltip="Cancelar registro"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/>
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  import Vue2Filters from 'vue2-filters'
  
  export default {
    mounted(){
      // limpiar variables para tipo de pago
      this.proveedorList();
    },
    mixins: [Vue2Filters.mixin],
    components:{ Boton },
    data() {
      return {
        totalTexto:"",
        modalShow: false,
        dataModal: {
          nombre_razon_social: " ",
          direccion: " ",    
          colonia: " ",
          telefono: " ", 
          contacto: " ",  
          e_mail: " ",
          estado: " ",
          ciudad: " ", 
          rfc: " ",
          persona_moral: 0,
          descuento_pronto_pago: 0,
        },
        loading: false
      };
    },
    watch: {
      "proveedor.messageProveedor": function (val) {
                if(val.clase == "toast-success"){
                     this.generateAlert(val.mensaje, val.clase);
                     this.proveedorList();
                }
            }
      
        
    },
    computed:{
      ...mapState([ "proveedor", "auth"]),
    },
    methods:{
      ...mapActions([ "proveedorCreate", "proveedorList" ]),
      //let resCambio = parseFloat()
      restrictDecimal() {
        let cambio = parseFloat(this.dataModal.cambio);
        this.dataModal.cambio = cambio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      },
    
      // cierra el modal
      cancel() {
        this.modalShow = false;
        this.loading = false;
        this.dataModal = { 
        nombre_razon_social:'', 
        direccion:'',
        colonia:'',
        telefono:'',
        contacto:'',
        e_mail:'',
        estado:'',
        ciudad:'',
        rfc:'',
        persona_moral:0,
        descuento_pronto_pago:0
        };
        this.proveedorList();
        return this.modalShow;
      },
      // setea todo el array
      setDetailRequisicionArray() {
        this.loading = true;
        
        this.cancel();
      },
      clearDigit(value){
        return Number(value.replace(/[^0-9.-]+/g,""));
      },
      realizarRegistro(){

        let data = {
          nombre_razon_social: this.dataModal.nombre_razon_social ? this.dataModal.nombre_razon_social : "NO TECLEADO",
          direccion: this.dataModal.direccion ? this.dataModal.direccion : "NO TECLEADO", 
          colonia: this.dataModal.colonia ? this.dataModal.colonia : "NO TECLEADO",
          telefono: this.dataModal.telefono ? this.dataModal.telefono : "NO TECLEADO", 
          contacto: this.dataModal.contacto ? this.dataModal.contacto : "NO TECLEADO",
          e_mail: this.dataModal.e_mail ? this.dataModal.e_mail : "NO TECLEADO",
          estado: this.dataModal.estado ? this.dataModal.estado : "NO TECLEADO",
          ciudad: this.dataModal.ciudad ? this.dataModal.ciudad : "NO TECLEADO",
          rfc: this.dataModal.rfc ? this.dataModal.rfc : "NO TECLEADO",
          persona_moral:1,//this.dataModal.persona_moral
          descuento_pronto_pago: this.dataModal.descuento_pronto_pago ? this.dataModal.descuento_pronto_pago : "0" ,
          id_usuario: this.auth.user.idUser
        }
        console.log('data', data)
        console.log('modal', this.dataModal)
        this.proveedorCreate(data);
        this.proveedorList();
        this.cancel();
      }

    },
  };
</script>
<style>
</style>