<template>
  <b-modal id="ModalCompra" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="EDITAR" title-class="modal-title w-100" body-class="p-4" >
    <b-row>
      <b-col class="col-md-12" >
        <Autocomplete placeholder="Selecciona una opción"  cols="col-md-12"
          :items="ordencompra.proveedoresList" text="nombre_razon_social" assign="id_proveedor"  
          label="PROVEEDOR:" filter="nombre_razon_social" v-model="datosModal.id_proveedor" />
      </b-col>
      <!-- <b-col class="col-md-6" >
        <b-form-group id="input-group-1" label="CREDITO:" label-class="form-label" label-for="input-1"  class="mb-0">
            <b-form-checkbox switch size="lg"></b-form-checkbox>
        </b-form-group>
      </b-col> -->
      <b-col class="col-md-6" >
        <b-form-group id="input-group-1" label="TOTAL DE COMPRA:" label-class="form-label" label-for="input-1"  class="mb-0">
          <b-input-group disabled class="m-0" size="md" prepend="$">
            <b-form-input style="text-align:right;" @focus="$event.target.select()" v-model="ordencompra.totalCompra.total" disabled ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col class="col-md-6">
        <b-form-group id="input-group-1" label="Observaciones:" label-class="form-label" label-for="input-1"  class="mb-0">
          <b-input-group disabled class="m-0" size="lg">
            <b-form-textarea id="textarea" v-model="datosModal.observaciones" placeholder="" rows="4" max-rows="6" ></b-form-textarea>
          </b-input-group>
        </b-form-group>
      </b-col>

    </b-row>
    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Guardar" class="mr-3"
        icon="lock-fill" fontscale="2" :onClic="realizarCompra" variant="info" btn="btn-secundary" size="sm"/>
        <Boton text="Cancelar" tooltip="Cancelar"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/>
        
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
  import Autocomplete from '@/components/Autocomplete'
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  import moment from 'moment'
  export default {
    mounted(){
      this.proveedorListOrdenCompra();
    },
    components:{ Boton, Autocomplete },
    data() {
      return {
        modalShow: false,
        datosModal:{
          id_proveedor: 0,
          observaciones: "",
          estatus_pago:1,
          fecha:"",
          id_usuario:0,
        },
      };
    },
    watch: {
      // despues de actualizar la variable mensaje despues de guardar lanza notificacion
      "ordencompra.messageOrdenCompra": function (val) {
        if(val.mensaje != ""){
          this.generateAlert(val.mensaje, val.clase);
        }
      },
    },
    computed:{
      ...mapState([ "ordencompra", "auth"]),
    },
    methods:{
      ...mapActions([ "ordenCompraCreate", "proveedorListOrdenCompra", "categoriaList" ]),
      // cierra el modal
      cancel() {
        this.modalShow = false;
        this.datosModal = {  id_proveedor: 0, observaciones: "", estatus_pago:1, fecha:"", id_usuario:0, };

        // limpia las variables declaradas
        this.$store.commit("SET_COMMIT_LOAD_PRODUCTOS", true);
        this.$store.commit("SET_COMMIT_SHOW", "categoria");
        this.$store.commit("SET_COMMIT_PRODUCTOS", []);
        // carga la lsita de categoria
        this.categoriaList();

        return this.modalShow;
      },
      // lanza la ruta de guardar
      realizarCompra(){
        // si no hay observaciones agrega texto de SIN OBSERVACIONES
        if(this.datosModal.observaciones ==""){
          this.datosModal.observaciones = "SIN OBSERVACIONES";
        }
        let fecha = moment(new Date()).format('YYYY-MM-DD');
        this.datosModal.fecha = fecha;
        this.datosModal.id_usuario = this.auth.user.idUser;
        this.ordenCompraCreate(this.datosModal);

        this.cancel();
      }
    },
  };
</script>
<style>
</style>