/*eslint-disable no-unused-vars*/
import { proveedor_get, create_Proveedor, update_Proveedor, proveedores_list } from '@/api/proveedor' 
import router from '@/router'
export const actions = {
    // setLoad:({commit})=>{
    //     commit("SET_COMMIT_LOAD_PROVEEDORES", false);
    // },
    //lista de proveedores
    proveedorList: async({ rootState, commit, dispatch }) => proveedores_list(rootState.auth.user.idUser).then(
        res=>{
            commit("SET_COMMIT_PROVEEDOR", res.data);
            dispatch("setLoad")
        }).catch(err=>console.log(err)
    ),

    //Obtener el proveedor
    proveedorGet: async({ rootState, commit }, id_proveedor) => proveedor_get(rootState.auth.user.idUser, id_proveedor).then(
        res=>{
            commit("SET_COMMIT_GET_PROVEEDORES", res.data);
            //dispatch("setLoad")
        }).catch(err=>console.log(err)
    ),
    //Registrar proveedor
    proveedorCreate:async({ commit, dispatch}, data) => create_Proveedor(data).then(
        res=>{
            console.log("res: " , data);
            commit("SET_MESSAGE_PROVEEDOR",{mensaje: res.data.message,
                clase: (res.data.message === "EXITO") ? "toast-success" : "toast-danger"})

            // 

            // setTimeout(async ()=>{
            //     dispatch("successProveedor");
            // },800)
        }).catch(err=>console.log(err)
    
    ),

    //Actualizar proveedor
    proveedorUpdate:async({commit, dispatch}, data) => update_Proveedor(data).then(
        res=>{
            commit("SET_MESSAGE_PROVEEDOR",{mensaje: res.data.message,
                clase: (res.data.message === "EXITO") ? "toast-success" : "toast-danger"})

            // console.log("res: " , res.data);

            // setTimeout(async ()=>{
            //     dispatch("successProveedor");
            // },800)
        }).catch(err=>console.log(err)
    ),
    //Eliminar articulo
    proveedorDelete:async({commit, dispatch}, data) => update_Proveedor(data).then(
        res=>{
            commit("SET_MESSAGE_PROVEEDOR",{mensaje: res.data.message,
                clase: (res.data.message === "EXITO") ? "toast-success" : "toast-danger"})

            // console.log("res: " , res.data);

            // setTimeout(async ()=>{
            //     dispatch("successProveedor");
            // },800)
        }).catch(err=>console.log(err)
    ),
    
    
};