/*eslint-disable no-unused-vars*/
import { venta_detail_list, venta_header_get, devolucion_mov_create, devolucion_detail_create, articulo_list} from '@/api/devolucion'
 import router from '@/router'
 import Vue from 'vue'

export const actions = {
    // cambia la variable load a false
    setLoad:({commit})=>{
        commit("SET_COMMIT_LOAD_COMPRAS", false);
    },
    // list los articulos ///////////////////CHECAR LOS ARTICULOS
    articulosList: async({ rootState, commit, dispatch }, id_subcategoria) => articulo_list(rootState.auth.user.idUser, id_subcategoria).then(
        res=>{
            commit("SET_COMMIT_ARTICULOS", res.data);
            dispatch("setLoad")
        }).catch(err=>console.log(err)
    ), 
    // list detalles de Venta 
    ventaDetailList: async({ rootState, commit, dispatch }, id_venta) => venta_detail_list(rootState.auth.user.idUser, id_venta).then(
        res=>{
            // valida contenido de array
            if(res.data.length == 0){
                const vm = new Vue();
                vm.generateAlert("No existe movimiento de venta", "toast-success");
            }
            commit("SET_COMMIT_VENTADETAIL", res.data);
            dispatch("setLoad")
        }).catch(err=>console.log(err)),
    //Obtiene el detalle de la Venta realizada
    articuloGetDirectoDevolucion: async({ rootState, commit, dispatch }, id_venta) => venta_header_get(rootState.auth.user.idUser, id_venta).then(
        res=>{
            commit("SET_COMMIT_VENTAHEADER", res.data);
            dispatch("setLoad")
        }).catch(err=>console.log(err)
    ),
    devolucionMovCreate: async({rootState, commit, dispatch}, datos) => devolucion_mov_create(datos).then(
        res=>{
            // commit("SET_MESSAGE_DEVOLUCION",{mensaje: res.data[0].message,
            //     clase: (res.data[0].message === "EXITO") ? "toast-success" : "toast-danger"})

            console.log("devolucionMovCreate: " , res.data);
            // let dataDetailCreate = new Array();
            // rootState.devolucion.ventaDetailList.forEach(element => {
            //     let dato = {
            //         id_devolucion: res.data.id_devolucion,
            //         id_articulo: element.id_articulo,
            //         cantidad: element.cantidad,
            //         id_unidad: element.id_unidad,
            //         id_usuario: rootState.auth.user.idUser,
            //     }
                
            //     dataDetailCreate.push(dato);
            // });
            let dataDetailCreate;
            rootState.devolucion.ventaDetailList.forEach(element => {
                let dato = {
                    id_devolucion: res.data.id_devolucion,
                    id_articulo: element.id_articulo,
                    cantidad: element.cantidad,
                    id_unidad: element.id_unidad,
                    id_usuario: rootState.auth.user.idUser,
                }
                
                dataDetailCreate = dato;
            });
            setTimeout(async ()=>{
                dispatch("devolucionDetailCreate", dataDetailCreate);
            },800)
        }).catch(err=>console.log(err)
    ),

    devolucionDetailCreate: async({commit, dispatch}, datos) => devolucion_detail_create(datos).then(
        res=>{
            commit("SET_MESSAGE_DEVOLUCION",{mensaje: res.data.message,
                clase: (res.data.message === "EXITO") ? "toast-success" : "toast-danger"})

                console.log("devolucionDetailCreate: " , res.data);

            setTimeout(async ()=>{
                dispatch("successDevolucion");
            },800)
        }).catch(err=>console.log(err)
    ),

    successDevolucion:({ commit })=>{
        //commit("SET_COMMIT_CARRITO_CLEAR");
        // dispatch("clicReverse");
        router.push('/caja')
    },
    arrayNuevo:({commit})=>{
        let importe = 0.00;

        commit("SET_COMMIT_ARRAY_NUEVO",{
            importe
        });
        // dispatch("clicReverse");
        //router.push('/devolucion')
    },
};