import Vue from 'vue'
import Vuex from 'vuex'

//modules
import auth from './auth'
import caja from './caja'
import cortecaja from './cortecaja'
import cotizacion from './cotizacion'
import devolucion from './devolucion'
import factura from './factura'
import facturacion from './facturacion'
import venta from './venta'
import contado from './contado'
import cobro from './cobro' 
import cliente from './cliente' 
import proveedor from './proveedor'
import articulos from './articulos'
import ordencompra from './ordencompra'
import ordencomprainventario from './ordencomprainventario'
import ingreso from './ingreso'

// eslint-disable
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        alert:{}
    },
    mutations: {
        SET_ALERT(state,alert){
            state.alert = alert
        }
    },
    actions: {
        
    },
    modules: {
        auth,
        caja,
        cortecaja,
        facturacion,
        devolucion,
        cotizacion,
        factura,
        venta,
        contado,
        cobro,
        cliente,
        proveedor,
        articulos,
        ordencompra,
        ordencomprainventario,
        ingreso
    }

})