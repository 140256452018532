// *** Pantalla que se muestra desde el boton de contado
<template>
  <b-container fluid>
    <b-col class="p-0 pb-5 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
        
        <b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
            <b-col cols="12">
                <Acciones :keyUpVentaEspecial="true"/>
            </b-col>
        </b-row>
        
        <Contado />
        
    </b-col>
  </b-container>
</template>

<script>
    import Acciones from "../caja/Acciones";
    import Contado from "./Contado";
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
            this.$store.commit("SET_COMMIT_CLIENTE_SELECTED", {nombre_razon_social:"No Seleccionado", limite_credito: "0.00"})
        },
        components: { Acciones, Contado },
        data() {
            return {
            }
        },
        computed: mapState([""]),
        methods: {
            ...mapActions([])
        }
    }
</script>
