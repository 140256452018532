<template :id="idTemplate" :per-page="perPage" >
    <div v-b-scrollspy:scrollspy-nested >
        <b-col class="col-md-12 " id="scrollspy-nested" style="line-height: 2; max-height:calc(52vh); overflow-y:auto">

            <b-row class="mt-3" v-for="(item, index) in itemsPaginated" v-bind:key="index">
                <!--v-bind:key="index" in itemsPaginated"  -->
                <b-col style="font-size: 3rem;" class="col-md-1">
                    <b-row >
                        <b-col style="font-size: 3rem;" cols="12" class="p-0 pt-2 text-titulo" >
                            <h1 style="font-size: 3rem;"  class="text-center w-100" >{{ itemsPaginated.indexOf(item) + 1 }}</h1>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >CODIGO</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.clave }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >DESCRIPCIÓN</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.descripcion }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-1">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >UNIDAD</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.unidad }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-1">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >CANTIDAD</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.cantidad }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >PRECIO</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.precio }}
                        </b-col>
                    </b-row>
                </b-col>
                 <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >IMPORTE</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.importe }}
                        </b-col>
                    </b-row>
                </b-col>
                 
                 <b-col class="col-md-1">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >DEVOLVER</strong>
                        </b-col>
                         <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                <b-form-checkbox :disabled="item.cantidad == 0" size="lg" v-model="item.check" name="check-button" switch
                                    @change.native="materialSelect(item)">
                                </b-form-checkbox>
                        </b-col> 
                    </b-row>
                </b-col>
               
                        
                        
            </b-row>
            <!-- <b-row class="p-0 m-0 mt-3"> -->
                <!-- <b-col  class="col-sm-1 text-titulo " style="display: flex; align-items: center;" >
                </b-col> -->
                
                <!-- <b-col class="col-sm-12"> -->
                <!-- </b-col> -->
                
            <!-- </b-row> -->
        </b-col>



        <!--<b-col class="col-md-12">
             paginación 
            <div class="mt-3 justify-content-md-center" v-show="items.length > 0">
                <b-pagination v-model="currentPage" :total-rows="searchInDetail.length" align="center" 
                    :per-page="perPage"
                    :aria-controls="idTemplate">
                </b-pagination>
            </div>
        </b-col>-->
    </div>
</template>
<script>
    import { mapState  } from "vuex";
    //import Boton from '@/components/Boton'
    //import Item from '@/components/Item'
    export default {
        mounted(){
            
        },
        props:["perPage",  "items", "materialSelect", "idTemplate", "search", "actions" ],//"currentPage",
        data() {
            return { };
        },
        components:{  },//Item
        computed:{
             ...mapState([ "venta", "contado", "devolucion"]),
          // busqueda dentro del array
            searchInDetail(){
                return this.items.filter(req => this.search.toLowerCase()
                .split(' ').every(v => this.conditionalFilter(v, req)))
            },
            
            //paginacion
             itemsPaginated(){ 
                 return this.items;
                //  return this.items.slice(
                //      (this.currentPage - 1) * this.perPage,
                //      this.currentPage * this.perPage
                //  )
             }
        },
        methods:{
           // muestra los primeros 172 caracteres del texto
            toggleText(text) {
                let res = "";
                let value = (text.length >= 172) ? 172 : (text.length / 2);
                for (let i = 0; i < value; i++) { res += text[i]; }
                return res += " ...";
            },
            conditionalFilter(v, req){
                for (const prop in req) {
                    if(req[prop] !== null){
                        if(req[prop].toString().toLowerCase().includes(v)){
                            return true;
                        }
                    }
                }
            },
        }
    };
</script>
<style scoped>
</style>