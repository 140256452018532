<template id="articulos" :per-page="perPage" :current-page="currentPage">
    <div v-b-scrollspy:scrollspy-nested >
        <!-- <b-col class="col-md-12 " > -->
            <b-list-group-item href="#" class="flex-column align-items-start col-md-12 mousehoverItem" 
                id="scrollspy-nested" style="line-height: 2; max-height:calc(52vh); overflow-y:auto"
                v-for="(item, index) in itemsPaginated" v-bind:key="index">
                <b-row >
                    <b-col class="col-md-1" >
                        <b-row >
                            <b-col style="font-size: 3rem;" cols="12" class="p-0 pt-2" >
                                <h1 style="font-size: 3rem;" class="text-center w-100 h-100" >{{ items.indexOf(item) + 1 }}</h1>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="col-md-9" v-on:click="verImagen(item.imagen)">
                        <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">{{ item.clave }}</h5>
                            <small class="text-muted">{{ item.existencia }} disponibles</small>
                        </div>
                        <p class="mb-1">
                            {{ item.descripcion }}
                        </p>
                    </b-col>
                    <b-col align-h="right">
                        <b-row class=" h-100" align-h="right"> 
                            <Boton align-h="right"
                            icon="chevron-compact-right" fontscale="2" :onClic="clicArticulo" :valorEventClic="item"
                            variant="danger" btn="btn-secundary" size="md" />
                        </b-row>
                    </b-col>
                </b-row>
            </b-list-group-item>
                <!-- <b-col class="col-md-8">
                    <b-row >
                        <b-col cols="12" class="p-1 pl-2 text-titulo text-shadow mb-0"
                            >
                            <strong >DESCRIPCIÓN</strong>
                        </b-col>
                        <b-col cols="9" class="p-2 text-shadow text-descripcion" style="margin-top: 0px !important;">
                            <b-collapse :visible="item.descripcion.length <= 171" :id="'colapse-material-'+(items.indexOf(item) + 1)">
                                {{ item.descripcion }}
                            </b-collapse>
                            <b-collapse :visible="item.descripcion.length >= 172" :id="'colapse-material-'+(items.indexOf(item) + 1)">
                                {{ toggleText(item.descripcion) }}
                            </b-collapse>
                        </b-col>
                        <b-col cols="3" class="p-1 pl-2 text-shadow text-descripcion" style="margin-top: 0px !important;">
                            <b-collapse :visible="item.descripcion.length >= 172" :id="'colapse-material-'+(items.indexOf(item) + 1)">
                                <b-button 
                                    v-b-toggle="'colapse-material-'+(items.indexOf(item) + 1)"
                                    variant="link" class="m-0 p-0">
                                    Ver más
                                </b-button>
                            </b-collapse>
                            <b-collapse :visible="item.descripcion.length <= 171" :id="'colapse-material-'+(items.indexOf(item) + 1)">
                                <b-button 
                                    v-b-toggle="'colapse-material-'+(items.indexOf(item) + 1)"
                                    variant="link" class="m-0 p-0">
                                    Ocultar
                                </b-button>
                            </b-collapse>
                        </b-col>
                    </b-row>
                </b-col> -->
        <!-- </b-col> -->
        <b-col class="col-md-12">
            <!-- paginación -->
            <div class="mt-3 justify-content-md-center" v-show="items.length > 0">
                <b-pagination v-model="currentPage" :total-rows="items.length" align="center" 
                    :per-page="perPage"
                    aria-controls="articulos">
                </b-pagination>
            </div>
        </b-col>
    </div>
</template>
<script>
    // import { mapState } from "vuex";
    import Boton from '@/components/Boton'
    export default {
        props:[ "items", "clicArticulo", "verImagen" ],
        data() {
            return {
                perPage: 7,
                currentPage: 1
            };
        },
        components:{ Boton },//Boton
        computed:{
            // ...mapState([ ]),
            // paginación
            itemsPaginated(){ 
                return this.items.slice(
                    (this.currentPage - 1) * this.perPage,
                    this.currentPage * this.perPage
                )
            }
        },
        methods:{
            // muestra los primeros 172 caracteres del texto
            toggleText(text) {
                let res = "";
                let value = (text.length >= 172) ? 172 : (text.length / 2);
                for (let i = 0; i < value; i++) { res += text[i]; }
                return res += " ...";
            },
        }
    };
</script>
<style scoped>
</style>