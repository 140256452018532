<template>
    <b-card header-tag="header" class="mt-3 card-mini" style="min-height: 740px;">
        <template v-slot:header>
            <b-row class="p-2">
                PRODUCTOS

                <b-input-group class="mt-3">
                    <b-col class="col-md-3">
                        <Autocomplete class="mr-1" placeholder="Categoria"  
                        cols="col-md-12"
                        :items="articulos.categoriasListEdit" text="categoria" 
                        v-model="id_categoria" 
                        filter="categoria" assign="id_categoria" label="" />
                    </b-col>
                    <b-col class="col-md-3">
                        <Autocomplete class="mr-1" placeholder="Subcategoria"  
                        cols="col-md-12"
                        :items="articulos.subcategoriasListEdit" text="sub_categoria" 
                        v-model="id_sub_categoria" 
                        filter="sub_categoria" assign="id_sub_categoria" label="" />
                    </b-col> 
                    <b-form-input @focus="$event.target.select()" v-model.number="precio" type="number" required placeholder="Precio"
                    ></b-form-input>

                    <b-button v-b-tooltip.hover type="button" size="sm" class="btn-secundary"
                        @click="aplicarTodos" :disabled="precio == '' || precio < 0 || id_categoria == 0" >
                        <b-col style="color:#ffff;" cols="12" >Aplicar a todos</b-col>
                    </b-button>
                    
                </b-input-group>
            </b-row>
        </template>

        <ItemsListArticulos class="p-1 animate__animated animate__bounceInLeft animate__fast 500ms"
         :items="articulos.articulosListEdit" :clicArticulo="aplicar"/>

    </b-card>
</template>
<script>
    import Autocomplete from '@/components/Autocomplete'
    import ItemsListArticulos from './ItemsListArticulos'
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
            // limpia array de lista de precios
            // this.$store.commit("SET_COMMIT_LIST_ARTICULOS_EDIT", []);
            this.categoriaTextoList();
        },
        components:{ ItemsListArticulos, Autocomplete },
        data(){
            return{
                precio:'',
                id_categoria: 0,
                id_sub_categoria: 0,
            }
        },
        watch: {
            "articulos.articulosListEdit": function (val) {
                console.log("valvalval: ", val);
            },
            "id_categoria": function (val) {
                if(val){
                    //Carga el select
                    this.subcategoriaTextoList(val);
                    this.articulosPrecioList({id_categoria: this.id_categoria, id_sub_categoria:0});
                }
            },
            "id_sub_categoria": function (val) {
                if(val){
                    //Carga el select
                    this.subCategoriaListCrud(val);
                    this.articulosPrecioList({id_categoria: 0, id_sub_categoria:this.id_sub_categoria});
                }
            },
             "articulos.messageArticuloEdit": function (val) {
                if(val.clase == "toast-success"){
                    this.generateAlert(val.mensaje, val.clase);
                    this.articulosPrecioList({id_categoria: this.id_categoria, id_sub_categoria: this.id_sub_categoria});
                    this.precio = '';
                }
            },
        },
        computed: mapState([ "caja", "articulos", "auth" ]),
        methods:{
            ...mapActions(["categoriaListCrud", "subCategoriaListCrud", "articuloList", "articuloGet", "clicReverse",
            "subcategoriaTextoList", "articulosPrecioList", "categoriaTextoList", "precioUpdate"]),

            aplicarTodos(){
                let params = {
                    id_categoria: this.id_sub_categoria == 0 ? this.id_categoria : 0,
                    id_sub_categoria: this.id_categoria != 0 && this.id_sub_categoria != 0 ? this.id_sub_categoria : 0,
                    id_articulo: 0,
                    costo: this.precio,
                    id_usuario: this.auth.user.idUser
                }
                this.precioUpdate(params);
            },
            aplicar(item){
                let params = {
                    id_articulo: item.id_articulo,
                    id_sub_categoria: 0,
                    id_categoria: 0,
                    costo: item.precio2,
                    id_usuario: this.auth.user.idUser
                }
                this.precioUpdate(params);
            },
        }
    }
</script>