/*eslint-disable no-unused-vars*/
import { login } from '@/api/auth'
import router from '@/router'

export const actions = {
    login: async({ commit }, auth) => login(auth).then(
        res=>{
            if(res.data.status>400) commit("SET_MESSAGE",res.data.message)
            else{
                localStorage.setItem('aceromax_HASH',res.data.HASH)
                localStorage.setItem('aceromax_PERMISSIONS',res.data.permisos)
                // establece por default la ip de la impresora
                localStorage.setItem('URL_IMPRESORA',res.data.url_impresora)
                
                localStorage.setItem('aceromax_USER',JSON.stringify({
                    idUser:res.data.id_user,
                    firstName: res.data.first_name,
                    lastName: res.data.last_name,
                    email: res.data.e_mail,
                    idRol: res.data.id_rol,
                    rolName: res.data.rol_name,

                    is_admin: res.data.is_admin,
                    is_casher: res.data.is_casher,
                    is_warehouse: res.data.is_warehouse,
                    id_rol: res.data.id_rol,
                    id_sucursal: res.data.id_sucursal,

                    cert_sat: res.data.cert_sat,
                    clave_sat: res.data.clave_sat,
                    key_sat: res.data.key_sat,
                    regimen_fiscal: res.data.regimen_fiscal,
                    rfc: res.data.rfc,
                    direccion: res.data.direccion,
                    sucursal: res.data.sucursal,

                    URL_IMPRESORA: res.data.url_impresora
                }))
                commit('SET_USER',{
                    idUser:res.data.id_user,
                    firstName: res.data.first_name,
                    lastName: res.data.last_name,
                    email: res.data.e_mail,
                    idRol: res.data.id_rol,
                    rolName: res.data.rol_name,

                    is_admin: res.data.is_admin,
                    is_casher: res.data.is_casher,
                    is_warehouse: res.data.is_warehouse,
                    id_rol: res.data.id_rol,
                    id_sucursal: res.data.id_sucursal,

                    cert_sat: res.data.cert_sat,
                    clave_sat: res.data.clave_sat,
                    key_sat: res.data.key_sat,
                    regimen_fiscal: res.data.regimen_fiscal,
                    rfc: res.data.rfc,
                    direccion: res.data.direccion,
                    sucursal: res.data.sucursal,

                    URL_IMPRESORA: res.data.url_impresora
                })
                // console.log("AUT: " , res.data);

                // is_casher = 1 -> directo a caja
                // is_admin = 1 -> pantalla en banco

                router.push('/caja')
            }
        }).catch(err=>console.log(err)
            
    ),
    
    logout:({state, commit})=>{
        commit('SET_USER',"");
        localStorage.clear()
        router.push('/')
    }
};