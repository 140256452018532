<template>
    <b-form-group :label="label" label-for="input-1" class="m-0" @mouseleave="overBan(false)">
        <b-form-input :disabled="disabled" :state="state" v-model="val" :placeholder="placeholder" @mouseleave="overBan(false)" @blur="showOptions()"
        @click.prevent="show=true" autocomplete="off"></b-form-input>
        <b-list-group class="autocomplete-list" @mouseover="overBan(true)" @mouseleave="overBan(false)" v-show="show">
            <b-list-group-item :style="item.color ? item.color + item.colorText : ''" :class="'list-group-item ' + cols"
                v-for="(item, index) in itemsFiltering" :key="index" @click="selectItem(item)" button>
                {{text ? item[text] : item}}
            </b-list-group-item>
        </b-list-group>
    </b-form-group>
</template>
<script>
// ***** PARAMS *****
//placeholder: PLACEHOLDER DE b-form-input
//label: LABEL DE b-form-group
//items: ARRAY DONDE SE RECORRE EL COMPONENT b-list-group-item
//text: VALOR QUE MOSTRARA AL USUARIO EL b-list-group-item
//filter: VALOR POR EL CUALO SE FILTRARAN EL ARRAY items
//assign: VALOR QUE SE LE VA ASIGNAR A LA VARIABLE DEL COMPONENTE PADRE, 
//SI NO TIENE VALOR SE LE ASIGNA TODO EL ITEM SELECCIONADO
export default {
    props:["search", "state","placeholder", "label", "items", "text", "filter", "assign", "value", "cols", "disabled"],
    data() {
        return {
            val:"",
            show:false,
            overSelect:false
        }
    },
    watch:{
        val(val){
            if(val.toString()==='' && !this.overSelect){
                this.show=false
            }
            if(val.toString().length === 1){
                this.show=true
            }
        },
        value(val){
            console.log("valval: " , val );
            if(val === ''){
                this.val=''
            }
            else{
                if(this.assign!==undefined) {
                    
                    let valor = this.items.filter(item=> item[this.assign]===val)[0]
                     
                    this.val= this.text ? valor[this.text] : valor
                }
                else { this.val = this.text ? val[this.text] : val }
            }
        }
    },
    computed: {
        // se modifico para no filtre
        itemsFiltering(){
            if(this.filter) {
                return this.items.filter(
                    item =>  
                        item[this.filter].toString()
                            .toLowerCase()
                                .includes(this.search ? this.val.toString().toLowerCase() : ""))
                                // .includes("this.val.toString().toLowerCase()"))
            }else{
                return this.items.filter(
                    item=>
                        item.toString()
                            .toLowerCase()
                                .includes(this.search ? this.val.toString().toLowerCase() : ""))
                                // .includes("this.val.toString().toLowerCase()"))
            }
        },
        model: {
            get() {
                return this.value
            },
            set(model) { this.$emit('input', model)}
        }
    },
    methods:{
        selectItem(item){
            this.val = this.text ? item[this.text] : item
            this.emitValue(item)
            this.show = false
        },
        overBan(ban){
            this.overSelect=ban
        },
        showOptions(){
            let val = this.items.filter(item => this.filter ? item[this.filter].toString().toLowerCase() === this.val.toString().toLowerCase() : item.toString().toLowerCase() === this.val.toString().toLowerCase() )
            
            // console.log(this.overSelect);
            // console.log(val.length);
            if(val.length===0 && !this.overSelect) {
                this.val = ""
                this.emitValue(false)
                this.show=false 
            }
            if(val.length>0 && !this.overSelect) {
                this.show=false 
            }
        },
        emitValue(item){
            if(item){
                if(this.assign) { this.model = item[this.assign] }
                else { this.model = item }
            }else{
                this.model = ''
            }
        }
    }
}
</script>
<style scoped>
    .autocomplete-list{
        overflow-y: overlay;
        max-height: 14rem;
        padding-top: 0.375rem;
        padding-right: 0.75rem;
        padding-bottom: 0.375rem;
        width: 98%;
        position: absolute;
        z-index: 1;
    }
</style>