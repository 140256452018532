<template>
    <b-container fluid class="p-0 m-0 h-100">
        <Header/>
        <router-view/>

	<footer>
		
		<div class="footer-limiter">
			<div class="footer-right">

                <center>
                    <b-link href="https://janambrestudios.mx/" target="_blank">
                        <b-img src="https://janambrestudios.mx/wp-content/uploads/2020/04/logo-janambre.png" fluid class="logo-janambre mr-3"></b-img>
                    </b-link>
                </center>
                <span class="text-footer-r text-small">Diseñado y desarrollado por Janambre Studios</span>

			</div>
			<!-- <div class="footer-left">
				<p class="footer-links">

                    <b-link href="https://aceromax.mx/" target="_blank">
                        <b-img width="60" :src="require('@/assets/img/acero-blanco.png')" fluid class="logo-header"></b-img> <br>
                        <b-img width="100" height="10" :src="require('@/assets/img/acero-blanco-texto.png')" class="logo-header"></b-img>
                    </b-link>
                    
                </p> -->
				<!-- <p>Aceromax 2020</p> -->
			<!-- </div> -->

		</div>

  </footer>

    </b-container>  
</template>

<script>
import Header from './Header'
import { mapState } from 'vuex'

export default {
    components:{
        Header
    },
    watch:{
        alert(val){
            
            if(Object.keys(val).length>0){
                
                this.generateAlert(Object.values(val)[0],Object.values(val)[1])
                this.$store.commit("SET_ALERT",{})
            }
        }
    },
    computed:mapState(["alert"])
}
</script>