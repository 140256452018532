import { orden_compra_detail_list, orden_compra_articulos_list, orden_compra_list } from '@/api/ordencompra'
import { articulo_get, unidad_get } from '@/api/caja'
import { lote_detail_create } from '@/api/ingreso'
import moment from 'moment'
export const actions = {
    //guarda el LOTE
    loteDetailCreate: async({ commit }, datos) => lote_detail_create(datos).then(
        res=>{
            console.log("loteDetailCreate: " , res.data);
            commit("SET_MESSAGE_INGRESO",{mensaje: res.data[0].message,
                clase: (res.data[0].message === "EXITO") ? "toast-success" : "toast-danger"})

            // commit("SET_COMMIT_COMPRA_CLEAR");
        }).catch(err=>console.log(err)
    ),
    //Obtener el articulo de la orden de compra
    articuloGetIngreso: async({ rootState, commit }, id_articulo) => articulo_get(rootState.auth.user.idUser, id_articulo).then(
        res=>{
            // console.log("articuloGetIngreso: " , res.data);
            commit("SET_COMMIT_UPDATE_ART_COMPRA_DETAIL_LIST", {datos: res.data, id_articulo:id_articulo});
            // commit("SET_COMMIT_ARTICULO_GET_INGRESO", res.data);
        }).catch(err=>console.log(err)
    ),
    //Obtener la unidad de la orden de compra
    unidadGetIngreso: async({ rootState, commit }, id_unidad_solicitada) => unidad_get(rootState.auth.user.idUser, id_unidad_solicitada).then(
        res=>{
            // console.log("unidadGetIngreso: " , res.data);
            commit("SET_COMMIT_UPDATE_UNI_COMPRA_DETAIL_LIST", {datos: res.data[0], id_unidad_solicitada:id_unidad_solicitada});
        }).catch(err=>console.log(err)
    ),
    //Obtener el detalle de la orden de compra
    ordenCompraDetailList: async({ rootState, commit }, id_orden_compra) => orden_compra_detail_list(rootState.auth.user.idUser, id_orden_compra).then(
        res=>{
            // console.log("ordenCompraDetailList: " , res.data);
            // let fecha = moment(new Date()).format('YYYY-MM-DD');
            // res.data.forEach(element => {
            //     element.art = { nombre:"", clave:"" };
            //     element.uni = { unidad:"" };
            //     element.cantidad = 0;
            //     element.flete = "0";
            //     element.fecha_entrada = fecha;
            //     element.peso_promedio = "0";
            //     element.id_almacen = 1;
            //     element.id_orden_compra = id_orden_compra;
            //     element.id_usuario = rootState.auth.user.idUser;
            //     // desde aqui lo lanza para que se actualice la posicion art
            //     dispatch("unidadGetIngreso", element.id_unidad_solicitada);
            //     // desde aqui lo lanza para que se actualice la posicion uni
            //     dispatch("articuloGetIngreso", element.id_articulo);
            // });

            // setTimeout(async ()=>{
                commit("SET_COMMIT_COMPRA_DETAIL_LIST", res.data);
                commit("SET_COMMIT_LOAD_CONTENIDO_ORDEN_COMPRA", false);
            // },800)
        }).catch(err=>console.log(err)
    ),
    //Obtener el detalle de la orden de compra
    ordenCompraArticulosList: async({ rootState, commit }, id_orden_compra) => orden_compra_articulos_list(rootState.auth.user.idUser, id_orden_compra).then(
        res=>{
            let fecha = moment(new Date()).format('YYYY-MM-DD');
            // se le crean las posiciones para poder acceder desde el modal
            res.data.forEach(element => {
                element.cantidad = 0;
                element.flete = "0";
                element.fecha_entrada = fecha;
                element.peso_promedio = "0";
                element.id_almacen = 1;
                element.id_orden_compra = id_orden_compra;
                element.id_usuario = rootState.auth.user.idUser;
            });
            // console.log("ordenCompraArticulosList: " , res.data);
            commit("SET_COMMIT_COMPRA_DETAIL_LIST", res.data);
            commit("SET_COMMIT_LOAD_CONTENIDO_ORDEN_COMPRA", false);
        }).catch(err=>console.log(err)
    ),
    //Obtener el header de la orden de compra
    ordenCompraList: async({ rootState, commit }) => orden_compra_list(rootState.auth.user.idUser).then(
        res=>{
            // console.log("ordenCompraList: " , res.data);
            commit("SET_COMMIT_COMPRA_HEADER_LIST", res.data);

            commit("SET_COMMIT_LOAD_ORDEN_COMPRA", false);
        }).catch(err=>console.log(err)
    ),
    // actualiza el total de la compra
    updateTotalCompra:({ rootState, commit})=>{
        let importe_ = 0.00;
        if(rootState.ordencompra.cajaCompra.length !== 0){
            rootState.ordencompra.cajaCompra.forEach(element => {
                if (typeof element.importe !== 'undefined') {
                    // console.log("element.importe: ", element.importe);
                    importe_ += Number(element.importe.replace(/[^0-9.-]+/g,""));
                }
            });
        }
        let resTotal = parseFloat(importe_);
        let resImporte = parseFloat(resTotal / 1.16);
        let resIva = parseFloat(resTotal - resImporte);

        commit("SET_COMMIT_TOTAL_ORDENCOMPRA", {
            subtotal: resImporte.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            iva: resIva.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            total: resTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        })
    },
};