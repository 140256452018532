import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        message: "",
        user: JSON.parse(localStorage.getItem('aceromax_USER')) ? JSON.parse(localStorage.getItem('aceromax_USER')) : ''
    },
    mutations,
    actions,
};
