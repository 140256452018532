/*eslint-disable no-unused-vars*/
export const mutations = {
    SET_MESSAGE(state,msj){
        state.message = msj
    },
    SET_COMMIT_UNIDADES(state, res){
        state.unidadesList = res;
    },
    SET_COMMIT_CLIENTE_SELECTED(state, res){
        state.clienteSelectedVar = res;
    },
    SET_COMMIT_TIPO_VENTA(state, val){
        state.tipoVenta = val;
    },
};