<template>
    <b-card header-tag="header" class="mt-1 card-mini" style="height: 13rem; background-color: #fad2e0; color: black;">

        <b-row>
            <b-col class="col-md-6">
                <b-col class="col-md-12" >
                    <h2>Crédito</h2>
                    <!-- {{clienteSelelctedVar.limite_credito}}  -->
                </b-col>
                <b-col class="col-md-12" >
                    <Autocomplete search="true"
                    placeholder="Selecciona una opción" 
                    cols="col-md-12"
                    :items="venta.clientesList" 
                    text="nombre_razon_social" 
                    v-model="id_cliente" assign="id_cliente"  
                    label="Cliente:" filter="nombre_razon_social" />
                </b-col>
            </b-col>

            <b-col class="col-md-6">
                <!-- <b-form-group> -->
                    <!-- <b-row> -->
                        <b-input-group 
                            label-align-sm="right"
                            prepend="Limite de Crédito $" class="mb-2"  v-b-tooltip.hover title="Limite de Crédito">
                            <b-form-input style="text-align:right;" @focus="$event.target.select()" :value="contado.clienteSelectedVar.limite_credito"
                            label-align-sm="right"
                            disabled aria-label="Small text input with custom switch"></b-form-input>
                        </b-input-group>
                        <b-input-group prepend="Crédito Actual $" class="mb-2"  v-b-tooltip.hover title="Crédito Actual">
                            <b-form-input style="text-align:right;" @focus="$event.target.select()" :value="contado.clienteSelectedVar.monto"
                            disabled aria-label="Small text input with custom switch"></b-form-input>
                        </b-input-group>
                        <b-input-group prepend="Capacidad de Crédito $" class="mb-2" 
                        v-b-tooltip.hover :title="!validateCapacidad ? `Capacidad de Crédito`: `Sobregirado`">
                            <b-form-input
                            :state="!validateCapacidad"
                             style="text-align:right;" @focus="$event.target.select()" :value="capacidadCredito"
                            disabled aria-label="Small text input with custom switch"></b-form-input>
                        </b-input-group>

                        <!-- <b-progress style="padding-left: 0px; padding-right: 0px;" class="col-md-12" v-b-tooltip.hover title="Limite de Crédito"
                            :value="contado.clienteSelectedVar.limite_credito" :max="contado.clienteSelectedVar.limite_credito" show-progress animated></b-progress>
                        <b-progress style="padding-left: 0px; padding-right: 0px;" class="mt-3 col-md-12" :max="max" show-value>
                            <b-progress-bar v-b-tooltip.hover title="Crédito Actual" :value="value * (6 / 10)" variant="success"></b-progress-bar>
                            <b-progress-bar v-b-tooltip.hover title="Capacidad de Crédito" :value="value * (4 / 10)" variant="warning"></b-progress-bar>
                        </b-progress> -->


                        <!-- comentado por si no se autoriza el de progreso -->
                        <!-- <b-input-group size="sm" prepend="Limite de Crédito" class="mb-2"  v-b-tooltip.hover title="Limite de Crédito">
                            <b-form-input @focus="$event.target.select()" :value="contado.clienteSelectedVar.limite_credito"
                            disabled aria-label="Small text input with custom switch"></b-form-input>
                        </b-input-group>
                        <b-input-group size="sm" prepend="Crédito Actual" class="mb-2"  v-b-tooltip.hover title="Crédito Actual">
                            <b-form-input @focus="$event.target.select()" 
                            disabled aria-label="Small text input with custom switch"></b-form-input>
                        </b-input-group>
                        <b-input-group size="sm" prepend="Capacidad de Crédito" class="mb-2"  v-b-tooltip.hover title="Capacidad de Crédito">
                            <b-form-input @focus="$event.target.select()" 
                            disabled aria-label="Small text input with custom switch"></b-form-input>
                        </b-input-group> -->
                    <!-- </b-row> -->
                <!-- </b-form-group> -->
            </b-col>
        </b-row>

    </b-card>
</template>

<script>
    import Autocomplete from '@/components/Autocomplete'
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
            // this.clientesList();
        },
        watch: {
            "id_cliente": function (val) {
                if(val){
                    let cliente = this.getCliente(val);
                    cliente.limite_credito = cliente.limite_credito.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    cliente.monto = cliente.monto.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    // console.log("cliente , " , cliente);
                    // actualiza la variable clienteSelected
                    this.$store.commit("SET_COMMIT_CLIENTE_SELECTED", cliente)
                    this.clienteSelelctedVar = cliente;
                }
            },
            // despues de actualizar la variable message y fue exitoso limpia cliente selected
            "caja.messageCaja": function (val) {
                if(val.clase == "toast-success"){
                    this.id_cliente = "";
                }
            },
            // "cliente_id"(val){
            //     if(val){this.clienteSelelctedVar = this.clienteSelected(val);}
                
            // },   
        },
        components:{ Autocomplete },
        props:[ ],
        data(){
            return{
                search:'', id_cliente:"", 
                value: 100,
                max: 100,
                clienteSelelctedVar: {
                    rfc: '',
                    limite_credito: '',
                    monto:''
               },
           }
        },
        computed:{
            ...mapState(["contado", "venta", "caja"]),
            validateCapacidad(){
                let res = this.clearDigit(this.contado.clienteSelectedVar.limite_credito) - this.clearDigit(this.contado.clienteSelectedVar.monto ? this.contado.clienteSelectedVar.monto : "0.00");
                return res <= -1; 
            },
            capacidadCredito(){
                let res = this.clearDigit(this.contado.clienteSelectedVar.limite_credito) - this.clearDigit(this.contado.clienteSelectedVar.monto ? this.contado.clienteSelectedVar.monto : "0.00");
                return res ? this.toMoneda(res) : "0.00";
                // return this.toMoneda(this.clearDigit(this.contado.clienteSelectedVar.limite_credito) - this.contado.clienteSelectedVar.monto);
            }
        },
        methods:{
            ...mapActions(["clientesList"]),
            // obtiene el cliente seleccionado
            getCliente(id){
                let searchRes = this.venta.clientesList.filter(item=>item.id_cliente === id)
                return searchRes[0]
            },
            clearDigit(value){
                return Number(value.replace(/[^0-9.-]+/g,""));
            },
            toMoneda(value){
                return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            },
            
        }
    }
</script>