<template>
    <b-card header-tag="header" class="mt-1 card-mini" style="background-color: #E5E5E5; color: black;">

        <b-row>
            <b-col class="col-md-6">
                <Autocomplete 
                :search="true"
                placeholder="Selecciona una opción" 
                cols="col-md-12"
                :items="venta.clientesList" 
                text="nombre_razon_social" 
                v-model="id_cliente" assign="id_cliente"  
                label="Cliente:" filter="nombre_razon_social" />
            </b-col>

            <b-col class="col-md-6">
                
                <b-input-group size=" " prepend="Limite de Crédito" class="mb-2"  v-b-tooltip.hover title="Limite de Crédito">
                    <b-form-input style="text-align:right;"  @focus="$event.target.select()" :value="contado.clienteSelectedVar.limite_credito"
                    disabled aria-label="Small text input with custom switch"></b-form-input>
                </b-input-group>
                <!-- <b-input-group size=" " prepend="Crédito Actual" class="mb-2"  v-b-tooltip.hover title="Crédito Actual">
                    <b-form-input @focus="$event.target.select()" :value="caja.totalCarrito.total" 
                    disabled aria-label="Small text input with custom switch"></b-form-input>
                </b-input-group> -->

                <!-- SUMAR TOTAL DEL LIST 
                SUMAR TOTAL DEL LIST 
                SUMAR TOTAL DEL LIST 
                SUMAR TOTAL DEL LIST  -->
                <!-- capacidad credito = limite_credito - sumatoria de total
                capacidad credito = limite_credito - sumatoria de total
                capacidad credito = limite_credito - sumatoria de total
                capacidad credito = limite_credito - sumatoria de total -->

<!-- {{cobro.totalAdeudo}} -->
                <b-input-group size=" " prepend="Capacidad de Crédito" class="mb-2"  v-b-tooltip.hover title="Capacidad de Crédito">
                    <b-form-input style="text-align:right;"  @focus="$event.target.select()" :value="capacidadCredito"
                    disabled aria-label="Small text input with custom switch"></b-form-input>
                </b-input-group>
            </b-col>
        </b-row>

    </b-card>
</template>

<script>
    import Autocomplete from '@/components/Autocomplete'
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
            this.$store.commit("SET_TOTAL_ADEUDO_CLIENTE", "0.00")
        },
        watch: {
            "id_cliente": function (val) {
                if(val){
                    let cliente = this.getCliente(val);
                    cliente.limite_credito = cliente.limite_credito.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    // actualiza la variable clienteSelected
                    this.$store.commit("SET_COMMIT_CLIENTE_SELECTED", cliente)
                    this.clienteSelelctedVar = cliente;


                    // console.log("aqui lanzar api de list compras del cliente: " , cliente);
                    this.clienteAbonosList(val);
                    // aqui lanzar api de list compras del cliente
                    // aqui lanzar api de list compras del cliente
                    // aqui lanzar api de list compras del cliente
                    // actualizar cobro.cajaCobro
                    // actualizar cobro.cajaCobro
                    // actualizar cobro.cajaCobro
                }
            },
            // cuando se cancela limpia el cliente selected
            "contado.clienteSelectedVar":function (val) {
                if(val.nombre_razon_social == "No Seleccionado"){
                    this.id_cliente = "";
                }
            },
            // despues de actualizar la variable message y fue exitoso limpia cliente selected
            "cobro.messageCobro": function (val) {
                if(val.clase == "toast-success"){
                    this.id_cliente = "";
                }
            },
            // "cliente_id"(val){
            //     if(val){this.clienteSelelctedVar = this.clienteSelected(val);}
                
            // },   
        },
        components:{ Autocomplete },
        props:[ ],
        data(){
            return{
                search:'', id_cliente:"", 
                value: 100,
                max: 100,
                clienteSelelctedVar: {
                    rfc: '',
                    limite_credito: '',
                    monto:''
               },
           }
        },
        computed: {
            ...mapState(["contado", "venta", "cobro"]),
            capacidadCredito(){
                return this.toMoneda(this.clearDigit(this.contado.clienteSelectedVar.limite_credito) - this.cobro.totalAdeudo);
            },
            // capacidadCredito(){
            //     let res = this.clearDigit(this.contado.clienteSelectedVar.limite_credito) - this.clearDigit(this.cobro.totalAdeudo ? this.cobro.totalAdeudo : "0.00");
            //     return res ? this.toMoneda(res) : "0.00";
            //     // return this.toMoneda(this.clearDigit(this.contado.clienteSelectedVar.limite_credito) - this.contado.clienteSelectedVar.monto);
            // }

        },
        methods:{
            ...mapActions(["clientesList", "clienteAbonosList"]),
            // obtiene el cliente seleccionado
            getCliente(id){
                let searchRes = this.venta.clientesList.filter(item=>item.id_cliente === id)
                return searchRes[0]
            },
            clearDigit(value){
                return Number(value.replace(/[^0-9.-]+/g,""));
            },
            toMoneda(value){
                // return value;
                // console.log("value: " , value);
                return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            },
            
        }
    }
</script>