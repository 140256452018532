import { HTTP } from "./http-common";
/*eslint-disable no-unused-vars*/
export const login = (auth) => new Promise ((rv, rj) => {
    HTTP.post("login/", auth).then(
    res=>{
        rv(res)
    },
    err=>{
        rj(err)
    })
});