/*eslint-disable no-unused-vars*/
export const mutations = {
    SET_MESSAGE(state,msj){
        state.message = msj
    },
    SET_COTIZACION(state,res){
        state.cotizacion = res
    },
    // PARA IDENTIFICAR SI LANZARÁ EL REPORTE
    SET_ID_VENTA_COTIZACION(state,res){
        state.id_venta_cotizacion = res
    },
    // inicializa los parametros para el reporte
    SET_COMMIT_REPORTE_COTIZACION(state){
        state.imprimirPrecioUnitario = false;
        state.imprimirCantidad = false;
        state.imprimirImporte = true;
    }
};