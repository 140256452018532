import { HTTP } from "./http-common";
export const orden_compra_create = (data) => new Promise ((rv, rj) => {
    HTTP.post("orden-compra-create/", data).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const orden_compra_detail_create = (id_venta) => new Promise ((rv, rj) => {
    HTTP.post("orden-compra-detail-create/", id_venta).then(
        res=>{
            rv(res)
        },
        err=>{
            rj(err)
        })
});

export const orden_compra_list = (id_usuario)  => new Promise ((rv, rj) => {
    HTTP.get("orden-compra-list/?id_usuario=" + id_usuario).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const orden_compra_detail_list = (id_usuario, id_orden_compra)  => new Promise ((rv, rj) => {
    HTTP.get("orden-compra-detail-list/?id_usuario=" + id_usuario + "&id_orden_compra=" + id_orden_compra).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const orden_compra_articulos_list = (id_usuario, id_orden_compra)  => new Promise ((rv, rj) => {
    HTTP.get("orden-compra-articulos-list/?id_usuario=" + id_usuario + "&id_orden_compra=" + id_orden_compra).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const orden_compra_autogenerate_list = (id_usuario)  => new Promise ((rv, rj) => {
    HTTP.get("orden-compra-autogenerate-list/?id_usuario=" + id_usuario).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});