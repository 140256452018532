<template>
    <b-card header-tag="header" class="mt-1 card-mini" >
        <b-row >
            <b-col class="col-xs-2 col-sm-2">
                <b-row class="col-xs-12 col-sm-12">
                    <Boton text="Grabar" tooltip="Grabar" style="height: 7rem; width: 7rem;"
                    :disabled="ingreso.ordenComprasDetail.length == 0"
                    icon="lock-fill" fontscale="4" :onClic="guardar" variant="success" btn="btn-secundary" size="md"/>
                </b-row>
            </b-col>
            <b-col class="col-xs-10 col-sm-10">
                <b-row class="row ml-3" align-h="end" align-w="end">
                    <div class="mr-3"
                        v-on:click="gotToCompra" v-b-tooltip.hover title="Realizar la compra">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/compras.png')" width="110" height="110"
                        ></b-img>
                    </div>
                    <div :class="isRoute('/ordencomprainventario/crear') ? 'mr-3 disabledbutton' : 'mr-3'"
                        v-on:click="goToIngreso" v-b-tooltip.hover title="Realizar ingreso">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/ingreso.png')" width="110" height="110"
                        ></b-img>
                        
                    </div>
                    <!-- <div :class="isRoute('/ordencompra/crear') ? 'mr-3 disabledbutton' : 'mr-3'"
                        v-on:click="goToPago" v-b-tooltip.hover title="Realizar pago">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/credito.png')" width="110" height="110"
                        ></b-img>
                    </div>
                    <div :class="isRoute('/ordencompra/crear') ? 'mr-3 disabledbutton' : 'mr-3'"
                        v-on:click="goToExistencias" v-b-tooltip.hover title="Realizar existencias">
                        <b-img class="mousehoverItem" 
                            :src="require('@/assets/botones/devolucion.png')" width="110" height="110"
                        ></b-img>
                    </div>
                    <div :class="isRoute('/ordencompra/crear') ? 'mr-3 disabledbutton' : 'mr-3'"
                        v-on:click="goToMovimientos" v-b-tooltip.hover title="Ver movimientos">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/cobros.png')" width="110" height="110"
                        ></b-img>
                    </div> -->
                    <div class="mr-3"
                        v-on:click="cancelar"
                        v-b-tooltip.hover title="Cancelar">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/cancelar.png')" width="110" height="110"
                        ></b-img>
                    </div>
                </b-row>
            </b-col>
        </b-row>
        <ModalCompra />
    </b-card>
</template>

<script>
    import router from '@/router'
    import Boton from '@/components/Boton'
    import ModalCompra from "./ModalCompra";
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
        },
        props:[ "clicProducto" ],
        components:{ Boton, ModalCompra },
        data(){
            return{  }
        },
        computed: {
            ...mapState([ "ingreso", "auth" ])
        },
        watch: {
            //despues de guardar lanza notificacion
            "ingreso.messageIngreso": function (val) {
                if(val.mensaje != ""){
                    this.generateAlert(val.mensaje, val.clase);

                    // limpia las variables declaradas
                    this.$store.commit("SET_COMMIT_LOAD_ORDEN_COMPRA", true);
                    this.$store.commit("SET_COMMIT_COMPRA_DETAIL_LIST", []);
                    this.$store.commit("SET_COMMIT_COMPRA_HEADER_LIST", []);
                    // carga la lsita de lotes
                    this.ordenCompraList();
                }
            },
        },
        methods:{
            ...mapActions(["loteDetailCreate", "ordenCompraList"]),
            guardar(){
                let datosLote = new Array();
                let i = 0;
                // crea el array que ocupa el api
                this.ingreso.ordenComprasDetail.forEach(element => {
                    let suma = Number(element.cantidad) + Number(element.flete) + Number(element.peso_promedio);
                    if(suma > 0){
                        let datoMientras = {
                            id_articulo: element.id_articulo,
                            id_almacen: element.id_almacen,
                            fecha_entrada: element.fecha_entrada,

                            cantidad_entrada: element.cantidad,
                            flete: element.flete,
                            peso_promedio: element.peso_promedio,
                            precio: element.precio,

                            promediado: element.promediado ? 1 : 0,

                            cantidad_existencia: 0,
                            id_unidad_entrada: element.uni.id_unidad,
                            id_orden_compra: element.id_orden_compra,
                            id_usuario: this.auth.user.idUser,
                        };
                        datosLote[i] = datoMientras;
                        i ++;
                    }
                });
                if(datosLote.length != 0){
                    // console.log("datosLote: ", datosLote);
                    this.loteDetailCreate({ lotes: datosLote });
                }
            },
            cancelar(){
                // limpia las variables declaradas
                this.$store.commit("SET_COMMIT_LOAD_ORDEN_COMPRA", true);
                this.$store.commit("SET_COMMIT_COMPRA_DETAIL_LIST", []);
                this.$store.commit("SET_COMMIT_COMPRA_HEADER_LIST", []);
                
                // carga la lsita de lotes
                this.ordenCompraList();
            },
            // dirige a pantalla de contado
            goToIngreso(){
                // router.push('/venta/crear/contado')
            },
            gotToCompra(){
                // router.push('/gotToCompra')
            },
            goToPago(){
                // router.push('/venta/crear')
            },
            goToExistencias(){
                // router.push('/devolucion/crear')
            },
            goToMovimientos(){
                // router.push('/cobro/crear')
            },
            isRoute(ruta){
                return router.history.current.path == ruta;
            }
        }
    }
</script>