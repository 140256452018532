<template>
    <b-card header-tag="header" class="mt-3 card-mini" style="min-height: 670px;">
        <template v-slot:header>
            <b-row class="p-2">
                CONTENIDO DEL ORDEN DE COMPRA
                <b-input-group class="mt-3">
                    <input @focus="$event.target.select()" v-model="search" required autocomplete="off" type="text" class="form-control"
                    placeholder="Buscar en detalles..." aria-label="DETALLES" aria-describedby="basic-addon">
                </b-input-group>
            </b-row>
        </template>
        <b-table small :fields="fields" :busy="ingreso.loadContenidoOrdenCompra" :items="searchInDetail" responsive >
        <!-- <b-table small :fields="fields" :busy="ingreso.loadContenidoOrdenCompra" :items="searchInDetail" responsive="sm" > -->
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                </div>
            </template>

            <!-- A virtual column -->
            <template v-slot:cell(index)="data">
                <b>{{ data.index + 1 }}</b>
            </template>
            <!-- A custom formatted column -->
            <template  v-slot:cell(articulo)="data" >
                <b class="text-info">{{ data.item.articulo.clave }} - {{ data.item.articulo.nombre }}</b>
            </template>
            <!-- A virtual composite column -->
            <template v-slot:cell(uni)="data">
                <b>{{ data.item.uni.unidad }}</b>
            </template>
            <!-- Optional default data cell scoped slot -->
            <template v-slot:cell(cantidad_solicitada)="data">
                <b>{{ data.item.cantidad_solicitada }}</b>
            </template>
            <!-- Optional default data cell scoped slot -->
            <template v-slot:cell(precio)="data">
                <b>{{ data.item.precio }}</b>
            </template>
            <!-- Optional default data cell scoped slot -->
            <template v-slot:cell(cantidad)="data">
                <b>{{ data.item.cantidad }}</b>
            </template>
            <!-- Optional default data cell scoped slot -->
            <template v-slot:cell(flete)="data">
                <b>{{ data.item.flete }}</b>
            </template>
            <!-- Optional default data cell scoped slot -->
            <template v-slot:cell(promediado)="data">
                <!-- <b>{{ data.item.id_usuario }}</b> -->
                <b-form-checkbox v-model="data.item.promediado" size="lg"
                cursor="pointer;" name="check-button" switch>
                {{ data.item.promediado ? "Si" : "No" }}
                </b-form-checkbox>
            </template>
            <!-- Optional default data cell scoped slot -->
            <template v-slot:cell(id_usuario)="data">
                <!-- <b>{{ data.item.id_usuario }}</b> -->
                <Boton class="justify-content-md-center"
                icon="pencil-square" fontscale="1" :onClic="editItem" :valorEventClic="data.item"
                variant="primary" btn="btn-default" size="sm" />
            </template>
        </b-table>

          <!-- <ItemsListLoteDetalles :search="search" :items="ingreso.ordenComprasDetail"
            tituloItemsEmpty="Contenido vacío" :getArt="articuloGetIngreso" :edit="editItem"
          />  -->
          
        <ModalEditarLote />
        <ModalImagen />
    </b-card>
</template>

<script>
    import Boton from '@/components/Boton'
    import ModalEditarLote from "./ModalEditarLote";
    import ModalImagen from "./ModalImagen";
    // import ItemsListLoteDetalles from '@/components/ItemsListLoteDetalles'
    import { mapState, mapActions } from "vuex";

    export default {
        mounted(){
            this.$store.commit("SET_COMMIT_UNIDADES_INGRESO", []);
        },
        components:{ ModalEditarLote, ModalImagen, Boton },
        data(){
            return{
                search: "",
                fields: [
                    { key: 'index', label: '#' },
                    { key: 'articulo', label: 'Artículo' },
                    { key: 'uni', label: 'Unidad' },
                    { key: 'cantidad_solicitada', label: 'Cantidad Sol.' },
                    { key: 'precio', label: 'Precio' },
                    { key: 'cantidad', label: 'Cantidad' },
                    { key: 'peso_promedio', label: 'P.Promedio' },
                    { key: 'flete', label: 'Flete' },
                    { key: 'promediado', label: 'Promediado' },
                    { key: 'id_usuario', label: 'Acción' },
                ],
            }
        },
        computed:{
            ... mapState([ "ingreso" ]),
            searchInDetail(){ 
                return this.ingreso.ordenComprasDetail.filter(req => this.search.toLowerCase()
                .split(' ').every(v => this.conditionalFilter(v, req)))
            }
        },
        watch: {
            "ingreso.ordenComprasDetail": function (val) {
                console.log("ordenComprasDetail: " , val);
                let unidades = new Array();
                this.ingreso.ordenComprasDetail.forEach(element => {

                    let suma = Number(element.cantidad) + Number(element.flete) + Number(element.peso_promedio);
                    if(suma > 0){
                        element._rowVariant = 'success';
                    }else{
                        element._rowVariant = "light";
                    }

                    let found1 = unidades.filter(req => element.unidad.toLowerCase()
                    .split(' ').every(v => this.conditionalFilterUnidad(v, req)))

                    console.log("found1: " , found1);
                    if(found1.length == 0){
                        unidades.push({unidad:element.unidad, suma: element.cantidad_solicitada});
                    }else{
                        found1[0].suma = found1[0].suma + element.cantidad_solicitada;
                    }
                });
                console.log("unidades: " , unidades);
                this.$store.commit("SET_COMMIT_UNIDADES_INGRESO", unidades);
            },
        },
        methods:{
            ...mapActions(["articuloGetIngreso"]),
            editItem(item){
                this.$store.commit("SET_COMMIT_COMPRA_EDIT", item.id_articulo);
            },
            conditionalFilter(v, req){
                for (const prop in req) {
                    // console.log("req[prop]: " , req[prop]);
                    // console.log("req: " , req);
                    // console.log("prop: " , prop);
                    // console.log("v: " , v);

                    // for (const ele in prop) {
                        // console.log("ele: " , ele);
                    // }
                    if(req[prop] !== null){
                        if(req[prop].toString().toLowerCase().includes(v)){
                            return true;
                        }
                    }
                }
            },
            conditionalFilterUnidad(v, req){
                for (const prop in req) {
                    if(req[prop] !== null){
                        if(req[prop].toString().toLowerCase().includes(v)){
                            return true;
                        }
                    }
                }
            },
        }
    }
</script>