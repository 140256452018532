import XLSX from "xlsx"
export default {
    data(){ return{ } },
    methods: {
        toExportExcelCSV: function (datos, filename) {
            let data = XLSX.utils.json_to_sheet(datos)
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, data, filename)
            XLSX.writeFile(workbook, `${filename}.xlsx`)
        }
    }
};