/*eslint-disable no-unused-vars*/
import { unidad_list } from '@/api/caja'
// import router from '@/router'

export const actions = {
    ejemploList: async({ rootState, commit }, id_articulo) => unidad_list(rootState.auth.user.idUser, id_articulo).then(
        res=>{
            commit("SET_COMMIT_UNIDADES", res.data);
        }).catch(err=>console.log(err)
    ),
};