<template>
  <b-container fluid class="h-100">
    <b-row class="h-100">
      <b-col cols sm="5" class="login-welcome d-none d-sm-block">
        <LeftColumn/>
      </b-col>
      <b-col cols sm="7" class="login-form text-blue-aceromax">
        <RightColumn/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import LeftColumn from './LeftColumn'
import RightColumn from './RightColumn'
export default {
  components:{
    LeftColumn,
    RightColumn
  }
};
</script>

