import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        facturacion: false,
        message: "",
        listCorteCaja:[
            {"tipo_pago":"EFECTIVO","forma_pago_sat":"1","monto":0},
            {"tipo_pago":"TARJETA DE CREDITO","forma_pago_sat":"2","monto":0},
            {"tipo_pago":"CHEQUE","forma_pago_sat":"3","monto":0}
            ,
            {"tipo_pago":"DEVOLUCIONES", "_rowVariant": 'danger',"monto":0},
            {"tipo_pago":"TOTAL", "monto":0}
        ]
    },
    mutations,
    actions,
};