<template>
    <b-card header-tag="header" class="mt-3 card-mini" style="min-height: 670px;">
        <template v-slot:header>
            <b-row class="p-2">
                COMPRAS
                <b-input-group class="mt-3">
                    <input @focus="$event.target.select()" v-model="search" required autocomplete="off" type="text" class="form-control"
                    placeholder="Buscar en la lista..." aria-label="PRODUCTOS" aria-describedby="basic-addon">
                </b-input-group>
            </b-row>
        </template>

        <ItemsListOrdenCompra :actions="true" :items="ordencomprainventario.cajaCompra"
            SET_COMMIT_DELETE="SET_COMMIT_COMPRA_AUTOGENERATE_DELETE"
            SET_COMMIT_EDIT="SET_COMMIT_ORDENCOMPRAINVENTARIO_EDIT"
            SET_COMMIT_IMG="SET_COMMIT_IMG_COMPRA"
            :updateTotalCarrito="updateTotalCompraOrdenInventario"
            tituloItemsEmpty="Compra vacía"
            idTemplate="productosCompra" :search="search"
        />
        <ModalEditarCompra />
        <ModalImagen />
    </b-card>
</template>

<script>
    import ModalEditarCompra from "./ModalEditarCompra";
    import ModalImagen from "./ModalImagen";
    // import ItemsList from '@/components/ItemsList'
    import ItemsListOrdenCompra from '../ordencompra/ItemsListOrdenCompra'
    import { mapState, mapActions } from "vuex";

    export default {
        mounted(){
            this.ordenCompraAutogenerateList();
        },
        components:{ ItemsListOrdenCompra, ModalEditarCompra, ModalImagen },
        data(){
            return{ search: "", }
        },
        watch:{
            // despues de actualixar vairbale messaje muestra el mensaje
            "ordencomprainventario.cajaCompra": function (val) {
                console.log("ordencomprainventario.cajaCompra: " , val);
            },
        },
        computed: mapState([ "ordencomprainventario" ]),
        methods:{
            ...mapActions(["updateTotalCompraOrdenInventario", "ordenCompraAutogenerateList"])
        }
    }
</script>