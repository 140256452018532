export default {
    data(){
        return{
            optionsAlert:{
                toaster: "b-toaster-top-full",
                solid: true,
                variant: "success",
                appendToast: true,
                autoHideDelay: 2500,
                noCloseButton: false,
                toastClass: "position-fixed fixed-top m-0 rounded-0",
            }
        }
    },
    methods: {
        generateAlert(message,variant){
            this.$bvToast.toast(message, {
                ...this.optionsAlert,
                bodyClass: variant
            })
        } 
    }
};