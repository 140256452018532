/*eslint-disable no-unused-vars*/
import { create_articulo, update_articulo, articulo_list, articulo_get, unidades_cat_list, 
    unidad_list,categoria_list,subcategoria_list, unidad_equivalencia_create, unidad_equivalencia_list, unidad_equivalencia_update,
    subcategoria_texto_list, articulos_precio_list, categoria_texto_list, precio_update } from '@/api/articulo' 
import Vue from 'vue'
export const actions = {
    // lista el catalogo de unidad del articulo seleccionado
    unidadEquivalenciaUpdate: async({ rootState, dispatch, commit }, data) => unidad_equivalencia_update(data).then(
        res=>{
            console.log("unidadEquivalenciaUpdate: " , res.data);
            const vm = new Vue()
            vm.generateAlert(res.data.message,'toast-success')
            // commit("SET_COMMIT_UNIDADES_LIST_ARTICULOS", res.data);
        }).catch(err=>console.log(err)
    ),
    // lista las EQUIVALENCIAS
    unidadEquivalenciaList: async({ rootState, commit }, id_articulo) => unidad_equivalencia_list(rootState.auth.user.idUser, id_articulo).then(
        res=>{
            console.log("res.data____ " , res.data);
            commit("SET_COMMIT_UNIDAD_EQUIVALENCIA", res.data);
        }).catch(err=>console.log(err)
    ),
    // lista las categorias con imagen
    categoriaListCrud: async({ rootState, commit, dispatch }) => categoria_list(rootState.auth.user.idUser).then(
        res=>{
            commit("SET_COMMIT_CATEGORIAS", res.data);
        }).catch(err=>console.log(err)
    ),
    // lista las subcategorias
    subCategoriaListCrud: async({ rootState, commit, dispatch }, id_categoria) => subcategoria_list(rootState.auth.user.idUser, id_categoria).then(
        res=>{
            commit("SET_COMMIT_SUBCATEGORIAS", res.data);
        }).catch(err=>console.log(err)
    ),
    //CATALOGO DE Unidades 
    unidadesCatList: async({ rootState, commit, dispatch }) => unidades_cat_list(rootState.auth.user.idUser).then(
        res=>{
            commit("SET_COMMIT_UNIDADES_CAT_LIST", res.data);
            //dispatch("setLoad")
        }).catch(err=>console.log(err)
    ),

    //unidades_list
    
    // list los articulos ///////////////////CHECAR LOS ARTICULOS
    articulosList: async({ rootState, commit, dispatch }, id_subcategoria) => articulo_list(rootState.auth.user.idUser, id_subcategoria).then(
        res=>{
            commit("SET_COMMIT_ARTICULOS", res.data);
            dispatch("setLoad")
        }).catch(err=>console.log(err)
    ), 
    // obtiene el articulos seleccionado
    articulosGet: async({ rootState, commit }, id_articulo) => articulo_get(rootState.auth.user.idUser, id_articulo).then(
        res=>{
            commit("SET_COMMIT_GET_ARTICULOS_LIST", res.data);
            //dispatch("setLoad")
        }).catch(err=>console.log(err)
    ), 
    // lista el catalogo de unidad del articulo seleccionado
    unidadArticuloList: async({ rootState, commit }, id_articulo) => unidad_list(rootState.auth.user.idUser, id_articulo).then(
        res=>{
            commit("SET_COMMIT_UNIDADES_LIST_ARTICULOS", res.data);
        }).catch(err=>console.log(err)
    ),
    // lista el catalogo de unidad del articulo seleccionado
    unidadEquivalenciaCreate: async({ rootState, dispatch, commit }, data) => unidad_equivalencia_create(data).then(
        res=>{
            console.log("unidadEquivalenciaCreate: " , res.data);
            commit("SET_ID_UNIDAD", 0);

            dispatch("unidadEquivalenciaList", data.id_articulo);
            // commit("SET_COMMIT_UNIDADES_LIST_ARTICULOS", res.data);
        }).catch(err=>console.log(err)
    ),
    // lista el catalogo de unidad del articulo seleccionado
    createArticuloNuevo: async({ commit }, data) => create_articulo(data).then(
        res=>{
            commit("SET_ID_ARTICULO_FORM",res.data.id_articulo)

            commit("SET_MESSAGE_ARTICULO",{mensaje: res.data.message,
                clase: (res.data.message === "EXITO") ? "toast-success" : "toast-danger"})
        }).catch(err=>console.log(err)
    ),
    //Registrar articulos
    articulosCreate: async({ rootState, commit, dispatch}, data) => create_articulo(data).then(
        res=>{
            
            console.log("rootState.articulos: " , rootState.articulos);
            
            commit("SET_MESSAGE_ARTICULO",{mensaje: res.data.message,
                clase: (res.data.message === "EXITO") ? "toast-success" : "toast-danger"})
            let datos = {
                equivalencia: 1,
                unidad_inicial: rootState.articulos.id_unidad,
                unidad_destino: rootState.articulos.id_unidad,
                id_articulo: res.data.id_articulo,
                id_usuario: rootState.auth.user.idUser,
            }
            dispatch("unidadEquivalenciaCreate", datos);
        }).catch(err=>console.log(err)
    ),

    //Actualizar articulos
    articulosUpdate:async({ rootState, commit, }, data) => update_articulo(data).then(
        res=>{
            // casi igual al de create articulos
            // commit("SET_ID_ARTICULO_FORM",res.data.id_articulo)
            
            commit("SET_MESSAGE_ARTICULO",{mensaje: res.data.message,
                clase: (res.data.message === "EXITO") ? "toast-success" : "toast-danger"})
        }).catch(err=>console.log(err)
    ),


    subcategoriaTextoList: async({ rootState, commit }, id_categoria) => subcategoria_texto_list(id_categoria, rootState.auth.user.idUser).then(
        res=>{
            commit("SET_COMMIT_LIST_SUBCATEGORIAS_EDIT", res.data);
        }).catch(err=>console.log(err)
    ),
    articulosPrecioList: async({ rootState, commit }, params) => articulos_precio_list(params.id_categoria, params.id_sub_categoria, rootState.auth.user.idUser).then(
        res=>{
            commit("SET_COMMIT_LIST_ARTICULOS_EDIT", res.data);
        }).catch(err=>console.log(err)
    ),
    categoriaTextoList: async({ rootState, commit }) => categoria_texto_list(rootState.auth.user.idUser).then(
        res=>{
            commit("SET_COMMIT_LIST_CATEGORIAS_EDIT", res.data);
        }).catch(err=>console.log(err)
    ),
    precioUpdate: async({ rootState, commit }, data) => precio_update(data).then(
        res=>{
            console.log("precio_update: " , res.data);
            commit("SET_COMMIT_MSJ_ARTICULOS_EDIT",{mensaje: res.data.message,
                clase: (res.data.message === "EXITO") ? "toast-success" : "toast-danger"});
        }).catch(err=>console.log(err)
    ),

  

    
    metodoSinApi:({state})=>{
        console.log("metodoSinApi: " , state);
    }
};