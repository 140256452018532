<template>
  <b-navbar toggleable="lg" type="dark" class="login-welcome p-0 pl-2 pr-2" variant>
    <b-navbar-brand to="/caja">
      <b-img width="60" :src="require('@/assets/img/acero-blanco.png')" fluid class="logo-header"></b-img>
      <b-img width="100" height="10" :src="require('@/assets/img/acero-blanco-texto.png')" class="logo-header"></b-img>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <MenuHeader/>

  </b-navbar>
</template>

<script>
import MenuHeader from "./MenuHeader"

export default {
    components:{
        MenuHeader
    },
};
</script>