<template>
  <b-modal id="ModalEditarCompra" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="EDITAR" title-class="modal-title w-100" body-class="p-4" >
    <b-row>
        <!-- <b-col class="col-md-12"> -->
        <!-- <b-row > -->
          <b-col class="col-md-6" >
            <b-form-group id="input-group-1" label="NOMBRE:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-form-input @focus="$event.target.select()" disabled v-model="datosModal.nombre" ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-6" >
            <b-form-group id="input-group-1" label="CÓDIGO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-form-input @focus="$event.target.select()" disabled v-model="datosModal.clave" ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-6" >
            <b-form-group id="input-group-1" label="PRECIO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="md" prepend="$">
                <b-form-input @focus="$event.target.select()" v-model.number="datosModal.precio" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-6" >
            <b-form-group id="input-group-1" label="IMPORTE:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group disabled class="m-0" size="md" prepend="$">
                <b-form-input @focus="$event.target.select()" v-model="datosModal.importe" disabled ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          
          <b-col class="col-md-6">
            <b-form-group id="input-group-1" label="CANTIDAD:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group>
                  <b-input-group-prepend>
                      <b-button @click="datosModal.cantidad > 1 ? datosModal.cantidad-- : datosModal.cantidad=1">-</b-button>
                  </b-input-group-prepend>

                  <b-form-input @focus="$event.target.select()"  v-model.number="datosModal.cantidad" max="10000" type="number" min="1"></b-form-input>

                  <b-input-group-append>
                      <b-button @click="datosModal.cantidad++">+</b-button>
                  </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-6" >
              <b-form-group id="input-group-1" label="Unidad:" label-size="10rem" label-for="input-1"  class="mr-1">
                  <b-form-select v-model="datosModal.id_unidad_solicitada" :options="caja.unidadesList"
                  class="mb-3" value-field="id_unidad" text-field="unidad" disabled-field="notEnabled" ></b-form-select>
              </b-form-group>
          </b-col>

          
          
        <!-- </b-row> -->
      <!-- </b-col> -->
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Guardar" class="mr-3"
        icon="lock-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-secundary" size="sm"/>

        <!-- <Boton text="Cancelar" tooltip="Cancelar"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/> -->
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  export default {
    mounted(){
      this.$store.commit("SET_COMMIT_ORDENCOMPRAINVENTARIO_EDIT", 0);
    },
    components:{ Boton },
    data() {
      return {
        modalShow: false,
        datosModal:{
          id_unidad_solicitada: "",
          nombre: "",
          clave:"",
          precio:"",
          importe:"",
          cantidad:""
        },
        loading: false
      };
    },
    watch: {
      "ordencomprainventario.editIdCompra": function (val) {
        // console.log("val: " , val);
        if(val >= 1){
          this.$bvModal.show('ModalEditarCompra');
          this.datosModal = this.getItemCarrito(val);
          // console.log("datosModal: " , this.ordencomprainventario.cajaCompra);
          // console.log("datosModal: " , this.datosModal);
          this.unidadList(this.datosModal.id_articulo);
        }
      },
      // actualiza el importe
      "datosModal.cantidad": function (val) {
        this.datosModal.importe = this.restrictDecimal(val * this.datosModal.precio);
        this.updateTotalCompraOrdenInventario();
      },
      // actualiza el importe
      "datosModal.precio": function (val) {
        this.datosModal.importe = this.restrictDecimal(val * this.datosModal.cantidad);
        this.updateTotalCompraOrdenInventario();
      }
        
    },
    computed:{
      // se accede a caja porque se requiere unidadeslist
      ...mapState([ "ordencomprainventario", "auth", "contado", "caja"]),
    },
    methods:{
      ...mapActions([ "headerCreateContado", "updateTotalCompraOrdenInventario", "unidadList"]),
      getItemCarrito(id){
          let searchRes = this.ordencomprainventario.cajaCompra.filter(item=>item.id_articulo === id)
          return searchRes[0]
      },
      restrictDecimal(val) {
        let cambio = parseFloat(val);
        return cambio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      },
      // cierra el modal
      cancel() {
        this.modalShow = false;
        this.loading = false;
        this.datosModal = { id_unidad_solicitada: "", nombre: "", clave:"", precio:"", importe:"", cantidad:""};
        this.$store.commit("SET_COMMIT_ORDENCOMPRAINVENTARIO_EDIT", 0);
        return this.modalShow;
      },
    },
  };
</script>
<style>
</style>