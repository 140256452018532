import { HTTP } from "./http-common";
export const corte_caja_list = (id_usuario, fecha) => new Promise ((rv, rj) => {
    HTTP.get("corte-caja-list/?id_usuario=" + id_usuario + "&fecha=" + fecha).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const quitar = (data) => new Promise ((rv, rj) => {
    HTTP.post("venta-tipo-pago-create/", data).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});