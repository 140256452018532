import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        factura: false,
        message: "",
        cajaCarrito: [],
        clientesList: []
    },
    mutations,
    actions,
};