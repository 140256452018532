<template :id="idTemplate">
    <div >
        <b-col v-b-scrollspy:scrollspy-nested v-if="items.length > 0" class="col-md-12 " id="scrollspy-nested" style="max-height:calc(52vh); overflow-y:auto">
            <b-row v-b-toggle="'colapse-desc-'+(items.indexOf(item) + 1)" class="mt-1 mousehoverItem animate__animated animate__bounceIn" v-for="(item, index) in itemsPaginated" v-bind:key="index">
               
                <b-col style="padding-left:1rem;" class="col-md-1">
                    <b-row >
                        <b-col cols="12" class="p-0 pt-2 text-titulo" >
                            <h1 class="text-center w-100" >{{ items.indexOf(item) + 1 }}</h1>
                        </b-col>
                    </b-row>
                </b-col> 
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >NOMBRE</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.nombre }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >DESCRIPCIÓN</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.descripcion }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >MAXIMO</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.maximo }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >MINIMO</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.minimo }}
                        </b-col>
                    </b-row>
                </b-col>
                    
              

                
                <!-- <b-col v-if="actions && !collapse" class="col-md-3 col-sm-3 col-xl-12"> -->
                    <b-row class="col-md-3 col-sm-3 col-xl-3">
                        <b-col class="p-1 pl-2 text-titulo text-left text-shadow mb-0 col-md-12" >
                            <strong >ACCIONES</strong>
                        </b-col>
                        <b-col class="justify-content-md-center text-shadow text-descripcion col-md-12" >
                            <b-row class="justify-content-md-center m-1" align-v="center">
                                 <b-col class="col-md-6 col-sm-6 col-xl-6">
                                    <Boton 
                                        icon="pencil-square" fontscale="1" :onClic="edit" 
                                        :valorEventClic="item.id_articulo"
                                        variant="primary" btn="btn-default" size="sm" />
                                </b-col>
                                <b-col class="col-md-6 col-sm-6 col-xl-6">
                                    <Boton class="mr-2"
                                        icon="trash-fill" fontscale="1" :onClic="realizarEliminacion" :valorEventClic="item"
                                        variant="danger" btn="btn-cancel" size="sm" />
                                </b-col>
                                 <!-- <b-col class="col-md-6 col-sm-6 col-xl-6">
                                    <Boton :tooltip="`Ver imagen de ${item.clave}`" :onClic="verImagen" :valorEventClic="item.imagen"
                                        icon="image" fontscale="1" variant="primary" btn="btn-detail" size="sm" />
                                </b-col>  -->
                            </b-row>
                        </b-col>
                    </b-row>
                <!-- </b-col> -->

            </b-row>
        </b-col>
    </div>
</template>
<script>
    // import { mapState } from "vuex";
    import Boton from '@/components/Boton'
            import { mapState, mapActions } from 'vuex';

    export default {
        props:[ "items", "SET_COMMIT_DELETE", "SET_COMMIT_EDIT", "idTemplate", "search", "actions" ],
        data() {
            return {
                
            };
        },
         watch: {
             "articulos.messageArticulo": function (val) {
                if(val.clase == "toast-success"){
                     this.generateAlert(val.mensaje, val.clase);
                    
                }
            },
            
        },
        components:{ Boton },
        computed:{
             ...mapState([ "articulos", "auth" ]),
            // paginación
             itemsPaginated(){ 
                return this.searchInDetail()
             }
        },
        methods:{
             ...mapActions(["articulosUpdate", "articulosList", "subCategoriaListCrud"]),//, "articulosList"
              searchInDetail(){
                 return this.items.filter(req => this.search.toLowerCase()
                 .split(' ').every(v => this.conditionalFilter(v, req)))
             },
             conditionalFilter(v, req){
                 for (const prop in req) {
                     if(req[prop] !== null){
                         if(req[prop].toString().toLowerCase().includes(v)){
                             return true;
                         }
                     }
                 }
             },
              
            // inicializa la variable id para mostrar en el modal EDITAR, usar watch para accionar el modal
            edit(item) {
                this.$store.commit(this.SET_COMMIT_EDIT, item)
                console.log("edit: " , item);
            },
            //Eliminacion de articulos
            realizarEliminacion(item){
                console.log("edit: " , item);
                new FormData();
                let formData = new FormData;
                if(item.id_articulo){ 
                        formData.append('id_articulo', item.id_articulo);
                    }
                    if(item.nombre){
                        formData.append('nombre', item.nombre);
                    }
                    if(item.descripcion){
                        formData.append('descripcion', item.descripcion);
                    }
                    // if(item.codigo_barras){
                        formData.append('codigo_barras', " ");
                    // }
                    if (item.factor_pv_1){
                        formData.append('factor_pv_1', item.factor_pv_1);
                    }
                    if (item.factor_pv_2){
                        formData.append('factor_pv_2', item.factor_pv_2);
                    }
                    if(item.factor_pv_3){
                        formData.append('factor_pv_3', item.factor_pv_3);
                    }
                    if(item.factor_pv_4){
                        formData.append('factor_pv_4', item.factor_pv_4);
                    }
                    if (item.id_unidad){
                        formData.append('id_unidad', item.id_unidad);//
                    }
                    if (item.id_sub_categoria){
                        formData.append('id_sub_categoria', item.id_sub_categoria);//
                    }
                    if(item.estatus){
                        formData.append('estatus', 0);//item.estatus
                    }
                    if('data:image/jpeg;base64,'+ item.imagen ){
                        
                        formData.append('imagen', 'data:image/jpeg;base64,'+ item.imagen);
                    }
                    if (item.maximo){
                        formData.append('maximo', item.maximo);
                    }
                    if (item.minimo){
                        formData.append('minimo', item.minimo);
                    }
                    if(item.punto_reorden){
                        formData.append('punto_reorden', item.punto_reorden);
                    }
                    if(item.clave){
                        formData.append('clave', item.clave);
                    }
                    if (item.clave_prod_serv){
                        formData.append('clave_prod_serv', item.clave_prod_serv);
                    }
                    if (this.auth.user.idUser){
                        formData.append('id_usuario', this.auth.user.idUser);
                    }
                    console.log('formData', formData)
                this.articulosUpdate(formData);
            //    this.articulosList();

                    this.subCategoriaListCrud(this.articulos.id_categoria);

                    this.articulosList(this.articulos.id_subcategoria);
           }
        }
    };
</script>
<style scoped>
</style>