<template :id="idTemplate" >
    <div v-b-scrollspy:scrollspy-nested >
        <b-list-group-item href="#" class="flex-column align-items-start col-md-12 mousehoverItem" 
            id="scrollspy-nested" style="line-height: 2; max-height:calc(52vh); overflow-y:auto"
            v-for="(item, index) in itemsPaginated" v-bind:key="index">
            <b-row >
                <b-col class="col-md-1" >
                    <b-row >
                        <b-col style="font-size: 3rem;" cols="12" class="p-0 pt-3 w-100 h-100" >
                            <h1 style="font-size: 3rem;" class="text-center" >{{ items.indexOf(item) + 1 }}</h1>
                        </b-col>
                    </b-row>
                </b-col>
                
                <b-col class="col-md-9" v-on:click="verImagen(item.imagen)">
                    <div class="d-flex justify-content-between">
                        <h6 class="mb-0">{{ item.clave }} {{ toggleText(item.descripcion) }}</h6>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                        <small class="text-muted">{{ item.unidad.unidad }}</small>
                        <h6 class="mb-0">$ {{ toMoneda(item.precio) }}</h6>
                        <h6 class="mb-0"># {{ item.cantidad }}</h6>
                        <h6 class="mb-0">$ {{ toMoneda(item.importe) }}</h6>
                    </div>
                </b-col>
                <b-col class="col-md-2">
                    <b-row class="w-100 h-100">
                        <Boton :tooltip="`Editar ${item.clave} del carrito`"
                            icon="pencil-square" fontscale="1" :onClic="edit" :valorEventClic="item.id_articulo"
                            variant="primary" btn="btn-default" size="sm" />

                        <Boton :tooltip="`Eliminar ${item.clave} del carrito`"
                            icon="trash-fill" fontscale="1" :onClic="eliminar" :valorEventClic="item"
                            variant="danger" btn="btn-cancel" size="sm" />
                    </b-row>
                </b-col>
            </b-row>
        </b-list-group-item>
    </div>
</template>
<script>
    // import { mapState } from "vuex";
    import Boton from '@/components/Boton'
    export default {
        props:["signos", "tituloItemsEmpty", "perPage", "currentPage", "items", "SET_COMMIT_IMG", "SET_COMMIT_DELETE", "SET_COMMIT_EDIT", "idTemplate", "search", "actions", "collapse", "updateTotalCarrito" ],
        data() {
            return {
            };
        },
        components:{ Boton },
        computed:{
            // ...mapState([ ]),
            // paginación
            itemsPaginated(){ 
                return this.searchInDetail()
            }
        },
        watch: {
            // "items.id": function (val) {
            //     if(val){
            //         console.log("val: " , val);
            //     }
            // },
            "items": function () {
                // console.log("CARRITO: " , this.items);
                // actualiza el total de la venta
                this.updateTotalCarrito();
            },
        },
        methods:{
            // busqueda dentro del array
            searchInDetail(){
                return this.items.filter(req => this.search.toLowerCase()
                .split(' ').every(v => this.conditionalFilter(v, req)))
            },
            conditionalFilter(v, req){
                for (const prop in req) {
                    if(req[prop] !== null){
                        if(req[prop].toString().toLowerCase().includes(v)){
                            return true;
                        }
                    }
                }
            },
            // muestra los primeros 172 caracteres del texto
            toggleText(text) {
                let res = "";
                let value = (text.length >= 172) ? 172 : text.length;
                for (let i = 0; i < value; i++) { res += text[i]; }
                return res += " ...";
            },
            // borra la posocion del array
            eliminar(item) {
                // console.log("eliminar: " , item);
                // console.log("indexOf: " , this.items.indexOf(item));
                this.$store.commit(this.SET_COMMIT_DELETE, this.items.indexOf(item))
            },
            // inicializa la variable id para mostrar en el modal EDITAR, usar watch para accionar el modal
            edit(item) {
                this.$store.commit(this.SET_COMMIT_EDIT, item)
                // console.log("edit: " , item);
            },
            // mostrar la imagen
            verImagen(imagen) {
                // console.log("imagen: " , imagen);
                this.$store.commit(this.SET_COMMIT_IMG, imagen)
            },
            toMoneda(value){
                return value;
                // console.log("value: " , value);
                // return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            },
        }
    };
</script>
<style scoped>
</style>