<template>
  <b-container fluid>
    <b-col class="p-0 pb-5 mb-5 m-0 h-100" >

      <b-row class="p-0 m-0 h-100 justify-content-md-center">
        <b-col cols="10">
            
            <b-card header-tag="header" class="mt-3 card-mini">
                <template v-slot:header>
                    <b-row class="p-2">
                        CORTE DE CAJA
                    </b-row>
                    <b-row>
                        <b-col cols="5">
                            <b-input-group  >
                                <b-form-datepicker placeholder="Seleccione la fecha de corte de caja" id="fechaCorte" v-model="fecha"></b-form-datepicker>
                            </b-input-group>
                        </b-col>
                    </b-row>
                     
                </template>
                <b-table striped hover :items="cortecaja.listCorteCaja" :tbody-tr-class="rowClass" :fields="fields"></b-table>
            </b-card>

        </b-col>

        <b-col cols="1" class="mr-2" style="position:fixed; width:110px; height:60px; top:78px; right:25px;" >
            <b-row class="justify-content-md-center">
                <b-col class="pb-2">
                    <Boton :loading="loading"
                    :disabled="cortecaja.listCorteCaja.length == 0" :onClic="imprimirCorteCaja" text="Imprimir" tooltip="Imprimir corte de caja" icon="file-earmark-spreadsheet" fontscale="4" variant="primary" btn="btn-default" size="md" />
                </b-col>
            </b-row>
            <b-row class="justify-content-md-center">
                <b-col class="pb-2">
                    <Boton text="Cancelar" tooltip="Cancelar corte de caja" icon="x-circle-fill" fontscale="4" variant="danger" btn="btn-cancel" size="md" />
                </b-col>
            </b-row> 
        </b-col>
      </b-row>

    </b-col>
  </b-container>
</template>

<script>
    //import KeyUp from '@/components/KeyUp'
    import Boton from '@/components/Boton'
    import { mapActions, mapState } from "vuex";
    export default {
        components: { Boton },
        watch: {
            "fecha": function (val) {
                if(val){
                    console.log("fecha: " , val);
                    this.corteCajaList(val);
                }
            },
        },
        computed: {
            ...mapState([ "cortecaja"])
        },
        data() {
            return {
                loading:false,
                fecha: "",
                fields: [
                    { key: 'tipo_pago', label: 'Tipo pago', sortable: true },
                    { key: 'monto', label: 'Cantidad', sortable: true, }
                ],
                variant: 'light',
                opacity: 0.85,
                blur: '2px',
                variants: [
                    'transparent',
                    'white',
                    'light',
                    'dark',
                    'primary',
                    'secondary',
                    'success',
                    'danger',
                    'warning',
                    'info',
                ],
                blurs: [{
                        text: 'None',
                        value: ''
                    },
                    '1px',
                    '2px',
                    '5px',
                    '0.5em',
                    '1rem'
                ]
            }
        },
        methods: {
            ...mapActions(["corteCajaList", "printCorteCaja"]),

            rowClass(item, type) {
                if (!item || type !== 'row') return
                if (item.status === 'awesome') return 'table-success'
            },
            imprimirCorteCaja(){
                this.printCorteCaja(this.fecha);
            }
        }
    }
</script>

