import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        facturacion: false,
        message: "",
        listVentasFacturacion: [],
        listAbonosFacturacion: [],
        loading: false,
        indexApi: 0,

        progresbarColor: "primary",
        estatusVenta: "",
        razonVenta: "",
        reloadVenta: 0,
        listVentaChecked: [],
        iClientes: 0,
        idsPGeneral: "",
        iteracionVentas: 0,
    },
    mutations,
    actions,
};