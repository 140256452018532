import axios from "axios";
import store from '../store'
import Vue from 'vue'

export let HTTP = axios.create({
    baseURL: 'https://aceromax.duckdns.org/api/',
});

HTTP.interceptors.request.use((request) => {
    if (localStorage.getItem('aceromax_HASH')) {
        request.headers.Authorization = 'Token ' + localStorage.getItem('aceromax_HASH');
    }
    return request;
});

HTTP.interceptors.response.use(
    response => {
        if (Array.isArray(response.data) && response.data[0] && response.data[0].status &&
            (response.data[0].status === 401 || response.data[0].status === 400)) {
            store.dispatch('logout')
        }

        return response
    },
    error => {
        let message = error.message
        let vm = new Vue()
        vm.generateAlert(message, 'toast-danger')

        return Promise.reject(error)
    }
)