<template>
  <b-modal id="modalEjemplo" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="PAGO" title-class="modal-title w-100" body-class="p-4" >
    <b-row>
        <b-col class="col-md-6">
        <!-- <b-row > -->
          <b-col class="col-md-12" >
            <b-form-group id="input-group-1" label="EFECTIVO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" prepend="$">
                <b-form-input @focus="$event.target.select()" type="number" v-model.number="dataModal.efectivo" min="0.00"></b-form-input>
                <!-- <b-form-spinbutton v-model.number="dataModal.efectivo" inline></b-form-spinbutton> -->
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="CHEQUE:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" prepend="$">
                <b-form-input @focus="$event.target.select()" type="number" v-model.number="dataModal.cheque" min="0.00"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="TARJETA:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" prepend="$">
                <b-form-input @focus="$event.target.select()" type="number" v-model.number="dataModal.tarjeta" min="0.00"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="TOTAL RECIBIDO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group disabled class="m-0" size="sm" prepend="$">
                <b-form-input @focus="$event.target.select()" v-model="dataModal.totalRecibido" disabled ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        <!-- </b-row> -->
      </b-col>
      <b-col class="col-md-6">
          <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="TOTAL VENTA:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group disabled class="m-0" size="lg" prepend="$">
                <b-form-input @focus="$event.target.select()" v-model="caja.totalCarrito.total" disabled ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="CAMBIO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group disabled class="m-0" size="lg" prepend="$">
                <b-form-input @focus="$event.target.select()" v-model="dataModal.cambio" disabled ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Imprimir" tooltip="Realizar venta" class="mr-3"
        icon="lock-fill" fontscale="2" :onClic="realizarVenta" variant="info" btn="btn-secundary" size="sm"/>

        <Boton text="Cancelar" tooltip="Cancelar venta"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/>
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  export default {
    components:{ Boton },
    data() {
      return {
        modalShow: false,
        dataModal: {
          efectivo:0,
          cheque:0,
          tarjeta:0,
          totalRecibido:0,
          cambio: 0
        },
        loading: false
      };
    },
    watch: {
        "dataModal.efectivo": function (val) {
          console.log("val; " , val)
          this.dataModal.totalRecibido += val;
          this.dataModal.cambio = this.dataModal.totalRecibido - this.clearDigit(this.caja.totalCarrito.total);
        },
        "dataModal.cheque": function (val) {
          console.log("val; " , val)
          this.dataModal.totalRecibido += val;
          this.dataModal.cambio = this.dataModal.totalRecibido - this.clearDigit(this.caja.totalCarrito.total);
        },
        "dataModal.tarjeta": function (val) {
          console.log("val; " , val)
          this.dataModal.totalRecibido += val;
          this.dataModal.cambio = this.dataModal.totalRecibido - this.clearDigit(this.caja.totalCarrito.total);
        },
        // despues de actualixar vairbale messaje muestra el mensaje
        "caja.messageCaja": function (val) {
          // muestra alerta
          this.generateAlert(val.mensaje, val.clase);
          if(val.clase == "toast-success"){
            // limpia carrito 
            this.$store.commit("SET_COMMIT_CARRITO_CLEAR");
            // limpia cliente seleccionado
            this.$store.commit("SET_COMMIT_CLIENTE_SELECTED", {nombre_razon_social:"No Seleccionado", limite_credito: "0.00"})
            this.cancel();
          }
        }
        
    },
    computed:{
      ...mapState([ "caja", "auth", "contado"]),
    },
    methods:{
      ...mapActions([ "headerCreateContado" ]),
      // cierra el modal
      cancel() {
        this.modalShow = false;
        this.loading = false;
        this.dataModal = { efectivo:0, cheque:0, tarjeta:0, totalRecibido:0, cambio: 0};
        
        return this.modalShow;
      },
      // setea todo el array
      setDetailRequisicionArray() {
        this.loading = true;
        
        this.cancel();
      },
      clearDigit(value){
        return value.replace(/[^0-9.-]+/g,"");
        // return Number(value.replace(/[^0-9.-]+/g,""));
      },
      realizarVenta(){
        let data = {
          tipo_venta: 3, // cotizacion
          cobrado: 0,
          total: this.clearDigit(this.caja.totalCarrito.total),
          id_cliente: this.contado.clienteSelectedVar.id_cliente,
          estatus: 1,
          id_sucursal: this.auth.user.id_sucursal,
          id_usuario: this.auth.user.idUser
        }
        this.headerCreateContado(data);

      }
    },
  };
</script>
<style>
</style>