<template>
  <b-container fluid>
    <b-col class="p-0 pb-5 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
      <b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
        
        <b-col cols="12">
          <Acciones />
        </b-col>
      </b-row>
      <b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
        <b-col class="col-xs-7 col-sm-7">
          <Productos />
        </b-col>
        <b-col class="col-xs-5 col-sm-5">
          <Compra />
        </b-col>

      </b-row>
    </b-col>
  </b-container>
</template>
<script>
  import Acciones from "./Acciones";
  import Productos from "./Productos";
  import Compra from "./Compra";
  
  import { mapActions, mapState } from 'vuex'
  export default {
    mounted(){
      
    },
    computed:mapState(["ordencompra"]),
    components:{ Acciones, Productos, Compra },
    data(){
      return{ }
    },
    methods:{
      ...mapActions([""]),
    }
  }
</script>