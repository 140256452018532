<template>
    <b-card header-tag="header" class="mt-3 card-mini" style="min-height: 670px;">
        <template v-slot:header>
            <b-row class="p-2">
                ORDENES DE COMPRAS
                <b-input-group class="mt-3">
                    <b-form-input @focus="$event.target.select()" v-model="search" autocomplete="off" type="text" class="form-control"
                    placeholder="Buscar en lotes..." aria-label="LOTES" aria-describedby="basic-addon"></b-form-input>
                </b-input-group>
            </b-row>
        </template>
        <b-table small :fields="fields" select-mode="single" selectable
            :busy="ingreso.loadOrdenCompra" :items="searchInDetail" responsive >
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Cargando...</strong>
                </div>
            </template>
            <!-- A virtual column -->
            <template class="mousehoverItemTable" v-slot:cell(index)="data">
                <b class="mousehoverItemTable" v-on:click="clicLote(data.item.id_ordern_compra)">{{ data.index + 1 }}</b>
            </template>
            <!-- A custom formatted column -->
            <template  v-slot:cell(nombre_razon_social)="data" >
                <b class="text-info mousehoverItemTable" v-on:click="clicLote(data.item.id_ordern_compra)">{{ data.item.nombre_razon_social }}</b>
            </template>
            <!-- A virtual composite column -->
            <template class="mousehoverItemTable" v-slot:cell(fecha)="data">
                <b class="mousehoverItemTable" v-on:click="clicLote(data.item.id_ordern_compra)">{{ data.item.fecha }}</b>
            </template>
            <!-- Optional default data cell scoped slot -->
            <template class="mousehoverItemTable" v-slot:cell(observaciones)="data">
                <b class="mousehoverItemTable" v-on:click="clicLote(data.item.id_ordern_compra)">{{ data.item.observaciones }}</b>
            </template>
        </b-table>
          <!-- <ItemsListLotes :search="search" :items="ingreso.ordenComprasHeader"
            tituloItemsEmpty="Orden de compra vacío" :clicItem="clicLote"
          />  -->
    </b-card>
</template>
<script>
    // import ItemsListLotes from '@/components/ItemsListLotes'
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
            // limpia las variables declaradas
            this.$store.commit("SET_COMMIT_LOAD_ORDEN_COMPRA", true);
            this.$store.commit("SET_COMMIT_COMPRA_DETAIL_LIST", []);
            this.$store.commit("SET_COMMIT_COMPRA_HEADER_LIST", []);
            
            
            // carga la lsita de lotes
            this.ordenCompraList();
        },
        components:{  },
        data(){
            return{
                search:"",
                fields: [
                    // A virtual column that doesn't exist in items
                    { key: 'index', label: '#' },
                    // A column that needs custom formatting
                    { key: 'nombre_razon_social', label: 'Nombre' },
                    // A regular column
                    { key: 'fecha', label: 'Fecha' },
                    // A regular column
                    { key: 'observaciones', label: 'Observaciones' }
                ],
                itemss: [
                    { nombre_razon_social: 'nombre_razon_social', fecha: 'fecha', observaciones: 'observaciones' },
                    { nombre_razon_social: 'nombre_razon_social1', fecha: 'fecha1', observaciones: 'observaciones1' },
                ],
            }
        },
        computed:{
            ... mapState([ "caja", "ingreso" ]),
            searchInDetail(){ 
                return this.ingreso.ordenComprasHeader.filter(req => this.search.toLowerCase()
                .split(' ').every(v => this.conditionalFilter(v, req)))
            }
        },
        methods:{
            ...mapActions(["ordenCompraList", "ordenCompraArticulosList"]),
            clicLote(id_orden_compra){
                this.$store.commit("SET_COMMIT_LOAD_CONTENIDO_ORDEN_COMPRA", true);
                this.ordenCompraArticulosList(id_orden_compra);
            },
            
            conditionalFilter(v, req){
                for (const prop in req) {
                    if(req[prop] !== null){
                        if(req[prop].toString().toLowerCase().includes(v)){
                            return true;
                        }
                    }
                }
            },
        }
    }
</script>