import { HTTP } from "./http-common";
export const categoria_list = (id_usuario) => new Promise ((rv, rj) => {
    HTTP.get("categoria-list/?id_usuario=" + id_usuario).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const subcategoria_list = (id_usuario, id_categoria) => new Promise ((rv, rj) => {
    HTTP.get("subcategoria-list/?id_usuario=" + id_usuario + "&id_categoria=" + id_categoria).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const articulo_list = (id_usuario, id_subcategoria) => new Promise ((rv, rj) => {
    HTTP.get("articulo-list/?id_usuario=" + id_usuario + "&id_categoria=" + id_subcategoria).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const articulo_get = (id_usuario, id_articulo) => new Promise ((rv, rj) => {
    HTTP.get("articulo-get/?id_usuario=" + id_usuario + "&id_articulo=" + id_articulo).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const unidad_list = (id_usuario, id_articulo) => new Promise ((rv, rj) => {
    HTTP.get("unidad-list/?id_usuario=" + id_usuario + "&id_articulo=" + id_articulo).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const unidades_cat_list = (id_usuario) => new Promise ((rv, rj) => {
    HTTP.get("unidad-cat-list/?id_usuario=" + id_usuario).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const articulo_clave_get = (id_usuario, clave) => new Promise ((rv, rj) => {
    HTTP.get("articulo-clave-get/?id_usuario=" + id_usuario + "&clave=" + clave).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});


//

export const create_articulo = (formData) => new Promise ((rv, rj) => {
    HTTP.post("articulo-create/", formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});

export const update_articulo = (formData) => new Promise ((rv, rj) => {
    HTTP.put("articulo-update/", formData,{ headers: {
        'Content-Type': 'multipart/form-data'
    }
    }).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});

export const unidad_equivalencia_create = (data) => new Promise ((rv, rj) => {
    HTTP.post("unidad-equivalencia-create/", data).then(
        res=>{ rv(res) },
        err=>{ rj(err) })
});
export const unidad_equivalencia_list = (id_usuario, id_articulo) => new Promise ((rv, rj) => {
    HTTP.get("unidad-equivalencia-list/?id_usuario=" + id_usuario + "&id_articulo=" + id_articulo).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const unidad_equivalencia_update = (data) => new Promise ((rv, rj) => {
    HTTP.put("unidad-equivalencia-update/", data).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const subcategoria_texto_list = (id_categoria, id_usuario) => new Promise ((rv, rj) => {
    HTTP.get("subcategoria-texto-list/?id_categoria=" + id_categoria + "&id_usuario=" + id_usuario).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const articulos_precio_list = (id_categoria, id_sub_categoria, id_usuario) => new Promise ((rv, rj) => {
    HTTP.get("articulos-precio-list/?id_categoria=" + id_categoria + "&id_sub_categoria=" + id_sub_categoria+ "&id_usuario=" + id_usuario).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const categoria_texto_list = (id_usuario) => new Promise ((rv, rj) => {
    HTTP.get("categoria-texto-list/?id_usuario=" + id_usuario).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const precio_update = (data) => new Promise ((rv, rj) => {
    HTTP.put("precio-update/", data).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});