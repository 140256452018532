<template>
    <b-card header-tag="header" class="mt-1 card-mini">
        <b-row >
            <b-col class="col-xs-2 col-sm-2 col-md-2">
                <b-card style="background-color: #ebebeb;">
                    <b-form-group id="codigo" label-for="codigo" class="mb-0">
                        <input @focus="$event.target.select()" v-model="search" required autocomplete="off" type="text" class="form-control"
                        placeholder="Código..." aria-label="PRODUCTOS" aria-describedby="basic-addon">
                        <b-row align-h="center" align-w="center" class="mt-2">
                            <Boton tooltip="Realizar la búsqueda" class="mr-1"
                            icon="search" fontscale="2" :onClic="clicBusquedaDirecta" variant="info" btn="btn-secundary" size="sm"/>

                            <Boton tooltip="Realizar la cotización" class="ml-1"
                            icon="camera" fontscale="2" :onClic="clicCotizacion" variant="info" btn="btn-default" size="sm"/>
                        </b-row>
                    </b-form-group>
                </b-card>
            </b-col>
            <b-col class="col-xs-10 col-sm-10 col-md-10">
                <b-row class="row ml-3" align-h="end" align-w="end">
                    <div :class="isRoute('/devolucion/crear') || isRoute('/articulos/actualizar') ? 'mr-2 mb-2 disabledbutton' : 'mr-2 mb-2'"
                        v-on:click="gotToCotizacion" v-b-tooltip.hover title="Realizar la cotización">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/cotizacion.png')" width="110" height="110"
                        ></b-img>

                        <!-- <Boton text="Cotización" tooltip="Realizar la cotización"
                        icon="file-text" fontscale="4" :onClic="clicCotizacion" variant="info" btn="btn-default" size="md"/> -->
                    </div>
                    <div
                        :class="isRoute('/devolucion/crear') || isRoute('/articulos/actualizar') ? 'mr-2 mb-2 disabledbutton' : 'mr-2 mb-2'"
                        v-on:click="goToContado" v-b-tooltip.hover title="Realizar la venta de contado">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/contado.png')" width="110" height="110"
                        ></b-img>
                        <!-- <Boton text="Contado" tooltip="Realizar la venta de contado" style="width: 8.529rem;"
                        icon="lightning-fill" fontscale="4" variant="primary" btn="btn-default" size="md"/> -->
                        
                    </div>
                    <div 
                        :class="isRoute('/devolucion/crear') || isRoute('/articulos/actualizar') ? 'mr-2 mb-2 disabledbutton' : 'mr-2 mb-2'"
                        v-on:click="goToCredito" v-b-tooltip.hover title="Realizar la venta a credito">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/credito.png')" width="110" height="110"
                        ></b-img>
                        <!-- <Boton text="Credito" tooltip="Realizar la venta a credito" style="width: 8.529rem;"
                        icon="credit-card" fontscale="4" variant="dark" btn="btn-default" size="md"/> -->
                    </div>
                    <div 
                        :class="caja.show !== 'categoria' ? 'mr-2 mb-2 disabledbutton' : 'mr-2 mb-2'"
                        v-on:click="goToDevoluciones" v-b-tooltip.hover title="Realizar una devolución">
                        <b-img class="mousehoverItem" 
                            :src="require('@/assets/botones/devolucion.png')" width="110" height="110"
                        ></b-img>
                        <!-- <Boton text="Devolución" tooltip="Realizar una devolución" :onClic="ejemploClic1"
                        icon="file-earmark-minus" fontscale="4" variant="inwarningfo" btn="btn-default" size="md"/> -->
                    </div>
                    <div 
                        :class="isRoute('/devolucion/crear') || isRoute('/articulos/actualizar') ? 'mr-2 mb-2 disabledbutton' : 'mr-2 mb-2'"
                        v-on:click="goToCobros" v-b-tooltip.hover title="Ver listado de cobros">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/cobros.png')" width="110" height="110"
                        ></b-img>
                        <!-- <Boton text="Cobros" tooltip="Ver listado de cobros" style="width: 8.529rem;"
                        icon="archive" fontscale="4" variant="success" btn="btn-default" size="md"/> -->
                    </div>
                    <div 
                        :class="caja.show !== 'categoria' ? 'mr-2 mb-2 disabledbutton' : 'mr-2 mb-2'"
                        v-on:click="cancelarCarrito"
                        v-b-tooltip.hover title="Cancelar">
                        <b-img class="mousehoverItem"
                            :src="require('@/assets/botones/cancelar.png')" width="110" height="110"
                        ></b-img>
                        <!-- <Boton 
                        :disabled="caja.cajaCarrito.length===0" 
                        text="Cancelar" tooltip="Cancelar carrito" :onClic="cancelarCarrito" style="width: 8.529rem;"
                        icon="x-circle-fill" fontscale="4" variant="danger" btn="btn-cancel" size="md"/> -->
                    </div>
                <!-- <div class="mr-3">
                    <Boton text="Ocultar" tooltip="Oculta la lista del carrito" 
                    :icon="carritoIcon" fontscale="4" :onClic="clicCarrito" variant="info" btn="btn-secundary" size="md"/>
                </div> -->
                </b-row>
            </b-col>
                <!-- <KeyUp v-if="keyUpFacturacion" v-on:keyup="keyPressedFacturacion"></KeyUp> 
                <KeyUp v-if="keyUpVentaEspecial" v-on:keyup="keyPressedVentaEspecial"></KeyUp>  -->
        </b-row>
            <!-- </b-col> -->
        <!-- </b-row> -->
    </b-card>
</template>

<script>
    import router from '@/router'
    //import KeyUp from '@/components/KeyUp'
    import Boton from '@/components/Boton'
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
        },
        props:["clicProducto","clicCarrito", "carritoIcon", "productoIcon", "clicCotizacion", "keyUpFacturacion", "keyUpVentaEspecial" ],
        components:{ Boton },
        watch: {
            "caja.facturacion": function () {
                this.isFacturacion();
            },
            "contado.tipoVenta": function () {
                this.isVentaEspecial();
            },
        },
        data(){
            return{ search:'', }
        },
        computed: {
            ...mapState([ "caja", "contado", "devolucion" ])
        },
        methods:{
            ...mapActions(["clicReverse", "articuloGetDirecto"]),
            clicBusquedaDirecta(){
                console.log("asdsddd: " , this.search);
                this.articuloGetDirecto(this.search);
            },
            isFacturacion(){
                if (this.caja.facturacion) {
                    this.generateAlert("Facturación activada", "toast-success");
                }else {
                    this.generateAlert("Facturación desactivada", "toast-success");
                }
            },
            isVentaEspecial(){
                if (this.contado.tipoVenta == 4) {
                    this.generateAlert("Venta especial activada", "toast-success");
                }else {
                    this.generateAlert("Venta especial desactivada", "toast-success");
                }
            },
            keyPressedFacturacion($event){
                if ($event.key === "ƒ") {
                    // console.log("$event: " , $event);
                    this.$store.commit("SET_FACTURACION", !this.$store.state.caja.facturacion);
                }
            },
            keyPressedVentaEspecial($event){
                if ($event.key === "∫") {
                    // console.log("$event: " , $event);
                    let tipo_venta = (this.$store.state.contado.tipoVenta == 1) ? 4 : 1;
                    this.$store.commit("SET_COMMIT_TIPO_VENTA", tipo_venta);
                }
            },
            ejemploClic1(){
                console.log("asdasdasdasd: ");
            },
            // solo limpia el carrito
            cancelarCarrito(){
                this.clicReverse("subcategoria");
                this.$store.commit("SET_COMMIT_CARRITO_CLEAR");
                this.generateAlert("Carrito cancelado", "toast-success");
                router.push('/caja')
            },
            // dirige a pantalla de contado
            goToContado(){
                router.push('/venta/crear/contado')
            },
            gotToCotizacion(){
                router.push('/cotizacion/crear')
            },
            goToCredito(){
                router.push('/venta/crear')
            },
            goToDevoluciones(){
                router.push('/devolucion/crear')
            },
            goToCobros(){
                router.push('/cobro/crear')
            },
            isRoute(ruta){
                return router.history.current.path == ruta;
            }
        }
    }
</script>