import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        venta: false,
        message: "",
        cajaCarrito: [],
        clientesList: [],
        id_venta: 0,
        tipoVenta: 2, 
        
        facutura: false,
        
    },
    mutations,
    actions,
};