<template>
  <router-view/>
</template>

<script>
  export default {
    name: 'App',
  }
</script>

<style>
  @import 'assets/css/animate.min.css';
  @import 'assets/css/style.css';
</style>
