import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        articulo: false,
        messageArticulo: { mensaje:"", clase:""},
        //imagenArticulo: null,
        id_unidad:0,
        getArticulos: {},
        articulosGet:[],
        id_categoria:0,
        unidadesList: [],
        unidadesCatList: [],
        categoriasList:[],
        subcategoriasList:[],
        id_subcategoria:0,
        editIdArticuloList: 0,
        loadArticulos: false,
        articulosList:[], 

        id_articulo_form: 0,
        id_unidad_equivalencia: 0,
        unidad_equivalencia_list:[], 


        categoriasListEdit:[],
        subcategoriasListEdit:[],
        articulosListEdit:[],
        messageArticuloEdit: { mensaje:"", clase:""},
    },
    mutations,
    actions,
}; 