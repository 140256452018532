<template>
  <b-modal id="codigoBarras" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="CÓDIGO DE BARRAS" title-class="modal-title w-100" body-class="p-4" >
    <!-- <b-row> -->
      <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>

    <!-- </b-row> -->

    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Cerrar" class="mr-3"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-secundary" size="sm"/>

        <!-- <Boton text="Cancelar" tooltip="Cancelar"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/> -->
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  import { StreamBarcodeReader } from "vue-barcode-reader";
  export default {
    mounted(){
    },
    components:{ StreamBarcodeReader, Boton },
    data() {
      return { modalShow: false, };
    },
    watch: {
      // actualiza el importe
      "datosModal.cantidad": function (val) {
        console.log("VAL: " , val);
      } 
    },
    computed:{
      ...mapState([ "caja", "auth", "contado"]),
    },
    methods:{
      ...mapActions(["articuloGetDirecto"]),
      // cierra el modal
      cancel() {
        this.modalShow = false;
      },
      onDecode(result) {
        this.articuloGetDirecto(result);
        console.log("onDecode: " , result)
        this.cancel();
      },
      onLoaded(result) {
          console.log("onError: " , result)
      }
    },
  };
</script>
<style>
</style>