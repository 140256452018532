import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        cobro: false,
        clientesAbonos: [],
        totalAdeudo:"0.00",
        messageCobro: { mensaje:"", clase:""},
        cajaCobro: [],
    },
    mutations,
    actions,
};