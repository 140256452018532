import { HTTP } from "./http-common";
export const proveedores_list = (id_usuario) => new Promise ((rv, rj) => {
    HTTP.get("proveedor-list/?id_usuario=" + id_usuario).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const proveedor_get = (id_usuario, id_proveedor) => new Promise ((rv, rj) => {
    HTTP.get("proveedor-get/?id_usuario=" + id_usuario + "&id_proveedor=" + id_proveedor).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});

export const create_Proveedor = (data) => new Promise ((rv, rj) => {
    HTTP.post("proveedor-create/", data).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});

export const update_Proveedor = (data) => new Promise ((rv, rj) => {
    HTTP.put("proveedor-update/", data).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});