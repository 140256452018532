<template :id="idTemplate">
    <div  >
        <!-- listado de items -->
        <b-col v-b-scrollspy:scrollspy-nested v-if="items.length > 0" class="col-md-12 " id="scrollspy-nested" style="max-height:calc(52vh); overflow-y:auto">
            <b-row v-b-toggle="'colapse-desc-'+(items.indexOf(item) + 1)" class="mt-1 mousehoverItem animate__animated animate__bounceIn" v-for="(item, index) in itemsPaginated" v-bind:key="index">
               
                <b-col style="padding-left:1rem;" class="col-md-1">
                    <b-row >
                        <b-col cols="12" class="p-0 pt-2 text-titulo" >
                            <h1 class="text-center w-100" >{{ cliente.clienteList.indexOf(item) + 1 }}</h1>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >RFC</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.rfc }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-3">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >CLIENTE</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.nombre_razon_social }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >CIUDAD</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.ciudad ? item.ciudad : "NO ASIGNADO" }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >LIMITE DE CREDITO</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.limite_credito }}
                        </b-col>
                    </b-row>
                </b-col>
                <!-- <b-col v-if="actions && !collapse" class="col-md-2 col-sm-3 col-xl-12"> -->
                    <b-row class="col-md-2 col-sm-2 col-xl-2">
                        <b-col class="p-1 pl-2 text-titulo text-left text-shadow mb-0 col-md-12" >
                            <strong >ACCIONES</strong>
                        </b-col>
                        <b-col class="justify-content-md-center text-shadow text-descripcion col-md-12" >
                            <b-row class="justify-content-md-center mb-1 mt-1" align-v="center">
                                <b-col class="col-md-6 col-sm-6 col-xl-6">
                                    <Boton class=""
                                        icon="pencil-square" fontscale="1" :onClic="edit" :valorEventClic="item.id_cliente"
                                        variant="primary" btn="btn-default" size="sm" />
                                </b-col>
                                <b-col class="col-md-6 col-sm-6 col-xl-6">
                                    <Boton class="mr-2"
                                        icon="trash-fill" fontscale="1" :onClic="realizarEliminacion" :valorEventClic="item"
                                        variant="danger" btn="btn-cancel" size="sm" />
                                </b-col>
                               
                                 <!-- <b-col class="col-md-6 col-sm-6 col-xl-6">
                                    <Boton :tooltip="`Ver imagen de ${item.clave}`" :onClic="verImagen" :valorEventClic="item.imagen"
                                        icon="image" fontscale="1" variant="primary" btn="btn-detail" size="sm" />
                                </b-col>  -->
                            </b-row>
                        </b-col>
                    </b-row>
                <!-- </b-col> -->

            </b-row>
        </b-col>
       


       
    </div>
</template>
<script>
    import Boton from '@/components/Boton'
import { mapState, mapActions } from 'vuex';
    //import { mapState  } from "vuex";
    export default {
          mounted(){
              
          },

        props:[ "item", "items", "SET_COMMIT_IMG", "SET_COMMIT_DELETE", "SET_COMMIT_EDIT", "idTemplate", "search", "actions", "collapse" ],
        data() {
            return {
       
            };
        },
        components:{ Boton },
        computed:{
             ...mapState(["cliente", "auth" ]),
           
            // paginación
            itemsPaginated(){ 
               // return this.items;
                return this.searchInDetail();
            }
        },
        watch: {
           "cliente.messageCliente": function (val) {
        if(val.mensaje != ""){
          this.generateAlert(val.mensaje, val.clase);
          this.clienteList();
        }
      },
           
              "items": function () {
                // console.log("CLIENTE: " , this.items);
                //realizarEliminacion(items);
                 
             },
        },
        methods:{
            ...mapActions(["clienteUpdate", "clienteList"]),
            // busqueda dentro del array
             searchInDetail(){
                 return this.items.filter(req => this.search.toLowerCase()
                 .split(' ').every(v => this.conditionalFilter(v, req)))
             },
            conditionalFilter(v, req){
                for (const prop in req) {
                    if(req[prop] !== null){
                        if(req[prop].toString().toLowerCase().includes(v)){
                            return true;
                        }
                    }
                }
            },
            // muestra los primeros 172 caracteres del texto
            toggleText(text) {
                let res = "";
                let value = (text.length >= 172) ? 172 : (text.length / 2);
                for (let i = 0; i < value; i++) { res += text[i]; }
                return res += " ...";
            },
            // borra la posocion del array
            // eliminar(item) {
            //     // console.log("eliminar: " , item);
            //     // console.log("indexOf: " , this.items.indexOf(item));
            //     this.$store.commit(this.SET_COMMIT_DELETE, this.items.indexOf(item))
            // },
            // inicializa la variable id para mostrar en el modal EDITAR, usar watch para accionar el modal
            edit(item) {
                this.$store.commit(this.SET_COMMIT_EDIT, item)
                 console.log("edit: " , item);
            },
            realizarEliminacion(item){
               let data = {
                   id_cliente: item.id_cliente,
                    rfc:item.rfc,
                    nombre_razon_social:  item.nombre_razon_social,
                    direccion: item.direccion,
                    colonia: item.colonia,
                    estado: item.estado,
                    ciudad:  item.ciudad,
                    limite_credito: item.limite_credito,
                    persona_moral:  item.persona_moral,
                    estatus: 0,
                    telefono: item.telefono,
                    calle: item.calle,
                    no_interior: item.numero_interior,
                    no_exterior: item.numero_exterior,
                    municipio: item.municipio,
                    pais: item.pais,
                    cp: item.codigo_postal,
                    e_mail: item.e_mail,
                    id_usuario:this.auth.user.idUser
                }
                this.clienteUpdate(data);
                this.clienteList();
           }
           
        }
    };
</script>
<style scoped>
</style>