<template>
<b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
    <b-col class="col-md-12">

        <b-card header-tag="header" class="mt-3 card-mini">
         <b-card header-tag="header" class="mt-1 card-mini" style="height: 10.5rem; background-color: #E5E5E5; color: black;">
        <b-row>
                <b-col class="col-md-12" >
                    <h2>Registro proveedores</h2>
                </b-col>
                
                 <b-col class="col-md-11" style="margin-top: 6px;">
                     
                    <b-input-group>
                                <b-input-group-append>
                            <Boton 
                            tooltip="Buscar" 
                             icon="search" fontscale="2" size="sm"/> <!--:onClic="buscar"   -->
                            
                                </b-input-group-append>
                                <b-form-input @focus="$event.target.select()" v-model="search" 
                                required autocomplete="off" 
                                type="text" 
                                class="form-control" 
                                placeholder="Buscar" aria-label="PRODUCTOS" 
                                aria-describedby="basic-addon"></b-form-input>
                            </b-input-group> 
                </b-col>      
                <b-row>
                    <b-col class="col-md-1" style="padding-bottom:32px;">
                        <Boton :onClic="agregar"  
                            tooltip="Agregar" style="background-color: #FF5923;"
                            icon="plus-circle" fontscale="2" size=""/>
                    </b-col>
                </b-row> 
                <b-col class="col-md-6" style="padding-top: 32px;">
                    <b-input-group class="mt-6">
                            
                            
                </b-input-group>
                </b-col>
               
        </b-row>
<b-row>
  
</b-row>
<Modal />
    </b-card>

          <ItemsList 
           :actions="true" 
           :search="search"
            :items="listClientes()"
           :proveedorList="proveedorList"
           SET_COMMIT_SELECT="SET_COMMIT_PROVEEDOR"
          SET_COMMIT_DELETE="SET_COMMIT_PROVEEDOR_DELETE"
                SET_COMMIT_EDIT="SET_COMMIT_EDIT_PROVEEDOR"
                
            /> 
        <b-col class="col-sm-12 p-0 m-0">
          <!-- paginación -->
          <div class="mt-3" v-show="searchInDetail().length > 0">
            <b-pagination
              v-model="currentPage"
              :total-rows="searchInDetail().length"
              align="center"
              :per-page="perPage"
              aria-controls="almacenItems"
            >
            </b-pagination>
          </div>
        </b-col>
<!-- :onClic="clic" -->
          
<ModalEditar />
        </b-card>

    </b-col>
   
</b-row>
</template>
<script>
    import Boton from '@/components/Boton'
    import Modal from '@/components/ModalProveedor'
     //import Titulo from "./Titulo"
    import ModalEditar from "./ModalEditarProveedor"
    import ItemsList from '@/components/ItemsListCrudProveedor'
    import { mapState, mapActions } from "vuex";

    export default {
        mounted(){
            this.$store.commit("SET_COMMIT_PROVEEDOR", []);
            this.proveedorList();
            //console.log("proveedor: " , this.proveedorList());
        },
         
        components:{ ItemsList,  ModalEditar, Boton, Modal },
        data(){
            return{
                search: "",
                perPage: 20,
                currentPage: 1,
              
            }
        },
        computed: mapState([ "proveedor" ]),
        methods:{
            ...mapActions(["proveedorList", "SET_COMMIT_PROVEEDOR"]),
            listClientes(){ 
                return this.searchInDetail().slice(
                    (this.currentPage - 1) * this.perPage,
                    this.currentPage * this.perPage
                )
            },
            searchInDetail(){
                return this.proveedor.proveedorList.filter(req => this.search.toLowerCase()
                    .split(' ').every(v => this.conditionalFilter(v, req)))
            },
            conditionalFilter(v, req){
                for (const prop in req) {
                    if(req[prop] !== null){
                        if(req[prop].toString().toLowerCase().includes(v)){
                            return true;
                        }
                    }
                }
            },
            agregar(){
                this.$bvModal.show('modal');
            }
        }
    }
</script>