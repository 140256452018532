<template>
  <b-modal id="modalCambioPrecio" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true" title="Capturar precio" title-class="modal-title w-100" body-class="p-4" >
    <b-row>
      
      <b-col class="col-md-12" >
        <b-form-group id="input-group-1" label="PRECIO:" label-class="form-label" label-for="input-1"  class="mb-0">
          <b-input-group class="m-0" size="md" prepend="$">

            <b-form-input id="input-live" type="number"
              v-model.number="datosModal.precio"
              @focus="$event.target.select()" 
              :state="false" aria-describedby="input-live-help input-live-feedback" placeholder="Precio..." trim
            ></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
                Está a punto de capturar un precio
            </b-form-invalid-feedback>

          </b-input-group>
        </b-form-group>
      </b-col>
          
          
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Guardar" class="mr-3" :disabled="datosModal.precio===''"
        icon="lock-fill" fontscale="2" :onClic="cambiarPrecio" variant="info" btn="btn-secundary" size="sm"/>

        <Boton text="Cancelar" tooltip="Cancelar"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/>
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  export default {
    mounted(){
      this.$store.commit("SET_PRECIO_TECLEADO", "");
    },
    components:{ Boton },
    data() {
      return {
        modalShow: false,
        datosModal:{
          precio:"",
        },
        loading: false
      };
    },
    watch: {
      "caja.editIdCarrito": function () {

      },
    },
    computed:{
      ...mapState([ "caja", "auth"]),
    },
    methods:{
      ...mapActions([ ]),

      restrictDecimal(val) {
        let cambio = parseFloat(val);
        return cambio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      },
      // cierra el modal
      cancel(param) {
        if(!param){ this.$store.commit("SET_PRECIO_TECLEADO", "");}
        this.modalShow = false;
        this.loading = false;
        this.datosModal = { precio:"" };
        return this.modalShow;
      },
      cambiarPrecio(){
        this.$store.commit("SET_PRECIO_TECLEADO", this.datosModal.precio);
        this.cancel(true);
      },
      clearDigit(value){
        return Number(value.replace(/[^0-9.-]+/g,""));
      },
    },
  };
</script>
<style>
</style>