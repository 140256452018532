/*eslint-disable no-unused-vars*/
import { login } from '@/api/auth'
// import router from '@/router'

export const actions = {
    realizarVenta: async({ commit }, auth) => login(auth).then(
        res=>{
            
        }).catch(err=>console.log(err)
            
    ),

};