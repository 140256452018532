<template>
  <b-collapse id="nav-collapse" is-nav>
    <b-navbar-nav class="ml-auto text-center">
      <b-nav-item-dropdown
        v-for="item in menu"
        :key="item.text"
        size="sm"
        class="btn-menu"
        :text="item.title"
        left
      >
        <b-dropdown-item
          v-for="subitem in item.submodules"
          :key="subitem.title"
          :to="subitem.to"
          v-show="subitem.visible"
        >{{subitem.title}}</b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-text class="text-center pl-2">
        <b-avatar></b-avatar>
        {{`${auth.user.firstName} ${auth.user.lastName}`}}
      </b-nav-text>
      
        <b-button size="sm" v-b-tooltip.hover title="Cerrar sesión" class="my-2 ml-3 text-decoration-none btn-salir" type="button" @click="exit">
          <b-icon icon="power" class="icon-size"></b-icon>
          Salir
        </b-button>
        
    </b-navbar-nav>
  </b-collapse>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  computed: mapState(["auth"]),
  data() {
    return {
      menu: [
        {
          title: "Punto de venta",
          visible: true,
          submodules: [
           {
              title: "Caja",
              visible: this.isPermiso("0201"),
              to: "/caja"
            },{
              title: "Corte de caja",
              visible: this.isPermiso("0201"),
              to: "/cortecaja/crear"
            },
            {
              title: "Facturación",
              visible: this.isPermiso("0205"),
              to: "/facturacion/crear"
            },
            // // se comentó porque no tiene funcionalidad
            // {
            //   title: "Factura",
            //   visible: this.isPermiso("0205"),
            //   to: "/factura/crear"
            // },
          ]
        },
        {
          title: "Almacén",
          visible: true,
          submodules: [
            {
              title: "Orden de compra desde inventario",
              visible: this.isPermiso("0401"),
              to: "/ordencomprainventario/crear"
            },
            {
              title: "Orden de compra",
              visible: this.isPermiso("0401"),
              to: "/ordencompra/crear"
            },
            {
              title: "Ingreso",
              visible: this.isPermiso("0401"),
              to: "/ingreso/crear"
            },
          ]
        },
        {
          title: "Herramientas",
          visible: true,
          submodules: [
            // {
            //   title: "Usuarios",
            //   visible: this.isPermiso("0601"),
            //   to: "/caja"
            // },
            {
              title: "Impresora",
              visible: true,
              to: "/configurarimpresora"
            },
          ]
        },
        {
          title: "Registro",
          visible: true,
          submodules: [
           {
              title: "Articulo",
              visible: this.isPermiso("0801"),
              to: "/articulos/crear"
            },
            {
              title: "Actualización precios",
              visible: this.isPermiso("0801"),
              to: "/articulos/actualizar"
            },
            {
              title: "Cliente",
              visible: this.isPermiso("0801"),
              to: "/cliente/crear"
            },
            {
              title: "Proveedor",
              visible: this.isPermiso("0805"),
              to: "/proveedor/crear"
            },
          ]
        },
      ]
    };
  },
  methods:{
    ...mapActions(["logout"]),
    exit(){

      // para el evento tecla, elimina el evento insertado
      // console.log("this.keyPressed: " , this.keyPressed);
      // window.removeEventListener("keyup", this.keyPressed, true);
      this.$store.commit("SET_FACTURACION", false);
      ////////////////////////////////////////////////////////

      this.logout();
    },
    isPermiso(permiso) {
      let values = localStorage.getItem("aceromax_PERMISSIONS");
      console.log("values: " , permiso);
      console.log("values: " , values);
      // if(values != null){
      //   let permisos = values.split(",");
      //   return permisos.indexOf(permiso) < 0 ? false : true;
      // }
      return true;
    }
  }
};
</script>