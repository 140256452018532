<template>
  <div
    class="col-sm-8 my-auto mx-auto"
    style="margin-bottom: 0px !important; padding-bottom: 0px !important"
  >
    <b-card class="bg-transparent border-none p-3" title sub-title>

      <h5 class="text-center p-3 text-dark">INICIAR SESIÓN</h5>
      <b-form @submit.prevent="sendCredentials">

        <b-form-group id="input-group-1" label="Usuario:" label-for="input-1" description>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon">
                <b-icon icon="person-fill"></b-icon>
              </span>
            </div>
            <input 
              v-model="credentials.user" required 
              autocomplete="off" type="text" class="form-control"
              placeholder="ejemplo" aria-label="Usuario" aria-describedby="basic-addon">
          </div>
        </b-form-group>

        <b-form-group id="input-group-2" label="Contraseña:" label-for="input-1" description>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon">
                <b-icon icon="lock-fill"></b-icon>
              </span>
            </div>
            <input 
              v-model="credentials.password" required 
              autocomplete="off" type="password" class="form-control"
              placeholder="*********" aria-label="Contraseña" aria-describedby="basic-addon">
          </div>
        </b-form-group>
        
        <b-row>
          <b-col cols="8">
            <b-button class="pl-0 btn-link2" type="button" variant="link">Recuperar contraseña</b-button>
          </b-col>
          <b-col cols="4" class="text-right">
            <b-button type="submit" class="btn-secundary" :disabled="loading">
              <b-spinner small v-if="loading"></b-spinner>
               {{loading ? 'Validando' : 'Acceder'}}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <MessageForm :message="auth.message" v-if="auth.message"/>
    </b-card>
  </div>
</template>

<script>
// import router from '@/router'
import { mapActions, mapState } from "vuex";
import MessageForm from "@/components/MessageForm"
export default {
  components:{
    MessageForm
  },
  data() {
    return {
      credentials: {
        user: "",
        password: ""
      },
      loading: false
    };
  },
  computed:mapState(["auth"]),
  methods: {
    ...mapActions(["login"]),
    async sendCredentials() {
      this.loading = true;
      setTimeout(async ()=>{
        // localStorage.setItem('aceromax_HASH',"res.data.HASH")
        // localStorage.setItem('aceromax_PERMISSIONS',"res.data.Permisos")
        // localStorage.setItem('aceromax_USER',JSON.stringify({
        //     idUser: 1,
        //     firstName: "Usuario",
        //     lastName: "Prueba",
        //     email: "usuario@example.com",
        //     idRol: 1,
        //     rolName: "Rol Name",
        // }))
        
        // this.$store.commit('SET_USER',{
        //     idUser: 1,
        //     firstName: "Usuario",
        //     lastName: "Prueba",
        //     email: "usuario@example.com",
        //     idRol: 1,
        //     rolName: "Rol Name",
        // })
        // router.push('/caja')
        await this.login(this.credentials);
        this.loading = false;
      },800)
    }
  }
};
</script>
