<template>
    <b-container fluid class="p-1">
        <b-row >
            <b-breadcrumb>
                <b-breadcrumb-item v-if="show == 'categoria' || show == 'subcategoria' || show == 'articulos' || show == 'articulo'"
                    :active="show == 'categoria'" v-on:click="clicReverse('categoria')">
                    <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
                    Categoría
                </b-breadcrumb-item>
                
                <b-breadcrumb-item v-if="show == 'subcategoria' || show == 'articulos' || show == 'articulo'" 
                    :active="show == 'subcategoria'" v-on:click="clicReverse('articulos')">Subcategoría</b-breadcrumb-item>

                <b-breadcrumb-item v-if="show == 'articulos' || show == 'articulo'" :active="show == 'articulos'"
                    v-on:click="clicReverse('articulo')">Artículos</b-breadcrumb-item>

                <b-breadcrumb-item v-if="show == 'articulo'" active>Artículo</b-breadcrumb-item>
            </b-breadcrumb>
        </b-row>

        <b-col v-if="load" class="col-md-12" >
            <b-row align-h="center" align-w="center" class="mb-1 mt-5 text-center" >
                <b-iconstack font-scale="7.5" animation="cylon" style="width: 170px; height: 170px;">
                    <b-icon stacked icon="circle-fill" variant="secondary"></b-icon>
                    <b-icon stacked icon="box-seam" scale="0.5" variant="white"></b-icon>
                    <b-icon stacked icon="circle" variant="secondary"></b-icon>
                </b-iconstack>
            </b-row>
            <b-row align-h="center" align-w="center" class="" >
                <h3><strong class="">Cargando...</strong></h3>
            </b-row>
        </b-col>

        <!-- lista de los items -->
        <b-row v-if="items.length > 0 && !load">

            <div v-show="show == 'categoria'" :class="responsivo" 
                v-on:click="clicCategoria(item)" v-for="(item) in searchInDetail" v-bind:key="item.categoria">
                <b-img class="mousehoverItem"
                    :src="item.imagen ? 'data:image/jpeg;base64,' + item.imagen : require('@/assets/img/nodisponible.png')"
                    :fluid="fluid" v-b-tooltip.hover :title="item.categoria"
                ></b-img>
            </div>

            <div v-show="show == 'subcategoria'" :class="show == 'subcategoria' ?
                responsivo + ' animate__animated animate__bounceInLeft animate__fast 500ms' : responsivo"
                v-on:click="clicSubCategoria(item)" v-for="(item) in searchInDetail" v-bind:key="item.sub_categoria">
                <b-img class="mousehoverItem"
                    :src="item.imagen ? 'data:image/jpeg;base64,' + item.imagen : require('@/assets/img/nodisponible.png')"
                    :fluid="fluid" v-b-tooltip.hover :title="item.sub_categoria"
                ></b-img>
            </div>

            <b-col v-if="show == 'articulos'">
                <ItemsListArticulos :verImagen="verImagen" :class="show == 'articulos' ?
                'p-1 animate__animated animate__bounceInLeft animate__fast 500ms' : 'p-1'"
                 :clicArticulo="clicArticulos" :items="searchInDetail"/>
            </b-col>
            <b-col v-if="show == 'articulo'" >
                <Item :precioCampo="precioCampo" :descuentoComponent="descuentoComponent" :class="show == 'articulo' ?
                'p-1 animate__animated animate__bounceInLeft animate__fast 500ms' : 'p-1'" :item="searchInDetail[0]" :cancelar="cancelar" :addToCarrito="addToCarrito"/>
            </b-col>
        </b-row>

        <!-- muestra que está vacío -->
        <b-col v-if="items.length == 0 && !load" class="col-md-12" >
            <b-row align-h="center" align-w="center" class="mb-1 mt-5" v-b-tooltip.hover title="No hay registros">
                <b-avatar icon="box" style="width: 170px; height: 170px;"></b-avatar>
                <!-- <b-avatar style="width: 170px; height: 170px;">
                    <b-icon icon="cart3" animation="fade" style="width: 120px; height: 120px;"></b-icon>
                </b-avatar> -->
            </b-row>
            <b-row align-h="center" align-w="center" class="" >
                <h3><strong class="">¡No hay registros!</strong></h3>
            </b-row>
        </b-col>
        <!-- para presionar tecla ESC y regresar entre la lista de productos -->
        <KeyUp v-if="show !== 'categoria'" v-on:keyup="keyPressedItems"></KeyUp> 
        <ModalImagen />
    </b-container>
</template>
<script>
    import ModalImagen from "./ModalImagen";
    import KeyUp from '@/components/KeyUp'
    import Item from '@/components/Item'
    import ItemsListArticulos from '@/components/ItemsListArticulos'
    export default {
        mounted(){
            // this.show = "categoria";
            // this.$store.commit(this.commitShow, "categoria");
        },
        components:{ Item, ItemsListArticulos, KeyUp, ModalImagen },
        props:[ "precioCampo", "descuentoComponent", "items", "onClic" , "thumbnail" , "fluid", "search", "addToCarrito", "clicReverse", "show", "load", "SET_COMMIT_IMG"],
        data() {
            return {  }
        },
        computed: {
            // busqueda dentro del array
            searchInDetail(){
                return this.items.filter(req => this.search.toLowerCase()
                .split(' ').every(v => this.conditionalFilter(v, req)))
            },
            // dependiendo de la cantidad de items se ajustan las columnas
            responsivo(){
                let res = "p-1";
                // 'p-1 col-xl-1'
                // 'p-1 col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12'
                if(this.searchInDetail.length >= 1 && this.searchInDetail.length <= 2){
                    res = 'p-1 col-xs-6 col-md-6';
                }
                if(this.searchInDetail.length >= 3 && this.searchInDetail.length <= 6){
                    res = 'p-1 col-xs-4 col-md-4';
                }
                if(this.searchInDetail.length >= 7 && this.searchInDetail.length <= 12){
                    res = 'p-1 col-xs-3 col-md-3';
                }
                if(this.searchInDetail.length >= 13 && this.searchInDetail.length <= 24){
                    res = 'p-1 col-xs-2 col-md-2';
                }
                if(this.searchInDetail.length >= 25){
                    res = 'p-1 col-xs-1 col-md-1';
                }
                return res;
            },
        },
        methods:{
            clicCategoria(item){
                this.onClic(item, "categoria");
                // this.show = 'subcategoria'
                // this.$store.commit(this.commitShow, "subcategoria");
            },
            clicSubCategoria(item){
                // se borra porque no se contemplaba que existiera en el item
                delete item.id_categoria;
                this.onClic(item, "subcategoria");
                // this.show = 'articulos'
                // this.$store.commit(this.commitShow, "articulos");
            },
            clicArticulos(item){
                // se borra porque no se contemplaba que existiera en el item
                delete item.id_sub_categoria;
                this.onClic(item, "articulos");
                // this.show = 'articulo';
                // this.$store.commit(this.commitShow, "articulo");
            },
            cancelar(){
                // this.show = 'articulos';
                // this.$store.commit(this.commitShow, "articulos");
                this.onClic(this.items, "articulo");
            },
            conditionalFilter(v, req){
                for (const prop in req) {
                    if(req[prop] !== null){
                        if(req[prop].toString().toLowerCase().includes(v)){
                            return true;
                        }
                    }
                }
            },
            // mostrar la imagen
            verImagen(imagen) {
                this.$store.commit(this.SET_COMMIT_IMG, imagen)
            },
            // evento para regresar un anterior en productos
            keyPressedItems($event){
                if ($event.key === "Escape") {
                    this.clicReverse(this.show);
                    // if(this.show == "subcategoria"){
                    //     this.clicReverse("subcategoria");
                    //     // this.show = "categoria";
                    //     // this.$store.commit(this.commitShow, "categoria");
                    // }
                    // if(this.show == "articulos"){
                    //     // se borra porque no se contemplaba que existiera en el item
                    //     // if(this.items.length > 0){
                    //     //     delete this.items[0].id_sub_categoria;
                    //     // }
                    //     this.clicReverse("articulos");
                    //     // this.show = "subcategoria";
                    //     // this.$store.commit(this.commitShow, "subcategoria");
                    // }
                    // if(this.show == "articulo"){
                    //     // this.cancelar();
                    //     this.clicReverse("articulo");
                    //     // this.show = 'articulos';
                    //     // this.$store.commit(this.commitShow, "articulos");
                    // }
                    // // console.log("$event: " , $event);
                }
            }
        }
    }
</script>
<style scoped>
</style>