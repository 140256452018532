import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        cotizacion: false,
        devolucion: false,
        message: "",
        ventaDetailList: [],
        articulosList:[], 
        ventaHeader: {
            fecha:"",
            total:0
        },
        arrayNuevo: []
    },
    mutations,
    actions,
};