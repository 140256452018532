<template>
  <b-container fluid>
    <b-col class="p-0 pb-5 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
      <b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">

        <b-col cols="12">
          <Acciones />
        </b-col>
      </b-row>

        <b-col class="col-xs-12 col-sm-12">
            <Productos />
        </b-col>

    </b-col>
      
  </b-container>
</template>
<script> 
    import Acciones from "./Acciones";
    import Productos from "./Productos";
    import { mapState, mapActions } from "vuex";

    export default {
        components: { Productos, Acciones },
        mounted(){
            this.$store.commit("SET_COMMIT_EDIT_ARTICULOS_LIST", 0);
            
        },
        data() {
            return {
                
            }
        },
        computed: mapState(["articulos", "caja"]),
        methods: {
            ...mapActions([]),
            clicProducto(){

            }
        }
    }
</script>