<template>
    <b-card header-tag="header" class="mt-3 card-mini" style="min-height: 740px;">
        <template v-slot:header>
            <b-row class="p-2">
                CARRITO

                <input @focus="$event.target.select()" v-model="search" required autocomplete="off" type="text"
                class="form-control mt-3" placeholder="Buscar en carrito..." aria-label="PRODUCTOS" aria-describedby="basic-addon">

            </b-row>
        </template>

            <ItemsList :actions="true" :items="caja.cajaCarrito"
                SET_COMMIT_DELETE="SET_COMMIT_CARRITO_DELETE"
                SET_COMMIT_EDIT="SET_COMMIT_CARRITO_EDIT"
                SET_COMMIT_IMG="SET_COMMIT_IMG_CARRITO"
                :updateTotalCarrito="updateTotalCarrito"
                tituloItemsEmpty="Carrito vacío"
                idTemplate="productosCarrito" :search="search"
            />

            <ModalEditarCarrito />
    </b-card>
</template>

<script>
    import ModalEditarCarrito from "./ModalEditarCarrito";
    // import Boton from '@/components/Boton'
    import ItemsList from '@/components/ItemsList'
    import { mapState, mapActions } from "vuex";

    export default {
        components:{ ItemsList, ModalEditarCarrito },
        data(){
            return{
                search: "",
            }
        },
        computed: mapState([ "caja" ]),
        methods:{
            ...mapActions(["updateTotalCarrito"])
        }
    }
</script>