<template>
  <b-modal id="modalEditar" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="EDITAR" title-class="modal-title w-100" body-class="p-4" >
    <b-row>
        <!-- <b-row > -->
          <b-card bg-variant="light" style="width: 50rem;"  header="DATOS ARTICULO">
            <b-row >
          <b-col class="col-md-6" >
            <b-form-group id="input-group-1" label="NOMBRE:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="articulos.getArticulos.nombre" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-3" >
            <b-form-group id="input-group-1" label="CLAVE:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="articulos.getArticulos.clave" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
           <b-col class="col-md-3" >
            <b-form-group id="input-group-1" label="CLAVE PROD:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="articulos.getArticulos.clave_prod_serv" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-3" >
              <b-form-group id="input-group-1" label="CÓDIGO BARRAS:" label-class="form-label" label-for="input-1"  class="mb-0">
                <b-input-group class="m-0" size="sm" >
                  <b-form-input @focus="$event.target.select()" v-model="articulos.getArticulos.codigo_barras" ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

          <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="DESCRIPCIÓN:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group >
                <b-textarea v-model="articulos.getArticulos.descripcion"  class="m-0" size="sm" ></b-textarea>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="SUBCATEGORIA:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm">
                <Autocomplete  class="mr-1" placeholder="Selecciona una opción" 
                  cols="col-md-12" :items="articulos.subcategoriasList"  
                  text="sub_categoria" 
                  v-model="articulos.getArticulos.id_sub_categoria" 
                  filter="sub_categoria"
                  assign="id_sub_categoria"  
                /> 
              </b-input-group>
            </b-form-group>
          </b-col>

        <b-col class="col-md-4 mb-3">
            <b-form-group id="input-group-1" label="UNIDAD ALMACENAMIENTO:" label-class="form-label" label-for="input-1"  class="mb-0">
            <b-input-group class="m-0" size="sm">
            <Autocomplete class="mr-1"
                    placeholder="" v-model="articulos.getArticulos.id_unidad"
                        :items="articulos.unidadesCatList"
                          text="unidad" assign="id_unidad"  
                          filter="unidad" 
                          />
              </b-input-group>
                        </b-form-group>
        </b-col>
        <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="IMAGEN:" label-class="form-label" label-for="input-1"  class="mb-0">
                <b-input-group  class="m-0" size="sm" >
                  <b-form-file v-if="articulos.getArticulos.imagen === null"
                    v-model="articulos.getArticulos.imagen" 
                    type="file" placeholder="Sin imagen" accept="image/png, image/jpeg"
                    class="m-0" browse-text="Seleccionar imagen" 
                    @change="onFileChange($event)" size="sm">
                    <!-- 'data:image/jpeg;base64,'+  -->
                  </b-form-file>
                </b-input-group> 
                <b-input-group  class="m-0" size="sm" >
                  <b-form-file v-if="articulos.getArticulos.imagen != null"
                    v-model="articulos.getArticulos.imagen" 
                    type="file" placeholder="Sin imagen" 
                    class="m-0" browse-text="Seleccionar imagen" 
                    @change="onFileChange($event)" size="sm">
                    <!-- 'data:image/jpeg;base64,'+  -->
                  </b-form-file>
                </b-input-group>
            </b-form-group>
          </b-col> 

            </b-row>
          </b-card>
          

          <b-card bg-variant="light" style="width: 50rem;"  header="DATOS PRECIOS">
            <b-row >
          <b-col class="col-md-3">
            <b-form-group id="input-group-1" label="PRECIO BLANCO:" label-class="form-label" label-for="input-1"  class="mb-0">
                <b-input-group class="m-0" size="sm" >
                  <b-form-input @focus="$event.target.select()"  v-model="articulos.getArticulos.factor_pv_1" type="number"></b-form-input>
                </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-3">
            <b-form-group id="input-group-1" label="PRECIO VERDE:" label-class="form-label" label-for="input-1"  class="mb-0">
                <b-input-group class="m-0" size="sm" >
                  <b-form-input @focus="$event.target.select()" style="border: 3px solid #458c2b;" v-model="articulos.getArticulos.factor_pv_2" type="number"></b-form-input>
                </b-input-group>
                
            </b-form-group>
          </b-col>
          <b-col class="col-md-3">
            <b-form-group id="input-group-1" label="PRECIO AZUL:" label-class="form-label" label-for="input-1"  class="mb-0">
                              <b-input-group class="m-0" size="sm" >
                  <b-form-input @focus="$event.target.select()" style="border: 3px solid #2b458c;" v-model="articulos.getArticulos.factor_pv_3" type="number"></b-form-input>
                </b-input-group>
              
            </b-form-group>
          </b-col>
          <b-col class="col-md-3">
            <b-form-group id="input-group-1" label="PRECIO ROJO:" label-class="form-label" label-for="input-1"  class="mb-0">
                <b-input-group class="m-0" size="sm" >
                  <b-form-input @focus="$event.target.select()" style="border: 3px solid #E40000;" v-model="articulos.getArticulos.factor_pv_4" type="number"></b-form-input>
                </b-input-group>
              
            </b-form-group>
          </b-col>

          <b-col class="col-md-3">
            <b-form-group id="input-group-1" label="MAXIMO:" label-class="form-label" label-for="input-1"  class="mb-0">
                 <b-input-group class="m-0" size="sm" >
                  <b-form-input @focus="$event.target.select()"  v-model="articulos.getArticulos.maximo" type="number"></b-form-input>
                </b-input-group>
              
            </b-form-group>
          </b-col>

           <b-col class="col-md-3">
            <b-form-group id="input-group-1" label="MINIMO:" label-class="form-label" label-for="input-1"  class="mb-0">
                <b-input-group class="m-0" size="sm" >
                  <b-form-input @focus="$event.target.select()"  v-model="articulos.getArticulos.minimo" type="number"></b-form-input>
                </b-input-group>
              
            </b-form-group>
          </b-col>
          <b-col class="col-md-3">
            <b-form-group id="input-group-1" label="PUNTO REORDEN:" label-class="form-label" label-for="input-1"  class="mb-0">
                <b-input-group class="m-0" size="sm" >
                  <b-form-input @focus="$event.target.select()"  v-model.number="articulos.getArticulos.punto_reorden" type="number" ></b-form-input>
                </b-input-group>
              
            </b-form-group>
          </b-col>
           <!-- <b-col class="col-md-3 mb-3">
                    <b-form-group id="input-group-1" label="EQUIVALENCIA:" label-class="form-label" label-for="input-1"  class="mb-0">
          <b-input-group class="m-0" size="sm" >
          <b-form-input @focus="$event.target.select()"  v-model.number="articulos.getArticulos.equivalencia" type="number" ></b-form-input>
        </b-input-group>
              
            </b-form-group>
                  </b-col> -->

              </b-row>
          </b-card>
          



          <b-card bg-variant="light" style="width: 50rem;"  header="EQUIVALENCIA" class="mt-2">
            <b-row >
              <b-col class="col-md-3">
                <b-form-group id="input-group-1" label="UNIDAD:" label-class="form-label" label-for="input-1"  class="mb-0">
                  <b-input-group disabled class="m-0" size="sm">
                    <Autocomplete disabled class="mr-1" placeholder="Seleccione una opción" 
                    v-model="articulos.getArticulos.id_unidad"
                        :items="articulos.unidadesCatList"
                          text="unidad" assign="id_unidad"
                          filter="unidad"/>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="col-md-3">
                <b-form-group id="input-group-1" label="UNIDAD:" label-class="form-label" label-for="input-1"  class="mb-0">
                  <b-input-group class="m-0" size="sm">
                    <Autocomplete class="mr-1" placeholder="Seleccione una opción" 
                    v-model="id_unidad_destino"
                        :items="articulos.unidadesCatList"
                          text="unidad" assign="id_unidad"
                          filter="unidad"/>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col class="col-md-3" >
                <b-form-group id="input-group-1" label="EQUIVALENCIA:" label-class="form-label" label-for="input-1"  class="mb-0">
                  <b-input-group class="m-0" size="sm" >
                    <b-form-input @focus="$event.target.select()" v-model="equivalencia" ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="col-md-3 mt-3 text-center w-100 h-100">
                <Boton v-if="articulos.id_unidad_equivalencia == 0" 
                :disabled="equivalencia == '' || id_unidad_destino == ''" text="Agregar" tooltip="Guardar equivalencia" class="mr-3"
                  icon="plus-circle" fontscale="2"
                  :onClic="crearEquivalencia" 
                  variant="info" btn="btn-secundary" size="sm"/>
                
                <Boton v-if="articulos.id_unidad_equivalencia != 0" 
                :disabled="id_unidad_destino == '' || equivalencia == ''" text="Guardar" tooltip="Guardar equivalencia" class="mr-3"
                  icon="lock-fill" fontscale="2"
                  :onClic="updateEquivalencia" 
                  variant="info" btn="btn-secundary" size="sm"/>
              </b-col>

              <b-col class="col-md-12 mt-2">
                <EqivalenciaList :actions="true" :items="articulos.unidad_equivalencia_list"
                    SET_COMMIT_DELETE="SET_COMMIT_CARRITO_DELETE"
                    SET_COMMIT_EDIT="SET_COMMIT_CARRITO_EDIT"
                    SET_COMMIT_IMG="SET_COMMIT_IMG_CARRITO"
                    :clicItem="clicItem"
                    idTemplate="productosCarrito" :search="search"
                />
              </b-col>

            </b-row>
          </b-card>


    </b-row>
    <b-row style="padding-top:1rem;" >
      <b-col class="col-md-12" style="padding-top:1rem;"  >
        
      </b-col>
      
    </b-row>
    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Guardar" tooltip="Guardar registro" class="mr-3"
        icon="lock-fill" fontscale="2" 
        :disabled="loading ||
        articulos.getArticulos.nombre == '' ||
        articulos.getArticulos.clave == '' ||
        articulos.getArticulos.clave_prod_serv == '' ||
        articulos.getArticulos.codigo_barras == '' || articulos.getArticulos.codigo_barras == null || articulos.getArticulos.codigo_barras == ' '
        || articulos.getArticulos.factor_pv_2 < 0 || articulos.getArticulos.factor_pv_2 == ' ' || articulos.getArticulos.factor_pv_2 == ''
        || articulos.getArticulos.factor_pv_3 < 0 || articulos.getArticulos.factor_pv_3 == ' ' || articulos.getArticulos.factor_pv_3 == ''
        || articulos.getArticulos.factor_pv_4 < 0 || articulos.getArticulos.factor_pv_4 == ' ' || articulos.getArticulos.factor_pv_4 == ''
        || articulos.getArticulos.factor_pv_1 < 0 || articulos.getArticulos.factor_pv_1 == ' ' || articulos.getArticulos.factor_pv_1 == ''
        
        || articulos.getArticulos.maximo < 0 || articulos.getArticulos.maximo == ' ' || articulos.getArticulos.maximo == ''
        || articulos.getArticulos.minimo < 0 || articulos.getArticulos.minimo == ' ' || articulos.getArticulos.minimo == ''
        || articulos.getArticulos.punto_reorden < 0 || articulos.getArticulos.punto_reorden == ' ' || articulos.getArticulos.punto_reorden == ''"
        :onClic="realizarRegistro" variant="info" btn="btn-secundary" size="sm"/>
<!-- :disabled="articulos.getArticulos.totalRecibido < caja.totalCarrito.total" -->
        <Boton text="Cerrar" tooltip="Cancelar registro"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/>
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  import Vue2Filters from 'vue2-filters'
  import EqivalenciaList from './EqivalenciaList'
  import Autocomplete from '@/components/Autocomplete'

  
  export default {
    mounted(){
      this.initModal();

      // if (this.id_categoria >= 1) {
        this.subcategoriasList();
      // }
    },
    mixins: [Vue2Filters.mixin],
    components:{ Boton, Autocomplete, EqivalenciaList },
    data() {
      return {
        id_unidad_equivalencia: "",
        id_categoria_modal:"",
        id_unidad_destino:"",
        equivalencia:"",
        search:"",
        id_sub_categoria:" ",

        totalTexto:"",
        modalShow: false,
        dataModal: {
           nombre:" ", 
          descripcion:" ",
          factor_pv_1: " ",
          factor_pv_2: " ",
          factor_pv_3: " ",
          factor_pv_4: " ",
          id_unidad: 0,
          id_sub_categoria: '',
          estatus: 1,
          imagen: null,
          maximo: " ",
          minimo: " ",
          punto_reorden:" ",
          equivalencia: "",
          clave:" ",
          clave_prod_serv:" ",
          codigo_barras:" ",
        },
        loading: false
      };
    },
     watch: {
     "id_categoria": function (val) {
          if(val){
              //Carga el list
              // this.articulosList(val);
              //Carga el select
              //this.subCategoriaList(val);
              //Lanzar commit
                this.$store.commit("SET_ID_CATEGORIA", val);
          } else if( val >= 1){
              //Carga el list
              // this.articulosList(val);
          }
      },
      "articulos.editIdArticuloList": function (val) {
         console.log("valEdi: " , val);
        if(val >= 1){
          this.$bvModal.show('modalEditar');
          this.articulosGet(val);
          this.unidadEquivalenciaList(val);
        } 
      },
         "articulos.getArticulos": function (val) {
           console.log("val: " , val);
         },
        //   "articulos.articulosList": function (val) {
        //    console.log("valArticulosList: " , val);
        //     // if(val){
        //     //   this.articulosList(val);
        //     // }
           
        //  },
        "articulos.messageArticulo": function (val) {
                if(val.mensaje != ""){
          this.generateAlert(val.mensaje, val.clase);
          
        }
                // if(val.clase == "toast-success"){
                //     this.articulosList(val);
                // }
            }
    }, 
    computed:{
      ...mapState([ "articulos", "auth"]),
    },
    methods:{
      ...mapActions(["unidadEquivalenciaList", "unidadesCatList", "subcategoriasList", "unidadEquivalenciaCreate", "unidadEquivalenciaUpdate", "articulosUpdate", "articulosGet", "id_unidad", "articulosList" ]),
      //let resCambio = parseFloat()
      initModal(){
        this.$store.commit("SET_COMMIT_EDIT_ARTICULOS_LIST", 0);
        this.$store.commit("SET_ID_CATEGORIA", 0);
        this.$store.commit("SET_COMMIT_UNIDADES", []);
        // this.$store.commit("SET_COMMIT_SUBCATEGORIAS", []);
        this.$store.commit("SET_COMMIT_UNIDADES_LIST_ARTICULOS", []);
        this.$store.commit("SET_COMMIT_UNIDADES_CAT_LIST", []);
        this.$store.commit("SET_COMMIT_UNIDAD_EQUIVALENCIA", []);
        // if (this.id_categoria >= 1) {
        //           this.articulosList();
        //        }

        //this.$store.commit("SET_ID_SUBCATEGORIA", 0);
        this.unidadesCatList();
        this.$store.commit("SET_ID_UNIDAD", 0);
        this.$store.commit("SET_COMMIT_ID_UNIDAD_EQUIVALENCIA", 0);
      },
      restrictDecimal() {
        let cambio = parseFloat(this.dataModal.cambio);
        this.dataModal.cambio = cambio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      },
      onFileChange(e){
      // // //   const file = e.target.files[0];
      // // // this.url = URL.createObjectURL(file);
      const files = e.target.files[0];
      if(files[0] !== undefined) {
        this.dataModal.imagen = files[0].name
        if(this.dataModal.imagen.lastIndexOf('.') <= 0) {
          return
        }
        this.dataModal.imagen = files[0]
      }
      else {
        this.dataModal.imagen = files[0]
      }
    },
      // cierra el modal
      cancel() {
        this.initModal();
        this.modalShow = false;
        this.loading = false;
         this.$store.commit("SET_COMMIT_EDIT_ARTICULOS_LIST", 0);
         this.$store.commit("SET_COMMIT_GET_ARTICULOS_LIST", 0);
       
        return this.modalShow;
      },
      // setea todo el array
      setDetailRequisicionArray() {
        this.loading = true;
         this.$store.commit("SET_COMMIT_EDIT_ARTICULOS_LIST", 0);
        this.$store.commit("SET_COMMIT_GET_ARTICULO", 0);
        this.cancel();
      },
      clearDigit(value){
        return Number(value.replace(/[^0-9.-]+/g,""));
      },
      realizarRegistro(){
        this.loading = true;
        new FormData();
       //formData.append("imagen", this.imagen);
        let formData = new FormData;
            if (this.articulos.getArticulos.id_articulo) {
                formData.append('id_articulo', this.articulos.editIdArticuloList);
            }
            if(this.articulos.getArticulos.nombre){
                formData.append('nombre', this.articulos.getArticulos.nombre);
            }
            if(this.articulos.getArticulos.descripcion){
                formData.append('descripcion', this.articulos.getArticulos.descripcion);
            }
            if (this.articulos.getArticulos.factor_pv_1){
                formData.append('factor_pv_1', this.articulos.getArticulos.factor_pv_1);
            }
            if (this.articulos.getArticulos.factor_pv_2){
                formData.append('factor_pv_2', this.articulos.getArticulos.factor_pv_2);
            }
            if(this.articulos.getArticulos.factor_pv_3){
                formData.append('factor_pv_3', this.articulos.getArticulos.factor_pv_3);
            }
            if(this.articulos.getArticulos.factor_pv_4){
                formData.append('factor_pv_4', this.articulos.getArticulos.factor_pv_4);
            }
            if (this.articulos.getArticulos.id_unidad){
                formData.append('id_unidad', this.articulos.getArticulos.id_unidad);//
            }
            if (this.articulos.getArticulos.id_sub_categoria){
                formData.append('id_sub_categoria', this.articulos.getArticulos.id_sub_categoria);//
            }
             if(this.articulos.getArticulos.estatus){
                formData.append('estatus', 1);//
            }
            if('data:image/jpeg;base64,'+ this.articulos.getArticulos.imagen){
                formData.append('imagen', 'data:image/jpeg;base64,'+  this.articulos.getArticulos.imagen);
            }
            if (this.articulos.getArticulos.maximo){
                formData.append('maximo', this.articulos.getArticulos.maximo);
            }
            if (this.articulos.getArticulos.minimo){
                formData.append('minimo', this.articulos.getArticulos.minimo);
            }
             if(this.articulos.getArticulos.punto_reorden){
                formData.append('punto_reorden', this.articulos.getArticulos.punto_reorden);
            }
            if(this.articulos.getArticulos.clave){
                formData.append('clave', this.articulos.getArticulos.clave);
            }
            if (this.articulos.getArticulos.clave_prod_serv){
                formData.append('clave_prod_serv', this.articulos.getArticulos.clave_prod_serv);
            }
            if (this.auth.user.idUser){
                formData.append('id_usuario', this.auth.user.idUser);
            }
            if (this.articulos.getArticulos.codigo_barras){
                formData.append('codigo_barras', this.articulos.getArticulos.codigo_barras);
            }
            
            console.log('formData', formData)
        this.articulosUpdate(formData);
       
        this.cancel();
      },
      crearEquivalencia(){

        let datos = {
          equivalencia: this.equivalencia,
            unidad_inicial: this.articulos.getArticulos.id_unidad,
            unidad_destino: this.id_unidad_destino,
            id_articulo: this.articulos.editIdArticuloList,
            id_usuario: this.auth.user.idUser,
        }
        this.unidadEquivalenciaCreate(datos);

        this.id_unidad_destino = "";
        this.equivalencia = "";
        

          // this.cancel();
      },
      updateEquivalencia(){
        let ress = {
          id_unidad_equivalencia: this.id_unidad_equivalencia,
          equivalencia: this.equivalencia,
          id_unidad_inicial: this.articulos.getArticulos.id_unidad,
          id_unidad_destino: this.id_unidad_destino,
          id_usuario: this.auth.user.idUser,
        }
        this.unidadEquivalenciaUpdate(ress);
        this.$store.commit("SET_COMMIT_ID_UNIDAD_EQUIVALENCIA", 0);
        this.id_unidad_destino = "";
        this.equivalencia = "";
        this.id_unidad_equivalencia = "";
        

          // this.cancel();
      },
      clicItem(item){
        this.id_unidad_equivalencia = item.id_unidad_equivalencia
        this.id_unidad_destino = item.id_unidad_destino;
        this.equivalencia = item.equivalencia;
        console.log("ccccc " , item);
        this.$store.commit("SET_COMMIT_ID_UNIDAD_EQUIVALENCIA", item.id_unidad_equivalencia);
      },
    },
  };
</script>
<style>
</style>