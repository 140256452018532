/*eslint-disable no-unused-vars*/
export const mutations = {
    SET_MESSAGE(state,msj){
        state.message = msj
    },
    SET_FACTURA(state,res){
        state.factura = res
    },
   
    SET_COMMIT_PRODUCTOS(state, res){
        state.cajaProductos = res;
    },
    SET_COMMIT_CLIENTES(state, res){
        state.clientesList = res;
    },
    SET_COMMIT_ID_CATEGORIA(state, res){
        state.id_categoria = res;
    },
    SET_COMMIT_ID_SUBCATEGORIA(state, res){
        state.id_sub_categoria = res;
    },
    SET_CREATE_VENTA(state, res){
        state.venta = res
    }
};