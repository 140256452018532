export const mutations = {
    SET_COBRO(state,res){
        state.cobro = res
    },

    SET_COMMIT_LOAD_ORDEN_COMPRA(state, val){
        state.loadOrdenCompra = val;
    },
    SET_COMMIT_LOAD_CONTENIDO_ORDEN_COMPRA(state, val){
        state.loadContenidoOrdenCompra = val;
    },
    SET_COMMIT_ARTICULO_GET_INGRESO(state, val){
        state.articuloGet = val;
    },
    SET_COMMIT_LOAD_LOTES(state, val){
        state.loadLotes = val;
    },
    SET_MESSAGE_INGRESO(state,msj){
        state.messageIngreso = msj
    },
    SET_COMMIT_COMPRA_HEADER_LIST(state,res){
        state.ordenComprasHeader = res
    },
    // actualiza la variable art dentro del array de ordenCompraDetails
    SET_COMMIT_UPDATE_ART_COMPRA_DETAIL_LIST(state, res){
        let searchRes = state.ordenComprasDetail.filter(item=>item.id_articulo === res.id_articulo)
        searchRes[0].art = res.datos
    },
    // actualiza la variable art dentro del array de ordenCompraDetails
    SET_COMMIT_UPDATE_UNI_COMPRA_DETAIL_LIST(state, res){
        let searchRes = state.ordenComprasDetail.filter(item=>item.id_unidad_solicitada === res.id_unidad_solicitada)
        searchRes[0].uni = res.datos
        console.log("res.id_unidad_solicitada: " , res.id_unidad_solicitada);
        console.log("searchRes: " , searchRes[0]);
    },
    SET_COMMIT_COMPRA_DETAIL_LIST(state,res){
        state.ordenComprasDetail = res
    },

    SET_COMMIT_COMPRA_CLEAR(state){
        state.cajaCompra = [];
    },
    SET_COMMIT_COMPRA_DELETE(state, element){
        state.cajaCompra.splice(element, 1);
    },
    SET_COMMIT_COMPRA_EDIT(state, id){
        state.editIdCompra = id;
    },
    SET_COMMIT_IMG_COMPRA(state, val){
        state.imagenCompra = val;
    },
    SET_COMMIT_TOTAL_ORDENCOMPRA(state, element){
        state.totalCompra.subtotal = element.subtotal;
        state.totalCompra.iva = element.iva;
        state.totalCompra.total = element.total;
    },
    SET_COMMIT_COMPRA_ADD_INGRESO(state, element){
        state.cajaCompra.push(element);
    },
    SET_COMMIT_PROVEEDORES_LIST(state, element){
        state.proveedoresList = element
    },
    SET_COMMIT_UNIDADES_INGRESO(state, element){
        state.unidadesListIngreso = element
    },
}