<template>
  <b-modal id="ModalImagenCompra" v-model="modalShow" hide-footer size="md" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="IMAGEN" title-class="modal-title w-100" body-class="p-2" >
    <b-row align-v="center" align-h="center">
      <b-img fluid class="mousehoverItem" width="400" height="400"
        :src="ordencompra.imagenCompra ? 'data:image/jpeg;base64,' + ordencompra.imagenCompra : require('@/assets/img/nodisponible.png')" 
      ></b-img>
    </b-row>

    <b-row class="mt-2" align-v="center" align-h="center">
      <b-col cols="12" class="text-right">

        <Boton text="Cerrar" class="mr-3"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-secundary" size="sm"/>

      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import { mapState } from "vuex";
  import Boton from '@/components/Boton'
  export default {
    mounted(){
      this.$store.commit("SET_COMMIT_IMG_COMPRA", 0);
    },
    components:{ Boton },
    data() {
      return { modalShow: false, };
    },
    watch: {
      "ordencompra.imagenCompra": function (val) {
        // console.log("val: " , val);
        if(val != 0){
          this.$bvModal.show('ModalImagenCompra');
        }
      },
        
    },
    computed:{ ...mapState([ "ordencompra" ]), },
    methods:{
      // cierra el modal
      cancel() {
        this.modalShow = false;
        this.$store.commit("SET_COMMIT_IMG_COMPRA", 0);
        return this.modalShow;
      },
    },
  };
</script>
<style>
</style>