/*eslint-disable no-unused-vars*/
export const mutations = {
    SET_MESSAGE(state,msj){
        state.message = msj
    },
    SET_FACTURACION(state,res){
        state.facturacion = res
    },
    SET_LIST_ABONOS_FACTURACION(state,res){
        state.listAbonosFacturacion = res
    },
    SET_LIST_VENTAS_FACTURACION(state,res){
        state.listVentasFacturacion = res
    },
    SET_LOADING(state,res){
        state.loading = res
    },
    SET_INDEX_API(state,res){
        state.indexApi = state.indexApi + res;
    },
    SET_CLEAR_INDEX_API(state){
        state.indexApi = 0;
    },
    SET_COLOR_PROGRESBAR(state, res){
        state.progresbarColor = res;
    },
    SET_ESTATUS_VENTA(state, res){
        state.estatusVenta = res;
    },
    SET_RAZON_VENTA(state, res){
        state.razonVenta = res;
    },
    SET_RELOAD_VENTA(state, res){
        state.reloadVenta = res;
    },
    SET_VENTAS_CHECKED(state, res){
        state.listVentaChecked = res;
    },
    SET_DELETE_LIST_VENTAS(state, element){
        state.listVentasFacturacion.splice(element, 1);
    },
    SET_ITER_CLIENTES(state, res){
        state.iClientes = res;
    },
    SET_ITER_VENTAS(state, res){
        state.iteracionVentas = res;
    },
    SET_IDS_PUBLICO_GENERAL(state, res){
        state.idsPGeneral = res;
    },
    

    
};