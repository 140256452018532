import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        cobro: false,

        loadOrdenCompra: false,
        loadContenidoOrdenCompra: false,
        editIdCompra: 0,
        articuloGet: {},
        loadLotes: false,
        messageIngreso: { mensaje:"", clase:""},
        ordenComprasHeader: [],
        ordenComprasDetail: [],

        imagenCompra: 0,
        totalCompra: {subtotal: "0.00", iva: "0.00", total: "0.00"},

        unidadesListIngreso:[]
    },
    mutations,
    actions,
};