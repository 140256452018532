<template>
  <b-container fluid>
    <b-col class="p-0 pb-5 mb-5 m-0 h-100" >

      <b-row class="p-0 m-0 h-100 justify-content-md-center">
        <b-col cols="10">
            <b-card header-tag="header" class="mt-3 card-mini">
                <template v-slot:header>
                    CONFIGURACIÓN DE IMPRESORA
                </template>
                
                <b-form-group id="input-group-1" label="IP DE IMPRESORA:" label-class="form-label" label-for="input-1"  class="mb-0">
                    <b-form-input @focus="$event.target.select()" v-model="ip" ></b-form-input>
                </b-form-group>
                
                <b-col class="col-md-12">
                    <!-- <b-form-group> -->
                        <b-row>
                            <b-row class="mt-2" >

                                <b-col class="col-md-6">
                                    <Boton text="ESTATUS" tooltip="ESTATUS DE IMPRESORA"
                                icon="exclamation-circle" fontscale="2" :onClic="test" variant="info" btn="btn-default" size="sm"/>
                                </b-col>
                                <b-col class="col-md-6">
                                    <Boton text="Guardar" tooltip="Guardar"
                                icon="lock-fill" fontscale="2" :onClic="guardar" variant="info" btn="btn-secundary" size="sm"/>
                                </b-col>

                            </b-row>
                        </b-row>
                    <!-- </b-form-group> -->
                </b-col>
            </b-card>

        </b-col>
      </b-row>
    </b-col>
  </b-container>
</template>

<script>
    //import KeyUp from '@/components/KeyUp'
    import Boton from '@/components/Boton'
    import { mapActions, mapState } from "vuex";
    export default {
        mounted(){
            this.ip = localStorage.getItem("URL_IMPRESORA");
        },
        components: { Boton },
        computed: {
            ...mapState([ "cortecaja"])
        },
        data() {
            return {
                loading:false,
                ip: "",
            }
        },
        methods: {
            ...mapActions(["estatusCaja"]),
            guardar(){
                
                this.$store.commit("SET_URL_IMPRESORAA", this.ip)
                localStorage.setItem('URL_IMPRESORA', this.ip)
                this.generateAlert("IP GUARDADA", "toast-success");
                
                // setTimeout(async ()=>{
                //     this.ip = "";
                // },800)
            },
            test(){
                this.estatusCaja();
            }
        }
    }
</script>

