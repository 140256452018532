<template>
  <b-container fluid>
    <b-col class="p-0 pb-5 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
      <b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
        
        <b-col cols="12">
          <Acciones />
        </b-col>
      </b-row>
      <b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
        <b-col class="col-xs-6 col-sm-6">
          <Lotes />
        </b-col>
        <b-col class="col-xs-6 col-sm-6">
          <Ingreso />

          <b-card header-tag="header" class="mt-3 card-mini">
              <b-col class="col-md-12 mt-3" v-for="(item, index) in ingreso.unidadesListIngreso" v-bind:key="index">
                  <b-input-group size="md" :prepend="item.unidad" class="mb-2"  v-b-tooltip.hover :title="item.unidad">
                      <b-form-input style="text-align:right;" :value="item.suma" disabled aria-label="Small text input with custom switch"></b-form-input>
                  </b-input-group>
              </b-col>
          </b-card>

        </b-col>

      </b-row>
    </b-col>
  </b-container>
</template>
<script>
  import Acciones from "./Acciones";
  import Lotes from "./Lotes";
  import Ingreso from "./Ingreso";
  
  import { mapActions, mapState } from 'vuex'
  export default {
    mounted(){
      this.$store.commit("SET_COMMIT_COMPRA_DETAIL_LIST", []);
    },
    computed:mapState(["ordencompra", "ingreso"]),
    components:{ Acciones, Lotes, Ingreso },
    data(){
      return{ }
    },
    methods:{
      ...mapActions([""]),
    }
  }
</script>