<template>
  <b-container fluid>
    <b-col class="p-0 pb-5 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
      <b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">

        <b-col cols="12">
          <!-- <Acciones :clicProducto="clicProducto" :clicCarrito="clicCarrito"
          :carritoIcon="carritoIcon" :productoIcon="productoIcon" :keyUpFacturacion="true" /> -->

          <Acciones :keyUpFacturacion="true" />
        </b-col>
      </b-row>
      <b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
        
         <!-- <div style="position:fixed; height:40px; top:225px; z-index: 100; left:0px; text-align:center;" >
          <b-btn class="btn-collapse" @click="clicProducto()">
            
              <b-icon v-show="productoCols == 7 || productoCols == 12" icon="arrow-bar-left" class="icon-card" aria-hidden="false" ></b-icon>
              
              <b-icon v-show="productoCols == 0" icon="arrow-bar-right" class="icon-card" aria-hidden="true" ></b-icon>
              
          </b-btn>
        </div>  -->


    <!-- <div class="categoria mr-3'" v-on:click="sendPrint" v-b-tooltip.hover title="Cancelar carrito">
        <b-img class="mousehoverItem"
            :src="require('@/assets/botones/cancelar.png')" width="110" height="110"
        ></b-img>
    </div> -->

        <b-col class="col-xs-7 col-sm-7">
          <!-- <b-collapse :appear="appearV" visible id="productos"> -->
              <Productos />
              <!-- <Productos2 /> -->
          <!-- </b-collapse> -->
        </b-col>

        <!-- <div style="position:fixed; height:40px; top:225px; z-index: 100; right:0px; text-align:center;" >
          <b-btn class="btn-collapse" @click="clicCarrito()">
            
              <b-icon v-show="carritoCols == 5 || carritoCols == 12" icon="arrow-bar-right" class="icon-card" aria-hidden="true" ></b-icon>
              
              <b-icon v-show="carritoCols == 0" icon="arrow-bar-left" class="icon-card" aria-hidden="false" ></b-icon>
              
          </b-btn>
        </div> -->

        <b-col class="col-xs-5 col-sm-5">
          <!-- <b-collapse :appear="appearV" visible id="carrito"> -->
            <Carrito />
          <!-- </b-collapse> -->
          <!-- 120 id venta -->
            <b-card header-tag="header" class="mt-3 card-mini">
                <b-col class="col-md-12 mt-3">
                    <b-input-group size="lg" prepend="Total $" class="mb-2"  v-b-tooltip.hover title="Total">
                        <b-form-input style="text-align:right;" :value="caja.totalCarrito.total" disabled aria-label="Small text input with custom switch"></b-form-input>
                    </b-input-group>
                </b-col>
            </b-card>

        </b-col>

      </b-row>
    </b-col>
  </b-container>
</template>
<script>
  import Acciones from "./Acciones";
  import Productos from "./Productos";
  // import Productos2 from "./Productos2";
  import Carrito from "./Carrito";
  
  import { mapActions, mapState } from 'vuex'
  export default {
    mounted(){
      
    },
    computed:mapState(["caja"]),
    components:{ Acciones, Productos, Carrito },
    data(){
      return{
        appearV: false,
        productoCols: "7",
        carritoCols: "5",
        carritoIcon: "arrow-bar-right",
        productoIcon: "arrow-bar-left"
      }
    },
    methods:{
      ...mapActions(["printTicket"]),
      // funcion para ocultar la lista del carrito
      clicCarrito(){
        if(this.carritoCols === "5"){
          this.productoCols = "12";
          this.carritoCols = "0";
          this.carritoIcon = "arrow-bar-left";
          this.productoIcon = "arrow-bar-left";
          this.$root.$emit('bv::toggle::collapse', 'carrito')
        }else if(this.carritoCols === "0"){
          this.productoCols = "7";
          this.carritoCols = "5";
          this.carritoIcon = "arrow-bar-right";
          this.productoIcon = "arrow-bar-left";
          this.$root.$emit('bv::toggle::collapse', 'carrito')
        }else if(this.carritoCols === "12"){
          this.productoCols = "7";
          this.carritoCols = "5";
          this.productoIcon = "arrow-bar-left";
          this.carritoIcon = "arrow-bar-right";
          this.$root.$emit('bv::toggle::collapse', 'productos')
        }
      },
      // funcion para ocultar la lista de productos
      clicProducto(){
        if(this.productoCols === "7"){
          this.productoCols = "0";
          this.carritoCols = "12";
          this.productoIcon = "arrow-bar-right";
          this.carritoIcon = "arrow-bar-right";
          this.$root.$emit('bv::toggle::collapse', 'productos')
        }else if(this.productoCols === "0"){
          this.productoCols = "7";
          this.carritoCols = "5";
          this.productoIcon = "arrow-bar-left";
          this.carritoIcon = "arrow-bar-right";
          this.$root.$emit('bv::toggle::collapse', 'productos')
        }else if(this.productoCols === "12"){
          this.productoCols = "7";
          this.carritoCols = "5";
          this.productoIcon = "arrow-bar-left";
          this.carritoIcon = "arrow-bar-right";
          this.$root.$emit('bv::toggle::collapse', 'carrito')
        }
      },
      sendPrint() {
        this.printTicket(1);
      }
    }
  }
</script>