<template>
    <b-card header-tag="header" class="mt-3 card-mini" style="min-height: 670px;">
        <template v-slot:header>
            <b-row class="p-2">
                COMPRAS
                <b-input-group class="mt-3">
                    <input @focus="$event.target.select()" v-model="search" required autocomplete="off" type="text" class="form-control"
                    placeholder="Buscar en la lista..." aria-label="PRODUCTOS" aria-describedby="basic-addon">
                </b-input-group>
            </b-row>
        </template>

        <ItemsListOrdenCompra :actions="true" :items="ordencompra.cajaCompra"
            SET_COMMIT_DELETE="SET_COMPRA_DELETE"
            SET_COMMIT_EDIT="SET_COMPRA_EDIT"
            SET_COMMIT_IMG="SET_COMMIT_IMG_COMPRA"
            :updateTotalCarrito="updateTotalCompra"
            tituloItemsEmpty="Compra vacía"
            idTemplate="productosCompra" :search="search"
        />
        <ModalEditarCompra />
        <ModalImagen />
    </b-card>
</template>

<script>
    import ModalEditarCompra from "./ModalEditarCompra";
    import ModalImagen from "./ModalImagen";
    // import ItemsList from '@/components/ItemsList'
    import ItemsListOrdenCompra from './ItemsListOrdenCompra'
    import { mapState, mapActions } from "vuex";

    export default {
        components:{ ItemsListOrdenCompra, ModalEditarCompra, ModalImagen },
        data(){
            return{ search: "", }
        },
        watch: {
            // despues de actualizar la variable mensaje despues de guardar lanza notificacion
            "ordencompra.cajaCompra": function (val) {
                console.log("COMPRA: " , val);
            },
        },
        computed: mapState([ "ordencompra" ]),
        methods:{
            ...mapActions(["updateTotalCompra"])
        }
    }
</script>