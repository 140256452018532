import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        precioVentaListEdit: [],
        facturacion: false,
        messageCaja: { mensaje:"", clase:""},
        editIdCarrito: 0,
        imagenCarrito: 0,
        cajaCarrito: [],
        cajaProductos: [],
        articuloList:[],
        articuloGet: {},
        unidadesList: [],
        precioVentaList: [],
        editIdArticulo:0,
        subCategoriaList:[],
        corteCajaList:[],
        id_categoria: 0,
        id_sub_categoria: 0,

        efectivo: 0,
        cheque: 0,
        tarjeta: 0,


        loadProductos: false,
        pesopromedio: "",
        // para mostrar el item
        show: "categoria",
        
        totalCarrito: {subtotal: "0.00", iva: "0.00", total: "0.00"},
        
        precioTecleado: "",

        // respaldos de listas para poder cambiar entre componentes en productos
        listProductosCategoria: [
            {
                src: require('@/assets/BD/prolamsa.png'),
                tooltip: "Ver productos de prolamsa",
                titulo: "Prolamsa",
                idCategoria: 1
            },
            {
                src: require('@/assets/BD/DEACERO.png'),
                tooltip: "Ver productos de deacero",
                titulo: "Deacero",
                idCategoria: 2
            },
            {
                src: require('@/assets/BD/CPP.png'),
                tooltip: "Ver productos de cpp",
                titulo: "CPP",
                idCategoria: 3
            },
            {
                src: require('@/assets/BD/DOAL.png'),
                tooltip: "Ver productos de doal",
                titulo: "Doal",
                idCategoria: 4
            },
            {
                src: require('@/assets/BD/CONSTRUCCION.png'),
                tooltip: "Ver productos de construcción",
                titulo: "Construcción",
                idCategoria: 5
            },
            {
                src: require('@/assets/BD/canales.png'),
                tooltip: "Ver productos de canales",
                titulo: "Canales",
                idCategoria: 6
            },
            {
                src: require('@/assets/BD/angulos.png'),
                tooltip: "Ver productos de angulos",
                titulo: "Angulos",
                idCategoria: 7
            },
            {
                src: require('@/assets/BD/soleras.png'),
                tooltip: "Ver productos de soleras",
                titulo: "Soleras",
                idCategoria: 8
            },
            {
                src: require('@/assets/BD/REDONDO Y CUADRADOS.png'),
                tooltip: "Ver productos redondo y cuadrados sólidos",
                titulo: "Redondo y cuadrados sólidos",
                idCategoria: 9
            },
            {
                src: require('@/assets/BD/placas.png'),
                tooltip: "Ver productos de placas",
                titulo: "Placas",
                idCategoria: 10
            },
            {
                src: require('@/assets/BD/laminas.png'),
                tooltip: "Ver productos de láminas",
                titulo: "Láminas",
                idCategoria: 11
            },
            {
                src: require('@/assets/BD/vigas.png'),
                tooltip: "Ver productos de vigas",
                titulo: "Vigas",
                idCategoria: 12
            },
            {
                src: require('@/assets/BD/FERRETERIA.png'),
                tooltip: "Ver productos de ferretería",
                titulo: "Ferretería",
                idCategoria: 13
            }
        ],
        listProductosSubCategoria: [
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/04-ternium.png",
                tooltip: "Ver productos de ternium",
                titulo: "Ternium",
                idSubCategoria: 1
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-makita.jpg",
                tooltip: "Ver productos de makita",
                titulo: "Makita",
                idSubCategoria: 2
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-deacero.jpg",
                tooltip: "Ver productos de deacero",
                titulo: "Deacero 1",
                idSubCategoria: 3
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-doal.jpg",
                tooltip: "Ver productos de doal",
                titulo: "Doal",
                idSubCategoria: 4
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2019/01/01-irwin.png",
                tooltip: "Ver productos de irwin",
                titulo: "Irwin",
                idSubCategoria: 5
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-truper.jpg",
                tooltip: "Ver productos de truper",
                titulo: "Truper",
                idSubCategoria: 6
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-termotek.jpg",
                tooltip: "Ver productos de termotek",
                titulo: "Termotek",
                idSubCategoria: 7
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-deacero.jpg",
                tooltip: "Ver productos de deacero",
                titulo: "Deacero 2",
                idSubCategoria: 8
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-dewalt.jpg",
                tooltip: "Ver productos de dewalt",
                titulo: "Dewalt",
                idSubCategoria: 9
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/03-prolamsa.png",
                tooltip: "Ver productos de prolamsa",
                titulo: "Prolamsa",
                idSubCategoria: 10
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/02-owens.png",
                tooltip: "Ver productos de owens",
                titulo: "Owens",
                idSubCategoria: 11
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-usg.jpg",
                tooltip: "Ver productos de usg",
                titulo: "Usg",
                idSubCategoria: 12
            },
        ],
        listProductosArticulos: [
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/04-ternium.png",
                tooltip: "Ver productos de ternium",
                descripcion: "Ver productos de ternium descripcion",
                codigo: "23434523",
                titulo: "Ternium",
                idArticulo: 1,
                Unidad: "UNIDAD",
                Precio: 233,
                Importe: 23,
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-makita.jpg",
                tooltip: "Ver productos de makita",
                descripcion: "Ver productos de makita descripcion",
                codigo: "27894523",
                titulo: "Makita",
                idArticulo: 2,
                Unidad: "UNIDAD",
                Precio: 2563,
                Importe: 26,
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-deacero.jpg",
                tooltip: "Ver productos de deacero",
                descripcion: "Ver productos de deacero descripcion",
                codigo: "3456243",
                titulo: "Deacero 1",
                idArticulo: 3,
                Unidad: "UNIDAD",
                Precio: 56,
                Importe: 12,
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-doal.jpg",
                tooltip: "Ver productos de doal",
                descripcion: "Ver productos de doal descripcion",
                codigo: "788423343",
                titulo: "Doal",
                idArticulo: 4,
                Unidad: "UNIDAD",
                Precio: 761,
                Importe: 10,
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2019/01/01-irwin.png",
                tooltip: "Ver productos de irwin",
                descripcion: "Ver productos de irwin descripcion",
                codigo: "456757523",
                titulo: "Irwin",
                idArticulo: 5,
                Unidad: "UNIDAD",
                Precio: 135,
                Importe: 9,
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-truper.jpg",
                tooltip: "Ver productos de truper",
                descripcion: "Ver productos de truper descripcion",
                codigo: "234478789",
                titulo: "Truper",
                idArticulo: 6,
                Unidad: "UNIDAD",
                Precio: 123,
                Importe: 32,
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-termotek.jpg",
                tooltip: "Ver productos de termotek",
                descripcion: "Ver productos de termotek descripcion",
                codigo: "789789",
                titulo: "Termotek",
                idArticulo: 7,
                Unidad: "UNIDAD",
                Precio: 56,
                Importe: 12,
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-deacero.jpg",
                tooltip: "Ver productos de deacero",
                descripcion: "Ver productos de deacero descripcion",
                codigo: "678657",
                titulo: "Deacero 2",
                idArticulo: 8,
                Unidad: "UNIDAD",
                Precio: 263,
                Importe: 12,
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-dewalt.jpg",
                tooltip: "Ver productos de dewalt",
                descripcion: "Ver productos de dewalt descripcion",
                codigo: "2567345",
                titulo: "Dewalt",
                idArticulo: 9,
                Unidad: "UNIDAD",
                Precio: 98,
                Importe: 5,
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/03-prolamsa.png",
                tooltip: "Ver productos de prolamsa",
                descripcion: "Ver productos de prolamsa descripcion",
                codigo: "3447564563",
                titulo: "Prolamsa",
                idArticulo: 10,
                Unidad: "UNIDAD",
                Precio: 12,
                Importe: 4,
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/02-owens.png",
                tooltip: "Ver productos de owens",
                descripcion: "Ver productos de owens descripcion",
                codigo: "345645868",
                titulo: "Owens",
                idArticulo: 11,
                Unidad: "UNIDAD",
                Precio: 34,
                Importe: 2,
            },
            {
                src: "https://aceromax.mx/wp-content/uploads/2020/05/0-usg.jpg",
                tooltip: "Ver productos de usg",
                descripcion: "Ver productos de usg descripcion",
                codigo: "5676754563423",
                titulo: "Usg",
                idArticulo: 12,
                Unidad: "UNIDAD",
                Precio: 452,
                Importe: 42,
            },
        ],
        listProductosArticulo: []
    },
    mutations,
    actions,
};
