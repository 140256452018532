<template :id="idTemplate" :per-page="perPage" :current-page="currentPage">
    <div v-b-scrollspy:scrollspy-nested >
        <b-col class="col-md-12 " id="scrollspy-nested" style="line-height: 2; height:calc(65vh); overflow-y:auto">

            <b-row class="mt-3" v-for="(item, index) in itemsPaginated" v-bind:key="index">
                
                <b-col style="font-size: 3rem;" class="col-md-1">
                    <b-row >
                        <b-col style="font-size: 3rem;" cols="12" class="p-0 pt-2 text-titulo" >
                            <h1 style="font-size: 3rem;"  class="text-center w-100" >{{ items.indexOf(item) + 1 }}</h1>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >RAZON SOCIAL</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.nombre_razon_social }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-1">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >FECHA</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ formatFecha(item.fecha) }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-3">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >TOTAL VENTA</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                $ {{ toMoneda(item.total) }}
                        </b-col>
                    </b-row>
                </b-col>

                <b-col class="col-md-4">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >CFDI</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                            <Autocomplete class="mr-1" placeholder="Selecciona una opción"  
                                cols="col-md-12"
                                :items="cfdis" text="nombre" 
                                v-model="item.id_cfdi" 
                                filter="nombre" assign="id" label="" 
                            />
                        </b-col>
                    </b-row>
                </b-col>
                
                <b-col class="col-md-1">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >FACTURAR</strong>
                        </b-col>
                         <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                            <b-form-checkbox size="lg" cursor="pointer;" v-model="item.checked" name="check-button" switch
                            >
                            </b-form-checkbox>
                        </b-col> 
                    </b-row>
                </b-col>
               
                        
                        
            </b-row>
            <!-- <b-row class="p-0 m-0 mt-3"> -->
                <!-- <b-col  class="col-sm-1 text-titulo " style="display: flex; align-items: center;" >
                </b-col> -->
                
                <!-- <b-col class="col-sm-12"> -->
                <!-- </b-col> -->
                
            <!-- </b-row> -->
        </b-col>



        <!-- <b-col class="col-md-12"> -->
            <!-- paginación -->
            <!-- <div class="mt-3 justify-content-md-center" v-show="items.length > 0">
                <b-pagination v-model="currentPage" :total-rows="searchInDetail().length" align="center" 
                    :per-page="perPage"
                    :aria-controls="idTemplate">
                </b-pagination>
            </div> -->
        <!-- </b-col> -->
    </div>
</template>
<script>
    import moment from 'moment'
    import Autocomplete from '@/components/Autocomplete'
    // import { mapState } from "vuex";
    //import Boton from '@/components/Boton'
    export default {
        props:[ "perPage", "currentPage", "items", "SET_COMMIT_DELETE", "SET_COMMIT_EDIT", "idTemplate", "search" ],
        data() {
            return {
                checked: false,
                cfdis: [
                    {
                        id: "G01",
                        nombre: "Adquisición de mercancias.",
                    },
                    {
                        id: "G02",
                        nombre: "Devoluciones, descuentos o bonificaciones.",
                    },
                    {
                        id: "G03",
                        nombre: "Gastos en general.",
                    },
                    {
                        id: "I01",
                        nombre: "Construcciones.",
                    },
                    {
                        id: "I02",
                        nombre: "Mobilario y equipo de oficina por inversiones.",
                    },
                    {
                        id: "I03",
                        nombre: "Equipo de transporte.",
                    },
                    {
                        id: "I04",
                        nombre: "Equipo de computo y accesorios.",
                    },
                    {
                        id: "I05",
                        nombre: "Dados, troqueles, moldes, matrices y herramental.",
                    },
                    {
                        id: "I06",
                        nombre: "Comunicaciones telefónicas.",
                    },
                    {
                        id: "I07",
                        nombre: "Comunicaciones satelitales.",
                    },
                    {
                        id: "I08",
                        nombre: "Otra maquinaria y equipo.",
                    },
                    {
                        id: "D01",
                        nombre: "Honorarios médicos, dentales y gastos hospitalarios.",
                    },
                    {
                        id: "D02",
                        nombre: "Gastos médicos por incapacidad o discapacidad.",
                    },
                    {
                        id: "D03",
                        nombre: "Gastos funerales.",
                    },
                    {
                        id: "D04",
                        nombre: "Donativos.",
                    },
                    {
                        id: "D05",
                        nombre: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).",
                    },
                    {
                        id: "D06",
                        nombre: "Aportaciones voluntarias al SAR.",
                    },
                    {
                        id: "D07",
                        nombre: "Primas por seguros de gastos médicos.",
                    },
                    {
                        id: "D08",
                        nombre: "Gastos de transportación escolar obligatoria.",
                    },
                    {
                        id: "D09",
                        nombre: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.",
                    },
                    {
                        id: "D10",
                        nombre: "Pagos por servicios educativos (colegiaturas).",
                    },
                    {
                        id: "S01",
                        nombre: "Sin efectos fiscales.",
                    }
                ]
            };
        },
        components:{ Autocomplete },//Boton
        computed:{
            // ...mapState([ ]),
            // paginación
            itemsPaginated(){ 
                return this.searchInDetail();
                // return this.searchInDetail().slice(
                //     (this.currentPage - 1) * this.perPage,
                //     this.currentPage * this.perPage
                // )
            }
        },
        watch: {
            "items.id": function (val) {
                if(val){
                    console.log("val: " , val);
                }
            },
        },
        methods:{
            // busqueda dentro del array
            searchInDetail(){
                return this.items.filter(req => this.search.toLowerCase()
                .split(' ').every(v => this.conditionalFilter(v, req)))
            },
            conditionalFilter(v, req){
                for (const prop in req) {
                    if(req[prop] !== null){
                        if(req[prop].toString().toLowerCase().includes(v)){
                            return true;
                        }
                    }
                }
            },
            // muestra los primeros 172 caracteres del texto
            toggleText(text) {
                let res = "";
                let value = (text.length >= 172) ? 172 : (text.length / 2);
                for (let i = 0; i < value; i++) { res += text[i]; }
                return res += " ...";
            },
            // borra la posocion del array
            eliminar(item) {
                console.log("eliminadoooo");
                this.$store.commit(this.SET_COMMIT_DELETE, this.items.indexOf(item))
            },
            // inicializa la variable id para mostrar en el modal EDITAR, usar watch para accionar el modal
            edit(id) {
                this.$store.commit(this.SET_COMMIT_EDIT, id)
            },
            // mostrar la imagen
            verImagen(id) {
                console.log("id: " , id);
                // this.$store.commit(this.SET_COMMIT_EDIT, id)
            },
            formatFecha(value){
                if(value){
                    return moment(value).format('YYYY-MM-DD')
                }
                return "";
            },
            toMoneda(value){
                // return value;
                // console.log("value: " , value);
                return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            },
        }
    };
</script>
<style scoped>
</style>