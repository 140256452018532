import { render, staticRenderFns } from "./ItemsListFacturacion.vue?vue&type=template&id=e96bc768&scoped=true&%3Aid=idTemplate&%3Aper-page=perPage&%3Acurrent-page=currentPage&"
import script from "./ItemsListFacturacion.vue?vue&type=script&lang=js&"
export * from "./ItemsListFacturacion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e96bc768",
  null
  
)

export default component.exports