<template>
  <b-modal id="ModalEditarLote" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="EDITAR" title-class="modal-title w-100" body-class="p-4" >
    <b-row>
        <!-- <b-col class="col-md-12"> -->
        <!-- <b-row > -->
          <b-col class="col-md-6" >
            <b-form-group id="input-group-1" label="UNIDAD SOLICITADA:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-form-input @focus="$event.target.select()" disabled v-model="datosModal.uni.unidad" ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-6" >
            <b-form-group id="input-group-1" label="ARTÍCULO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-form-input @focus="$event.target.select()" disabled v-model="datosModal.articulo.nombre" ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-6" >
            <b-form-group id="input-group-1" label="CANTIDAD:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-form-input type="number" @focus="$event.target.select()" @change="cambioIngreso" :max="datosModal.cantidad_solicitada" v-model.lazy="datosModal.cantidad" ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-6" >
            <b-form-group id="input-group-1" label="FLETE:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-form-input type="number" @focus="$event.target.select()" v-model="datosModal.flete" ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-6" >
            <b-form-group id="input-group-1" label="PESO PROMEDIO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-form-input type="number" @focus="$event.target.select()" v-model="datosModal.peso_promedio" ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="col-md-6" >
            <b-form-group id="input-group-1" label="PRECIO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-form-input type="number" @focus="$event.target.select()" v-model="datosModal.precio" ></b-form-input>
            </b-form-group>
          </b-col>
        <!-- </b-row> -->
      <!-- </b-col> -->
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Guardar" class="mr-3"
        icon="lock-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-secundary" size="sm"/>

        <Boton text="Cancelar" tooltip="Cancelar"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/>
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  export default {
    mounted(){
      this.$store.commit("SET_COMMIT_COMPRA_EDIT", 0);
    },
    components:{ Boton },
    data() {
      return {
        idIngresoo: 0,
        modalShow: false,
        datosModal:{
          uni:{ unidad: ""},
          articulo: { nombre:""},
          cantidad:"",
          flete:"",
          peso_promedio:"",
          _rowVariant: "success"
        },
      };
    },
    watch: {
      "ingreso.editIdCompra": function (val) {
        // console.log("val: " , val);
        if(val >= 1){
          this.$bvModal.show('ModalEditarLote');
          this.idIngresoo = val;
          this.datosModal = this.getItemLote(this.idIngresoo);
          // this.datosModal._rowVariant = 'success';
          this.datosModal.peso_promedio = 1;
          console.log("datosModal: " , this.datosModal);
        }
      },
        
    },
    computed:{
      ...mapState([ "ingreso", "auth", "contado"]),
    },
    methods:{
      ...mapActions([ "headerCreateContado", "updateTotalCarrito" ]),
      getItemLote(id){
          let searchRes = this.ingreso.ordenComprasDetail.filter(item=>item.id_articulo === id)
          return searchRes[0]
      },
      
      // cierra el modal
      cancel() {
        // let suma = Number(this.datosModal.cantidad) + Number(this.datosModal.flete) + Number(this.datosModal.peso_promedio);
        // if(suma > 0){
        //   this.datosModal._rowVariant = 'success';
        // }else{
        //   this.datosModal._rowVariant = "light";
        // }

        //   console.log("suma:" , suma);
        //   console.log("this.datosModal:" , this.datosModal);

        this.modalShow = false;
        this.datosModal = { uni:{ unidad: ""}, articulo: { nombre:""}, cantidad:"", flete:"", peso_promedio:"", };
        this.$store.commit("SET_COMMIT_COMPRA_EDIT", 0);
        return this.modalShow;
      },
      // setea todo el array
      setDetailRequisicionArray() {
        this.cancel();
      },
      realizarVenta(){
        console.log("jejeje");
      },
      cambioIngreso(){
          if(this.datosModal.cantidad > this.datosModal.cantidad_solicitada){
              this.datosModal.cantidad = this.datosModal.cantidad_solicitada;
          }
      },
    },
  };
</script>
<style>
</style>