/*eslint-disable no-unused-vars*/
import { corte_caja_list } from '@/api/cortecaja'
import { print_corte_caja } from '@/api/printer'
// import router from '@/router'

export const actions = {
    // lista las categorias con imagen
    printCorteCaja: async({ rootState, commit }, fecha) => print_corte_caja(rootState.auth.user.URL_IMPRESORA, rootState.auth.user.idUser, rootState.auth.user.firstName, fecha).then(
        res=>{
            console.log("PRINTprintCorteCajaER: " , res);
        }).catch(err=>console.log(err)
    ),

    corteCajaList: async({ rootState, commit }, fecha) => corte_caja_list(rootState.auth.user.idUser, fecha).then(
        res=>{
            let init = [
                {"tipo_pago":"EFECTIVO","forma_pago_sat":"1","monto":0},
                {"tipo_pago":"TARJETA DE CREDITO","forma_pago_sat":"2","monto":0},
                {"tipo_pago":"CHEQUE","forma_pago_sat":"3","monto":0}
                ,
                {"tipo_pago":"DEVOLUCIONES", "_rowVariant": 'danger',"monto":0},
                {"tipo_pago":"TOTAL", "monto":0}
            ];
            
            let i = res.data.length;
            if(i != 0){
                let total = 0;
                res.data.forEach(element => {
                    total += element.monto;
                });
    
                res.data[i] = {
                    monto: 0,
                    tipo_pago: "DEVOLUCIONES",
                    _rowVariant: 'danger'
                }
                i++;
                res.data[i] = {
                    monto: total,
                    tipo_pago: "TOTAL"
                }
                commit("SET_CORTE_CAJA_LIST", res.data);
            }else{
                commit("SET_CORTE_CAJA_LIST", init);
            }
        }).catch(err=>console.log(err)
            
    ),
    
};