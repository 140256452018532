<template>
  <b-container fluid>
    <b-col class="p-0 pb-5 m-0 h-100" >

      <b-row class="p-0 m-0 h-100 justify-content-md-center">

        <b-col cols="10">
            <b-card no-body class="mt-3 card-mini">
                <b-tabs v-model="tabIndex" card align="center" content-class="mt-3">
                <b-tab>
                    <template v-slot:title>
                        <!-- <b-spinner v-if="facturacion.loading" type="border" small></b-spinner> -->
                        Ventas
                    </template>
                    <b-list-group-item  v-if="facturacion.loading || facturacion.progresbarColor == 'danger'" class="flex-column align-items-start col-md-12" 
                    style="line-height: 2; max-height:calc(52vh); overflow-y:auto" >
                        <b-row >
                            <b-col class="col-md-1" >
                                <b-row >
                                    <b-col style="font-size: 3rem;" cols="12" class="p-0 pt-2" >
                                        <h1 style="font-size: 3rem;" class="text-center w-100 h-100" >{{ facturacion.iteracionVentas }}</h1>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col class="col-md-11">
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">{{ facturacion.razonVenta }}</h5>
                                    <small class="text-muted">{{ facturacion.estatusVenta }}</small>
                                </div>
                                <p class="mb-1">
                                    <b-progress show-progress
                                    :value="facturacion.indexApi" :variant="facturacion.progresbarColor" :max="max" class="mb-3" animated></b-progress>
                                </p>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                    <b-row >
                        <b-col cols="6" >
                            <b-form-datepicker class="mt-3" placeholder="Seleccione la fecha inicial"
                            id="fiVenta" :max="minVentafi" v-model="fiVenta"></b-form-datepicker>
                        </b-col>
                        <b-col cols="6" >
                            <b-form-datepicker class="mt-3" placeholder="Seleccione la fecha final"
                            id="ffVenta" :min="minVentaff" v-model="ffVenta"></b-form-datepicker>
                        </b-col>
                        <!-- <b-col class="col-md-2 mt-3" >
                            <b-form-checkbox v-model="switchVenta" cursor="pointer;" name="check-button" switch>
                            Factura global
                            </b-form-checkbox>
                        </b-col> -->
                    </b-row>
                    <ItemsVentas :perPage="perPage" :currentPage="currentPage"
                    :items="facturacion.listVentasFacturacion" idTemplate="ventas" :search="searchVenta" />

                </b-tab>
                <b-tab title="Abonos" >
                    <b-row >
                        <b-col cols="6" >
                            <b-form-datepicker class="mt-3" placeholder="Seleccione la fecha inicial"
                            id="fiAbono" :max="maxAbonofi" v-model="fiAbono"></b-form-datepicker>
                        </b-col>
                        <b-col cols="6" >
                            <b-form-datepicker class="mt-3" placeholder="Seleccione la fecha final"
                            id="ffAbono" :min="minAbonoff" v-model="ffAbono"></b-form-datepicker>
                        </b-col>
                        <!-- <b-col class="col-md-2 mt-3" >
                            <b-form-checkbox cursor="pointer;" name="check-button" switch>
                            Factura global
                            </b-form-checkbox>
                        </b-col> -->
                    </b-row>
                    <ItemsListFacturacion
                    :perPage="perPage" :currentPage="currentPage"
                    :items="facturacion.listAbonosFacturacion" idTemplate="abonos" :search="searchAbono" />
                </b-tab>
                </b-tabs>
            </b-card>
        </b-col>

        <b-col cols="1" class="mr-2" style="position:fixed; width:110px; height:60px; top:78px; right:25px;" >
            <b-row class="justify-content-md-center">
                <b-col class="pb-2">
                    <Boton 
                    :disabled="validVentas && validAbono || facturacion.loading"
                    :onClic="facturar" text="Facturar" tooltip="Imprimir factura" icon="file-earmark-spreadsheet" fontscale="4" variant="success" btn="btn-default" size="md"/>
                </b-col>
            </b-row>
            <b-row class="justify-content-md-center">
                <b-col class="pb-2">
                    <Boton text="Cancelar" tooltip="Cancelar factura" icon="x-circle-fill" fontscale="4" variant="danger" btn="btn-cancel" size="md"/>
                </b-col>
            </b-row> 
        </b-col>
      </b-row>

    </b-col>
  </b-container>
</template>
<script>
    // import moment from 'moment'
    import ItemsVentas from "./ItemsVentas";
    import Boton from '@/components/Boton'
    import ItemsListFacturacion from '@/components/ItemsListFacturacion'
    import { mapState, mapActions } from "vuex";

    export default {
        mounted(){
            this.$store.commit("SET_LIST_ABONOS_FACTURACION", []);
            this.$store.commit("SET_LIST_VENTAS_FACTURACION", []);
            this.$store.commit("SET_LOADING", false);
            this.$store.commit("SET_CLEAR_INDEX_API");
            this.$store.commit("SET_COLOR_PROGRESBAR", "primary");
            this.$store.commit("SET_ESTATUS_VENTA", "");
            this.$store.commit("SET_RAZON_VENTA", "");
            this.$store.commit("SET_RELOAD_VENTA", 0);
            this.$store.commit("SET_ITER_VENTAS", 0);

            this.$store.commit("SET_VENTAS_CHECKED", []);
            this.$store.commit("SET_ITER_CLIENTES", 0);
            this.$store.commit("SET_IDS_PUBLICO_GENERAL", "0");
            
            
            this.tabIndex = 0;

            let now = new Date()
            let today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            this.maxAbonofi = new Date(today)
            this.minVentafi = new Date(today)
        },
        watch:{
            // al terminar recarga el list 
            "facturacion.reloadVenta"(val){
                if(val != 0){

                    this.$store.commit("SET_LOADING", false);
                    this.$store.commit("SET_CLEAR_INDEX_API");
                    this.$store.commit("SET_COLOR_PROGRESBAR", "primary");
                    this.$store.commit("SET_ESTATUS_VENTA", "");
                    this.$store.commit("SET_RAZON_VENTA", "");
                    this.$store.commit("SET_RELOAD_VENTA", 0);
                    this.$store.commit("SET_ITER_VENTAS", 0);

                    this.$store.commit("SET_VENTAS_CHECKED", []);
                    this.$store.commit("SET_ITER_CLIENTES", 0);
                    this.$store.commit("SET_IDS_PUBLICO_GENERAL", "0");

                    this.listDatosVenta();
                }
            },
            "facturacion.indexApi"(val){
                if(val != 0){
                    this.progreso();
                }
            },
            "switchVenta"(val){
                if(val){
                    this.searchVenta = "PUBLICO EN GENERAL";
                }else{
                    this.searchVenta = "";
                }
            },
            "fiAbono"(val){
                this.minAbonoff = new Date(val);
                this.minAbonoff = val;
                if(val != "" && this.ffAbono != ""){
                    let datos = {
                        fi: this.fiAbono,
                        ff: this.ffAbono
                    }
                    this.abonosFacturaList(datos);
                }
            },
            "ffAbono"(val){
                if(val != "" && this.fiAbono != ""){
                    let datos = {
                        fi: this.fiAbono,
                        ff: this.ffAbono
                    }
                    this.abonosFacturaList(datos);
                }
            },
            "fiVenta"(val){
                this.minVentaff = new Date(val);
                this.minVentaff = val;
                if(val != "" && this.ffVenta != ""){
                    this.listDatosVenta();
                }
            },
            "ffVenta"(val){
                if(val != "" && this.fiVenta != ""){
                    this.listDatosVenta();
                }
            }
        },
        components: {
            Boton,
            ItemsListFacturacion,
            ItemsVentas
        },
        data() {
            return {
                max: 100,
                tabIndex: 0,
                switchVenta: false,
                fiAbono: "",
                ffAbono: "",
                maxAbonofi: "",
                minAbonoff: "",
                fiVenta: "",
                ffVenta: "",
                minVentafi: "",
                minVentaff: "",
                searchAbono: "",
                searchVenta: "",
                perPage: 10,
                currentPage: 1,
                publicoG:{},
                cliente:{},
            }
        },
        computed: {
            ...mapState(["facturacion", "auth"]),
            
            validAbono(){
                return this.getCheckedVenta().length == 0;
            },
            validVentas(){
                return this.getCheckedVenta().length == 0;
            }
        },
        methods: {
            ...mapActions(["abonosFacturaList", "ventasFacturaList", "facturaCreate", "facturaGeneralCreate"]),
            // lanza api para refrescar el list de abonos
            listDatosAbono(){
            },
            facturar(){
                this.$store.commit("SET_LOADING", true);
                // ventas
                if(this.tabIndex == 0){
                    this.ventas();
                }
                // abonos
                else if(this.tabIndex == 1){
                    this.abonos();
                }
            },
            abonos(){
                console.log("abonos: ");
            },
            

            // lanza api para refrescar el list de ventas
            listDatosVenta(){
                let datos = {
                    fi: this.fiVenta,
                    ff: this.ffVenta
                }
                this.ventasFacturaList(datos);
            },
            // filtra los ceckeados solamente
            // se agrego que seleccione el cfdi
            getCheckedVenta(){
                const checked = (v, req) => {
                    if(req !== null){ return req.checked && req.id_cfdi !== undefined && req.id_cfdi != ""; }
                };
                return this.facturacion.listVentasFacturacion.filter(req => "".toLowerCase()
                .split('  ').every(v => checked(v, req)))
            },
            // obtiene los checkeados y setea la variable
            ventas(){
                this.$store.commit("SET_CLEAR_INDEX_API");
                this.$store.commit("SET_COLOR_PROGRESBAR", "primary");
                this.$store.commit("SET_ESTATUS_VENTA", "");
                this.$store.commit("SET_RAZON_VENTA", "");
                this.$store.commit("SET_RELOAD_VENTA", 0);
                this.$store.commit("SET_ITER_VENTAS", 0);
                this.$store.commit("SET_VENTAS_CHECKED", []);
                this.$store.commit("SET_ITER_CLIENTES", 0);
                this.$store.commit("SET_IDS_PUBLICO_GENERAL", "0");

                let facturar = this.getCheckedVenta();
                this.$store.commit("SET_VENTAS_CHECKED", facturar);
                this.progresoAntiguo();
            },
            // filtra los clientes de un array
            getClientes(listchecked){
                const filtroC = (v, req) => {
                    if(req !== null){
                        if(req.nombre_razon_social.toString().toLowerCase() != v){
                            return true;
                        }
                    }
                };
                return listchecked.filter(req => "PUBLICO EN GENERAL".toLowerCase()
                .split('  ').every(v => filtroC(v, req)))
            },
            // filtra los de publico general de un array
            getPublicoGeneral(listchecked){
                const filtroPG = (v, req) => {
                    for (const prop in req) {
                        if(req[prop] !== null){
                            if(req[prop].toString().toLowerCase().includes(v)){
                                return true;
                            }
                        }
                    }
                };
                return listchecked.filter(req => "PUBLICO EN GENERAL".toLowerCase()
                .split(' ').every(v => filtroPG(v, req)))
            },
            // empieza el proceso para el de venta
            progreso(){
                let clientes = this.getClientes(this.facturacion.listVentaChecked);
                let publicoGeneral = this.getPublicoGeneral(this.facturacion.listVentaChecked);

                let cantPG = publicoGeneral.length;
                let cantC = clientes.length;
                let totalLimit = cantPG + cantC;

                if (this.facturacion.iteracionVentas < totalLimit){
                    if(this.facturacion.iClientes < clientes.length){
                        // this.clienteV(clientes[this.facturacion.iClientes])
                        this.$store.commit("SET_ITER_CLIENTES", this.facturacion.iClientes+1);
                        this.$store.commit("SET_ITER_VENTAS", this.facturacion.iteracionVentas+1);
                    }else if(cantPG >= 1){
                        let idGeneral = "";
                        publicoGeneral.forEach(element => {
                            idGeneral += element.id_venta + ",";
                        });
                        this.$store.commit("SET_IDS_PUBLICO_GENERAL", idGeneral.substring(0, idGeneral.length - 1));
                        // this.publicoGeneral(this.facturacion.idsPGeneral);
                        this.$store.commit("SET_ITER_VENTAS", this.facturacion.iteracionVentas+1);
                    }
                }
            },
            // empieza el proceso para el de venta
            progresoAntiguo(){
                let clientes = this.getClientes(this.facturacion.listVentaChecked);
                let publicoGeneral = this.getPublicoGeneral(this.facturacion.listVentaChecked);
                let cantPG = publicoGeneral.length;
                let totalLimit = this.facturacion.listVentaChecked.length - cantPG;
                
                // Sirve porque agregaba una iteracion al ser un solo cliente
                // en actions tambien se utiliza
                if(cantPG != 0){
                    totalLimit = totalLimit + (cantPG - (cantPG - 1))
                }
                console.log("cantPG: " , cantPG);
                console.log("totalLimit: " , totalLimit);

                if (this.facturacion.iteracionVentas < totalLimit){
                // if (this.facturacion.iteracionVentas < this.facturacion.listVentaChecked.length) {
                    if(this.facturacion.iClientes < clientes.length){
                        this.clienteV(clientes[this.facturacion.iClientes])
                        this.$store.commit("SET_ITER_CLIENTES", this.facturacion.iClientes+1);
                        this.$store.commit("SET_ITER_VENTAS", this.facturacion.iteracionVentas+1);
                    }else if(cantPG >= 1){
                        let idGeneral = "";
                        publicoGeneral.forEach(element => {
                            idGeneral += element.id_venta + ",";
                        });
                        this.$store.commit("SET_IDS_PUBLICO_GENERAL", idGeneral.substring(0, idGeneral.length - 1));
                        this.publicoGeneral(this.facturacion.idsPGeneral);
                        this.$store.commit("SET_ITER_VENTAS", this.facturacion.iteracionVentas+1);
                    }
                }
            },
            // envia los datos a la api
            // public en general agrega todos los iDS seleccionados para razon social: PUBLICO EN GENERAL
            publicoGeneral(ventas){
                this.$store.commit("SET_ESTATUS_VENTA", "enviando...");
                this.$store.commit("SET_RAZON_VENTA", "PUBLICO EN GENERAL");
                let params = {
                    ventas: ventas,
                    id_usuario: this.auth.user.idUser
                }
                console.log("params: " , params);
                this.facturaGeneralCreate(params);
            },
            // envia los datos a la api
            // lanza individual mente por cliente cuando razon social: tiene nombre
            clienteV(element){
                this.$store.commit("SET_ESTATUS_VENTA", "enviando...");
                this.$store.commit("SET_RAZON_VENTA", element.nombre_razon_social);
                // se agrego id_cfdi para la nueva facturacion
                let params = {
                    id_venta: element.id_venta,
                    id_usuario: this.auth.user.idUser,
                    uso_cfdi: element.id_cfdi
                }
                console.log("params: " , params);
                this.facturaCreate(params);
            }
        }
    }
</script>
