<template>
  <b-modal id="modal" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="REGISTRO" title-class="modal-title w-100" body-class="p-4" >
    <b-row>
        <!-- <b-row > -->
           <b-card bg-variant="light" style="width: 50rem;" header="DATOS ARTICULO" class="pt-0">
            <b-row >
            <b-col class="col-md-6" >
              <b-form-group id="input-group-1" label="NOMBRE:" label-class="form-label" label-for="input-1"  class="mb-0">
                <b-input-group class="m-0" size="sm" >
                  <b-form-input :disabled="articulos.id_articulo_form != '0'"  @focus="$event.target.select()" v-model="dataModal.nombre" ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col class="col-md-3" >
              <b-form-group id="input-group-1" label="CLAVE:" label-class="form-label" label-for="input-1"  class="mb-0">
                <b-input-group class="m-0" size="sm" >
                  <b-form-input :disabled="articulos.id_articulo_form != '0'" @focus="$event.target.select()" v-model="dataModal.clave" ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col class="col-md-3" >
              <b-form-group id="input-group-1" label="CLAVE PROD:" label-class="form-label" label-for="input-1"  class="mb-0">
                <b-input-group class="m-0" size="sm" >
                  <b-form-input :disabled="articulos.id_articulo_form != '0'" @focus="$event.target.select()" v-model="dataModal.clave_prod_serv" ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col class="col-md-3" >
              <b-form-group id="input-group-1" label="CÓDIGO BARRAS:" label-class="form-label" label-for="input-1"  class="mb-0">
                <b-input-group class="m-0" size="sm" >
                  <b-form-input :disabled="articulos.id_articulo_form != '0'" @focus="$event.target.select()" v-model="dataModal.codigo_barras" ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="DESCRIPCIÓN:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group >
                <b-textarea :disabled="articulos.id_articulo_form != '0'" v-model="dataModal.descripcion"  class="m-0" size="sm" ></b-textarea>
              </b-input-group>
            </b-form-group>
          </b-col>

        
          <b-col class="col-md-4">
              <b-form-group id="input-group-1" label="IMAGEN:" label-class="form-label" label-for="input-1"  class="mb-0">
                  <b-input-group class="m-0" size="sm" >
                    <b-form-file  :disabled="articulos.id_articulo_form != '0'"
                    v-model="dataModal.imagen" accept="image/png, image/jpeg"
                    type="file" placeholder="Sin imagen" 
                    class="m-0" browse-text="Seleccionar" 
                    @change="onFileChange($event)" size="sm"></b-form-file>
                  </b-input-group>
              </b-form-group>
          </b-col>

          <b-col class="col-md-4 mb-3">
            <b-form-group id="input-group-1" label="CATEGORIA:" label-class="form-label" label-for="input-1"  class="mb-0">
            <b-input-group class="m-0" size="sm">
            <Autocomplete :disabled="articulos.id_articulo_form != '0'" class="mr-1" style="height: 32px;" placeholder="Seleccione una opción" 
              cols="col-md-12" :items="articulos.categoriasList"  
                  text="categoria" 
                  v-model="id_categoria_modal" 
                  filter="categoria"
                  assign="id_categoria"/>
              </b-input-group>
              </b-form-group>
          </b-col>
          <b-col class="col-md-4 mb-3">
            <b-form-group id="input-group-1" label="SUBCATEGORIA:" label-class="form-label" label-for="input-1"  class="mb-0">
            <b-input-group class="m-0" size="sm">
            <Autocomplete :disabled="articulos.id_articulo_form != '0'" class="mr-1" style="height: 32px;" placeholder="Seleccione una opción" 
              cols="col-md-12" :items="articulos.subcategoriasList"  
                  text="sub_categoria" 
                  v-model="dataModal.id_sub_categoria" 
                  filter="sub_categoria"
                  assign="id_sub_categoria"/>
              </b-input-group>
              </b-form-group>
          </b-col>

          <b-col class="col-md-4 mb-3">
            <b-form-group id="input-group-1" label="UNIDAD ALMACENAMIENTO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm">
                <Autocomplete :disabled="articulos.id_articulo_form != '0'" class="mr-1" placeholder="Seleccione una opción" 
                v-model="dataModal.id_unidad"
                    :items="articulos.unidadesCatList"
                      text="unidad" assign="id_unidad"
                      filter="unidad"/>
              </b-input-group>
            </b-form-group>
          </b-col>
            </b-row>
           </b-card>
          

          <b-card bg-variant="light" style="width: 50rem;"  header="DATOS PRECIOS" class="mt-2">
            <b-row >
              <b-col class="col-md-3">
                <b-form-group id="input-group-1" label="PRECIO BLANCO:" label-class="form-label" label-for="input-1"  class="mb-0">
                    <b-input-group class="m-0" size="sm" >
                      <b-form-input :disabled="articulos.id_articulo_form != '0'" @focus="$event.target.select()"  v-model="dataModal.factor_pv_1" type="number"></b-form-input>
                    </b-input-group>
                    
                    <!-- <b-form-spinbutton  v-model="dataModal.factor_pv_1" inline></b-form-spinbutton> -->
                </b-form-group>
              </b-col>
              <b-col class="col-md-3">
                <b-form-group id="input-group-1" label="PRECIO VERDE:" label-class="form-label" label-for="input-1"  class="mb-0">
                    <b-input-group class="m-0" size="sm" >
                      <b-form-input :disabled="articulos.id_articulo_form != '0'" @focus="$event.target.select()" style="border: 3px solid #458c2b;" v-model="dataModal.factor_pv_2" type="number"></b-form-input>
                    </b-input-group>
                    
                </b-form-group>
              </b-col>
              <b-col class="col-md-3">
                <b-form-group id="input-group-1" label="PRECIO AZUL:" label-class="form-label" label-for="input-1"  class="mb-0">
                  <b-input-group class="m-0" size="sm" >
                    <b-form-input :disabled="articulos.id_articulo_form != '0'" @focus="$event.target.select()" style="border: 3px solid #2b458c;" v-model="dataModal.factor_pv_3" type="number"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="col-md-3">
                <b-form-group id="input-group-1" label="PRECIO ROJO:" label-class="form-label" label-for="input-1"  class="mb-0">
                    <b-input-group class="m-0" size="sm" >
                      <b-form-input :disabled="articulos.id_articulo_form != '0'" @focus="$event.target.select()" style="border: 3px solid #E40000;" v-model="dataModal.factor_pv_4" type="number"></b-form-input>
                    </b-input-group>

                </b-form-group>
              </b-col>

              <b-col class="col-md-3">
                <b-form-group id="input-group-1" label="MAXIMO:" label-class="form-label" label-for="input-1"  class="mb-0">
                    <b-input-group class="m-0" size="sm" >
                      <b-form-input :disabled="articulos.id_articulo_form != '0'" @focus="$event.target.select()"  v-model="dataModal.maximo" type="number"></b-form-input>
                    </b-input-group>
                  
                </b-form-group>
              </b-col>

              <b-col class="col-md-3">
                <b-form-group id="input-group-1" label="MINIMO:" label-class="form-label" label-for="input-1"  class="mb-0">
                    <b-input-group class="m-0" size="sm" >
                      <b-form-input :disabled="articulos.id_articulo_form != '0'" @focus="$event.target.select()"  v-model="dataModal.minimo" type="number"></b-form-input>
                    </b-input-group>
                  
                </b-form-group>
              </b-col>
              <b-col class="col-md-3">
                <b-form-group id="input-group-1" label="PUNTO REORDEN:" label-class="form-label" label-for="input-1"  class="mb-0">
                    <b-input-group class="m-0" size="sm" >
                      <b-form-input  :disabled="articulos.id_articulo_form != '0'" @focus="$event.target.select()"  v-model.number="dataModal.punto_reorden" type="number" ></b-form-input>
                    </b-input-group>
                  
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>



          <b-card bg-variant="light" style="width: 50rem;"  header="EQUIVALENCIA" class="mt-2">
            <b-row >
              <b-col class="col-md-3">
                <b-form-group id="input-group-1" label="UNIDAD:" label-class="form-label" label-for="input-1"  class="mb-0">
                  <b-input-group disabled class="m-0" size="sm">
                    <Autocomplete disabled class="mr-1" placeholder="Seleccione una opción" 
                    v-model="dataModal.id_unidad"
                        :items="articulos.unidadesCatList"
                          text="unidad" assign="id_unidad"
                          filter="unidad"/>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="col-md-3">
                <b-form-group id="input-group-1" label="UNIDAD:" label-class="form-label" label-for="input-1"  class="mb-0">
                  <b-input-group class="m-0" size="sm">
                    <Autocomplete :disabled="articulos.id_articulo_form == '0'" class="mr-1" placeholder="Seleccione una opción" 
                    v-model="id_unidad_destino"
                        :items="articulos.unidadesCatList"
                          text="unidad" assign="id_unidad"
                          filter="unidad"/>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col class="col-md-3" >
                <b-form-group id="input-group-1" label="EQUIVALENCIA:" label-class="form-label" label-for="input-1"  class="mb-0">
                  <b-input-group class="m-0" size="sm" >
                    <b-form-input :disabled="articulos.id_articulo_form == '0'" @focus="$event.target.select()" v-model="equivalencia" ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="col-md-3 mt-3 text-center w-100 h-100">
                <Boton v-if="articulos.id_unidad_equivalencia == 0" 
                :disabled="equivalencia == '' || id_unidad_destino == '' || articulos.id_articulo_form == '0'" text="Agregar" tooltip="Guardar equivalencia" class="mr-3"
                  icon="plus-circle" fontscale="2"
                  :onClic="crearEquivalencia" 
                  variant="info" btn="btn-secundary" size="sm"/>
                
                <Boton v-if="articulos.id_unidad_equivalencia != 0" 
                :disabled="id_unidad_destino == '' || equivalencia == '' || articulos.id_articulo_form == '0'" text="Guardar" tooltip="Guardar equivalencia" class="mr-3"
                  icon="lock-fill" fontscale="2"
                  :onClic="updateEquivalencia" 
                  variant="info" btn="btn-secundary" size="sm"/>
              </b-col>

              <b-col class="col-md-12 mt-2" :disabled="articulos.id_articulo_form == '0'">
                <EqivalenciaList :actions="true" :items="articulos.unidad_equivalencia_list"
                    SET_COMMIT_DELETE="SET_COMMIT_CARRITO_DELETE"
                    SET_COMMIT_EDIT="SET_COMMIT_CARRITO_EDIT"
                    SET_COMMIT_IMG="SET_COMMIT_IMG_CARRITO"
                    :clicItem="clicItem"
                    idTemplate="productosCarrito" :search="search"
                />
              </b-col>

            </b-row>
          </b-card>

    </b-row>
    <b-row style="padding-top:1rem;" >
      <b-col class="col-md-12" style="padding-top:1rem;"  >
      </b-col>
      
    </b-row>
    
    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Guardar" tooltip="Guardar registro" class="mr-3"
        icon="lock-fill" fontscale="2"
        :disabled="dataModal.nombre===' ' || dataModal.descripcion===' ' || 
        dataModal.factor_pv_1===' ' || dataModal.factor_pv_2===' ' || dataModal.factor_pv_3===' '
        || dataModal.factor_pv_4===' ' || dataModal.maximo===' ' || dataModal.minimo===' ' ||
        dataModal.punto_reorden===' ' || dataModal.imagen===' ' || dataModal.id_unidad ===' '
        || dataModal.clave ===' '|| dataModal.clave_prod_serv ===' '|| dataModal.codigo_barras ===' '
        || loading
        || dataModal.factor_pv_4 < 0 || dataModal.factor_pv_4 == ' ' || dataModal.factor_pv_4 == ''
        || dataModal.factor_pv_3 < 0 || dataModal.factor_pv_3 == ' ' || dataModal.factor_pv_3 == ''
        || dataModal.factor_pv_2 < 0 || dataModal.factor_pv_2 == ' ' || dataModal.factor_pv_2 == ''
        || dataModal.factor_pv_1 < 0 || dataModal.factor_pv_1 == ' ' || dataModal.factor_pv_1 == ''
        
        || dataModal.maximo < 0 || dataModal.maximo == ' ' || dataModal.maximo == ''
        || dataModal.minimo < 0 || dataModal.minimo == ' ' || dataModal.minimo == ''
        || dataModal.punto_reorden < 0 || dataModal.punto_reorden == ' ' || dataModal.punto_reorden == ''"
        :onClic="realizarRegistro" 
        variant="info" btn="btn-secundary" size="sm"/>

        <Boton text="Cerrar" tooltip="Cerrar"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/>
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import Autocomplete from '@/components/Autocomplete'
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  import EqivalenciaList from '../pages/articulos/EqivalenciaList'
  import Vue2Filters from 'vue2-filters'
  
  export default {
    mounted(){
      this.initModal();
    },
    mixins: [Vue2Filters.mixin],
    components:{ Boton , Autocomplete, EqivalenciaList },//
    data() {
      return {
        id_unidad_equivalencia: "",
        id_categoria_modal:"",
        id_unidad_destino:"",
        equivalencia:"",
        search:"",
        id_sub_categoria:" ",
        estatus: 1,
        modalShow: false,
        dataModal: {
          nombre:" ", 
          descripcion:" ",
          factor_pv_1: " ",
          factor_pv_2: " ",
          factor_pv_3: " ",
          factor_pv_4: " ",
          id_unidad: 0,
          id_sub_categoria: '',
          estatus: 1,
          imagen: null,
          maximo: " ",
          minimo: " ",
          punto_reorden:" ",
          equivalencia: "",
          clave:" ",
          clave_prod_serv:" ",
          codigo_barras:" ",
        },
        loading: false
      };
    },
    watch: {
        "id_categoria_modal": function (val) {
          if(val){
            this.subCategoriaListCrud(val);
          }
        },
      // "articulos.id_categoria": function (val) {
      //       if(val){
      //         // this.articulosList(val);
      //       }
      //      console.log("valUnidades",val)
      //  },
      //  actualiza el i unidad para lanzarlo en update equivalencia
      "dataModal.id_unidad": function (val) {
          if(val != 0){
            console.log("id_unidad: " , val);
            this.$store.commit("SET_ID_UNIDAD", val);
          }
      },
      "articulos.id_sub_categoria": function (val) {
          console.log("valEdiSub: " , val);
          if(val >= 1){
            val = this.dataModal.id_sub_categoria;
            //Lanzar commit
            this.$store.commit("SET_ID_SUBCATEGORIA", val);
          } 
        },
      "articulos.messageArticulo": function (val) {
        if(val.mensaje != ""){
          this.generateAlert(val.mensaje, val.clase);
        }
      },
      "articulos.id_articulo_form": function (val) {
        if(val != ""){
            console.log("val: " , val);
            console.log("dataModal.id_unidad: " , this.dataModal.id_unidad);
          let datos = {
              equivalencia: 1,
              unidad_inicial: this.dataModal.id_unidad,
              unidad_destino: this.dataModal.id_unidad,
              id_articulo: val,
              id_usuario: this.auth.user.idUser,
          }
          this.unidadEquivalenciaCreate(datos);
        }
      },
    },
    computed:{
      ...mapState([ "auth", "articulos"]),
    },
    methods:{
      ...mapActions(["subCategoriaListCrud", "unidadEquivalenciaUpdate", "unidadEquivalenciaCreate", "articulosList","createArticuloNuevo", "unidadesCatList" ,"SET_COMMIT_UNIDADES_CAT_LIST", "SET_MESSAGE_ARTICULO" ]),//,"SET_ID_SUBCATEGORIA"
      initModal(){
        this.$store.commit("SET_COMMIT_UNIDADES", []);
        this.$store.commit("SET_COMMIT_UNIDADES_LIST_ARTICULOS", []);
        this.$store.commit("SET_COMMIT_UNIDADES_CAT_LIST", []);
        this.$store.commit("SET_COMMIT_SUBCATEGORIAS", []);
        this.$store.commit("SET_COMMIT_UNIDAD_EQUIVALENCIA", []);
        //this.$store.commit("SET_ID_SUBCATEGORIA", 0);
        this.unidadesCatList();
        
        this.$store.commit("SET_ID_UNIDAD", 0);
        this.$store.commit("SET_COMMIT_ID_UNIDAD_EQUIVALENCIA", 0);
      },
      clicItem(item){
        this.id_unidad_equivalencia = item.id_unidad_equivalencia
        this.id_unidad_destino = item.id_unidad_destino;
        this.equivalencia = item.equivalencia;
        console.log("ccccc " , item);
        this.$store.commit("SET_COMMIT_ID_UNIDAD_EQUIVALENCIA", item.id_unidad_equivalencia);
      },
      onFileChange(e){
      // //     const file = e.target.files[0];
      // // this.dataModal.imagen = URL.createObjectURL(file);
      const files = e.target.files;
      if(files[0] !== undefined) {
        this.dataModal.imagen = files[0].name
        if(this.dataModal.imagen.lastIndexOf('.') <= 0) {
          return
        }
        this.dataModal.imagen = files[0]
      }
      else {
        this.dataModal.imagen = files[0]
      }
    },
      restrictDecimal() {
        let cambio = parseFloat(this.dataModal.cambio);
        this.dataModal.cambio = cambio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      },
     
      // cierra el modal
      cancel() {
        this.initModal();
        this.modalShow = false;
        this.loading = false;
        this.dataModal = { 
          nombre:" ", 
          descripcion:" ",
          factor_pv_1: " ",
          factor_pv_2: " ",
          factor_pv_3: " ",
          factor_pv_4: " ",
          id_unidad: 0,
          id_sub_categoria: '',
          estatus: 1,
          imagen: null,
          maximo: " ",
          minimo: " ",
          punto_reorden:" ",
          equivalencia: "",
          clave:" ",
          clave_prod_serv:" ",
          codigo_barras:" ",};
        this.$store.commit("SET_ID_ARTICULO_FORM", 0);
        return this.modalShow;
      },
      realizarRegistro(){
        this.loading = true;
        let formData = new FormData;
        if(this.dataModal.nombre){
            formData.append('nombre', this.dataModal.nombre);
        }
        if(this.dataModal.descripcion){
            formData.append('descripcion', this.dataModal.descripcion);
        }
        if (this.dataModal.factor_pv_1){
            formData.append('factor_pv_1', this.dataModal.factor_pv_1);
        }
        if (this.dataModal.factor_pv_2){
            formData.append('factor_pv_2', this.dataModal.factor_pv_2);
        } 
        if(this.dataModal.factor_pv_3){
            formData.append('factor_pv_3', this.dataModal.factor_pv_3);
        }
        if(this.dataModal.factor_pv_4){
            formData.append('factor_pv_4', this.dataModal.factor_pv_4);
        }
        if (this.dataModal.id_unidad){ 
            formData.append('id_unidad', this.dataModal.id_unidad);//
        }
        if (this.dataModal.id_sub_categoria){
            formData.append('id_sub_categoria',this.dataModal.id_sub_categoria);//this.dataModal.id_sub_categoria
        }
        if(this.estatus){
            formData.append('estatus', 1);//this.dataModal.estatus
        }
        if(this.dataModal.imagen){
            formData.append('imagen', this.dataModal.imagen);
        }
        else {
            formData.append('imagen', null);
        }
        if (this.dataModal.maximo){
            formData.append('maximo', this.dataModal.maximo);
        }
        if (this.dataModal.minimo){
            formData.append('minimo', this.dataModal.minimo);
        }
        if(this.dataModal.punto_reorden){
          formData.append('punto_reorden', this.dataModal.punto_reorden);
        }
        if(this.dataModal.clave){
          formData.append('clave', this.dataModal.clave);
        }
        if (this.dataModal.clave_prod_serv){
          formData.append('clave_prod_serv', this.dataModal.clave_prod_serv);
        }
        if (this.dataModal.codigo_barras){
          formData.append('codigo_barras', this.dataModal.codigo_barras);
        }
        formData.append('id_usuario', this.auth.user.idUser);
        this.createArticuloNuevo(formData);
        // this.articulosList(id);
      },
      crearEquivalencia(){

        let datos = {
          equivalencia: this.equivalencia,
            unidad_inicial: this.dataModal.id_unidad,
            unidad_destino: this.id_unidad_destino,
            id_articulo: this.articulos.id_articulo_form,
            id_usuario: this.auth.user.idUser,
        }
        this.unidadEquivalenciaCreate(datos);

        this.id_unidad_destino = "";
        this.equivalencia = "";
        

          // this.cancel();
      },
      updateEquivalencia(){
        let ress = {
          id_unidad_equivalencia: this.id_unidad_equivalencia,
          equivalencia: this.equivalencia,
          id_unidad_inicial: this.dataModal.id_unidad,
          id_unidad_destino: this.id_unidad_destino,
          id_usuario: this.auth.user.idUser,
        }
        this.unidadEquivalenciaUpdate(ress);
        this.$store.commit("SET_COMMIT_ID_UNIDAD_EQUIVALENCIA", 0);
        this.id_unidad_destino = "";
        this.equivalencia = "";
        this.id_unidad_equivalencia = "";
        

          // this.cancel();
      }
    },
  };
</script>
<style>
</style>