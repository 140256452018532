// *** Pantalla que se muestra desde el boton de devolución
<template>
  <b-container fluid>
    <b-col class="p-0 pb-5 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
        
        <b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
            <b-col cols="12">
                <Acciones />
            </b-col>
        </b-row>
        
        <Devolucion />
        
    </b-col>
  </b-container>
</template>

<script>
import Acciones from "../devolucion/Acciones";
import Devolucion from "./Devolucion"

export default {
    components: { Acciones, Devolucion },
}
</script>
