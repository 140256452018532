<template>
  <b-modal id="modalEjemplo" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="DEVOLUCIÓN" title-class="modal-title w-100" body-class="p-4" >
    <b-row>
        <b-col class="col-md-12">
        <!-- <b-row > -->
          <b-row>
            <b-col class="col-md-6">
            <b-form-group id="input-group-1" label="MONTO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group disabled class="m-0" size="sm" prepend="$">
                <b-form-input style="text-align:right;" @focus="$event.target.select()" :value="toMoneda(dataModal.total)" disabled >
                  <!--  v-model="devolucion.ventaDetailList.precio" -->
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col class="col-md-6">
            <b-form-group id="input-group-1" label="FECHA DE LA COMPRA:" label-class="form-label" label-for="input-1"  class="mb-0">
              
              
        <b-input-group disabled size="sm" class="m-0" v-b-tooltip.hover title="Fecha de la compra">
            <template v-slot:prepend>
                <span class="input-group-text" id="basic-addon">
                    <b-icon icon="calendar2-date-fill"></b-icon>
                </span>
            </template>
            <b-form-input @focus="$event.target.select()" :value="formatFecha(devolucion.ventaHeader.fecha)" disabled aria-label="Small text input with custom switch"></b-form-input>
        </b-input-group>

            </b-form-group>
          </b-col>



          <b-col class="col-md-6">
            <b-form-group id="input-group-1" label="CANTIDAD:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm">
                <b-form-input
                 @change="cambioIngreso" :max="dataModal.cantidadItem" v-model.lazy="dataModal.cantidad" 
                @focus="$event.target.select()" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          </b-row>
          <b-row>
            <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="MOTIVO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group disabled class="m-0" size="lg">
                <b-form-textarea id="textarea" v-model="dataModal.motivo" placeholder="" rows="2" max-rows="3" ></b-form-textarea>
              </b-input-group>
            </b-form-group>
          </b-col>
          </b-row>
          
        <!-- </b-row> -->
      </b-col>
      
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Realizar devolución" :disabled="dataModal.cantidad == 0" tooltip="Realizar devolución" class="mr-3"
        
        icon="lock-fill" fontscale="2" :onClic="realizarMovDevolucion" variant="info" btn="btn-secundary" size="sm"/>

        <Boton text="Cancelar" tooltip="Cancelar devolución"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/>
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import moment from 'moment'
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  import Vue2Filters from 'vue2-filters'
  
  export default {
    mounted(){
      // limpiar variables para tipo de pago
      this.$store.commit("SET_COMMIT_MONTO", 0);
      this.$store.commit("SET_COMMIT_ARRAY_NUEVO", 0)
      // console.log("cajjaaa: " , this.caja.cajaCarrito);
    },
    mixins: [Vue2Filters.mixin],
    components:{ Boton },
    data() {
      return {
        modalShow: false,
        dataModal: {
          total:0,
          fecha:'',
          precio:0,
          motivo:'',
          cantidadItem: 0,
          cantidad: 1
        },
        loading: false
      };
    },
    watch:{
      "devolucion.arrayNuevo"(val){
        console.log("RRR " , val);
        if(val.length == 0){ this.dataModal.total = 0; }
        val.forEach(element => {
          let _importe = element.importe.split("$ ");
          this.dataModal.total += Number(_importe[1].replace(/[^0-9.-]+/g,"")) / 1.10;

          this.dataModal.cantidadItem = element.cantidad;
        });
      },
    },
    computed:{
      ...mapState([ "auth", "venta", "contado", "devolucion"]),
    },
    methods:{
      ...mapActions([ "devolucionMovCreate", "devolucionDetailCreate" ]),

      cambioIngreso(){
        if(this.dataModal.cantidad > this.dataModal.cantidadItem){
            this.dataModal.cantidad = this.dataModal.cantidadItem;
        }
      },

      formatFecha(value){
          if(value){
              return moment(value).format('YYYY-MM-DD')
          }
          return "";
      },
      // cierra el modal
      cancel() {
        this.modalShow = false;
        this.loading = false;
        this.dataModal = { 
          total:0,
          fecha:'',
          precio:0,
          motivo:'' };
        
        return this.modalShow;
      },
      realizarMovDevolucion(){
        // motivo
        // *id_venta
        // *monto
        // *id_usuario
        let data = {
          motivo:  this.dataModal.motivo,
          id_venta: this.devolucion.ventaDetailList[0].id_venta,
          monto: this.dataModal.total,
          id_usuario: this.auth.user.idUser
        }
        this.devolucionMovCreate(data);
        this.cancel();

      },
      realizarDevolucion(){
        let data = {
          id_devolucion: this.data.id_devolucion, // devolucion
          id_articulo: this.id_articulo,
          cantidad: this.dataModal.cantidad,
          id_unidad: this.id_unidad,
          id_usuario: this.auth.user.idUser
        }
        this.devolucionDetailCreate(data);
      },
      toMoneda(value){
          return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      },
    },
  };
</script>
<style>
</style>