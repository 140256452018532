/*eslint-disable no-unused-vars*/
export const mutations = {
    SET_PRECIO_TECLEADO(state,res){
        state.precioTecleado = res
    },
    SET_MESSAGE_CAJA(state,msj){
        state.messageCaja = msj
    },
    SET_FACTURACION(state,res){
        state.facturacion = res
    },
    SET_COMMIT_CARRITO_VENTA_COTIZACION(state, res){
        state.cajaCarrito = res;
    },
    SET_COMMIT_CARRITO_DELETE(state, element){
        state.cajaCarrito.splice(element, 1);
    },
    SET_COMMIT_CARRITO_ADD(state, element){
        state.cajaCarrito.push(element);
    },
    SET_COMMIT_TOTAL(state, element){
        state.totalCarrito.subtotal = element.subtotal;
        state.totalCarrito.iva = element.iva;
        state.totalCarrito.total = element.total;
    },
    SET_COMMIT_CARRITO_CLEAR(state){
        state.cajaCarrito = [];
    },
    SET_COMMIT_CARRITO_EDIT(state, id){
        state.editIdCarrito = id;
    },
    SET_COMMIT_PRODUCTOS(state, res){
        state.cajaProductos = res;
    },
    SET_COMMIT_UNIDADES(state, res){
        state.unidadesList = res;
    },
    SET_COMMIT_ARTICULO(state, res){
         state.articuloList = res;
    },
    SET_COMMIT_PRECIO_VENTA(state, res){
        console.log("SET_COMMIT_PRECIO_VENTA: " , res)
        state.precioVentaList = res["precios"] ? res["precios"] : [];
        state.pesopromedio = res["peso"];
    },
    SET_COMMIT_CLEAR_PESOPROMEDIO(state, res){
        state.pesopromedio = res;
    },
    SET_COMMIT_PRECIO_VENTA_EDIT(state, res){
        state.precioVentaListEdit = res;
    },
    SET_COMMIT_ID_CATEGORIA(state, res){
        state.id_categoria = res;
    },
    SET_COMMIT_ID_SUBCATEGORIA(state, res){
        state.id_sub_categoria = res;
    },
    SET_COMMIT_SHOW(state, val){
        state.show = val;
    },
    SET_COMMIT_LOAD_PRODUCTOS(state, val){
        state.loadProductos = val;
    },
    SET_COMMIT_IMG_CARRITO(state, val){
        state.imagenCarrito = val;
    },
    SET_COMMIT_EFECTIVO(state, val){
        state.efectivo = val;
    },
    SET_COMMIT_CHEQUE(state, val){
        state.cheque = val;
    },
    SET_COMMIT_TARJETA(state, val){
        state.tarjeta = val;
    },
    SET_COMMIT_CORTECAJA(state, val){
        state.corteCajaList = val;
    },
    SET_COMMIT_EDIT_ARTICULO(state, id){
        state.editIdArticulo = id;
    },
    SET_COMMIT_GET_ARTICULO(state, val){
        state.getArticulo = val;
    },
};