<template>
  <b-modal id="modal" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="REGISTRO" title-class="modal-title w-100" body-class="p-4" >
    <b-row>
        <!-- <b-row > -->
          <b-card bg-variant="light" style="width: 50rem;"  header="DATOS CONTACTO">
            <b-row >
            <b-col class="col-md-8" >
            <b-form-group id="input-group-1" label="NOMBRE / RAZON SOCIAL:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" required v-model="dataModal.nombre_razon_social" ></b-form-input>
                <!-- <b-form-spinbutton v-model.number="dataModal.efectivo" inline></b-form-spinbutton> -->
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="Correo:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="dataModal.e_mail" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="RFC:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="dataModal.rfc" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
<b-col class="col-md-4">
            <b-form-group id="input-group-1" label="TELÉFONO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="dataModal.telefono" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col style="padding-top:2rem;" class="col-md-4">
      <b-row>
        <b-form-checkbox v-model="dataModal.persona_moral"  switch size="lg">Personal Moral</b-form-checkbox>
      <!-- <b-form-checkbox style="padding-left:4rem;" v-model="dataModal.persona_moral" switch size="lg">Inactivo</b-form-checkbox> -->
      </b-row>
      
    </b-col>


          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="Regimen fiscal:" label-class="form-label" label-for="input-1"  class="mb-0">
              <Autocomplete class="mr-1" placeholder="Selecciona una opción"  
              cols="col-md-12"
              :items="cliente.regimenFiscalList" text="Descripcion" 
              v-model="dataModal.id_regimen_fiscal" 
              filter="Descripcion" assign="idRegimenFiscal" label="" 
              />
            </b-form-group>
          </b-col>


            </b-row>
          </b-card>

           <b-card bg-variant="light" style="width: 50rem;"  header="DATOS DOMICILIO">
            <b-row >
<b-col class="col-md-12">
            <b-form-group id="input-group-1" label="DIRECCIÓN:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="dataModal.direccion"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          
           
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="CALLE:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="dataModal.calle" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="NO INTERIOR:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="dataModal.no_interior" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="NO EXTERIOR:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="dataModal.no_exterior" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
<b-col class="col-md-4">
            <b-form-group id="input-group-1" label="C.P.:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="dataModal.cp" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        <!-- </b-row> -->
         <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="COLONIA:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="dataModal.colonia"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="CIUDAD:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="dataModal.ciudad" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
           <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="MUNICIPIO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="dataModal.municipio"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="ESTADO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm">
                <b-form-input @focus="$event.target.select()" v-model="dataModal.estado"  ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
            <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="PAÍS:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="dataModal.pais"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

            </b-row>
          </b-card>

          <b-card bg-variant="light" style="width: 50rem;"  header="DATOS CRÉDITO">
            <b-row >
              <b-col class="col-md-6">
            <b-form-group id="input-group-1" label="LÍMITE DE CRÉDITO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm">
                <b-form-input @focus="$event.target.select()" v-model="dataModal.limite_credito" type="number" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

 </b-row>
          </b-card>
           
          
          
          

    </b-row>
    

      
    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Guardar" tooltip="Guardar registro" class="mr-3"
        :disabled="dataModal.nombre_razon_social==='' || dataModal.id_regimen_fiscal==='' ||
         dataModal.rfc==='' || dataModal.direccion==='' || 
         dataModal.colonia==='' ||  dataModal.estado==='' || 
         dataModal.ciudad==='' || dataModal.limite_credito =='' || dataModal.limite_credito < 0 ||
         dataModal.limite_credito == ' ' || dataModal.limite_credito == ''"
        icon="lock-fill" fontscale="2" :onClic="realizarRegistro" variant="info" btn="btn-secundary" size="sm"/>

        <Boton text="Cancelar" tooltip="Cancelar registro"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/>
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  import Vue2Filters from 'vue2-filters'
  import Autocomplete from '@/components/Autocomplete'
  
  export default {
    mounted(){
      // limpiar variables para tipo de pago
      this.$store.commit("SET_COMMIT_REGIMEN_FISCAL", []);
      this.regimenFiscalList();
    },
    mixins: [Vue2Filters.mixin],
    components:{ Boton, Autocomplete },
    data() {
      return {
        totalTexto:"",
        modalShow: false,
        dataModal: {
          e_mail:"",
          rfc:"",
          telefono:"",
          calle:"",
          no_interior:"",
          no_exterior:"",
          municipio:"",
          pais:"",
          cp:"",
          nombre_razon_social:"",
          direccion:"",
          colonia:"",
          estado:"",
          ciudad:"",
          limite_credito:'0',
          persona_moral:0,
          id_regimen_fiscal:0,
        },
        loading: false
      };
    },
    watch: {
      "cliente.messageCliente": function (val) {
        if(val.mensaje != ""){
          this.generateAlert(val.mensaje, val.clase);
        }
        if(val.clase == "toast-success"){
          this.cancel();
        }
      },
     
    },
    computed:{
      ...mapState([ "cliente", "auth"]),
    },
    methods:{
      ...mapActions([ "clienteCreate", "clienteList","regimenFiscalList" ]),
      //let resCambio = parseFloat()
      restrictDecimal() {
        let cambio = parseFloat(this.dataModal.cambio);
        this.dataModal.cambio = cambio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      },
    
      // cierra el modal
      cancel() {
        this.modalShow = false;
        this.loading = false;
        this.dataModal = { 
        rfc:"",
          telefono:"",
          calle:"",
          no_interior:"",
          no_exterior:"",
          municipio:"",
          pais:"",
          cp:"",
          nombre_razon_social:"",
          direccion:"",
          colonia:"",
          estado:"",
          ciudad:"",
          limite_credito:0,
          persona_moral:0,
          id_regimen_fiscal:0,
        };
        this.clienteList();
        return this.modalShow;
      },
      // setea todo el array
      setDetailRequisicionArray() {
        this.loading = true;
        
        this.cancel();
      },
      clearDigit(value){
        return Number(value.replace(/[^0-9.-]+/g,""));
      },
      realizarRegistro(){
        let data = {
          rfc:this.dataModal.rfc ? this.dataModal.rfc : "NO TECLEADO",
          telefono:this.dataModal.telefono ? this.dataModal.telefono : "NO TECLEADO",
          calle:this.dataModal.calle ? this.dataModal.calle : "NO TECLEADO",
          no_interior:this.dataModal.no_interior ? this.dataModal.no_interior : "NO TECLEADO",
          no_exterior:this.dataModal.no_exterior ? this.dataModal.no_exterior : "NO TECLEADO",
          municipio:this.dataModal.municipio ? this.dataModal.municipio : "NO TECLEADO",
          pais:this.dataModal.pais ? this.dataModal.pais : "NO TECLEADO",
          cp:this.dataModal.cp ? this.dataModal.cp : "NO TECLEADO",
          nombre_razon_social: this.dataModal.nombre_razon_social ? this.dataModal.nombre_razon_social : "NO TECLEADO",
          direccion:this.dataModal.direccion ? this.dataModal.direccion : "NO TECLEADO",
          colonia:this.dataModal.colonia ? this.dataModal.colonia : "NO TECLEADO",
          estado:this.dataModal.estado ? this.dataModal.estado : "NO TECLEADO",
          ciudad: this.dataModal.ciudad ? this.dataModal.ciudad : "NO TECLEADO",
          limite_credito:this.dataModal.limite_credito ? this.dataModal.limite_credito : "NO TECLEADO",
          persona_moral: this.dataModal.persona_moral ? 1 : 0,
          e_mail: this.dataModal.e_mail ? this.dataModal.e_mail : "NO TECLEADO",
          id_usuario:this.auth.user.idUser,
          id_regimen_fiscal:this.dataModal.id_regimen_fiscal
        }
        console.log("form: " , data);
        this.clienteCreate(data);
        this.clienteList();
      },
    },
  };
</script>
<style>
</style>