<template>
  <b-row class="p-0 m-0 h-100" style="colorfield-height: calc(100vh - FF0000)">
    <b-col class="col-md-12">
      <b-card header-tag="header" class="mt-3 card-mini">
        <b-card
          header-tag="header"
          class="mt-1 card-mini"
          style="height: 11.5rem; background-color: #e5e5e5; color: black"
        >
          <b-row>
            <b-col class="col-md-11">
              <h2>Registro Clientes</h2>
            </b-col>

            <b-col class="col-md-9" style="margin-top: 6px">
              <b-input-group>
                <b-input-group-append>
                  <Boton
                    tooltip="Buscar"
                    icon="search"
                    fontscale="2"
                    size="sm"
                  /><!--   -->
                </b-input-group-append>
                <input
                  @focus="$event.target.select()"
                  v-model="search"
                  required
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  placeholder="Buscar"
                  aria-describedby="basic-addon"
                />
              </b-input-group>
            </b-col>

            <b-col class="col-md-2" style="padding-bottom: 32px">
              <Boton
                :onClic="agregar"
                tooltip="Agregar"
                style="background-color: #ff5923"
                icon="plus-circle"
                fontscale="2"
                size=""
              />
            </b-col>

            <b-col class="col-md-6" style="padding-top: 32px">
              <b-input-group class="mt-6"> </b-input-group>
            </b-col>
          </b-row>
          <b-row> </b-row>
          <Modal />
        </b-card>

        <ItemsList
          class="mt-3"
          :actions="true"
          :items="listClientes()"
          :search="search"
          idTemplate="clientesCrud"
          :per-page="perPage" :current-page="currentPage"
          SET_COMMIT_SELECT="SET_COMMIT_CLIENTES"
          SET_COMMIT_DELETE="SET_COMMIT_CLIENTE_DELETE"
          SET_COMMIT_EDIT="SET_COMMIT_CLIENTE_EDIT"
        />
        <b-col class="col-sm-12 p-0 m-0">
          <!-- paginación -->
          <div class="mt-3" v-show="searchInDetail().length > 0">
            <b-pagination
              v-model="currentPage"
              :total-rows="searchInDetail().length"
              align="center"
              :per-page="perPage"
              aria-controls="almacenItems"
            >
            </b-pagination>
          </div>
        </b-col>

        <ModalEditar />
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
//import Titulo from "./Titulo"
import Boton from "@/components/Boton";
import Modal from "@/components/Modal";

import ModalEditar from "./ModalEditarCliente";
import ItemsList from "@/components/ItemsListCrud";
import { mapState, mapActions } from "vuex";

export default {
  mounted() {
    //
    this.$store.commit("SET_COMMIT_CLIENTES", []);
    this.$store.commit("SET_COMMIT_LOAD_CLIENTE", true);
    this.clienteList();
  },

  components: { ItemsList, ModalEditar, Boton, Modal },
  data() {
    return {
        search: "",
        perPage: 20,
        currentPage: 1,
    };
  },
  computed: mapState(["cliente"]),
  methods: {
    ...mapActions(["clienteList", "clienteGet"]),
    listClientes(){ 
        return this.searchInDetail().slice(
            (this.currentPage - 1) * this.perPage,
            this.currentPage * this.perPage
        )
    },
    searchInDetail(){
        return this.cliente.clienteList.filter(req => this.search.toLowerCase()
            .split(' ').every(v => this.conditionalFilter(v, req)))
    },
    conditionalFilter(v, req){
        for (const prop in req) {
            if(req[prop] !== null){
                if(req[prop].toString().toLowerCase().includes(v)){
                    return true;
                }
            }
        }
    },
    agregar() {
      this.$bvModal.show("modal");
    },
  },
};
</script>