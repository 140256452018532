export const mutations = {
    SET_MESSAGE_COBRO(state,msj){
        state.messageCobro = msj
    },
    SET_COBRO(state,res){
        state.cobro = res
    },
    SET_LIST_CLIENTES_ABONO(state,res){
        state.clientesAbonos = res
    },
    SET_TOTAL_ADEUDO_CLIENTE(state, res){
        state.totalAdeudo = res;
    }
}