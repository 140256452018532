/*eslint-disable no-unused-vars*/
export const mutations = {
    SET_MESSAGE_ARTICULO(state,msj){
        state.messageArticulo = msj;
    },
    // SET_COMMIT_ARTICULO(state, res){
    //     state.articuloList = res;
    // },
    SET_COMMIT_ARTICULOS(state, res){
        state.articulosList = res;
    },
    SET_COMMIT_CATEGORIAS(state, res){
        state.categoriasList = res;
    },
    SET_COMMIT_SUBCATEGORIAS(state, res){ 
        state.subcategoriasList = res;
    },
    SET_COMMIT_GET_ARTICULOS_LIST(state, val){
        state.getArticulos = val;
    }, 
    SET_COMMIT_IMG_ARTICULOS(state, val){
        state.imagenArticulos = val;
    },
    SET_COMMIT_ARTICULO_ADD(state, element){
        state.registroArticulo.push(element);
    },
    SET_COMMIT_EDIT_ARTICULOS_LIST(state, id){
        state.editIdArticuloList = id;
    },
    SET_COMMIT_ARTICULO_DELETE(state, element){
        state.articulosList.splice(element, 1);
    },
    SET_ID_CATEGORIA( state, id){
        state.id_categoria = id;
    }, 
    // id_subcategoria
    SET_ID_SUBCATEGORIA( state, id){
     state.id_subcategoria = id;
    },
    SET_COMMIT_UNIDADES_LIST_ARTICULOS(state, res){
            state.unidadesList = res;
    },
    SET_COMMIT_UNIDADES_CAT_LIST(state, res){
        state.unidadesCatList = res;
    },
    SET_ID_UNIDAD( state, id){
        state.id_unidad = id;
    },
    SET_ID_ARTICULO_FORM( state, id){
        state.id_articulo_form = id;
    },
    SET_COMMIT_UNIDAD_EQUIVALENCIA( state, id){
        state.unidad_equivalencia_list = id;
    },
    SET_COMMIT_ID_UNIDAD_EQUIVALENCIA( state, id){
        state.id_unidad_equivalencia = id;
    },
    SET_COMMIT_LIST_CATEGORIAS_EDIT(state, id){
        state.categoriasListEdit = id;
    },
    SET_COMMIT_LIST_SUBCATEGORIAS_EDIT(state, id){
        state.subcategoriasListEdit = id;
    },
    SET_COMMIT_LIST_ARTICULOS_EDIT(state, id){
        state.articulosListEdit = id;
    },
    SET_COMMIT_MSJ_ARTICULOS_EDIT(state, id){
        state.messageArticuloEdit = id;
    },

};