import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        cobro: false,
        messageOrdenCompra: { mensaje:"", clase:""},
        cajaCompra: [],
        editIdCompra: 0,
        imagenCompra: 0,
        proveedoresList: [],
        totalCompra: {subtotal: "0.00", iva: "0.00", total: "0.00"},
    },
    mutations,
    actions,
};