// *** Pantalla que se muestra desde el boton de cotizacion
<template>
  <b-container fluid>
    <b-col class="p-0 pb-5 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
        
        <b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
            <b-col cols="12">
                <Acciones carritoIcon="arrow-bar-right" productoIcon="arrow-bar-left" />
            </b-col>
        </b-row>
        
        <Venta />
        
    </b-col>
  </b-container>
</template>

<script>
    import Acciones from "../caja/Acciones";
    import Venta from "./Venta";
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
            this.clientesList();
            this.$store.commit("SET_COMMIT_CLIENTE_SELECTED", {nombre_razon_social:"No Seleccionado", limite_credito: "0.00"})
        },
        components: { Acciones, Venta },
        data() {
            return {
            }
        },
        computed: mapState([""]),
        methods: {
            ...mapActions(["clientesList"])
        }
    }
</script>