<template>
  <b-modal id="modalEditar" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="EDITAR" title-class="modal-title w-100" body-class="p-4" >
    <b-row>
        <!-- <b-row > -->
          <b-card bg-variant="light" style="width: 50rem;"  header="DATOS CONTACTO">
            <b-row >
              
                 <b-col class="col-md-12" >
            <b-form-group id="input-group-1" label="NOMBRE / RAZON SOCIAL:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="cliente.getCliente.nombre_razon_social" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="Correo:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="cliente.getCliente.e_mail" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

           <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="RFC:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="cliente.getCliente.rfc" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="TELÉFONO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="cliente.getCliente.telefono" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          
          <b-col style="padding-top:2rem;" class="col-md-4">
            <b-row>
              <b-form-checkbox switch v-model="p_moral" size="lg">Personal Moral</b-form-checkbox>
            <!-- <b-form-checkbox style="padding-left:4rem;" switch size="lg">Inactivo</b-form-checkbox> -->
            </b-row>
            
          </b-col>

          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="Regimen fiscal:" label-class="form-label" label-for="input-1"  class="mb-0">
              <Autocomplete class="mr-1" placeholder="Selecciona una opción"  
              cols="col-md-12"
              :items="cliente.regimenFiscalList" text="Descripcion" 
              v-model="cliente.getCliente.idRegimenFiscal" 
              filter="Descripcion" assign="idRegimenFiscal" label="" 
              />
            </b-form-group>
          </b-col>


            </b-row>
          </b-card>
          <b-card bg-variant="light" style="width: 50rem;"  header="DATOS DOMICILIO">
            <b-row>
            <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="DIRECCIÓN:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="cliente.getCliente.direccion"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
           
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="CALLE:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="cliente.getCliente.calle" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="NO EXTERIOR:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="cliente.getCliente.numero_exterior" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="NO INTERIOR:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="cliente.getCliente.numero_interior" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
         
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="COLONIA:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="cliente.getCliente.colonia"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="CIUDAD:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="cliente.getCliente.ciudad" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
           <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="C.P.:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()"  v-model="cliente.getCliente.codigo_postal" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
           <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="MUNICIPIO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="cliente.getCliente.municipio"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="ESTADO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm">
                <b-form-input @focus="$event.target.select()" v-model="cliente.getCliente.estado"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
         
          <b-col class="col-md-4">
            <b-form-group id="input-group-1" label="PAÍS:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" >
                <b-form-input @focus="$event.target.select()" v-model="cliente.getCliente.pais"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
            </b-row>
          </b-card>
           <b-card bg-variant="light" style="width: 50rem;"  header="DATOS CRÉDITO">
            <b-row>
            <b-col class="col-md-6">
            <b-form-group id="input-group-1" label="LÍMITE DE CRÉDITO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group  class="m-0" size="sm">
                <b-form-input @focus="$event.target.select()" type="number" v-model="cliente.getCliente.limite_credito" ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

            </b-row>
          </b-card>
           
         

    </b-row>
    

      
    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Guardar" tooltip="Guardar registro" class="mr-3"
        
        icon="lock-fill" fontscale="2" :onClic="realizarRegistro" 
        :disabled="cliente.getCliente.e_mail === null || dataModal.nombre_razon_social===' ' || dataModal.rfc===' '
        || dataModal.direccion===' ' || cliente.getCliente.limite_credito < 0 || cliente.getCliente.limite_credito == ' '
        || cliente.getCliente.limite_credito == '' || cliente.getCliente.id_regimen_fisca || cliente.getCliente.idRegimenFiscal === undefined"
        variant="info" btn="btn-secundary" size="sm"/>
<!-- :disabled="dataModal.totalRecibido < caja.totalCarrito.total" -->
        <Boton text="Cancelar" tooltip="Cancelar registro"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/>
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  import Vue2Filters from 'vue2-filters'
  import Autocomplete from '@/components/Autocomplete'
  
  export default {
    mounted(){
      this.$store.commit("SET_COMMIT_CLIENTE_EDIT", 0);
      this.$store.commit("SET_COMMIT_REGIMEN_FISCAL", []);
      this.regimenFiscalList();
    },
    mixins: [Vue2Filters.mixin],
    components:{ Boton, Autocomplete },
    data() {
      return {
        totalTexto:"",
        modalShow: false,
        dataModal: {
          rfc:"",
          nombre_razon_social:"",
          direccion:"",
          colonia:"",
          estado:"",
          ciudad:"",
          limite_credito:"",
          persona_moral:"",
          estatus:"",
          telefono:"",
          calle:"",
          no_interior:"",
          no_exterior:"",
          municipio:"",
          pais:"",
          cp:"",
        },
        loading: false
      };
    },
    watch: {
     
     "cliente.editIdCliente": function (val) {
        // console.log("val: " , val);
        if(val >= 1){
          this.$bvModal.show('modalEditar');
         this.clienteGet(val)
        }
      },
      "cliente.clienteGet": function (val) {
          console.log("val: " , val);
        },
        // "cliente.messageCliente": function (val) {
        //         if(val.clase == "toast-success"){
        //             this.clienteList();
        //         }
        //     }
            // despues de actualizar la variable mensaje despues de guardar lanza notificacion
      "cliente.messageCliente": function (val) {
        if(val.mensaje != ""){
          this.generateAlert(val.mensaje, val.clase);
        }
        if(val.clase == "toast-success"){
          this.cancel();
        }
      },
    },
    computed:{
      ...mapState([ "cliente", "auth"]),
      p_moral(){
        return this.cliente.getCliente.persona_moral == 1 ? true : false;
      }
    },
    methods:{
      ...mapActions([ "clienteGet", "clienteUpdate", "clienteList","regimenFiscalList" ]),
     
      // cierra el modal
      cancel() {
        this.modalShow = false;
        this.loading = false;
        this.$store.commit("SET_COMMIT_CLIENTE_EDIT", 0);
        this.$store.commit("SET_COMMIT_GET_CLIENTES", 0);
        this.clienteList();
        return this.modalShow;
      },
      // setea todo el array
      setDetailRequisicionArray() {
        this.loading = true;
        
        this.cancel();
      },
      clearDigit(value){
        return Number(value.replace(/[^0-9.-]+/g,""));
      },
      realizarRegistro(){
        let data = {
          id_cliente: this.cliente.editIdCliente ?  this.cliente.editIdCliente : "NO TECLEADO",
          rfc:this.cliente.getCliente.rfc ? this.cliente.getCliente.rfc : "NO TECLEADO",
          nombre_razon_social: this.cliente.getCliente.nombre_razon_social ?  this.cliente.getCliente.nombre_razon_social : "NO TECLEADO",
          direccion:this.cliente.getCliente.direccion ? this.cliente.getCliente.direccion : "NO TECLEADO",
          colonia:this.cliente.getCliente.colonia ? this.cliente.getCliente.colonia : "NO TECLEADO",
          estado:this.cliente.getCliente.estado ? this.cliente.getCliente.estado : "NO TECLEADO",
          ciudad: this.cliente.getCliente.ciudad ?  this.cliente.getCliente.ciudad : "NO TECLEADO",
          limite_credito:this.cliente.getCliente.limite_credito ? this.cliente.getCliente.limite_credito : "NO TECLEADO",
          persona_moral: this.cliente.getCliente.persona_moral ? 1 : 0,
          estatus: 1,
          telefono:this.cliente.getCliente.telefono ? this.cliente.getCliente.telefono : "NO TECLEADO",
          calle:this.cliente.getCliente.calle ? this.cliente.getCliente.calle : "NO TECLEADO",
          no_interior:this.cliente.getCliente.numero_interior ? this.cliente.getCliente.numero_interior : "NO TECLEADO",
          no_exterior:this.cliente.getCliente.numero_exterior ? this.cliente.getCliente.numero_exterior : "NO TECLEADO",
          municipio:this.cliente.getCliente.municipio ? this.cliente.getCliente.municipio : "NO TECLEADO",
          pais:this.cliente.getCliente.pais ? this.cliente.getCliente.pais : "NO TECLEADO",
          cp:this.cliente.getCliente.codigo_postal ? this.cliente.getCliente.codigo_postal : "NO TECLEADO",
          e_mail: this.cliente.getCliente.e_mail,
          id_usuario:this.auth.user.idUser,
          id_regimen_fiscal: this.cliente.getCliente.idRegimenFiscal
        }
        console.log("form: " , data);
        this.clienteUpdate(data);
      },
    },
  };
</script>
<style>
</style>