import { actions } from './actions'
import { mutations } from './mutations'

export default {
    state: {
        cliente: false,
        messageCliente: { mensaje: "", clase: "" },
        editIdCliente: 0,
        getCliente: {},
        clienteGet: [],
        clienteList: [],
        regimenFiscalList: []

    },
    mutations,
    actions,
};