export const mutations = {
    SET_MESSAGE_ORDEN_COMPRA(state,msj){
        state.messageOrdenCompra = msj
    },
    SET_COBRO(state,res){
        state.cobro = res
    },

    SET_COMMIT_COMPRA_CLEAR(state){
        state.cajaCompra = [];
    },
    SET_COMPRA_DELETE(state, element){
        state.cajaCompra.splice(element, 1);
    },
    SET_COMPRA_EDIT(state, id){
        state.editIdCompra = id;
    },
    SET_COMMIT_IMG_COMPRA(state, val){
        state.imagenCompra = val;
    },
    SET_COMMIT_TOTAL_ORDENCOMPRA(state, element){
        state.totalCompra.subtotal = element.subtotal;
        state.totalCompra.iva = element.iva;
        state.totalCompra.total = element.total;
    },
    SET_COMMIT_COMPRA_ADD(state, element){
        state.cajaCompra.push(element);
    },
    SET_COMMIT_PROVEEDORES_LIST(state, element){
        state.proveedoresList = element
    },
}