/*eslint-disable no-unused-vars*/
export const mutations = {
    SET_MESSAGE_CLIENTE(state, msj) {
        state.messageCliente = msj;
    },
    SET_COMMIT_SHOW(state, val) {
        state.show = val;
    },
    SET_COMMIT_CLIENTES(state, res) {
        state.clienteList = res;
    },
    SET_COMMIT_GET_CLIENTES(state, val) {
        state.getCliente = val;
    },
    SET_COMMIT_CLIENTE_ADD(state, element) {
        state.registroCliente.push(element);
    },
    SET_COMMIT_CLIENTE_EDIT(state, id) {
        state.editIdCliente = id;
    },
    SET_COMMIT_CLIENTE_DELETE(state, element) {
        state.clienteList.splice(element, 1);
    },
    SET_COMMIT_LOAD_CLIENTE(state, val) {
        state.loadCliente = val;
    },
    SET_COMMIT_REGIMEN_FISCAL(state, res) {
        state.regimenFiscalList = res;
    },
};