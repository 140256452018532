/*eslint-disable no-unused-vars*/
export const mutations = {
    SET_MESSAGE(state,msj){
        state.message = msj
    },
    SET_MESSAGE_DEVOLUCION(state,msj){
        state.messageDevolucion = msj
    },
    SET_DEVOLUCION(state,res){
        state.devolucion = res
    },
    SET_COMMIT_ARTICULOS_DEVOLUCION(state, res){
        state.articulosList = res;
    },
    SET_COMMIT_VENTADETAIL(state, res){
        state.ventaDetailList = res;
    },
    SET_COMMIT_VENTAHEADER(state, res){
        state.ventaHeader = res;
    },
    SET_COMMIT_LOAD_COMPRAS(state, val){
        state.loadCompras = val;
    },
    SET_COMMIT_MONTO(state, val){
        state.monto = val;
    },
    // agrega un nuevo elemento al array
    SET_COMMIT_ARRAY_NUEVO(state, element){
        state.arrayNuevo.push(element);
    },
    // limpia todo el array 
    SET_COMMIT_CLEAR_ARRAY(state, element){
        state.arrayNuevo = [];
    },
    // borra un elemento dentro del array
    SET_ARRAY_NUEVO_DELETE(state, element){
        state.arrayNuevo.splice(element, 1);
    },
};