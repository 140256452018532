<template>
  <b-container fluid>
    <b-col class="p-0 pb-5 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
        
        <b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
           
        </b-row>
        
        <Cliente />
        
    </b-col>
  </b-container>
</template>

<script>
import Cliente from "./Cliente"

import { mapState, mapActions } from "vuex";

export default {
    components: { Cliente },
     mounted(){
        this.$store.commit("SET_COMMIT_CLIENTE_EDIT", 0);
        
    },
    data() {
        return {
            
        }
    },
    computed: mapState(["cliente"]),
    methods: {
        ...mapActions([]),
       
    }
}
</script>