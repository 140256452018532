<template>
<b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
    <b-col class="col-md-12">

        <b-card header-tag="header" class="mt-3 card-mini">
             <b-card header-tag="header" class="mt-1 card-mini" style="height: 12.5rem; background-color: #E5E5E5; color: black;">
        <b-row>
                <b-col class="col-md-8" >
                    <h2>Registro articulos</h2>
                </b-col>



                <b-input-group class="mt-3">
                    <b-col class="col-md-3">
                        <Autocomplete class="mr-1" placeholder="Selecciona una opción"  
                        cols="col-md-12"
                        :items="articulos.categoriasList" text="categoria" 
                        v-model="id_categoria" 
                        filter="categoria" assign="id_categoria" label="Categoria:" 
                        />
                    </b-col>

                    <b-col class="col-md-3">
                        <Autocomplete class="mr-1" placeholder="Selecciona una opción"  
                        cols="col-md-12"
                        :items="articulos.subcategoriasList" text="sub_categoria" 
                        v-model="id_sub_categoria" 
                        filter="sub_categoria" assign="id_sub_categoria" label="Subcategoria:" 
                        />
                    </b-col>   
                    
                    <b-col class="col-md-4" style="margin-top: 30px;">
                        
                        <b-input-group>
                            <!-- <b-input-group-append>
                                <Boton tooltip="Buscar" icon="search" fontscale="2" size="sm"/>
                            </b-input-group-append> -->
                            <b-form-input @focus="$event.target.select()" v-model="search" 
                            required autocomplete="off" 
                            type="text" 
                            class="form-control" 
                            placeholder="Buscar"  
                            aria-describedby="basic-addon"></b-form-input>
                                    <!--  -->
                        </b-input-group> 
                    </b-col>

                    <b-col class="col-md-1" style="margin-top: 26px;">
                        <Boton 
                        :onClic="agregar"  
                        tooltip="Agregar" 
                        style="background-color: #FF5923;"
                        icon="plus-circle"
                        fontscale="2" size=""/>
                            <!-- :disabled="id_sub_categoria === 0":valorEventClic="item.id_unidad"  -->
                    </b-col>

                </b-input-group>
  
        </b-row>
<b-row> 
  
</b-row>
<Modal />
    </b-card>  
          <ItemsList class="mt-3"
          v-if="id_categoria != 0"
         :actions="true" 
         :search="search"
         :items="articulos.articulosList"
         :articulosList="articulosList"
                SET_COMMIT_SELECT="SET_COMMIT_ARTICULOS"
          SET_COMMIT_DELETE="SET_COMMIT_ARTICULO_LIST_DELETE"
               SET_COMMIT_EDIT="SET_COMMIT_EDIT_ARTICULOS_LIST" 
            /> 
            <!-- 
                
                 :onClic="clic" -->
<ModalEditar />
        </b-card>

    </b-col>
    <b-col class="col-md-3">
    </b-col>
</b-row>
</template>
<script>
         import ModalEditar from "./ModalEditarArticulo"
         import Autocomplete from '@/components/Autocomplete'
    import ItemsList from '@/components/ItemsListCrudArticulos'
        import Boton from '@/components/Boton'
    import Modal from '@/components/ModalArticulo'
    import { mapState, mapActions } from "vuex";

    export default {
        mounted(){
            this.$store.commit("SET_COMMIT_ARTICULOS", []);
            this.$store.commit("SET_ID_CATEGORIA", 0);
            this.$store.commit("SET_ID_SUBCATEGORIA", 0);
            this.$store.commit("SET_COMMIT_CATEGORIAS", []);
            this.$store.commit("SET_COMMIT_SUBCATEGORIAS", []);
            //  if (this.id_categoria >= 1) {
            //     this.articulosList();
            //  }
            this.categoriaListCrud();
        },
        components:{ ItemsList, ModalEditar ,Boton, Modal, Autocomplete },
        data(){
            
            return{
                modalShow: false,
                search: "",
                id_categoria:" ",
                id_sub_categoria:" ",
               
            }
        },
        watch:{
             "id_categoria": function (val) {
                if(val){
                    // console.log('id_categoria', val);
                    //Carga el list
                    // this.articulosList(val);
                    //Carga el select
                    this.subCategoriaListCrud(val);
                    //Lanzar commit
                    this.$store.commit("SET_ID_CATEGORIA", val);
                }
            },
             "id_sub_categoria": function (val) {
                if(val){
                    // console.log('id_sub_categoria', val);
                    //Carga el list
                    this.articulosList(val);
                    //Carga el select
                    // this.subCategoriaListCrud(val);
                    //Lanzar commit
                    this.$store.commit("SET_ID_SUBCATEGORIA", val);
                }
            },
        },
        computed: mapState([ "articulos", "caja" ]),
        methods:{
            ...mapActions([ "subCategoriaListCrud", "categoriaListCrud","categoriasList","subcategoriasList","cajaProductos" ,"articulosList", "SET_COMMIT_CATEGORIAS", "SET_COMMIT_ARTICULOS", "SET_COMMIT_SUBCATEGORIAS"]),
            agregar(){//item
                this.$bvModal.show('modal');
            },
             
           getCategoria(id){
               let searchRes = this.articulos.categoriasList.filter(item=>item.id_categoria === id)
               return searchRes[0]
           },
        }
    }
</script>