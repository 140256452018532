<template>
    <b-card header-tag="header" class="mt-1 card-mini" style="height: 11.5rem; background-color: #dfedd6; color: black;">
        <b-row >
            <!-- <b-col class="col-md-12"> -->

                <b-row class="col-md-12">
                    <!-- <b-row class="row ml-3" align-h="end" align-w="end"> -->
                    <b-col class="col-md-6"><h2>Venta de Contado</h2></b-col>
                    <!-- </b-row> -->

                    <!-- <b-col class="col-md-6">
                        <b-row align-v="end" align-h="end" >
                            <b-badge v-if="contado.tipoVenta == 1" v-b-tooltip.hover title="Presione la tecla ALT + s para activar la venta especial" 
                            variant="secondary">Especial</b-badge>
                            <b-badge v-else v-b-tooltip.hover title="Presione la tecla ALT + s para desactivar la venta especial"
                            variant="success">Especial</b-badge>
                        </b-row>
                    </b-col> -->
                    
                </b-row>
            <!-- </b-col> -->
            <b-col class="col-md-4 mb-3">
                <Autocomplete search="true" placeholder="Selecciona una opción" cols="col-md-12"
                :items="venta.clientesList" text="nombre_razon_social" v-model="id_cliente" assign="id_cliente"
                label="Cliente:" filter="nombre_razon_social" />
                
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
    import Autocomplete from '@/components/Autocomplete'
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
            this.clientesList();
        },
        watch: {
            // establece por default el PUBLIC EN GENERAL
            "venta.clientesList": function (val) {
                if(val){ this.id_cliente = 24; }
            },
            "id_cliente": function (val) {
                if(val){
                    let cliente = this.getCliente(val);
                    // actualiza la variable clienteSelected
                    this.$store.commit("SET_COMMIT_CLIENTE_SELECTED", cliente)
                }
            },
            // despues de actualizar la variable message y fue exitoso limpia cliente selected
            "caja.messageCaja": function (val) {
                if(val.clase == "toast-success"){
                    this.id_cliente = "";
                }
            }
        },
        components:{ Autocomplete },
        props:[ ],
        data(){
            return{ search:'', id_cliente:"" }
        },
        computed: mapState([ "venta", "caja", "contado"]),
        methods:{
            ...mapActions(["clientesList"]),
            // obtiene el cliente seleccionado
            getCliente(id){
                let searchRes = this.venta.clientesList.filter(item=>item.id_cliente === id)
                return searchRes[0]
            }
        }
    }
</script>