import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth'

import cortecaja from './cortecaja'
import facturacion from './facturacion'
import devolucion from './devolucion'
import cotizacion from './cotizacion'
// import factura from './factura';
import venta from './venta'
import contado from './contado'
import cobro from './cobro'
import cliente from './cliente'
import articulo from './articulo'
import proveedor from './proveedor'
import ordencompra from './ordencompra'
import ordencomprainventario from './ordencomprainventario'
import ingreso from './ingreso'
import impresora from './impresora'

//components
import Layout from '../layout'
import Caja from '../pages/caja'

Vue.use(Router)

const router = new Router({
    base: process.env.BASE_URL,
    routes: [{
            path: '*',
            redirect: '/login'
        },
        {
            path: '/caja',
            component: Layout,
            meta: { requiresAuth: true },
            children: [{
                    path: '/',
                    component: Caja,
                    name: 'caja',
                },
                ...cortecaja,
                ...facturacion,
                ...devolucion,
                ...cotizacion,
                // ...factura,
                ...venta,
                ...contado,
                ...cobro,
                ...cliente,
                ...articulo,
                ...proveedor,
                ...ordencompra,
                ...ordencomprainventario,
                ...ingreso,
                ...impresora
            ]
        },
        ...auth
    ]
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('aceromax_HASH') === null || localStorage.getItem('aceromax_HASH') === '') next({ path: '/login' })
        else next()
    } else next()
})

export default router;