/*eslint-disable no-unused-vars*/
import { abono_mov_create } from '@/api/cobro'
import { cliente_abonos_list } from '@/api/cliente'

// import router from '@/router'

export const actions = {
    // lista las categorias con imagen
    clienteAbonosList: async({ rootState, commit }, id_cliente) => cliente_abonos_list(rootState.auth.user.idUser, id_cliente).then(
        res=>{
            let totalAdeudo = 0;
            res.data.forEach(element => {
                element.cobro = 0;
                totalAdeudo = totalAdeudo + element.adeudo;
                // console.log("element.adeudo: ", element.adeudo)
            });
            commit("SET_TOTAL_ADEUDO_CLIENTE", totalAdeudo);
            commit("SET_LIST_CLIENTES_ABONO", res.data);
        }).catch(err=>console.log(err)
    ),
    abonoMovCreate: async({ commit }, datos) => abono_mov_create(datos).then(
        res=>{
            // console.log("abonoMovCreate: " , res.data);
            commit("SET_MESSAGE_COBRO",{mensaje: res.data.message,
                clase: (res.data.message === "EXITO") ? "toast-success" : "toast-danger"})
        }).catch(err=>console.log(err)
            
    ),
    // dispatch
    sendCobros:({ commit, dispatch, state, rootState})=>{

        rootState.cobro.clientesAbonos.forEach(element => {
            if(element.cobro != "" && element.cobro != "0" && element.cobro != " " && element.cobro != 0){
                let datos = {
                    id_venta: element.id_venta,
                    cobro: element.cobro,
                    id_tipo_pago: 1,
                    id_usuario: rootState.auth.user.idUser,
                }
                dispatch("abonoMovCreate", datos);
                console.log("element: " , element);
            }
        });

        commit("SET_LIST_CLIENTES_ABONO", []);
        commit("SET_COMMIT_CLIENTE_SELECTED", {nombre_razon_social:"No Seleccionado", limite_credito: "0.00"})
        console.log("State: " , state);
    },
    
};