/*eslint-disable no-unused-vars*/
import { reporte_cotizacion } from '@/api/cotizacion'
// import router from '@/router'
export const actions = {
    
    reporteCotizacion:({ state, rootState }, datos)=>{
        console.log("reporteCotizacion: " , datos);
        return reporte_cotizacion(
            datos.id_venta, rootState.auth.user.idUser,
            datos.cantidad, datos.precio, datos.importe)
    },
};