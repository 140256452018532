<template>
  <div class="row h-100">

      <FormLogin/>
    
    <div
      class="col-sm-11 my-auto mx-auto text-right"
      style="margin-bottom: 30px !important; padding: 0px !important"
    >
      <span class="text-secondary text-small mr-2">Diseñado y desarrollado por Janambre Studios</span>
      <b-link href="https://janambrestudios.mx/" target="_blank">
        <b-img :src="require('@/assets/img/logo-janambre.png')" fluid class="logo-janambre mr-3"></b-img>
      </b-link>
    </div>
  </div>
</template>

<script>
import FormLogin from './FormLogin'

export default {
    components:{
        FormLogin
    }
}
</script>