/*eslint-disable no-unused-vars*/
export const mutations = {
    SET_MESSAGE(state,msj){
        state.message = msj
    },
    SET_FACTURACION(state,res){
        state.facturacion = res
    },
    SET_CORTE_CAJA_LIST(state,res){
        state.listCorteCaja = res
    }
    
};