import { HTTP } from "./http-common";

export const factura_general_create = (data) => new Promise ((rv, rj) => {
    HTTP.post("factura-general-create/", data).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const factura_create = (data) => new Promise ((rv, rj) => {
    HTTP.post("factura-create/", data).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const abonos_factura_list = (fecha_ini, fecha_fin, id_usuario)  => new Promise ((rv, rj) => {
    HTTP.get("abonos-factura-list/?fecha_ini=" + fecha_ini + "&fecha_fin=" + fecha_fin + "&id_usuario=" + id_usuario).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const ventas_factura_list = (fecha_ini, fecha_fin, id_usuario)  => new Promise ((rv, rj) => {
    HTTP.get("ventas-factura-list/?fecha_ini=" + fecha_ini + "&fecha_fin=" + fecha_fin + "&id_usuario=" + id_usuario).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});