/*eslint-disable no-unused-vars*/
export const mutations = {
    SET_MESSAGE(state,msj){
        state.message = msj
    },
    SET_USER(state,user){
        state.user = user
    },
    SET_URL_IMPRESORAA(state,res){
        state.user.URL_IMPRESORA = res
    }
};