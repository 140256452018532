<template>
    <b-card header-tag="header" class="mt-3 card-mini">

        <!-- <template v-slot:header> -->
            <!-- <b-row class="justify-content-md-center"> -->
                <!-- INFORMACIÓN -->
            <!-- </b-row> -->
        <!-- </template> -->


        <!-- <b-col class="col-md-12 mb-2"> -->
        <!-- <b-list-group-item> -->
            <b-row class="justify-content-md-center" v-b-tooltip.hover title="Cliente">
                <b-avatar button variant="primary" :text="iniciales" class="align-baseline"></b-avatar>
            </b-row>
            <b-row class="justify-content-md-center mb-2" >
                <strong class="">{{contado.clienteSelectedVar.nombre_razon_social}}</strong>
            </b-row>
            <b-row class="justify-content-md-center mb-2" >
                <strong class="">facturacion_fac@aceromax.com</strong>
            </b-row>
            <b-row class="justify-content-md-center mb-2" v-b-tooltip.hover title="RFC">
                <strong class="">{{ contado.clienteSelectedVar.rfc }}</strong>
            </b-row>
            

            <!-- <b-form-group id="input-group-1" label="Cobro:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="md" prepend="$">
                <b-form-input @focus="$event.target.select()" type="number" v-model.number="cantidadCobro" min="0.00"></b-form-input>
                
              </b-input-group>
            </b-form-group> -->

            
            <b-col class="col-md-12">
                <b-form-group>
                    <b-row>
                        <b-row class="row mt-2 justify-content-md-center" align-v="start" align-h="start">
                            
                            <b-col class="col-md-5 mr-2">
                                <Boton text="Cancelar" tooltip="Cancelar cobro"
                                icon="x-circle-fill" fontscale="2" :onClic="cancelarCobro" variant="info" btn="btn-cancel" size="sm"/>
                            </b-col>
                        
                            <b-col class="col-md-5 ml-2">
                                <Boton text="Guardar" tooltip="Realizar cobro"
                                :disabled="contado.clienteSelectedVar.nombre_razon_social==='No Seleccionado' || cobro.clientesAbonos.length == 0"
                                icon="lock-fill" fontscale="2" :onClic="enviarCobros" variant="info" btn="btn-secundary" size="sm"/>
                            </b-col>
                            
                        </b-row>

                    </b-row>
                </b-form-group>

            </b-col>
    </b-card>
</template>

<script>
    import Boton from '@/components/Boton'
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
            this.$store.commit("SET_LIST_CLIENTES_ABONO", []);
        },
        components:{ Boton },
        data(){
            return{ search:"", cantidadCobro: '' }
        },
        computed: {
            // se ocupa contado para acceder al cliente selecionado
            // se ocupa auth para acceder al id usuario
            // se ocupa cobro para acceder a las variables de cobro
            ...mapState([ "contado", "auth", "cobro"]),
            iniciales(){
                let separado = this.contado.clienteSelectedVar.nombre_razon_social.split(" ");
                return separado[0].charAt(0) + separado[1].charAt(0);
            }
        },
        watch:{
            // despues de actualixar vairbale messaje muestra el mensaje
            "cobro.messageCobro": function (val) {
                // muestra alerta
                this.generateAlert(val.mensaje, val.clase);
                if(val.clase == "toast-success"){
                    // limpia cliente seleccionado
                    this.$store.commit("SET_COMMIT_CLIENTE_SELECTED", {nombre_razon_social:"No Seleccionado", limite_credito: "0.00"})
                    this.cantidadCobro = ''
                    // this.cancel();
                }
            },
        },
        methods:{
            ...mapActions(["sendCobros"]),
            cancelarCobro(){
                this.generateAlert("Cobro cancelado", "toast-success");
                this.$store.commit("SET_LIST_CLIENTES_ABONO", []);
                this.$store.commit("SET_COMMIT_CLIENTE_SELECTED", {nombre_razon_social:"No Seleccionado", limite_credito: "0.00"})
                this.cantidadCobro = ''
            },
            enviarCobros(){
                this.sendCobros();
                this.$store.commit("SET_TOTAL_ADEUDO_CLIENTE", "0.00")
                // setTimeout(async ()=>{
                //     dispatch("successProveedor");
                // },800)
                // this.cancelarCobro();
            }
        }
    }
</script>