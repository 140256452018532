<template>
    <b-button v-b-tooltip.hover :title="tooltip" type="button" :size="size" :class="btn"
        :variant="variant" @click="onClic(valorEventClic)" :disabled="disabled">
        <b-icon v-show="!text" :icon="icon" :font-scale="fontscale"></b-icon>
        <b-col v-show="text" style="color:#ffff;" cols="12" class="text-center">
            <b-icon :icon="icon" :font-scale="fontscale"></b-icon>
        </b-col>
        <b-col v-show="text" style="color:#ffff;" cols="12" >{{text}}</b-col>

        <b-spinner v-if="loading" label="Spinning"></b-spinner>
    </b-button>
</template>
// el valorEventClic es cuando se requiere recibir un parametro al dar clic al boton, este parametro no es obligatorio
<script>
export default {
    props:[ "text","tooltip", "icon", "fontscale", "variant", "btn", "size", "onClic", "disabled", "loading" , "valorEventClic" ],
}
</script>
<style scoped>
</style>