import articulos from '../pages/articulos';
import articulos_actualizar from '../pages/articulos_actualizar';

export default [
    {
      path: '/articulos/crear',
      component: articulos,
      name: 'crearArticulo',
      
    },
    {
      path: '/articulos/actualizar',
      component: articulos_actualizar,
      name: 'actualizarArticulo',
      
    }
]