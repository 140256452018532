<template>
<b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
    <b-col class="col-md-9">

        <b-card header-tag="header" class="mt-3 card-mini">
         <Titulo  />   

            <ItemsList 
                tituloItemsEmpty="Carrito vacío" 
                :actions="true" :items="devolucion.ventaDetailList"
                SET_COMMIT_SELECT="SET_COMMIT_VENTADETAIL"
                :load="devolucion.loadCompras"
                :materialSelect="materialSelect"
                idTemplate="devolucion" search
                perPage="" currentPage=""
            /> 

        </b-card>

    </b-col>
    <b-col class="col-md-3">
        <Cliente  />
    </b-col>
</b-row>
</template>

<script>
    import Titulo from "./Titulo"
    import Cliente from "./Cliente"
    import ItemsList from '@/components/ItemsListDevolucion'
    import { mapState, mapActions } from "vuex";

    export default {
        mounted(){
            // limpia el array nuevo
            this.$store.commit("SET_COMMIT_CLEAR_ARRAY");
            // limpia el item list al entrar a la pantalla
            this.$store.commit("SET_COMMIT_VENTADETAIL", []);
            // limpia el venta header 
            this.$store.commit("SET_COMMIT_VENTAHEADER", { fecha:"", total:0 });

            this.$store.commit("SET_COMMIT_LOAD_PRODUCTOS", true);
        },
        components:{ Titulo, Cliente, ItemsList },
        data(){
            return{ }
        },
        computed: mapState([ "devolucion" ]),
        methods:{
            ...mapActions([""]),

            materialSelect(item){
                 if(item.check){
                    //  agrega el nuevo elemento al array
                     this.$store.commit("SET_COMMIT_ARRAY_NUEVO", item);
                 }else{
                    //  borra el elemento del array
                     this.$store.commit("SET_ARRAY_NUEVO_DELETE",this.devolucion.ventaDetailList.indexOf(item.id_articulo))
                 }
            },
        }
    }
</script>