import ingreso from '../pages/ingreso';

export default [
    {
      path: '/ingreso/crear',
      component: ingreso,
      name: 'crearIngreso',
      
    }
]
