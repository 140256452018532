<template :id="idTemplate" :per-page="perPage" :current-page="currentPage">
    <div  >
        <!-- listado de items -->
        <b-col v-b-scrollspy:scrollspy-nested v-if="items.length > 0" class="col-md-12 " id="scrollspy-nested" style="max-height: 570px; overflow-y:auto">
            <b-row class="mt-1 mousehoverItem animate__animated animate__bounceIn" v-for="(item, index) in itemsPaginated" v-bind:key="index">
                
                <b-col v-b-toggle="'colapse-desc-'+(items.indexOf(item) + 1)" class="col-md-2">
                    <b-row >
                        <b-col cols="12" class="p-0 pt-2 text-titulo" >
                            <h1 class="text-center w-100" >{{ items.indexOf(item) + 1 }}</h1>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col v-b-toggle="'colapse-desc-'+(items.indexOf(item) + 1)" class="col-md-4">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >CÓDIGO</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.clave }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col v-b-toggle="'colapse-desc-'+(items.indexOf(item) + 1)" class="col-md-3">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >UNIDAD</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.unidad.unidad }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col v-b-toggle="'colapse-desc-'+(items.indexOf(item) + 1)" class="col-md-3">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >CANTIDAD</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.cantidad }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col v-b-toggle="'colapse-desc-'+(items.indexOf(item) + 1)" class="col-md-4">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >PRECIO</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ toMoneda(item.precio) }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col v-b-toggle="'colapse-desc-'+(items.indexOf(item) + 1)" class="col-md-4">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >DESCUENTO</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ item.descuento }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col v-b-toggle="'colapse-desc-'+(items.indexOf(item) + 1)" class="col-md-4">
                    <b-row >
                        <b-col cols="12" class="p-1 text-titulo text-shadow" >
                            <strong >IMPORTE</strong>
                        </b-col>
                        <b-col cols="12" class="text-shadow p-2 text-descripcion" >
                                {{ toMoneda(item.importe) }}
                        </b-col>
                    </b-row>
                </b-col>

                <!-- oculta descripcion y acciones -->
                <b-collapse v-if="collapse" :id="'colapse-desc-'+(items.indexOf(item) + 1)" class="col-md-12">
                    <!-- <b-col class="col-md-12"> -->
                        <b-row >
                            <b-col cols="12" class="p-1 pl-2 text-titulo text-shadow mb-0">
                                <strong >DESCRIPCIÓN</strong>
                            </b-col>
                            <b-col cols="9" class="p-2 text-shadow text-descripcion" style="margin-top: 0px !important;">
                                <b-collapse :visible="item.descripcion.length <= 171" :id="'colapse-carrito-'+(items.indexOf(item) + 1)">
                                    {{ item.descripcion }}
                                </b-collapse>
                                <b-collapse :visible="item.descripcion.length >= 172" :id="'colapse-carrito-'+(items.indexOf(item) + 1)">
                                    {{ toggleText(item.descripcion) }}
                                </b-collapse>
                            </b-col>
                            <b-col cols="3" class="p-1 pl-2 text-shadow text-descripcion" style="margin-top: 0px !important;">
                                <b-collapse :visible="item.descripcion.length >= 172" :id="'colapse-carrito-'+(items.indexOf(item) + 1)">
                                    <b-button 
                                        v-b-toggle="'colapse-carrito-'+(items.indexOf(item) + 1)"
                                        variant="link" class="m-0 p-0">
                                        Ver más
                                    </b-button>
                                </b-collapse>
                                <b-collapse :visible="item.descripcion.length <= 171" :id="'colapse-carrito-'+(items.indexOf(item) + 1)">
                                    <b-button 
                                        v-b-toggle="'colapse-carrito-'+(items.indexOf(item) + 1)"
                                        variant="link" class="m-0 p-0">
                                        Ocultar
                                    </b-button>
                                </b-collapse>
                            </b-col>
                        </b-row>
                    <!-- </b-col> -->
                    <!-- <b-col v-show="actions" class="col-md-3 col-sm-3 col-xl-12"> -->
                        <b-row v-if="actions" >
                            <b-col class="p-1 pl-2 text-titulo text-left text-shadow mb-0 col-md-12" >
                                <strong >ACCIONES</strong>
                            </b-col>
                            <b-col class="justify-content-md-center text-shadow text-descripcion col-md-12" >
                                <b-row class="justify-content-md-center m-1" align-v="center">
                                    <b-col class="col-md-2 col-sm-2 col-xl-2">
                                        <Boton :tooltip="`Eliminar ${item.clave} del carrito`"
                                            icon="trash-fill" fontscale="1" :onClic="eliminar" :valorEventClic="item"
                                            variant="danger" btn="btn-cancel" size="sm" />
                                    </b-col>
                                    <b-col class="col-md-2 col-sm-2 col-xl-2">
                                        <Boton :tooltip="`Editar ${item.clave} del carrito`"
                                            icon="pencil-square" fontscale="1" :onClic="edit" :valorEventClic="item.id_articulo"
                                            variant="primary" btn="btn-default" size="sm" />
                                    </b-col>
                                    <b-col class="col-md-2 col-sm-2 col-xl-2">
                                        <Boton :tooltip="`Ver imagen de ${item.clave}`" :onClic="verImagen" :valorEventClic="item.imagen"
                                            icon="image" fontscale="1" variant="primary" btn="btn-detail" size="sm" />
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    <!-- </b-col> -->
                </b-collapse>

                <b-col v-if="!collapse" class="col-md-12">
                    <b-row >
                        <b-col cols="12" class="p-1 pl-2 text-titulo text-shadow mb-0">
                            <strong >DESCRIPCIÓN</strong>
                        </b-col>
                        <b-col cols="9" class="p-2 text-shadow text-descripcion" style="margin-top: 0px !important;">
                            <b-collapse :visible="item.descripcion.length <= 171" :id="'colapse-carrito-'+(items.indexOf(item) + 1)">
                                {{ item.descripcion }}
                            </b-collapse>
                            <b-collapse :visible="item.descripcion.length >= 172" :id="'colapse-carrito-'+(items.indexOf(item) + 1)">
                                {{ toggleText(item.descripcion) }}
                            </b-collapse>
                        </b-col>
                        <b-col cols="3" class="p-1 pl-2 text-shadow text-descripcion" style="margin-top: 0px !important;">
                            <b-collapse :visible="item.descripcion.length >= 172" :id="'colapse-carrito-'+(items.indexOf(item) + 1)">
                                <b-button 
                                    v-b-toggle="'colapse-carrito-'+(items.indexOf(item) + 1)"
                                    variant="link" class="m-0 p-0">
                                    Ver más
                                </b-button>
                            </b-collapse>
                            <b-collapse :visible="item.descripcion.length <= 171" :id="'colapse-carrito-'+(items.indexOf(item) + 1)">
                                <b-button 
                                    v-b-toggle="'colapse-carrito-'+(items.indexOf(item) + 1)"
                                    variant="link" class="m-0 p-0">
                                    Ocultar
                                </b-button>
                            </b-collapse>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col v-if="actions && !collapse" class="col-md-12 col-sm-12 col-xl-12 ">
                    <b-row >
                        <b-col class="p-1 pl-2 text-titulo text-left text-shadow mb-0 col-md-12" >
                            <strong >ACCIONES</strong>
                        </b-col>
                        <b-col class="justify-content-md-center text-shadow text-descripcion col-md-12" >
                            <b-row class="justify-content-md-center mb-1 mt-1" align-v="center">
                                <b-col class="col-md-2 col-sm-2 col-xl-2 ml-3">
                                    <Boton :tooltip="`Eliminar ${item.clave} del carrito`"
                                        icon="trash-fill" fontscale="1" :onClic="eliminar" :valorEventClic="item"
                                        variant="danger" btn="btn-cancel" size="sm" />
                                </b-col>
                                <b-col class="col-md-2 col-sm-2 col-xl-2 ml-3">
                                    <Boton :tooltip="`Editar ${item.clave} del carrito`"
                                        icon="pencil-square" fontscale="1" :onClic="edit" :valorEventClic="item.id_articulo"
                                        variant="primary" btn="btn-default" size="sm" />
                                </b-col>
                                <b-col class="col-md-2 col-sm-2 col-xl-2 ml-3">
                                    <Boton :tooltip="`Ver imagen de ${item.clave}`" :onClic="verImagen" :valorEventClic="item.imagen"
                                        icon="image" fontscale="1" variant="primary" btn="btn-detail" size="sm" />
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>

            </b-row>
        </b-col>
        <!-- muestra que está vacío -->
        <b-col v-else class="col-md-12" >
            <b-row align-h="center" align-w="center" class="mb-1 mt-5" v-b-tooltip.hover title="Carrito vacío">
                <b-avatar icon="cart3" style="width: 170px; height: 170px;"></b-avatar>
                <!-- <b-avatar style="width: 170px; height: 170px;">
                    <b-icon icon="cart3" animation="fade" style="width: 120px; height: 120px;"></b-icon>
                </b-avatar> -->
            </b-row>
            <b-row align-h="center" align-w="center" class="" >
                <h3 v-if="signos"><strong class="">¡{{tituloItemsEmpty}}!</strong></h3>
                <h3 v-else><strong class="">{{tituloItemsEmpty}}</strong></h3>
            </b-row>
        </b-col>


        <!-- no se requiere la paginacion en carrito -->
        <!-- <b-col class="col-md-12">
            <div class="mt-3 justify-content-md-center" v-show="items.length > 0">
                <b-pagination v-model="currentPage" :total-rows="searchInDetail().length" align="center" 
                    :per-page="perPage"
                    :aria-controls="idTemplate">
                </b-pagination>
            </div>
        </b-col> -->
    </div>
</template>
<script>
    import Boton from '@/components/Boton'
    export default {
        props:["signos", "tituloItemsEmpty", "perPage", "currentPage", "items", "SET_COMMIT_IMG", "SET_COMMIT_DELETE", "SET_COMMIT_EDIT", "idTemplate", "search", "actions", "collapse", "updateTotalCarrito" ],
        data() {
            return {
            };
        },
        components:{ Boton },
        computed:{
            // ...mapState([ ]),
            // paginación
            itemsPaginated(){ 
                return this.searchInDetail()
            }
        },
        watch: {
            // "items.id": function (val) {
            //     if(val){
            //         console.log("val: " , val);
            //     }
            // },
            "items": function () {
                // console.log("CARRITO: " , this.items);
                // actualiza el total de la venta
                this.updateTotalCarrito();
            },
        },
        methods:{
            // busqueda dentro del array
            searchInDetail(){
                return this.items.filter(req => this.search.toLowerCase()
                .split(' ').every(v => this.conditionalFilter(v, req)))
            },
            conditionalFilter(v, req){
                for (const prop in req) {
                    if(req[prop] !== null){
                        if(req[prop].toString().toLowerCase().includes(v)){
                            return true;
                        }
                    }
                }
            },
            // muestra los primeros 172 caracteres del texto
            toggleText(text) {
                let res = "";
                let value = (text.length >= 172) ? 172 : (text.length / 2);
                for (let i = 0; i < value; i++) { res += text[i]; }
                return res += " ...";
            },
            // borra la posocion del array
            eliminar(item) {
                // console.log("eliminar: " , item);
                // console.log("indexOf: " , this.items.indexOf(item));
                this.$store.commit(this.SET_COMMIT_DELETE, this.items.indexOf(item))
            },
            // inicializa la variable id para mostrar en el modal EDITAR, usar watch para accionar el modal
            edit(item) {
                this.$store.commit(this.SET_COMMIT_EDIT, item)
                // console.log("edit: " , item);
            },
            // mostrar la imagen
            verImagen(imagen) {
                // console.log("imagen: " , imagen);
                this.$store.commit(this.SET_COMMIT_IMG, imagen)
            },
            toMoneda(value){
                return value;
                // console.log("value: " , value);
                // return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            },
        }
    };
</script>
<style scoped>
</style>