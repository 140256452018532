<template>
    <b-card header-tag="header" class="mt-1 card-mini" style="height: 9.5rem; background-color: #d4e4fc; color: black;">
        <b-row>
            <b-col class="col-md-6">
                <b-col class="col-md-12" >
                    <h2>Cotización</h2>
                </b-col>
                <b-col class="col-md-12" >
                    <Autocomplete placeholder="Selecciona una opción" cols="col-md-12" search="true"
                :items="venta.clientesList" text="nombre_razon_social" v-model="id_cliente" assign="id_cliente" label="Cliente:" filter="nombre_razon_social" />
                
                </b-col>
            </b-col>

            <b-col class="col-md-6">
                <b-col class="col-md-12" >
                    <b-form-checkbox switch v-model="cotizacion.imprimirPrecioUnitario">Imprimir Precio Unitario</b-form-checkbox>
                    <b-form-checkbox switch v-model="cotizacion.imprimirCantidad">Imprimir Cantidad</b-form-checkbox>
                    <b-form-checkbox switch v-model="cotizacion.imprimirImporte" id="">Imprimir Importe</b-form-checkbox>
                </b-col>
            </b-col>
        </b-row>

        <!-- <b-row >
            <b-col class="col-md-6">
                <b-col class="col-md-12">
                    <b-row>

                        <b-col class="col-md-6">
                            <h2>Cotización</h2>
                        </b-col>
                        <b-col class="col-md-6">

                            <b-form-checkbox switch size="sm">Imprimir Precio Unitario</b-form-checkbox>
                            <b-form-checkbox switch size="sm">Imprimir Cantidad</b-form-checkbox>
                            <b-form-checkbox switch  size="sm">Imprimir Importe</b-form-checkbox>
                            
                        </b-col>

                    </b-row>
                </b-col>
            </b-col>

            <b-col class="col-md-6">
                <b-col class="col-md-4 mb-3">
                    <Autocomplete placeholder="Selecciona una opción" cols="col-md-12"
                    :items="venta.clientesList" text="nombre_razon_social" v-model="id_cliente" assign="id_cliente" label="Cliente:" filter="nombre_razon_social" />
                    
                </b-col>
            </b-col>
        </b-row> -->
    </b-card>
</template>

<script>
    import Autocomplete from '@/components/Autocomplete'
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
            this.clientesList();
        },
        watch: {
            "id_cliente": function (val) {
                if(val){
                    let cliente = this.getCliente(val);
                    // actualiza la variable clienteSelected
                    this.$store.commit("SET_COMMIT_CLIENTE_SELECTED", cliente)
                }
            },
        },
        components:{ Autocomplete },
        props:[ ],
        data(){
            return{ search:'', id_cliente:"", importe: true }
        },
        computed: mapState([ "venta", "cotizacion"]),
        methods:{
            ...mapActions(["clientesList"]),
            // obtiene el cliente seleccionado
            getCliente(id){
                let searchRes = this.venta.clientesList.filter(item=>item.id_cliente === id)
                return searchRes[0]
            }
        }
    }
</script>