//libs
import Vue from 'vue'

//componente principal
import App from './App.vue'

//bootstrapvue
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

//rutas
import router from './router'

//store
import store from './store'

//mixins
import Alert from './mixins/alert'; 
import ExportCSV from './mixins/exportCSV'; 
import Numeroletra from './mixins/numeroletra'; 

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.mixin(Alert)
Vue.mixin(Numeroletra)
Vue.mixin(ExportCSV)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
