<template id="articulos" :per-page="perPage" :current-page="currentPage">
    <div v-b-scrollspy:scrollspy-nested >
        
        <b-list-group-item href="#" class="flex-column align-items-start col-md-12 mousehoverItem" 
            id="scrollspy-nested" style="line-height: 2; max-height:calc(52vh); overflow-y:auto"
            v-for="(item, index) in itemsPaginated" v-bind:key="index">
            <b-row >
                <b-col class="col-md-1" >
                    <b-row >
                        <b-col style="font-size: 3rem;" cols="12" class="p-0 pt-2" >
                            <h5 style="font-size: 3rem;" class="text-center w-100 h-100" >{{ items.indexOf(item) + 1 }}</h5>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-9">
                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{ item.equivalencia }}</h5>
                    </div>
                    <p class="mb-1">
                        {{ item.unidad_inicial }} A {{ item.unidad_destino }}
                    </p>
                </b-col>
                <b-col class="col-md-2">
                    <b-row class="w-100 h-100">
                        
                        <Boton :tooltip="`Editar`"
                            icon="pencil-square" fontscale="1" :onClic="clicItem" :valorEventClic="item"
                            variant="primary" btn="btn-default" size="sm" />
                    </b-row>
                </b-col>
            </b-row>
        </b-list-group-item>
        <b-col class="col-md-12">
            <!-- paginación -->
            <div class="mt-3 justify-content-md-center" v-show="items.length > 0">
                <b-pagination v-model="currentPage" :total-rows="items.length" align="center" 
                    :per-page="perPage"
                    aria-controls="articulos">
                </b-pagination>
            </div>
        </b-col>
    </div>
</template>
<script>
    // import { mapState } from "vuex";
    import Boton from '@/components/Boton'
    export default {
        props:[ "items", "clicItem", "verImagen" ],
        data() {
            return {
                perPage: 7,
                currentPage: 1
            };
        },
        components:{ Boton },//Boton
        computed:{
            // ...mapState([ ]),
            // paginación
            itemsPaginated(){ 
                return this.items.slice(
                    (this.currentPage - 1) * this.perPage,
                    this.currentPage * this.perPage
                )
            }
        },
        methods:{
            // muestra los primeros 172 caracteres del texto
            toggleText(text) {
                let res = "";
                let value = (text.length >= 172) ? 172 : (text.length / 2);
                for (let i = 0; i < value; i++) { res += text[i]; }
                return res += " ...";
            },
        }
    };
</script>
<style scoped>
</style>