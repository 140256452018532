import { HTTP } from "./http-common";
//import auth from "../store/auth";
export const categoria_list = (id_usuario) => new Promise ((rv, rj) => {
    HTTP.get("categoria-list/?id_usuario=" + id_usuario).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const articulo_list = (id_usuario, id_subcategoria) => new Promise ((rv, rj) => {
    HTTP.get("articulo-list/?id_usuario=" + id_usuario + "&id_categoria=" + id_subcategoria).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const articulo_get = (id_usuario, id_articulo) => new Promise ((rv, rj) => {
    HTTP.get("articulo-get/?id_usuario=" + id_usuario + "&id_articulo=" + id_articulo).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const cliente_list = (id_usuario) => new Promise ((rv, rj) => {
    HTTP.get("cliente-list/?id_usuario=" + id_usuario).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const pos_detail_create = (id_venta) => new Promise ((rv, rj) => {
    HTTP.post("pos-detail-create/", id_venta).then(
        res=>{
            rv(res)
        },
        err=>{
            rj(err)
        })
});
export const pos_header_create = (id_venta) => new Promise ((rv, rj) => {
    HTTP.post("pos-header-create/", id_venta).then(
        res=>{
            rv(res)
        },
        err=>{
            rj(err)
        })
});
