<template>
  <b-modal id="buscarVenta" v-model="modalShow" hide-footer size="md" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="BÚSQUEDA DE VENTA/COTIZACIÓN" title-class="modal-title w-100" body-class="p-4" >
    <b-row>

      <b-col class="col-md-12" >
        <b-form-group id="input-group-1" label="FOLIO:" label-class="form-label" label-for="input-1"  class="mb-0">
          <b-form-input v-on:keyup.enter="buscarVenta" @focus="$event.target.select()"
          type="number" v-model.number="folio" ></b-form-input>

          <b-row align-h="center" align-w="center" class="mt-2">
              <Boton tooltip="Realizar la búsqueda" class="mr-1"
              icon="search" fontscale="2" :onClic="buscarVenta" variant="info" btn="btn-secundary" size="sm"/>

              <Boton tooltip="Realizar búsqueda por código" class="ml-1"
              icon="camera" fontscale="2" :onClic="showCamara" variant="info" btn="btn-default" size="sm"/>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col class="col-md-12 mt-3" v-if="camara">
        <StreamBarcodeReader
            @decode="onDecode"
            @loaded="onLoaded"
        ></StreamBarcodeReader>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12" class="text-right">
        <Boton text="Cerrar" class="mr-3"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/>
        <!-- <Boton text="Cancelar" tooltip="Cancelar"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/> -->
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  import { StreamBarcodeReader } from "vue-barcode-reader";
  export default {
    mounted(){
    },
    components:{ Boton, StreamBarcodeReader },
    data() {
      return { modalShow: false, folio: "", camara: false };
    },
    watch: {
      // listener de folio
      "caja.cajaCarrito": function () {
        if(this.caja.cajaCarrito.length != 0){
          this.folio = "";
          this.cancel();
        }
      } 
    },
    computed:{
      ...mapState([ "caja", "auth", "contado"]),
    },
    methods:{
      ...mapActions(["articuloGetDirecto", "listVentaCaja"]),
      // cierra el modal
      cancel() {
        this.modalShow = false;
      },
      onDecode(result) {
        console.log("onDecode: " , result)
        this.cancel();
      },
      onLoaded(result) {
          console.log("onLoaded: " , result)
      },
      showCamara(){
        this.camara = !this.camara;
      },
      buscarVenta(){
        if (this.folio != ""){
            // console.log("asdsddd: " , this.buscarVenta);
            this.listVentaCaja(this.folio);
        }
      }
    },
  };
</script>
<style>
</style>