<template>
<b-row class="p-0 m-0 h-100" style="colorField-height:calc(100vh - FF0000);">
    <b-col class="col-md-9">

        <b-card header-tag="header" class="mt-3 card-mini">
         <Titulo  />
         
            <ItemsCobroCliente 
                :items="cobro.clientesAbonos"
                tituloItemsEmpty="Lista vacía" :collapse="true" :actions="true"
                SET_COMMIT_DELETE="SET_COMMIT_CARRITO_DELETE"
                SET_COMMIT_EDIT="SET_COMMIT_CARRITO_EDIT"
                idTemplate="productosCompra" :search="search"
            />

        </b-card>

    </b-col>
    <b-col class="col-md-3">
        <Cliente  />
    </b-col>
</b-row>
</template>

<script>
    import Titulo from "./Titulo";
    import Cliente from "./Cliente";
    import ItemsCobroCliente from '@/components/ItemsCobroCliente'
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
        },
        props:[ ],
        components:{ ItemsCobroCliente, Titulo, Cliente },
        data(){
            return{ search:'', }
        },
        computed: mapState([ "cobro" ]),
        methods:{
            ...mapActions(["updateTotalCarrito"])
        }
    }
</script>