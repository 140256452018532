<template id="articulos" :per-page="perPage" :current-page="currentPage">
    <div v-b-scrollspy:scrollspy-nested >


        <b-input-group class="mb-3 " v-if="itemsPaginated.length > 0" >
            <b-col class="col-md-9">
                <b-form-input @focus="$event.target.select()" v-model="search" autocomplete="off" type="text" class="form-control"
                placeholder="Buscar en productos..." aria-label="PRODUCTOS" aria-describedby="basic-addon"></b-form-input>
            </b-col> 

            <b-col class="col-md-3">
                <b-form-group id="clinica-list1" label-for="input-1" >
                    <b-form-select v-model="perPage" >
                        <b-form-select-option value="10">10</b-form-select-option>
                        <b-form-select-option value="30">30</b-form-select-option>
                        <b-form-select-option value="50">50</b-form-select-option>
                        <b-form-select-option
                            v-if="itemsPaginated.length > 50"
                            :value="itemsPaginated.length">{{ itemsPaginated.length }}</b-form-select-option>
                    
                    </b-form-select>
                </b-form-group>
            </b-col> 
        </b-input-group>


        <!-- <b-col class="col-md-12 " > -->
        <b-list-group-item href="#" class="flex-column align-items-start col-md-12 mousehoverItem" 
            id="scrollspy-nested" style="line-height: 2; max-height:calc(52vh); overflow-y:auto"
            v-for="(item, index) in itemsPaginated" v-bind:key="index">
            <b-row >
                <b-col class="col-md-1" >
                    <b-row >
                        <b-col style="font-size: 3rem;" cols="12" class="p-0 pt-2" >
                            <h1 style="font-size: 3rem;" class="text-center w-100 h-100" >{{ items.indexOf(item) + 1 }}</h1>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col class="col-md-11" >
                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">{{ item.clave }}</h5>
                        <small class="text-muted">{{ item.fecha_ultima_modificacion }} ultima actualización</small>
                        <small class="text-muted">${{ item.costo }} precio actual</small>
                    </div>

                    <p class="mb-1">
                        {{ item.descripcion }}
                    </p>

                    <b-input-group class="mt-3">
                        <b-form-input @focus="$event.target.select()" required placeholder="Precio"
                        v-model="item.precio2" type="number" ></b-form-input>

                        <b-button v-b-tooltip.hover type="button" size="sm" class="btn-secundary"
                            @click="clicArticulo(item)" :disabled="item.precio2 == null || item.precio2 == '' || item.precio2 <= 0">
                            <b-col style="color:#ffff;" cols="12" >Aplicar</b-col>
                        </b-button>

                    </b-input-group>

                </b-col>
            </b-row>
        </b-list-group-item>
        <!-- </b-col> -->
        <b-col class="col-md-12">
            <!-- paginación -->
            <div class="mt-3 justify-content-md-center" v-show="items.length > 0">
                <b-pagination v-model="currentPage" :total-rows="items.length" align="center" 
                    :per-page="perPage"
                    aria-controls="articulos">
                </b-pagination>
            </div>
        </b-col>
    </div>
</template>
<script>
    import moment from 'moment'
    // import { mapState } from "vuex";
    export default {
        props:[ "items", "clicArticulo" ],
        data() {
            return {
                perPage: 10,
                search:"",
                currentPage: 1
            };
        },
        components:{  },//Boton
        computed:{
            // busqueda dentro del array
            searchInDetail(){
                return this.items.filter(req => this.search.toLowerCase()
                .split(' ').every(v => this.conditionalFilter(v, req)))
            },
            // ...mapState([ ]),
            // paginación
            itemsPaginated(){ 
                return this.searchInDetail.slice(
                    (this.currentPage - 1) * this.perPage,
                    this.currentPage * this.perPage
                )
            }
        },
        methods:{
            conditionalFilter(v, req){
                for (const prop in req) {
                    if(req[prop] !== null){
                        if(req[prop].toString().toLowerCase().includes(v)){
                            return true;
                        }
                    }
                }
            },
            // muestra los primeros 172 caracteres del texto
            toggleText(text) {
                let res = "";
                let value = (text.length >= 172) ? 172 : (text.length / 2);
                for (let i = 0; i < value; i++) { res += text[i]; }
                return res += " ...";
            },
            formatFecha(value){
                if(value){
                    return moment(value).format('YYYY-MM-DD')
                }
                return "";
            },
        }
    };
</script>
<style scoped>
</style>