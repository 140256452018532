import { orden_compra_create, orden_compra_detail_create, orden_compra_autogenerate_list } from '@/api/ordencompra'
import { proveedores_list } from '@/api/proveedor' 
import Vue from 'vue'
import moment from 'moment'

export const actions = {
    
    // lista de CompraAutogenerateList
    ordenCompraAutogenerateList: async({ rootState, commit }) => orden_compra_autogenerate_list(rootState.auth.user.idUser).then(//, dispatch
        res=>{
            res.data.forEach(element => {
                element.cantidad = element.orden;
                element.precio = 0;
                element.importe = "0.00";
                element.id_unidad_solicitada = "";
                
            });
            console.log("res.ssss: " , res.data);
            commit("SET_COMMIT_COMPRA_AUTOGENERATE", res.data);
        }).catch(err=>console.log(err)
    ),

    //lista de proveedores
    proveedorListCompraInventario: async({ rootState, commit }) => proveedores_list(rootState.auth.user.idUser).then(//, dispatch
        res=>{
            commit("SET_COMMIT_PROVEEDORES_LIST1", res.data);
        }).catch(err=>console.log(err)
    ),
    //guarda el orden de compra
    ordenCompraCreateInventario: async({ dispatch, rootState }, datos) => orden_compra_create(datos).then(
        res=>{
            // console.log("ordenCompraCreate: " , res.data);

            if(rootState.ordencomprainventario.cajaCompra.length !== 0 && res.data.id_ordern_compra){
                let dataDetailCompra = new Array();
                let i = 0;
                rootState.ordencomprainventario.cajaCompra.forEach(element => {
                    if(element.id_unidad != "" && element.id_unidad != "0" ){
                    // if(element.precio != "" && element.precio != "0" ){
                        let datoMientras = {
                            id_articulo: element.id_articulo,
                            id_orden_compra: res.data.id_ordern_compra,
                            cantidad_solicitada: element.cantidad, 
                        
                            id_unidad_solicitada: element.id_unidad,
                            precio: element.precio,
                            id_usuario: rootState.auth.user.idUser
                        };
                        dataDetailCompra[i] = datoMientras;
                        i ++;
                    }
                });
                setTimeout(async ()=>{
                    // console.log("datoMientras: " , datoMientras);
                    dispatch("ordenCompraDetailCreateInventario", { ordenes: dataDetailCompra });
                },800)
            }
        }).catch(err=>console.log(err)
    ),
    //guarda el detail de orden de compra
    ordenCompraDetailCreateInventario: async({ commit, rootState }, datos) => orden_compra_detail_create(datos).then(
        res=>{
            // console.log("Inventario: " , res.data);
            commit("SET_MESSAGE_ORDEN_COMPRA",{mensaje: res.data[0].message,
                clase: (res.data[0].message === "EXITO") ? "toast-success" : "toast-danger"})
            if(res.data[0].message === "EXITO"){
                let vm = new Vue();
                let dataDetailCompra = new Array();
                let i = 0;
                rootState.ordencomprainventario.cajaCompra.forEach(element => {
                    if(element.id_unidad != "" && element.id_unidad != "0" ){
                        let datoMientras = {
                            codigo: element.clave,
                            cantidad: element.cantidad,
                            unidad: element.unidad,
                            descripcion: element.descripcion,
                        };
                        dataDetailCompra[i] = datoMientras;
                        i ++;
                    }
                });
                vm.toExportExcelCSV(dataDetailCompra, "orden_compra_" + moment().format('YYYY_MM_DD') + "_" + res.data[0].id_ordern_compra)
            }
            commit("SET_COMMIT_COMPRA_CLEAR");
        }).catch(err=>console.log(err)
    ),
    // actualiza el total de la compra
    updateTotalCompraOrdenInventario:({ rootState, commit})=>{
        let importe_ = 0.00;
        if(rootState.ordencomprainventario.cajaCompra.length !== 0){
            rootState.ordencomprainventario.cajaCompra.forEach(element => {
                if (typeof element.importe !== 'undefined') {
                    console.log("element.importe: ", element.importe);
                    importe_ += Number(element.importe.replace(/[^0-9.-]+/g,""));
                }
            });
        }
        let resTotal = parseFloat(importe_);
        let resImporte = parseFloat(resTotal / 1.16);
        let resIva = parseFloat(resTotal - resImporte);

        commit("SET_COMMIT_TOTAL_ORDENCOMPRAINVENTARIO", {
            subtotal: resImporte.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            iva: resIva.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'),
            total: resTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        })
    },
};