import { HTTP } from "./http-common";
export const categoria_list = (id_usuario) => new Promise ((rv, rj) => {
    HTTP.get("categoria-list/?id_usuario=" + id_usuario).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const subcategoria_list = (id_usuario, id_categoria) => new Promise ((rv, rj) => {
    HTTP.get("subcategoria-list/?id_usuario=" + id_usuario + "&id_categoria=" + id_categoria).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const articulo_list = (id_usuario, id_subcategoria) => new Promise ((rv, rj) => {
    HTTP.get("articulo-list/?id_usuario=" + id_usuario + "&id_categoria=" + id_subcategoria).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const articulo_get = (id_usuario, id_articulo) => new Promise ((rv, rj) => {
    HTTP.get("articulo-get/?id_usuario=" + id_usuario + "&id_articulo=" + id_articulo).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const unidad_list = (id_usuario, id_articulo) => new Promise ((rv, rj) => {
    HTTP.get("unidad-list/?id_usuario=" + id_usuario + "&id_articulo=" + id_articulo).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const unidad_get = (id_usuario, id_unidad) => new Promise ((rv, rj) => {
    HTTP.get("unidad-get/?id_usuario=" + id_usuario + "&id_unidad=" + id_unidad).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const precio_venta_list = (id_usuario, id_articulo, id_unidad, id_sucursal) => new Promise ((rv, rj) => {
    HTTP.get("precio-venta-list/?id_usuario=" + id_usuario + "&id_articulo=" + id_articulo
    + "&id_unidad=" + id_unidad + "&id_sucursal=" + id_sucursal).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const pos_header_createContado = (data) => new Promise ((rv, rj) => {
    HTTP.post("pos-header-create/", data).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const pos_detail_createContado = (data) => new Promise ((rv, rj) => {
    HTTP.post("pos-detail-create/", data).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const articulo_get_directo = (id_usuario, id_articulo) => new Promise ((rv, rj) => {
    HTTP.get("articulo-get/?id_usuario=" + id_usuario + "&id_articulo=" + id_articulo).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const venta_tipo_pago_create = (data) => new Promise ((rv, rj) => {
    HTTP.post("venta-tipo-pago-create/", data).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});
export const corte_caja_list = (id_usuario, fecha) => new Promise ((rv, rj) => {
    HTTP.get("corte-caja-list/?id_usuario=" + id_usuario + "&fecha=" + fecha).then(
    res=>{ rv(res) },
    err=>{ rj(err) })
});

