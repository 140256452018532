<template>
    <div class="m-0">
        <h3><strong class="">{{item.nombre}}</strong></h3>
        <b-row>
            <b-col class="col-md-6">
                <b-row class="m-0">
                    <b-img fluid class="mousehoverItem" width="250" height="250"
                        :src="item.imagen ? 'data:image/jpeg;base64,' + item.imagen : require('@/assets/img/nodisponible.png')" 
                    ></b-img>
                </b-row>
            </b-col>
            <b-col class="col-md-6 ">
                <b-row align-v="start" align-h="start" >
                    <h5 style="padding-right: 6px;" ><strong>NOMBRE: </strong></h5>
                    {{item.nombre}}
                </b-row>
                <b-row align-v="start" align-h="start" >
                    <h5 style="padding-right: 6px;" ><strong>CLAVE: </strong></h5>
                    {{item.clave}}
                </b-row>
                <b-row align-v="start" align-h="start" >
                    <h5 style="padding-right: 6px;" ><strong>DESCRIPCIÓN: </strong></h5>
                    {{item.descripcion}}
                </b-row>
                <b-row align-v="start" align-h="start" >
                    <h5 style="padding-right: 6px;" ><strong>EXISTENCIA:</strong></h5>
                    {{item.existencia}} {{ item.unidad }}
                </b-row>
                <b-row align-v="start" align-h="start" >
                    <h5 style="padding-right: 6px;" ><strong>PESO PROMEDIO:</strong></h5>
                    {{caja.pesopromedio}}
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-md-6 col-sm-6 col-xl-3 " style="padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">
                <b-form-group id="input-group-1" label="Unidad:" label-size="10rem" label-for="input-1"  class="mr-1">
                    <b-form-select v-model="unidad_id" :options="caja.unidadesList"
                    class="mb-3" value-field="id_unidad" text-field="unidad" disabled-field="notEnabled" ></b-form-select>
                </b-form-group>
                <!-- <Autocomplete class="m-1" placeholder="Selecciona una opción" cols="col-md-12"
                :items="caja.unidadesList" text="unidad" v-model="unidad_id" assign="id_unidad" label="Unidad:" filter="unidad" /> -->
            </b-col>
            <b-col class="col-md-6 col-sm-6 col-xl-3 " style="padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">
                <b-form-group id="input-group-1" label="Cantidad:" label-for="input-1" class="mr-1">
                    <b-input-group>
                        <b-input-group-prepend>
                            <b-button @click="cantidad > 1 ? cantidad-- : cantidad=1">-</b-button>
                        </b-input-group-prepend>

                        <b-form-input @focus="$event.target.select()" v-model.number="cantidad" max="10000" type="number" min="1"></b-form-input>

                        <b-input-group-append>
                            <b-button @click="cantidad++">+</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col class="col-md-6 col-sm-6 col-xl-3 " style="padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">
                <b-form-group v-if="precioCampo" id="input-group-1" label="Precio:" label-for="input-1" class="mr-1">
                    <b-form-input @focus="$event.target.select()" id="input-small" v-model="precio" ></b-form-input>
                </b-form-group>
            
                <Autocomplete v-if="!precioCampo" class="mr-1" placeholder="Selecciona una opción" cols="col-md-12"
                    :search="false" :items="caja.precioVentaList" text="precio" v-model="precio" assign="id" label="Precio:" filter="precio" />
            </b-col>
            <b-col v-show="descuentoComponent" class="col-md-6 col-sm-6 col-xl-3 " style="padding-left: 0px; padding-right: 0px; margin-left: 0px; margin-right: 0px;">
                <b-form-group id="input-group-1" label="Descuento:" label-for="input-1" class="mr-1">
                    <b-input-group>
                        <b-input-group-prepend>
                            <b-button @click="descuento > -factor_pv ? descuento = descuento-1 : descuento=descuento">-</b-button>
                            <!-- <b-button @click="descuento = descuento-1">-</b-button> -->
                        </b-input-group-prepend>

                        <b-form-input @focus="$event.target.select()" v-model.number="descuento" type="number" ></b-form-input>

                        <b-input-group-append>
                            <b-button @click="descuento < factor_pv ? descuento = descuento+1 : descuento=descuento">+</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

        </b-row>
        <b-row align-v="end" align-h="end" class="m-0">
            <Boton :disabled="cantidad===0 || unidad_id===0 || precio===0" :onClic="add" :valorEventClic="{item, cantidad, descuento, precio, unidad: unidad_id}"
            class="mr-2" text="Guardar" tooltip="Guardar" icon="file-earmark-check" fontscale="4" variant="success" btn="btn-secundary" size="md"/>
            
            <Boton :onClic="cancelar" class="mr-2" text="Cancelar" tooltip="Cancelar" icon="x-circle-fill" fontscale="4" variant="danger" btn="btn-cancel" size="md"/>
        </b-row>
        <ModalCambioPrecio />
    </div>
</template>
<script>
    import router from '@/router'
    import ModalCambioPrecio from "../pages/caja/ModalCambioPrecio"
    import Autocomplete from '@/components/Autocomplete'
    import Boton from '@/components/Boton'
    import { mapState, mapActions } from "vuex";
    export default {
        mounted(){
            this.unidadList(this.item.id_articulo);
            // console.log("item: " , this.item);
        },
        props:[ "precioCampo", "descuentoComponent", "item", "cancelar", "addToCarrito" ],
        components:{ Boton, Autocomplete, ModalCambioPrecio },
        methods:{
            ...mapActions(["unidadList", "precioVentaList"]),
            // valida que no exista el articulo en el carrito
            existe(element){
                // valida que si esta en orden compra verifique el array de orden de compra
                if(this.isRoute('/ordencompra/crear')){
                    let searchRes = this.ordencompra.cajaCompra.filter(item=>item.id_articulo === element.id_articulo)
                    return searchRes[0]
                }else{
                    let searchRes = this.caja.cajaCarrito.filter(item=>item.id_articulo === element.id_articulo)
                    return searchRes[0]
                }
            },
            // el parametro es de valorEventClic
            add(item){
                //let _descuento = this.descuento;
                let existe = this.existe(item.item);
                if(!existe){
                    let _precio = { precio: 0 };

                    if(this.isRoute('/ordencompra/crear') || this.isRoute('/ordencomprainventario/crear')){
                        _precio.precio = item.precio;
                    }else{
                        _precio = this.getPrecio(item.precio);
                    }
                    // se agrego esto porque requieren teclear el precio
                    if(this.caja.precioTecleado != ""){
                        item.precio = this.caja.precioTecleado;
                        _precio.precio = this.caja.precioTecleado;
                    }

                    if (item.descuento === 0 ) {
                        item.precio = _precio.precio;
                    } else {
                        let desc = item.descuento / 100;
                        let res = _precio.precio * desc;
                        res = _precio.precio - res;
                        // console.log("res: " , res);
                        item.precio = this.restrictDecimal(res);
                    }

                    item.importe = this.restrictDecimal(item.cantidad * item.precio);

                    let unidad = this.getUnidad(item.unidad);
                    item.unidad = unidad;

                    item.datosArticulo = _precio;
                    item.datosPrecios = this.caja.precioVentaList;
                    item.datosPrecios = item.datosPrecios.filter(function(item) {
                        return item["precio"] !== "Capturar"
                    })

                    this.addToCarrito(item);
                    this.cancelar();

                    // campos nuevos para cuando se teclee
                    this.precioTecleado = "";

                }else{
                    this.generateAlert("Ya existe este artículo", "toast-danger");
                }
            },
            isRoute(ruta){
                return router.history.current.path == ruta;
            },
            getPrecio(id){
                let searchRes = this.caja.precioVentaList.filter(item=>item.id === id)
                return searchRes[0]
            },
            getUnidad(id){
                let searchRes = this.caja.unidadesList.filter(item=>item.id_unidad === id)
                return searchRes[0]
            },
            // limpia cantidad y solo deja 2 digitos
            restrictDecimal(val) {
                let res = parseFloat(val);
                return res.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            },
        },
        watch:{
            // es el onchangue de UNIDAD
            "unidad_id"(val){
                if(val){ this.precioVentaList({id_articulo: this.item.id_articulo, id_unidad:val}); }
                this.precio = 0;
            },
            "precio"(val){
                if(val == 1){
                    this.factor_pv = this.item.factor_pv_1;
                }else if(val == 2){
                    this.factor_pv = this.item.factor_pv_2;
                }else if(val == 3){
                    this.factor_pv = this.item.factor_pv_3;
                }else if(val == 4){
                    this.factor_pv = this.item.factor_pv_4;
                }else if(val == 5){
                    if(this.isRoute('/caja')){
                        this.factor_pv = this.item.factor_pv_4;
                        this.$bvModal.show('modalCambioPrecio');
                    }
                }
                this.descuento = 0;
            }
    //         cantidad(val){
    //             if(val < 10)
    //                 this.cantidad = 1;
    //             else if(val > 10000)
    //                 this.cantidad = 10000;
    // }
        },
        computed: mapState([ "caja", "ordencompra" ]),
        data(){
            return{
                cantidad: 1,
                descuento: 0,
                unidad_id: 0,
                precio: 0,
                factor_pv: 0,
            }
        }
    }
</script>
<style scoped>
    /* .input-group-prepend{ */
        /* background-color: blue !important; */
        /* display: none !important; */
    /* } */
</style>