<template>
  <b-modal id="modalEjemplo" v-model="modalShow" hide-footer size="lg" hide-header-close
    :no-close-on-backdrop="true" :no-close-on-esc="true"  title="PAGO" title-class="modal-title w-100" body-class="p-4" >
    <b-row>
        <b-col class="col-md-6">
        <!-- <b-row > -->
          <b-col class="col-md-12" >
            <b-form-group id="input-group-1" label="EFECTIVO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" prepend="$">
                <b-form-input @focus="$event.target.select()" type="number" v-model.number="dataModal.efectivo" v-on:keyup="onchangueEfectivo" min="0.00"></b-form-input>
                <!-- <b-form-spinbutton v-model.number="dataModal.efectivo" inline></b-form-spinbutton> -->
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="CHEQUE:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" prepend="$">
                <b-form-input @focus="$event.target.select()" type="number" v-model.number="dataModal.cheque" v-on:keyup="onchangueCheque" min="0.00"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="TARJETA:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group class="m-0" size="sm" prepend="$">
                <b-form-input @focus="$event.target.select()" type="number" v-model.number="dataModal.tarjeta" v-on:keyup="onchangueTarjeta" min="0.00"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="TOTAL RECIBIDO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group disabled class="m-0" size="sm" prepend="$">
                <b-form-input @focus="$event.target.select()" v-model="dataModal.totalRecibido" disabled ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        <!-- </b-row> -->
      </b-col>
      <b-col class="col-md-6">
          <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="TOTAL VENTA:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group disabled class="m-0" size="lg" prepend="$">
                <b-form-input @focus="$event.target.select()" v-model="caja.totalCarrito.total" disabled ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-12">
            <b-form-group id="input-group-1" label="CAMBIO:" label-class="form-label" label-for="input-1"  class="mb-0">
              <b-input-group disabled class="m-0" size="lg" prepend="$">
                <b-form-input @focus="$event.target.select()" v-model="dataModal.cambio" disabled ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="col-md-12 mt-2">
            {{totalTexto}}
          </b-col>
          
          <b-col class="col-md-12 mt-2" v-if="contado.tipoVenta == 4">
            <b-form-checkbox v-model="venta.facutura" size="lg" cursor="pointer;" name="check-button" switch>
              Factura
            </b-form-checkbox>
          </b-col>

      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12" class="text-right">

        <Boton text="Imprimir" tooltip="Realizar venta" class="mr-3"
        :disabled="dataModal.totalRecibido < caja.totalCarrito.total"
        icon="lock-fill" fontscale="2" :onClic="realizarVenta" variant="info" btn="btn-secundary" size="sm"/>

        <Boton text="Cancelar" tooltip="Cancelar venta"
        icon="x-circle-fill" fontscale="2" :onClic="cancel" variant="info" btn="btn-cancel" size="sm"/>
        
      </b-col>
    </b-row>

  </b-modal>
</template>
<script>
  import { mapState, mapActions } from "vuex";
  import Boton from '@/components/Boton'
  export default {
    mounted(){
      this.$store.commit("SET_COMMIT_EFECTIVO", 0);
      this.$store.commit("SET_COMMIT_CHEQUE", 0);
      this.$store.commit("SET_COMMIT_TARJETA", 0);
      this.totalTexto = this.NumeroALetras(this.clearDigit(this.caja.totalCarrito.total), {
        plural: 'PESOS',
        singular: 'PESO',
        centPlural: 'CENTAVOS',
        centSingular: 'CENTAVO'
      });
      this.$store.commit("SET_COMMIT_TIPO_VENTA", 1);
    },
    components:{ Boton },
    data() {
      return {
        totalTexto:"",
        modalShow: false,
        dataModal: {
          efectivo:0,
          cheque:0,
          tarjeta:0,
          totalRecibido:0,
          cambio: 0
        },
        loading: false
      };
    },
    watch: {
      "dataModal.efectivo": function (val) {
        this.$store.commit("SET_COMMIT_EFECTIVO", val);
        // console.log("efectivo; " , val)
      //   this.dataModal.totalRecibido += val;
      //   this.dataModal.cambio = this.dataModal.totalRecibido - this.clearDigit(this.caja.totalCarrito.total);
      },
      "dataModal.cheque": function (val) {
        this.$store.commit("SET_COMMIT_CHEQUE", val);
        // console.log("cheque; " , val)
      //   this.dataModal.totalRecibido += val;
      //   this.dataModal.cambio = this.dataModal.totalRecibido - this.clearDigit(this.caja.totalCarrito.total);
      },
      "dataModal.tarjeta": function (val) {
        this.$store.commit("SET_COMMIT_TARJETA", val);
        // console.log("tarjeta; " , val)
      //   this.dataModal.totalRecibido += val;
      //   this.dataModal.cambio = this.dataModal.totalRecibido - this.clearDigit(this.caja.totalCarrito.total);
      },
        // despues de actualixar vairbale messaje muestra el mensaje
        "caja.messageCaja": function (val) {
          // muestra alerta
          this.generateAlert(val.mensaje, val.clase);
          if(val.clase == "toast-success"){
            // limpia carrito 
            this.$store.commit("SET_COMMIT_CARRITO_CLEAR");
            // limpia cliente seleccionado
            this.$store.commit("SET_COMMIT_CLIENTE_SELECTED", {nombre_razon_social:"No Seleccionado", limite_credito: "0.00"})
            this.cancel();
          }
        }
        
    },
    computed:{
      ...mapState([ "caja", "auth", "contado", "venta"]),
    },
    methods:{
      ...mapActions([ "headerCreateContado" ]),
      restrictDecimal() {
          let cambio = parseFloat(this.dataModal.cambio);
          this.dataModal.cambio = cambio.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
          },
      onchangueEfectivo(){
        if(this.dataModal.efectivo < 0){
          this.dataModal.efectivo = 0;
          return false;
        }
        this.dataModal.totalRecibido = this.dataModal.efectivo + this.dataModal.cheque + this.dataModal.tarjeta;
        this.dataModal.cambio = this.dataModal.totalRecibido - this.clearDigit(this.caja.totalCarrito.total);
        this.restrictDecimal();
        // console.log("efectivo: " , this.dataModal.efectivo);
      },
      onchangueCheque(){
        this.dataModal.totalRecibido = this.dataModal.efectivo + this.dataModal.cheque + this.dataModal.tarjeta;
        this.dataModal.cambio = this.dataModal.totalRecibido - this.clearDigit(this.caja.totalCarrito.total);
        this.restrictDecimal();
        // console.log("cheque: " , this.dataModal.cheque);
      },
      onchangueTarjeta(){
        this.dataModal.totalRecibido = this.dataModal.efectivo + this.dataModal.cheque + this.dataModal.tarjeta;
        this.dataModal.cambio = this.dataModal.totalRecibido - this.clearDigit(this.caja.totalCarrito.total);
        this.restrictDecimal();
        // console.log("tarjeta: " , this.dataModal.tarjeta);
      },
      // cierra el modal
      cancel() {
        this.modalShow = false;
        this.loading = false;
        this.dataModal = { efectivo:0, cheque:0, tarjeta:0, totalRecibido:0, cambio: 0};
        this.$store.commit("SET_COMMIT_TIPO_VENTA", 1);
        return this.modalShow;
      },
      // setea todo el array
      setDetailRequisicionArray() {
        this.loading = true;
        
        this.cancel();
      },
      clearDigit(value){
        return value.replace(/[^0-9.-]+/g,"");
      },
      realizarVenta(){
        // console.log("total: " , this.clearDigit(this.caja.totalCarrito.total));
            
        let data = {
          tipo_venta: this.contado.tipoVenta,
          // cobrado: this.dataModal.totalRecibido,
          cobrado: 1,
          // total: this.caja.totalCarrito.total,
          total: this.clearDigit(this.caja.totalCarrito.total),
          id_cliente: this.contado.clienteSelectedVar.id_cliente,
          estatus: 1,
          id_sucursal: this.auth.user.id_sucursal,
          id_usuario: this.auth.user.idUser
        }
        this.headerCreateContado(data);

      }
    },
  };
</script>
<style>
</style>